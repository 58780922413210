import * as React from 'react';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { SetYourLocation } from '../../components/set-your-location';
import { ApiDate } from 'core';
import {
  CurrentRespondent,
  CurrentUser,
  useCurrentRespondent,
} from 'client/respondent/hooks';
import { SnackbarMessageProps } from 'client/shared/hooks';
import { WellType } from 'client/shared/components/base';
import moment from 'moment';
import { QueryInfos } from 'client/shared/graphql-queries';

interface Props {
  readonly className?: string;
  readonly onSaveLocation: () => void;
  readonly onSkip?: () => void;
  readonly setSnackbarMessage: (msg: SnackbarMessageProps) => void;
  readonly showSkipButton?: boolean;
}

const TODAY_API_DATE = ApiDate.toApi(moment().startOf('day').toDate());

const buildInitialCenter = (respInfo: CurrentUser | null) => {
  if (respInfo?.location?.latLng) {
    return {
      lat: respInfo.location.latLng.lat,
      lng: respInfo.location.latLng.lng,
    };
  }
  if (
    respInfo?.user?.respondent?.inputtedLat &&
    respInfo?.user?.respondent?.inputtedLon
  ) {
    return {
      lat: respInfo.user.respondent.inputtedLat,
      lng: respInfo.user.respondent.inputtedLon,
    };
  }
  return undefined;
};

export const LocationContainer: React.FC<Props> = (props) => {
  const currentUser = useCurrentRespondent();
  const respInfo =
    CurrentRespondent.RESPONDENT.isA(currentUser) ||
    CurrentRespondent.GUEST_RESPONDENT.isA(currentUser)
      ? currentUser
      : null;
  const updateProfileMut = useMutationInfo(MutationInfos.updateRespondentProfile);

  const onSaveLocation = React.useCallback(
    async (args: {
      readonly center: google.maps.LatLngLiteral;
      readonly zipCode: string;
    }) => {
      if (!respInfo?.user?.respondent?.id) {
        props.setSnackbarMessage({
          type: WellType.ICON_ERROR,
          message: 'The current user is not valid',
        });
        return;
      }

      const { center, zipCode: zipCodeAttached } = args;
      const res = await updateProfileMut.fn({
        variables: {
          respondentId: respInfo.user.respondent.id,
          input: {
            inputtedLat: center.lat,
            inputtedLon: center.lng,
            zipCode: zipCodeAttached,
            requestLocationLastShown: TODAY_API_DATE,
          },
        },
        refetchQueries: [QueryInfos.currentRespondent.refetchInfo({})],
      });

      if (res.data) {
        props.onSaveLocation();
      } else {
        props.setSnackbarMessage({
          type: WellType.ICON_ERROR,
          message: 'There was an error trying to save the location',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [respInfo?.user?.respondent?.id]
  );

  const onSkipLocation = React.useCallback(async () => {
    if (!respInfo?.user?.respondent?.id) {
      props.setSnackbarMessage({
        type: WellType.ICON_ERROR,
        message: 'The current user is not valid',
      });
      return;
    }

    const res = await updateProfileMut.fn({
      variables: {
        respondentId: respInfo.user.respondent.id,
        input: {
          requestLocationLastShown: TODAY_API_DATE,
        },
      },
    });

    if (res.data) {
      props.onSkip && props.onSkip();
    } else {
      props.setSnackbarMessage({
        type: WellType.ICON_ERROR,
        message: 'There was an error trying to save the last shown date',
      });
    }
  }, [respInfo?.user?.respondent?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const initialCenter = React.useMemo(
    () => buildInitialCenter(respInfo),
    [respInfo?.user?.respondent?.id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <SetYourLocation
      center={initialCenter}
      onSave={onSaveLocation}
      onSkip={onSkipLocation}
      showSkipButton={!!props.showSkipButton}
      zipCode={respInfo?.location?.zip}
    />
  );
};
