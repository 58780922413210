import * as React from 'react';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { VerificationStep } from '../../components/verification-step';
import { VerificationFormInputs } from '../../types';
import { CurrentRespondent, useCurrentRespondent } from 'client/respondent/hooks';
import { SnackbarMessageProps } from 'client/shared/hooks';
import { WellType } from 'client/shared/components/base/well';

interface Props {
  readonly action: () => void;
  readonly isActiveSlide: boolean;
  readonly isFeed: boolean;
  readonly setSnackbarMessage: (msg: SnackbarMessageProps) => void;
}

export const VerificationStepContainer: React.FC<Props> = (p) => {
  const currentUser = useCurrentRespondent();
  const respInfo =
    CurrentRespondent.RESPONDENT.isA(currentUser) ||
    CurrentRespondent.GUEST_RESPONDENT.isA(currentUser)
      ? currentUser
      : null;

  const { fn: updateRespondentData } = useMutationInfo(
    MutationInfos.updateRespondentProfile
  );

  const submitForm = async (fields: VerificationFormInputs) => {
    if (!respInfo?.user?.respondent?.id) {
      p.setSnackbarMessage({
        type: WellType.ICON_ERROR,
        message: 'The current user is not valid',
      });

      return;
    }

    const res = await updateRespondentData({
      variables: {
        respondentId: respInfo.user.respondent.id,
        input: {
          firstName: fields.firstName,
          lastName: fields.lastName,
          zipCode: p.isFeed ? fields.zipCode : undefined,
        },
      },
    });
    if (res.data?.updateRespondent) {
      p.action();
    }
  };

  return (
    <VerificationStep
      cancel={p.action}
      initialProfile={{
        firstName: respInfo?.user?.respondent?.firstName ?? null,
        lastName: respInfo?.user?.respondent?.lastName ?? null,
        zipCode: respInfo?.user?.respondent?.zipCode ?? null,
      }}
      isFeed={p.isFeed}
      submitForm={submitForm}
      tabIndex={p.isActiveSlide ? 0 : -1}
    />
  );
};
