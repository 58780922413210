import {
  ContentUnavailableType,
  ContentUnavailableWell,
} from 'client/shared/components/content-unavailable';
import { SurveyItemSimulation } from 'client/shared/core/question';
import { simulationTypeLabel } from 'core';
import * as React from 'react';
import { defaultPadding } from '../util';

interface Props {
  readonly itemData: SurveyItemSimulation;
}

export const SurveySimulation: React.FC<Props> = (props) => {
  const simUrl = props.itemData.simulation?.url;
  const url = simUrl ? new URL(simUrl) : null;
  url?.searchParams?.append('embed', 'post');
  return (
    <div className={defaultPadding}>
      {url ? (
        <iframe
          src={url.toString()}
          style={{
            height: '1000px',
            width: '100%',
            border: '1px solid #c6cbc8',
            borderRadius: '8px',
          }}
          title="Simulation"
        />
      ) : (
        <ContentUnavailableWell
          contentTypeLabel={simulationTypeLabel[props.itemData.simulationType]}
          type={ContentUnavailableType.NOT_FOUND}
        />
      )}
    </div>
  );
};
