import * as React from 'react';
import { InputType } from 'client/shared/components/base/text-input';
import { Form, Col } from 'react-bootstrap';
import {
  ClickableDiv,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { RegisterOptions } from 'react-hook-form';
import { RBRef } from 'client/shared/core/types';
import { MIN_PASSWORD_LENGTH } from 'core';
import { useId } from 'react-use-id-hook';

import './styles.scss';

interface Props {
  readonly label: string | null;
  readonly submitted: boolean;
  readonly error: string | null;
  readonly createRef: (opts: RegisterOptions) => RBRef;
  readonly opts?: {
    readonly placeholder?: string;
    readonly maxLength?: number;
    readonly assistiveText?: string;
  };
}

const copy = {
  required: 'This field is required',
  minLength: `Password must be at least ${MIN_PASSWORD_LENGTH} characters`,
};

const baseClass = 'pn-form-password';

export const FormPassword: React.FC<Props> = (p) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const passwordControlId = useId();
  const passswordFeedbackId = useId();
  const isPasswordInvalid = !!p.error;

  return (
    <Form.Group as={Col}>
      {p.label && (
        <Form.Label className="font-size-m" htmlFor={passwordControlId}>
          {p.label}
        </Form.Label>
      )}
      <Form.Control
        aria-errormessage={isPasswordInvalid ? passswordFeedbackId : undefined}
        aria-invalid={isPasswordInvalid}
        aria-label={p.label ?? undefined}
        autoComplete="current-password"
        className={`rounded accessible-input ${
          p.error && p.submitted ? 'has-error' : ''
        }`}
        id={passwordControlId}
        maxLength={p.opts?.maxLength}
        name="password"
        placeholder={p.opts?.placeholder}
        ref={p.createRef({
          required: {
            value: true,
            message: copy.required,
          },
          minLength: {
            value: 5,
            message: copy.minLength,
          },
        })}
        required
        type={passwordVisible ? InputType.TEXT : InputType.PASSWORD}
      />
      {p.opts?.assistiveText ? (
        <Form.Text className="text-muted font-size-xs  justify-content-center">
          {p.opts?.assistiveText}
        </Form.Text>
      ) : null}
      <Form.Control.Feedback
        className={p.submitted ? 'd-block' : ''}
        id={passswordFeedbackId}
        type="invalid"
      >
        {p.error}
      </Form.Control.Feedback>
      <ClickableDiv
        action={() => setPasswordVisible(!passwordVisible)}
        className={`${baseClass}-clickable-div`}
      >
        <MaterialIcon
          className={`${baseClass}-eye-icon position-absolute`}
          icon={
            passwordVisible
              ? MaterialIconName.VISIBILITY_OFF
              : MaterialIconName.VISIBILITY
          }
        />
      </ClickableDiv>
    </Form.Group>
  );
};
