import * as React from 'react';
import { VisualizationPicker } from 'client/shared/components/visualization-picker';
import {
  SavedSurveyItem,
  SurveyItemType,
  scrollIdForSurveyItem,
} from 'client/shared/core/question';
import { defaultPadding } from '../util';
import _ from 'lodash';
import { extractVariableIdFromConcatenatedVisualizationId } from 'client/shared/helpers';

export interface SurveyVisualizationProps {
  readonly surveyItem: Extract<
    SavedSurveyItem,
    { readonly type: SurveyItemType.VISUALIZATION }
  >;
}

export const SurveyVisualization: React.FC<SurveyVisualizationProps> = (props) => {
  const [expandedVisualization, setExpandedVisualization] = React.useState(true);
  const handleToggleExpandedVisualization = () =>
    setExpandedVisualization(!expandedVisualization);
  const visualizationData = props.surveyItem.data;
  const { visualization } = visualizationData;

  const visualizationDataToPopulate = visualization
    ? {
        id: visualization.id,
        recordedAt: visualization.recordedAt,
        state: visualization.state,
        benchmarkFilter: visualization.benchmarkFilter,
        visualizationType: visualization.visualizationType,
        trackVariable: visualization.trackVariables[0],
        baseFips: visualization.baseFips,
        comparisonGroupId: visualization.comparisonGroupId,
        mapData: {
          fipsAreasShapes: visualization.placeShapeByFips,
        },
        publishingEntityId: visualization.publishingEntityId,
      }
    : null;

  const goalsByVariableId = _.keyBy(
    visualization?.plans[0]?.goals ?? [],
    (g) => g.variable.id
  );

  const goal = visualization
    ? goalsByVariableId[
        extractVariableIdFromConcatenatedVisualizationId(
          visualization.trackVariables[0].id,
          visualization.id
        )
      ]
    : undefined;
  return (
    <div
      className={`${defaultPadding}`}
      id={scrollIdForSurveyItem(visualizationData.id)}
    >
      <VisualizationPicker
        expanded={expandedVisualization}
        goal={goal}
        label={visualizationData.label ?? undefined}
        postEmbed
        toggleExpanded={handleToggleExpandedVisualization}
        visualizationData={visualizationDataToPopulate}
      />
    </div>
  );
};

SurveyVisualization.displayName = 'SurveyVisualization';
