import * as React from 'react';
import { LoaderBars } from 'client/shared/components/loader-bars';

import './styles.scss';

export interface Props {
  readonly className?: string;
}

/**
 * Provides a general page loader for the respondent page.
 * @param p A Props object that maybe contain a class name.
 */
export const RespondentPageLoader: React.FC<Props> = (p) => {
  return (
    <LoaderBars className={`pn-respondent-page-loader-bars ${p.className || ''}`} />
  );
};
RespondentPageLoader.displayName = 'RespondentPageLoader';
