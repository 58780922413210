// export interface SocialParams {
//   redirect_to: string;
//   success: string;
//   token: string;
// }

// NOTE: this is all copied from the legacy app, and only works in that context
// const providersMap = {
//   facebook: '/auth/facebook',
//   google: '/auth/google_login',
// };

export function serverOAuthLogin(_provider: 'facebook' | 'google') {
  // Server OAuth Login is currently not working, throw an error if a code path attempts to use this method
  throw new Error('Server-side OAuth is not supported.');

  // const socialParams: Partial<SocialParams> = {};
  // const url = providersMap[provider];
  // const req = new XMLHttpRequest();
  // req.open('GET', url + '?' + QueryString.stringify(socialParams), true);
  // req.send();
  // if (req.status !== 200) {
  //   errorLogger.log(
  //     `Error trying to OAuth Login with ${provider}:${req.responseText}`
  //   );
  // }
}
