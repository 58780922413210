import * as React from 'react';
import { Btn, ButtonTypes } from 'client/shared/components/base';
import { LEARN_MORE_COPY } from './copy';
import './styles.scss';

interface Props {
  readonly setShowOnboarding: (show: boolean) => void;
  readonly login: () => void;
}

const baseClass = 'pn-learn-more-prompt';

export const LearnMorePrompt: React.FC<Props> = (p) => {
  return (
    <div className={`${baseClass} w-100 px-3 bg-gray-5`}>
      <div className="text-gray-60 font-size-xs py-3 text-center">
        {LEARN_MORE_COPY.headerMessage}
      </div>
      <div className="mx-2 pb-3 text-center">
        <div className="d-flex pb-2 justify-content-center">
          <Btn
            action={() => {
              p.setShowOnboarding(true);
            }}
            ariaLabel={LEARN_MORE_COPY.learnMore}
            className={`${baseClass}-button btn-sm mx-2`}
            type={ButtonTypes.PRIMARY}
          >
            {LEARN_MORE_COPY.learnMore}
          </Btn>
        </div>
        <div
          className={`${baseClass}-bottom-text text-jungle font-size-xs d-flex align-items-center justify-content-center`}
        >
          {LEARN_MORE_COPY.userWithAccount}
          <Btn
            action={p.login}
            ariaLabel="Login page"
            className="ml-1"
            customPadding="p-1"
            size="small"
            type={ButtonTypes.SEAMLESS}
          >
            {LEARN_MORE_COPY.login}
          </Btn>
        </div>
      </div>
    </div>
  );
};
