import * as React from 'react';
import './styles.scss';

export interface Props {
  readonly text: string;
}

export const SeparatorWithText: React.FC<Props> = (p) => {
  return (
    <div className="pn-separator-with-text py-3 text-gray-20 text-center font-size-xs">
      <span className="mx-3 text-gray-40">{p.text}</span>
    </div>
  );
};
