import { serverOAuthLogin } from '../server-social-login';
import {
  SocialLoginData,
  SocialLoginType,
  SocialLoginMode,
} from 'client/respondent/core/types';
import { errorLogger } from 'client/shared/core/error-handler';
import { getIntegrationConfigKey } from 'client/shared/integrations';
import { isGAuthInitialized } from 'client/shared/integrations/google/core';

export function googleLogin(
  mode: SocialLoginMode,
  onSuccess: (data: SocialLoginData) => void,
  onFailure?: () => void
) {
  const clientId = getIntegrationConfigKey('googleClientId');
  if (!google.accounts) {
    errorLogger.log('Error: google accounts is not defined');
    return;
  }

  if (mode === SocialLoginMode.CLIENT && clientId && isGAuthInitialized()) {
    google.accounts.oauth2
      .initTokenClient({
        client_id: clientId,
        scope: 'profile email',
        callback: (res) => {
          if (res.access_token) {
            onSuccess(googleLoginResponseToSocialLoginData(res.access_token));
          } else {
            onFailure?.();
          }
        },
        error_callback: () => onFailure?.(),
      })
      .requestAccessToken();
  } else {
    serverOAuthLogin('google');
  }
}
function googleLoginResponseToSocialLoginData(token: string) {
  const successData: SocialLoginData = {
    type: SocialLoginType.GOOGLE,
    token,
  };
  return successData;
}
