import { Btn, ButtonTypes } from 'client/shared/components/base';
import * as React from 'react';
import REGISTRATION_PROMPT_COPY from './copy';
import './styles.scss';

const baseClass = 'pn-registration-prompt';

export interface RegistrationPromptProps {
  readonly secondaryButtonLabel?: string;
  readonly description: string;
  readonly image: React.ReactNode;
  readonly title: string;
  readonly switchButtons?: boolean;
}

export interface Props extends RegistrationPromptProps {
  readonly onLogin: () => void;
  readonly onSignUp: () => void;
}

export const RegistrationPrompt: React.FC<Props> = (p) => {
  return (
    <div className={`${baseClass} px-3`}>
      <div className={`${baseClass}-image mt-4 mb-5 text-center`}>{p.image}</div>
      <div className={`${baseClass}-content my-4`}>
        <h2
          className={`${baseClass}-title font-weight-bold text-gray-60 font-size-xl`}
        >
          {p.title}
        </h2>
        <p className="font-size-md my-3 text-gray-60">{p.description}</p>
      </div>
      <div
        className={`${baseClass}-actions d-flex mt-4 mb-3 ${
          !!p.switchButtons ? 'flex-column-reverse' : 'flex-column'
        }`}
      >
        <Btn
          action={p.onLogin}
          ariaLabel={REGISTRATION_PROMPT_COPY.loginAction}
          className={`${baseClass}-button w-100`}
          type={!!p.switchButtons ? ButtonTypes.SECONDARY : ButtonTypes.PRIMARY}
        >
          {REGISTRATION_PROMPT_COPY.loginAction}
        </Btn>
        <Btn
          action={p.onSignUp}
          ariaLabel={REGISTRATION_PROMPT_COPY.signUpAction}
          className={`${baseClass}-button w-100`}
          type={!!p.switchButtons ? ButtonTypes.PRIMARY : ButtonTypes.SECONDARY}
        >
          {p.secondaryButtonLabel ?? REGISTRATION_PROMPT_COPY.signUpAction}
        </Btn>
      </div>
    </div>
  );
};
RegistrationPrompt.displayName = 'RegistrationPrompt';
