import * as React from 'react';
import Slider, { Settings } from 'react-slick';
import { Modal } from 'client/shared/components/modal';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { WhyWeNeedLocation } from 'client/respondent/location/components/why-we-need-location';
import { LocationUpdated } from 'client/respondent/location/components/location-updated';
import { LocationContainer } from 'client/respondent/location/containers/set-your-location';
import { SnackbarMessageProps, useSnackbarMessage } from 'client/shared/hooks';

const baseClass = 'pn-location-flow';

export interface LocationSliderModalProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onSkip: () => void;
}

export enum LocationFlowSlideType {
  WHY_WE_NEED_LOCATION = 'WHY_WE_NEED_LOCATION',
  SET_YOUR_LOCATION = 'SET_YOUR_LOCATION',
  LOCATION_UPDATED = 'LOCATION_UPDATED',
}

export interface LocationFlowSlide {
  readonly name: string;
  readonly type: LocationFlowSlideType;
}

interface LocationSlideExtras extends LocationFlowSlide {
  readonly isActiveSlide: boolean;
  readonly showNextSlide: () => void;
  readonly setSnackbarMessage: (msg: SnackbarMessageProps) => void;
  readonly onSkip: () => void;
}

export const LocationSliderModal: React.FC<LocationSliderModalProps> = (props) => {
  const [SnackbarMessage, setSnackbarMessage] = useSnackbarMessage();

  const slides: readonly LocationFlowSlide[] = [
    {
      name: 'Why we need your location',
      type: LocationFlowSlideType.WHY_WE_NEED_LOCATION,
    },
    {
      name: 'Set your location',
      type: LocationFlowSlideType.SET_YOUR_LOCATION,
    },
    {
      name: 'Location updated',
      type: LocationFlowSlideType.LOCATION_UPDATED,
    },
  ];

  const slider = React.useRef<Slider | null>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState<number>(0);
  const locationUpdatedNum = slides.findIndex(
    (s) => s.type === LocationFlowSlideType.LOCATION_UPDATED
  );

  const showNextSlide = (slideNum: number) => {
    if (!slider.current) {
      return;
    }
    slider.current.slickNext();
    setCurrentSlideIndex(slideNum + 1);
  };

  const settings: Settings = {
    className: `${baseClass}-slider`,
    accessibility: false,
    infinite: false,
    dots: false,
    arrows: false,
    swipe: false,
    adaptiveHeight: true,
  };

  const onClose = () =>
    currentSlideIndex === locationUpdatedNum ? props.onSkip() : props.onClose();

  return (
    <>
      <SnackbarMessage />
      <Modal
        className={`${baseClass}-modal`}
        header={{
          title: (
            <div className="d-flex align-items-center justify-content-end">
              <Btn action={onClose} type={ButtonTypes.SEAMLESS}>
                <MaterialIcon
                  className="text-gray-40"
                  icon={MaterialIconName.CLOSE}
                  style={{ fontSize: '24px' }}
                />
              </Btn>
            </div>
          ),
          bsClassName: 'bg-white p-3 border-gray-20 border-bottom sticky-top',
          className: 'font-size-lg w-100',
        }}
        isCloseable={false}
        isOpen={props.isOpen}
        onRequestClose={onClose}
        size="md"
      >
        <Slider ref={slider} {...settings}>
          {slides.map((slide, slideNum) => {
            return (
              <Slide
                {...slide}
                isActiveSlide={slideNum === currentSlideIndex}
                key={slide.name}
                onSkip={props.onSkip}
                setSnackbarMessage={setSnackbarMessage}
                showNextSlide={() => {
                  slideNum === slides.length - 1
                    ? props.onSkip()
                    : showNextSlide(slideNum - 1);
                }}
              />
            );
          })}
        </Slider>
      </Modal>
    </>
  );
};

LocationSliderModal.displayName = 'LocationSlider';

const Slide: React.FC<LocationSlideExtras> = (props) => {
  function switchRenderSlide() {
    switch (props.type) {
      case LocationFlowSlideType.WHY_WE_NEED_LOCATION: {
        return <WhyWeNeedLocation onContinue={props.showNextSlide} />;
      }
      case LocationFlowSlideType.SET_YOUR_LOCATION:
        return (
          <LocationContainer
            onSaveLocation={props.showNextSlide}
            onSkip={props.onSkip}
            setSnackbarMessage={props.setSnackbarMessage}
            showSkipButton
          />
        );
      case LocationFlowSlideType.LOCATION_UPDATED: {
        return <LocationUpdated onFinish={props.showNextSlide} />;
      }
    }
  }

  return (
    <div className={`${baseClass}-slide`}>
      <div className={`${baseClass}-content`}>{switchRenderSlide()}</div>
    </div>
  );
};
