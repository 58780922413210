import * as React from 'react';
import { CurrentUser } from 'client/respondent/hooks';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { ListIcon, IconType } from 'client/shared/components/list-icon';
import logo from './assets/polco-logo.svg';
import { MaterialIcon, MaterialIconName } from 'client/shared/components/base';
import './styles.scss';
import { COLORS_POLCO_GREEN_L_HEX } from 'client/shared/core/colors';
import { useHistory } from 'react-router';
import { KeyPressKey } from 'client/shared/core/types';
const COPY = {
  unauthenticated: {
    explore: 'Explore Polco',
    insights:
      'Bringing real data-based insights, governments and communities together to improve outcomes for all',
    links: [
      {
        title: 'What is Polco?',
        linkText: 'Learn more',
        path: ClientUrlUtils.respondent.whatIsPolco.path(),
        icon: {
          type: IconType.SVG,
          name: logo,
        },
      },
      {
        title: 'What kind of data?',
        linkText: 'See insights in action',
        path: ClientUrlUtils.respondent.whatKindOfData.path(),
        icon: {
          type: IconType.MATERIAL,
          name: MaterialIconName.BAR_CHART,
        },
      },
      {
        title: 'Is this safe?',
        linkText: 'Your privacy guaranteed',
        path: ClientUrlUtils.respondent.isThisSafe.path(),
        icon: {
          type: IconType.MATERIAL,
          name: MaterialIconName.LIGHTBULB_OUTLINE,
          style: {
            fontSize: '1rem',
          },
        },
      },
    ],
  },
};

interface RightSideBarProps {
  readonly respondent: CurrentUser | null;
  readonly className?: string;
}

const baseClass = 'pn-respondent-right-sidebar';
export const RideSidebarContentsUnauthenticated: React.FC<RightSideBarProps> = (
  p
) => {
  const history = useHistory();
  return (
    <div className={`${baseClass} d-flex flex-column ${p.className ?? ''}`}>
      <div className="text-gray-50 font-size-sm font-weight-bold pb-2">
        {COPY.unauthenticated.explore}
      </div>
      <div className="text-gray-40 font-size-sm pb-4">
        {COPY.unauthenticated.insights}
      </div>
      <div className={`${baseClass}-links d-flex flex-column`}>
        {COPY.unauthenticated.links.map((l, idx) => (
          <div
            className={`${baseClass}-link-row px-1 py-2 d-flex flex-row align-items-center`}
            key={l.title}
            onClick={() => {
              history.push(l.path);
            }}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === KeyPressKey.ENTER) {
                history.push(l.path);
              }
            }}
            role="button"
            tabIndex={idx}
          >
            <div
              className={`${baseClass}-icon-wrapper d-flex justify-content-center align-items-center`}
            >
              <ListIcon
                className={l.icon.type === IconType.MATERIAL ? 'p-1' : ''}
                materialStyles={{ ...l.icon.style, color: COLORS_POLCO_GREEN_L_HEX }}
                name={l.icon.name}
                type={l.icon.type}
              />
            </div>
            <div className="flex-grow-1 d-flex flex-column justify-content-between text-gray-50 text-nowrap">
              <div className="font-weight-bold font-size sm">{l.title}</div>
              <div className={`${baseClass}-link-text pt-2 `}>{l.linkText}</div>
            </div>

            <MaterialIcon
              className={`${baseClass}-close-icon text-gray-50`}
              icon={MaterialIconName.CHEVRON_RIGHT}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
