import { BannerBadgeType } from 'client/respondent/feed/components/body/feed-card/feed-card-banner';
import { RespondentFeedItemBadgeType } from 'client/shared/graphql-client/graphql-operations.g';

export function convertBadgeType_GqlToClient(
  badge: RespondentFeedItemBadgeType
): BannerBadgeType {
  switch (badge) {
    case RespondentFeedItemBadgeType.BALANCING_ACT_SIMULATION:
      return BannerBadgeType.BALANCING_ACT_SIMULATION;
    case RespondentFeedItemBadgeType.POLL_SET:
      return BannerBadgeType.POLL_SET;
    case RespondentFeedItemBadgeType.SURVEY:
      return BannerBadgeType.SURVEY;
    case RespondentFeedItemBadgeType.CONTENT_POST:
      return BannerBadgeType.CONTENT_POST;
    case RespondentFeedItemBadgeType.REPOST:
      return BannerBadgeType.REPOST;
    case RespondentFeedItemBadgeType.QUESTION:
      return BannerBadgeType.QUESTION;
    case RespondentFeedItemBadgeType.POLCO_LIVE:
      return BannerBadgeType.POLCO_LIVE;
    case RespondentFeedItemBadgeType.IDEA:
      return BannerBadgeType.IDEA;
  }
}
