import * as React from 'react';
import './styles.scss';
import { Btn, ButtonTypes } from 'client/shared/components/base';
import locationLogo from 'client/assets/location-logo.png';

interface Props {
  readonly className?: string;
  readonly onFinish: () => void;
}

export const COPY = {
  subtitle: 'We have updated your location!',
  description:
    'Thank you for providing your approximate location. You may update this at any time from your Account page.',
  button: 'Finish',
};

export const baseClass = 'pn-location-updated';

export const LocationUpdated: React.FC<Props> = (p) => {
  return (
    <div
      className={`${baseClass}-container d-flex flex-column p-3 align-items-center ${
        p.className ?? ''
      }`}
    >
      <img
        alt="Location logo"
        className={`${baseClass}-location-logo w-50 mb-4`}
        src={locationLogo}
      />
      <h4 className={`${baseClass}-subtitle font-weight-bold mb-4 text-left w-100`}>
        {COPY.subtitle}
      </h4>
      <p className="w-100 mb-4">{COPY.description}</p>
      <Btn
        action={p.onFinish}
        ariaLabel={COPY.button}
        className={`${baseClass}-button btn-sm w-100 mt-3`}
        type={ButtonTypes.PRIMARY}
      >
        {COPY.button}
      </Btn>
    </div>
  );
};
