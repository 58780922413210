import * as React from 'react';
import { RegistrationStart } from 'client/respondent/shared/account/components/access/start';
import { RegistrationContainerProps } from 'client/respondent/shared/account/components/access';
import { useMutationInfo } from 'client/shared/containers/mutation';
import * as MutationInfos from 'client/shared/graphql-mutations/mutation-infos';
import {
  EmailSignupData,
  RegistrationErrors,
  RegistrationResult,
  RegistrationResultFieldErrors,
  SocialLoginType,
} from 'client/respondent/core/types';
import {
  useRespondentAnalyticsCallback,
  useRespondentAnalyticsViewEvent,
  useSocialLogins,
} from 'client/respondent/hooks';
import {
  AnalyticsEvent,
  AnalyticsEventType,
  analyticNameFromUser,
} from 'client/respondent/core/analytics';
import { socialLoginHandler } from 'client/respondent/shared/account/containers/shared';
import { getFingerprint } from 'client/shared/integrations';
import { EmailSignupFailedReason } from 'client/shared/graphql-client/graphql-operations.g';

export const SignupContainer: React.FC<RegistrationContainerProps> = (p) => {
  const [registrationErrors, setRegistrationErrors] =
    React.useState<RegistrationErrors>({
      [SocialLoginType.GOOGLE]: null,
      [SocialLoginType.FACEBOOK]: null,
    });
  const muts = {
    socialLogin: useMutationInfo(MutationInfos.socialLogin),
  };
  const eventHandler = useRespondentAnalyticsCallback();
  useRespondentAnalyticsViewEvent(AnalyticsEventType.VIEWED_REGISTRATION);
  const logins = useSocialLogins();

  const { fn: emailSignup, result } = useMutationInfo(MutationInfos.emailSignup);

  useRespondentAnalyticsViewEvent(AnalyticsEventType.VIEWED_SIGN_UP);

  return (
    <RegistrationStart
      emailSignupProps={{
        events: {
          converted: p.converted,
          async emailSignup(d) {
            try {
              const res = await emailSignup({
                variables: {
                  email: d.email,
                  firstName: d.firstName,
                  lastName: d.lastName,
                  password: d.password,
                  zipCode: d.zipCode,
                  fingerprint: getFingerprint(),
                },
              });
              const signup = res.data?.emailSignup;
              if (signup) {
                if (signup.__typename === 'CurrentUser') {
                  if (signup.user?.respondent) {
                    let email = null;
                    let requiresVerification = false;

                    if (signup.user.respondent.__typename === 'PrivateRespondent') {
                      const {
                        email: userEmail,
                        firstName,
                        lastName,
                      } = signup.user.respondent;

                      email = userEmail;
                      requiresVerification = !firstName || !lastName;
                    }

                    eventHandler(
                      AnalyticsEvent.SIGNED_UP({
                        email,
                        loginType: 'EMAIL',
                        name: analyticNameFromUser(signup.user.respondent),
                        user_id: signup.user.id,
                      })
                    );
                    return {
                      type: 'SUCCESS',
                      isNewUser: true,
                      requiresVerification,
                      respId: signup.user.respondent.id,
                      userId: signup.user.id,
                      email,
                    };
                  } else {
                    return {
                      type: 'ERROR',
                      data: {
                        title: 'EMAIL LOGIN UNSUCCESSFUL',
                        description: 'No respondent found',
                      },
                    };
                  }
                } else {
                  return {
                    type: 'FIELD_ERRORS',
                    errors: gqlSignupFailReasonToErrors(signup.reason),
                  };
                }
              } else {
                return {
                  type: 'FIELD_ERRORS',
                  errors: {
                    email: 'Something went wrong.',
                  },
                };
              }
            } catch (e) {
              return errorsToRegistrationResult(e);
            }
          },
        },
        pendingConversion: result.loading,
        fields: {
          firstName: { isRequired: false },
          lastName: { isRequired: false },
        },
      }}
      events={{
        socialLogin: (provider) =>
          socialLoginHandler(
            provider,
            logins,
            muts.socialLogin,
            eventHandler,
            p.converted,
            setRegistrationErrors
          ),
      }}
      onClickLoginLink={p.onClickLogin}
      pendingConversion={false}
      registrationErrors={registrationErrors}
    />
  );
};

export function errorsToRegistrationResult(
  e: any
): RegistrationResult<EmailSignupData> {
  if (e.graphQLErrors) {
    return {
      type: 'ERROR',
      data: {
        title: 'SIGNUP UNSUCCESSFUL',
        description: e.toString(),
      },
    };
  } else {
    throw e;
  }
}

function gqlSignupFailReasonToErrors(
  reason: EmailSignupFailedReason
): RegistrationResultFieldErrors<EmailSignupData>['errors'] {
  switch (reason) {
    case EmailSignupFailedReason.INVALID:
      return {
        email: 'Invalid email or password',
      };
    case EmailSignupFailedReason.RESPONDENT_TAKEN:
      return {
        firstName: 'Respondent already registered',
      };
    case EmailSignupFailedReason.EMAIL_TAKEN:
      return {
        email: 'Account with email already exists',
      };
    case EmailSignupFailedReason.COULD_NOT_INSERT_USER:
      return {
        email: 'Could not insert user for unknown reasons',
      };
  }
}
