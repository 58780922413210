import * as React from 'react';
import { Circle, GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { getIntegrationConfigKey } from 'client/shared/integrations';
import {
  MAP_CIRCLE_OPTIONS,
  DEFAULT_MAP_ZOOM,
  CIRCLE_RADIUS_PER_ZOOM,
  MAP_DEFAULT_CIRCLE_RADIUS,
} from 'client/shared/core/geo';
import './styles.scss';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
  TextInput,
  WellType,
} from 'client/shared/components/base';
import { useId } from 'react-use-id-hook';
import { useWellMessage } from 'client/shared/hooks';

interface Props {
  readonly center?: google.maps.LatLngLiteral;
  readonly zipCode?: string | null;
  readonly requestCurrentDeviceLocation?: boolean;
  readonly className?: string;
  readonly onSave: (args: {
    readonly center: google.maps.LatLngLiteral;
    readonly zipCode: string;
  }) => Promise<void>;
  readonly onSkip?: () => void;
  readonly showSkipButton?: boolean;
}

// Polco HQ
const DEFAULT_CENTER: google.maps.LatLngLiteral = {
  lat: 43.08494784805375,
  lng: -89.52334277116371,
};

const defaultValues = {
  zoom: DEFAULT_MAP_ZOOM,
  circleRadius: CIRCLE_RADIUS_PER_ZOOM[DEFAULT_MAP_ZOOM],
  circleOptions: MAP_CIRCLE_OPTIONS,
  containerStyle: {
    width: '100%',
    height: '376px',
  },
  options: {
    clickableIcons: false,
    streetViewControl: false,
    minZoom: 5,
    maxZoom: 15,
  },
};

export const COPY = {
  description:
    'Drag the map to your approximate location. Use the field below to quickly center the map on a particular zip code.',
  inputPlaceholder: '5-digit zip code',
  primaryAction: 'Save location',
  secondaryAction: `I'll do this later`,
  locationNotInUsa: 'Please enter a location inside the USA',
  invalidLocation: 'The location is not part of any valid zip code',
  errorResolvingLocationFromInput:
    'There was an error resolving the provided zip code, please try again',
  errorResolvingLocationToSave:
    'There was an error resolving the location to be saved, please try again',
};

const getCurrentDeviceLocation = async (): Promise<
  google.maps.LatLng | undefined
> => {
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        resolve(
          new google.maps.LatLng({
            lat: location.coords.latitude,
            lng: location.coords.longitude,
          })
        );
      },
      () => {
        resolve(undefined);
      }
    );
  });
};

const resolveGeocode = async (
  request: google.maps.GeocoderRequest
): Promise<google.maps.GeocoderResult> => {
  const geocoder = new google.maps.Geocoder();
  const { results } = await geocoder.geocode(request);
  if (results?.length) {
    return results[0];
  }
  throw Error('There was an error resolving location');
};

const MAP_KEY = getIntegrationConfigKey('googleMapsApiKey');
export const baseClass = 'pn-set-your-location';

export const SetYourLocation: React.FC<Props> = ({
  center,
  zipCode,
  requestCurrentDeviceLocation = true,
  className,
  showSkipButton = true,
  onSkip,
  onSave,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: MAP_KEY as string,
  });
  const [map, setMap] = React.useState<google.maps.Map | undefined>();
  const [mapCenter, setMapCenter] = React.useState<google.maps.LatLng | undefined>();
  const [inputValue, setInputValue] = React.useState<string>('');
  const [WellMessage, setWellMessage] = useWellMessage();
  const [radius, setRadius] = React.useState<number>(defaultValues.zoom);

  const onMapLoad = (gMap: google.maps.Map) => setMap(gMap);

  const onCenterChanged = () => {
    const newCenter = map?.getCenter();
    if (newCenter !== undefined) {
      setMapCenter(newCenter);
    }
  };

  const resolveInitialCenter = async (args: {
    readonly center?: google.maps.LatLngLiteral;
    readonly zipCode?: string | null;
    readonly requestCurrentDeviceLocation?: boolean;
  }) => {
    const { center: pCenter, zipCode: pZipCode } = args;
    // Attempt 1: use the provided lat/lon point
    if (pCenter) {
      setMapCenter(new google.maps.LatLng(pCenter));
      return;
    }
    // Attempt 2: use the provided zipCode and try to center there
    if (pZipCode) {
      try {
        const zipCodeCenter = await resolveGeocode({ address: pZipCode });
        if (zipCodeCenter) {
          setMapCenter(zipCodeCenter.geometry.location);
          return;
        }
      } catch (error) {
        console.error(error);
      }
    }
    // Attempt 3: use current device location, if granted by the current user
    if (requestCurrentDeviceLocation) {
      const currentDeviceLocation = await getCurrentDeviceLocation();
      if (currentDeviceLocation) {
        setMapCenter(currentDeviceLocation);
        return;
      }
    }
    // Default if none of above works: use default location (Polco HQ)
    setMapCenter(new google.maps.LatLng(DEFAULT_CENTER));
  };

  // Meant to run only the first time (the "mount") of the component
  React.useEffect(() => {
    if (isLoaded && map) {
      resolveInitialCenter({ center, zipCode, requestCurrentDeviceLocation }).catch(
        console.error
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, map, zipCode, center]);

  React.useEffect(() => {
    setInputValue(zipCode || '');
  }, [zipCode]);

  const resolveLocationBasedOnInput = async () => {
    if (inputValue && Number(inputValue)) {
      try {
        const resolvedGeoCode = await resolveGeocode({ address: inputValue });
        if (resolvedGeoCode) {
          setMapCenter(resolvedGeoCode.geometry.location);
        }
      } catch (error) {
        console.error(error);
        setWellMessage({
          type: WellType.ICON_ERROR,
          message: COPY.errorResolvingLocationFromInput,
        });
      }
    }
  };

  const tryResolveLocation = async () => {
    if (mapCenter) {
      try {
        const geocodeResult = await resolveGeocode({ location: mapCenter });
        const geoCodeZipCode = geocodeResult.address_components.find(
          (c) => c.types.indexOf('postal_code') > -1
        )?.short_name;
        const country = geocodeResult.address_components.find(
          (c) => c.types.indexOf('country') > -1
        )?.short_name;
        if (country !== 'US') {
          setWellMessage({
            type: WellType.ICON_ERROR,
            message: COPY.locationNotInUsa,
            className: 'w-100',
          });
          return;
        }
        if (!geoCodeZipCode) {
          setWellMessage({
            type: WellType.ICON_ERROR,
            message: COPY.invalidLocation,
            className: 'w-100',
          });
          return;
        }
        await onSave({
          center: { lat: mapCenter.lat(), lng: mapCenter.lng() },
          zipCode: geoCodeZipCode,
        });
      } catch (error) {
        console.error(error);
        setWellMessage({
          type: WellType.ICON_ERROR,
          message: COPY.errorResolvingLocationToSave,
        });
      }
    }
  };

  const onZoomChanged = () => {
    const mapZoom = map?.getZoom() || defaultValues.zoom;
    setRadius(CIRCLE_RADIUS_PER_ZOOM[mapZoom] ?? MAP_DEFAULT_CIRCLE_RADIUS);
  };

  return (
    <div className={`${baseClass}-container`}>
      {isLoaded && (
        <GoogleMap
          center={mapCenter}
          mapContainerStyle={defaultValues.containerStyle}
          onCenterChanged={onCenterChanged}
          onLoad={onMapLoad}
          onZoomChanged={onZoomChanged}
          options={defaultValues.options}
          zoom={defaultValues.zoom}
        >
          <Circle
            center={mapCenter}
            options={defaultValues.circleOptions}
            radius={radius}
          />
        </GoogleMap>
      )}
      <div
        className={`${baseClass}-content d-flex flex-column px-3 pt-3 m-3 align-items-center ${
          className ?? ''
        }`}
      >
        <p className="w-100 mb-4">{COPY.description}</p>
        <div className={`${baseClass}-zip-code-container d-flex w-100 mb-3`}>
          <TextInput
            applyAccessibilityStyles
            ariaLabel={COPY.inputPlaceholder}
            className="w-100 m-0"
            id={useId()}
            onChange={setInputValue}
            placeholder={COPY.inputPlaceholder}
            value={inputValue}
          />
          <Btn
            action={resolveLocationBasedOnInput}
            className="ml-1"
            disabled={!inputValue}
            iconOnly
            size="small"
            type={ButtonTypes.SECONDARY}
          >
            <MaterialIcon icon={MaterialIconName.NAVIGATION} />
          </Btn>
        </div>
        <WellMessage />
        <Btn
          action={tryResolveLocation}
          className="w-100 mt-4"
          disabled={!mapCenter}
          type={ButtonTypes.PRIMARY}
        >
          {COPY.primaryAction}
        </Btn>
        {showSkipButton && onSkip && (
          <Btn action={onSkip} className="w-100 mt-3" type={ButtonTypes.SECONDARY}>
            {COPY.secondaryAction}
          </Btn>
        )}
      </div>
    </div>
  );
};
