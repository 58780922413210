import * as React from 'react';
import './styles.scss';

import {
  CurrentRespondent,
  useCurrentRespondent,
  useRespondentAnalyticsCallback,
} from 'client/respondent/hooks';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { Modal } from 'client/shared/components/modal';
import { ClientUrlUtils, validateEmail } from 'client/shared/core/helpers';
import { WELCOME_BACK_MODAL_COPY } from './copy';
import {
  AppLink,
  Btn,
  ButtonTypes,
  MaterialIconName,
  SeparatorWithText,
} from 'client/shared/components/base';
import {
  SignupType,
  SocialSignupContainer,
} from 'client/respondent/account/containers/access/social';
import { EmailSignupButton } from 'client/respondent/shared/account/components/access/email-signup-button';
import {
  EmailLoginForm,
  generateEmailLoginAction,
} from 'client/respondent/shared/account/components/access/email-login-form';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { useRedirect } from 'client/shared/hooks';
import { omit } from 'lodash';
import { WELCOME_BACK_EMAIL_PARAM_KEY } from 'core';

const baseClass = 'pn-welcome-back-modal';
enum ContentStates {
  DEFAULT = 'DEFAULT',
  EMAIL_LOGIN = 'EMAIL_LOGIN',
  MAGIC_LINK = 'MAGIC_LINK',
}

export const WelcomeBackModalContainer: React.FC = () => {
  // Use all the hooks!
  const currentRespondent = useCurrentRespondent();
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(true); // default to being open
  const [currentContent, setCurrentContent] = React.useState<ContentStates>(
    ContentStates.DEFAULT
  );
  const emailLogin = useMutationInfo(MutationInfos.emailLogin);
  const sendMagicLink = useMutationInfo(MutationInfos.requestMagicLinkEmail);
  const eventHandler = useRespondentAnalyticsCallback();
  const redirect = useRedirect();
  const queryParams = qs.parse(location.search) as Record<
    typeof WELCOME_BACK_EMAIL_PARAM_KEY,
    string
  >;
  const email: string | undefined = queryParams[WELCOME_BACK_EMAIL_PARAM_KEY];
  const respondentUser = getNonGuestRespondentUser(currentRespondent);
  React.useEffect(() => {
    if (respondentUser && email) {
      // if this is a respondent/guest with a user, no need to prompt.
      // In this case we should also remove the "welcome back" query param
      const updatedParams = omit(queryParams, WELCOME_BACK_EMAIL_PARAM_KEY);
      redirect(`${location.pathname}?${qs.stringify(updatedParams)}`, {
        push: false,
      });
    }
  }, [respondentUser, location, redirect, email, queryParams]);

  if (respondentUser) {
    return null;
  }

  if (CurrentRespondent.LOADING.isA(currentRespondent)) {
    // Don't show the modal if we are still loading
    return null;
  }

  // Find if we should be showing (is the query param present)
  if (!email || !validateEmail(email)) {
    // if we don't have an email to "welcome back", then this modal should not show
    return null;
  }

  /**
   * This function handles flipping between our 3 "pages"
   * in this modal. The default page that has all the options,
   * the email login page (with a username/password form), and
   * a magic link info section
   */
  function getContent(promptEmail: string) {
    const sendMagicLinkFn = () =>
      sendMagicLink.fn({
        variables: {
          input: {
            email: promptEmail,
            path: document.location.pathname,
            params: Object.entries(
              omit(queryParams, WELCOME_BACK_EMAIL_PARAM_KEY)
            ).map(([key, value]) => ({
              key,
              value: value as string,
            })),
          },
        },
      });
    switch (currentContent) {
      case ContentStates.DEFAULT:
        return (
          <DefaultWelcomeBackContent
            email={promptEmail}
            sendMagicLink={sendMagicLinkFn}
            setCurrentContent={setCurrentContent}
          />
        );
      case ContentStates.EMAIL_LOGIN:
        return (
          <div className="pt-4">
            <EmailLoginForm
              converted={() => {}}
              emailLogin={generateEmailLoginAction(emailLogin, eventHandler)}
              prefillEmail={promptEmail}
            />

            <div className="my-3 text-center">
              <AppLink
                className={`${baseClass}-link text-jungle font-size-sm font-weight-bold`}
                to={ClientUrlUtils.respondent.resetPasswordRequest.path()}
                unstyled
              >
                {WELCOME_BACK_MODAL_COPY.emailLogin.forgotPassword}
              </AppLink>
            </div>
          </div>
        );
      case ContentStates.MAGIC_LINK:
        return (
          <div>
            <div className="py-4">{WELCOME_BACK_MODAL_COPY.linkSent}</div>

            <Btn
              action={sendMagicLinkFn}
              className="w-100"
              type={ButtonTypes.PRIMARY}
            >
              {WELCOME_BACK_MODAL_COPY.resend}
            </Btn>

            <Btn
              action={() => setCurrentContent(ContentStates.DEFAULT)}
              className="w-100 mt-2"
              type={ButtonTypes.SEAMLESS}
            >
              {WELCOME_BACK_MODAL_COPY.back}
            </Btn>
          </div>
        );
    }
  }

  const contentConfiguration = {
    [ContentStates.DEFAULT]: {
      displayIcon: MaterialIconName.WAVING_HAND,
      header: { title: '' },
      headerIsLogo: false,
      title: WELCOME_BACK_MODAL_COPY.title,
    },
    [ContentStates.EMAIL_LOGIN]: {
      displayIcon: undefined,
      header: { title: '', bsClassName: 'border-gray-20 border-bottom' },
      headerIsLogo: true,
      title: WELCOME_BACK_MODAL_COPY.emailLogin.title,
    },
    [ContentStates.MAGIC_LINK]: {
      displayIcon: MaterialIconName.WAVING_HAND,
      header: { title: '' },
      headerIsLogo: false,
      title: WELCOME_BACK_MODAL_COPY.title,
    },
  };

  const { displayIcon, header, headerIsLogo, title } =
    contentConfiguration[currentContent];

  // At this point, we know that the respondent is "No Data" (i.e. not known)
  return (
    <Modal
      className={`${baseClass} mx-auto p-0`}
      displayIcon={displayIcon}
      header={header}
      headerIsLogo={headerIsLogo}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <div className="d-flex flex-column">
        <h2 className="text-gray-60 font-size-xl font-weight-bold mb-0">{title}</h2>

        {getContent(email)}
      </div>
    </Modal>
  );
};
WelcomeBackModalContainer.displayName = 'WelcomeBackModal';

const DefaultWelcomeBackContent: React.FC<{
  readonly email: string;
  readonly setCurrentContent: (content: ContentStates) => Promise<any> | void;
  readonly sendMagicLink: () => Promise<any> | void;
}> = (p) => {
  return (
    <div>
      <div className="py-4">
        <span className="text-gray-60 font-size-sm">
          {WELCOME_BACK_MODAL_COPY.description + p.email}
        </span>
      </div>

      <Btn
        action={async () => {
          await p.sendMagicLink();
          await p.setCurrentContent(ContentStates.MAGIC_LINK);
        }}
        className="w-100"
        type={ButtonTypes.PRIMARY}
      >
        {WELCOME_BACK_MODAL_COPY.sendMagicLink}
      </Btn>
      <SeparatorWithText text={WELCOME_BACK_MODAL_COPY.or} />

      <EmailSignupButton
        action={() => p.setCurrentContent(ContentStates.EMAIL_LOGIN)}
        context="login"
      />
      <SocialSignupContainer
        context={SignupType.LOG_IN}
        converted={async () => {}}
      />
    </div>
  );
};
DefaultWelcomeBackContent.displayName = 'DefaultWelcomeBackContent';

function getNonGuestRespondentUser(currentRespondent: CurrentRespondent) {
  if (CurrentRespondent.RESPONDENT.isA(currentRespondent)) {
    return currentRespondent.user;
  }

  return null;
}
