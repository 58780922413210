import * as React from 'react';
import './styles.scss';
import { TERMS_OF_USE_URL, PRIVACY_URL } from 'client/shared/core/constants';
import { AppLink } from 'client/shared/components/base';
import { TERMS_AND_PRIVACY_COPY } from './copy';

const baseClass = 'pn-respondent-terms-and-privacy';

export const TermsAndPrivacy = () => {
  return (
    <div className={`${baseClass}-container d-flex align-items-center font-size-xs`}>
      {TERMS_AND_PRIVACY_COPY.copyright}
      <div className={`${baseClass}-links d-flex justify-content-center`}>
        <AppLink
          ariaLabel={TERMS_AND_PRIVACY_COPY.termsAriaLabel}
          className="text-jungle"
          target="_blank"
          to={TERMS_OF_USE_URL}
        >
          {TERMS_AND_PRIVACY_COPY.terms}
        </AppLink>
        <AppLink
          ariaLabel={TERMS_AND_PRIVACY_COPY.privacyAriaLabel}
          className="text-jungle ml-3"
          target="_blank"
          to={PRIVACY_URL}
        >
          {TERMS_AND_PRIVACY_COPY.privacy}
        </AppLink>
      </div>
    </div>
  );
};
