import * as React from 'react';
import './styles.scss';

import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { ClientPublishingEntityId } from 'client/shared/core/publishing-entity';
import { FollowingEvent } from 'client/respondent/core/types';

export enum FollowStatus {
  NOT_FOLLOWING = 'NOT_FOLLOWING',
  FOLLOWING = 'FOLLOWING',
}

const baseClass = 'pn-following-button';

export const copy = {
  following: 'Following',
  follow: 'Follow',
};

export const FollowingBtn: React.FC<{
  readonly pubId: ClientPublishingEntityId;
  readonly events: FollowingEvent;
  readonly followStatus: FollowStatus;
  readonly customMargin?: string;
}> = (p) => {
  const isFollowing = p.followStatus === FollowStatus.FOLLOWING;
  return (
    <Btn
      action={async () => {
        isFollowing
          ? await p.events.unfollow(p.pubId)
          : await p.events.follow(p.pubId);
      }}
      className={`${baseClass} ${p.customMargin ?? `mx-1`}`}
      type={isFollowing ? ButtonTypes.SECONDARY : ButtonTypes.PRIMARY}
    >
      <div className="d-flex align-items-center">
        <span className={`${baseClass}-btn-text`}>
          {isFollowing ? copy.following : copy.follow}
        </span>
        <MaterialIcon className="ml-2" icon={MaterialIconName.RSS_FEED} />
      </div>
    </Btn>
  );
};
