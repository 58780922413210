import * as React from 'react';
import './styles.scss';
import { Form, Col } from 'react-bootstrap';
import { useForm, RegisterOptions } from 'react-hook-form';
import {
  RegistrationEvents,
  ConversionType,
  EmailLoginData,
} from 'client/respondent/core/types';
import { TextInputProps } from 'client/shared/components/base/text-input';
import { RegistrationContainerProps } from '.';
import { MIN_PASSWORD_LENGTH, Result, EMAIL_VALIDATION_REGEX } from 'core';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { handlePressEnter } from 'client/shared/core/helpers';
import { RBRef } from 'client/shared/core/types';
import { FormPassword } from 'client/shared/components/form-password';
import { EventHandler } from 'client/respondent/hooks/use-respondent-analytics';
import { MutationReturn } from 'client/shared/containers/mutation';
import {
  analyticNameFromUser,
  AnalyticsEvent,
} from 'client/respondent/core/analytics';
import { getFingerprint } from 'client/shared/integrations';
import { useId } from 'react-use-id-hook';
import {
  EmailLogin,
  EmailLoginVariables,
} from 'client/shared/graphql-client/graphql-operations.g';
import { ACCESS_COPY as copy } from './copy';

enum ErrorType {
  LOGIN_FAILED = 'LOGIN_FAILED',
}

export namespace RegistrationEmailLogin {
  export interface Props {
    readonly prefillEmail?: string;
    readonly emailLogin: RegistrationEvents['emailLogin'];
    readonly converted: RegistrationContainerProps['converted'];
  }

  export interface Fields {
    readonly email: string;
    readonly password: string;
  }

  type ValidationSet = Record<keyof Fields, RegisterOptions>;
  export const validations: ValidationSet = {
    email: {
      required: {
        value: true,
        message: copy.errorMessages.email.required,
      },
      pattern: {
        value: EMAIL_VALIDATION_REGEX,
        message: copy.errorMessages.email.pattern,
      },
    },
    password: {
      required: {
        value: true,
        message: copy.errorMessages.password.required,
      },
      minLength: {
        value: MIN_PASSWORD_LENGTH,
        message: copy.errorMessages.password.minLength,
      },
    },
  };
}

const baseClass = 'pn-email-login-form';

export const EmailLoginForm: React.FC<RegistrationEmailLogin.Props> = (props) => {
  const { register, handleSubmit, errors, setError, clearErrors, formState } =
    useForm<RegistrationEmailLogin.Fields>({
      mode: 'onChange',
    });
  const submitted = formState.isSubmitted;
  const createRef = (opts: RegisterOptions): RBRef => {
    return register(opts) as RBRef;
  };
  const emailControlId = useId();
  const emailFeedbackId = useId();
  const text = (
    label: string,
    k: keyof RegistrationEmailLogin.Fields,
    id: string,
    feedbackId: string,
    opts?: {
      readonly inputType?: TextInputProps['inputType'];
      readonly autoComplete?: string;
      readonly placeholder?: string;
      readonly defaultValue?: string;
      readonly required?: boolean;
    }
  ) => {
    const isInvalid = !!errors[k]?.message && submitted;
    return (
      <Form.Group as={Col}>
        <Form.Label className="font-size-m" htmlFor={id}>
          {label}
        </Form.Label>
        <Form.Control
          aria-errormessage={isInvalid ? feedbackId : undefined}
          aria-invalid={isInvalid}
          aria-label={label}
          autoComplete={opts?.autoComplete}
          className={`rounded accessible-input ${
            !!errors[k]?.message && submitted ? 'has-error' : ''
          }`}
          defaultValue={opts?.defaultValue}
          id={id}
          isInvalid={isInvalid}
          name={k}
          placeholder={opts?.placeholder}
          ref={createRef(RegistrationEmailLogin.validations[k] ?? {})}
          required={opts?.required}
          type={opts?.inputType}
        />
        <Form.Control.Feedback
          className={submitted ? 'd-block' : ''}
          id={feedbackId}
          type="invalid"
        >
          {errors[k]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  };

  const submit = async (fields: RegistrationEmailLogin.Fields) => {
    clearErrors();
    const result = await props.emailLogin(fields);

    if (Result.isSuccess(result)) {
      await props.converted({
        conversionType: ConversionType.EMAIL_LOGIN,
        respondentId: result.value.respondentId,
      });
    } else {
      setError('email', {
        type: ErrorType.LOGIN_FAILED,
        message: copy.errorMessages.general,
      });
    }
  };

  return (
    <Form
      className={`${baseClass}`}
      onKeyUp={handlePressEnter(handleSubmit(submit))}
      onSubmit={handleSubmit(submit)}
    >
      <Form.Row className="mb-2">
        {text(copy.labels.email, 'email', emailControlId, emailFeedbackId, {
          autoComplete: 'email',
          defaultValue: props.prefillEmail,
          required: true,
        })}
      </Form.Row>
      <Form.Row>
        <FormPassword
          createRef={createRef}
          error={errors.password?.message ?? null}
          label={copy.labels.password}
          submitted={submitted}
        />
      </Form.Row>
      <Btn
        action={handleSubmit(submit)}
        className="w-100 mt-3"
        type={ButtonTypes.PRIMARY}
      >
        {copy.actions.login}
        <MaterialIcon className="ml-1" icon={MaterialIconName.ARROW_FORWARD} />
      </Btn>
    </Form>
  );
};

export function generateEmailLoginAction(
  emailLoginMutation: MutationReturn<EmailLogin, EmailLoginVariables>,
  eventHandler: EventHandler
) {
  return async (d: EmailLoginData) => {
    const res = await emailLoginMutation.fn({
      variables: {
        email: d.email,
        password: d.password,
        fingerprint: getFingerprint(),
      },
    });
    const login = res.data?.emailLogin;
    if (
      login &&
      login.__typename === 'CurrentUser' &&
      login.user?.respondent?.__typename === 'PrivateRespondent'
    ) {
      eventHandler(
        AnalyticsEvent.SIGNED_IN({
          email: login.user.respondent.email,
          loginType: 'EMAIL',
          name: analyticNameFromUser(login.user.respondent),
          user_id: login.user.id,
        })
      );

      return Result.success({
        userId: login.user.id,
        respondentId: login.user.respondent.id,
        email: login.user.respondent.email,
      });
    }
    return Result.failure(null);
  };
}
