import * as React from 'react';
import { Modal } from 'client/shared/components/modal';
import { VerificationStepContainer } from '../verification-step';
import { useSnackbarMessage } from 'client/shared/hooks';

interface Props {
  readonly action: () => void;
  readonly isFeed: boolean;
  readonly onClose: () => void;
}

export const VerificationModal: React.FC<Props> = (p) => {
  const [SnackbarMessage, setSnackbarMessage] = useSnackbarMessage();

  return (
    <>
      <SnackbarMessage />
      <Modal
        bodyClassName="pb-0"
        header={{ title: '', bsClassName: 'border-gray-20 border-bottom' }}
        headerIsLogo
        isCloseable
        isOpen
        onRequestClose={p.onClose}
        size="md"
      >
        <VerificationStepContainer
          action={p.action}
          isActiveSlide
          isFeed={p.isFeed}
          setSnackbarMessage={setSnackbarMessage}
        />
      </Modal>
    </>
  );
};
