import { Flavor } from 'core';
import { PolcoColor } from 'client/shared/core/colors-class';

export type ClientGeoViewId = Flavor<string, 'geo_view'>;

const JUNGLE_L = new PolcoColor('Jungle-L').hex;

const ONE_MILE_IN_METERS = 1609.34;
export const MAP_DEFAULT_CIRCLE_RADIUS = 5 * ONE_MILE_IN_METERS;
export const DEFAULT_MAP_ZOOM = 14;

export const MAP_CIRCLE_OPTIONS = {
  strokeColor: JUNGLE_L,
  strokeWeight: 4,
  fillColor: JUNGLE_L,
  fillOpacity: 0.2,
};

export const CIRCLE_RADIUS_PER_ZOOM: Record<number, number> = {
  // Numbers below will use default 5 miles radius
  12: 2.2 * ONE_MILE_IN_METERS,
  13: 1.1 * ONE_MILE_IN_METERS,
  14: 0.57 * ONE_MILE_IN_METERS,
  15: 0.35 * ONE_MILE_IN_METERS,
};
