import * as React from 'react';
import { SetRouteSlugs } from '../../shared/pages/voting-wrapper';
import { SafeRecordDictionary } from 'core';
import { RentBurdenScorecard } from '../pages/rent-burden-scorecard';
import { NotFound } from 'client/respondent/shared/pages/not-found';

const getRouteSlugsKey = (slugs: SetRouteSlugs): string => {
  return `set-${slugs.setSlug}-pub-${slugs.pubSlug ?? 'none'}`;
};

const customComponentByRouteSlugs: SafeRecordDictionary<string, React.FC<any>> = {
  [getRouteSlugsKey({ setSlug: 'rent-burden-scorecard', pubSlug: 'polco' })]:
    RentBurdenScorecard,
};

interface CustomVotingPageWrapperProps {
  readonly slugs: SetRouteSlugs;
}

export const CustomVotingPageWrapper: React.FC<CustomVotingPageWrapperProps> = (
  p
) => {
  const key = getRouteSlugsKey(p.slugs);
  const CustomComponent = customComponentByRouteSlugs[key];
  if (CustomComponent) {
    return <CustomComponent />;
  }
  return <>{p.children ?? <NotFound />}</>;
};
