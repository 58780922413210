/// <reference path="../../../shared/custom-typings/graphql.d.ts" />
import VotingDocument from 'client/shared/graphql-queries/Voting.graphql';
import * as MutationInfos from 'client/shared/graphql-mutations/mutation-infos';
import {
  Voting,
  VotingVariables,
  UpvoteComment,
  UnUpvoteComment,
  VoteType,
  InputVoteType,
  VoteMetadataEntry,
} from 'client/shared/graphql-client/graphql-operations.g';
import { RespondentActions } from 'client/respondent/core/reducers/actions';
import { mutate } from 'client/shared/graphql-mutations/helpers';
import gql from 'graphql-tag';
import {
  VotingProps,
  QuestionDispatchContext,
  Type,
  ModalErrorData,
  SetVotingTypeResult,
  QuestionVotingTypeResult,
  NotLoggedInActions,
  NotLoggedInActionStatus,
  PreLoginAction,
  QuestionMetadataEntry,
  QuestionPageRedirectLocation,
} from 'client/respondent/core/types';
import { concat, uniqBy, first, compact, fromPairs } from 'lodash';
import { ToMutationReturn } from 'client/shared/containers/mutation';
import {
  PollSetVoteResult,
  VoteResultType,
  PollSetVoteFailureMessage,
  AnswerChangeability,
  PointAllocationChoice,
  SurveyLoadedEvents,
  MCChoice,
  GridChoice,
} from 'client/shared/core/types';
import { DataProxy, QueryResult, FetchResult } from '@apollo/client';
import { updateQuery } from 'client/respondent/shared/functions';
import { ToQueryResult } from 'client/shared/containers/query';
import { QueryInfos } from 'client/shared/graphql-queries';
import { RedirectFunction, SelectLanguageTextFunction } from 'client/shared/hooks';
import {
  SavedSurveyItem,
  PositionTypedData,
  PositionType,
  SurveyItemType,
  QuestionTypedData,
  QuestionType,
  ClientQuestionId,
  RandomizedSurveyItems,
} from 'client/shared/core/question';
import {
  ApiDate,
  ApiError,
  as,
  ExtractGql,
  isEnum,
  LanguageKey,
  PolcoGqlErrors,
  QuestionSetType,
  wrap,
} from 'core';
import { SurveyResponseProps } from 'client/respondent/voting/survey/containers/survey-loaded';

import { gqlToClient_respondent } from 'client/respondent/graphql-util/transform/question';

import * as VotingRules from 'client/respondent/core';
import { hasVote } from 'client/respondent/core';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';

import {
  CurrentUser,
  CurrentRespondentData,
} from 'client/respondent/hooks/use-respondent';
import { mapContextToEvents } from 'client/respondent/voting/shared/pages/question-voting/events';
import {
  SurveyVotingMutationReturns,
  QuestionVotingMutationReturns,
} from 'client/shared/graphql-mutations/mutation-infos';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import {
  ClientPublishingEntityId,
  publishingEntityPrimaryLogoUrl,
} from 'client/shared/core/publishing-entity';
import { gqlToClient_conditions } from 'client/shared/core/conditions';
import { ClientQuestionSetId, gqlToSetType } from 'client/shared/core/question-set';
import { RESPONDENT_STATE_IN_LOCAL_STORAGE } from 'client/respondent/core/reducers/context';
import { PerformanceDataTx } from 'client/shared/graphql-util/transforms';
import { isPolcoGqlError } from 'client/shared/graphql-client';
import { gqlSimulationNodeTypeToClient } from 'client/shared/core/balancing-act-simulation';
import { GraphQLError } from 'graphql';

type GqlSurvey = ExtractGql<
  NonNullable<Gql.RespondentVotingPage['openContentSetBySlug']>,
  'Survey'
>;

type GqlContentPost = ExtractGql<
  NonNullable<Gql.RespondentVotingPage['openContentSetBySlug']>,
  'ContentPost'
>;

const upvoteFragment = gql`
  fragment upvoteComments on Vote {
    upvotedComments {
      id
    }
  }
`;

const commentUpvoteFragment = gql`
  fragment commentUpvotes on Comment {
    upvoteCount
  }
`;

type UpvotableMutation =
  | { readonly type: 'UPVOTE'; readonly mutation: FetchResult<UpvoteComment> }
  | {
      readonly type: 'UNUPVOTE';
      readonly mutation: FetchResult<UnUpvoteComment>;
    };

function getVote(r: UpvotableMutation) {
  switch (r.type) {
    case 'UPVOTE':
      return r.mutation.data?.upvoteComment;
    case 'UNUPVOTE':
      return r.mutation.data?.unUpvoteComment;
  }
}

export function modifyUpvotes(apolloCache: DataProxy, r: UpvotableMutation) {
  try {
    const data = getVote(r);
    if (!data?.comment) return;
    apolloCache.writeFragment({
      id: 'Comment:' + data.comment.id,
      data: { upvoteCount: data.comment.upvoteCount },
      fragment: commentUpvoteFragment,
    });
    const upvoteData: {
      readonly upvotedComments: readonly {
        readonly id: string;
        readonly __typename: string;
      }[];
    } = apolloCache.readFragment({
      id: 'Vote:' + data.voteId,
      fragment: upvoteFragment,
    }) || { upvotedComments: [] };

    let upvotedComments = upvoteData.upvotedComments;
    if (data.direction === VoteType.UP) {
      upvotedComments = uniqBy(
        concat([{ __typename: 'Comment', id: data.comment.id }], upvotedComments),
        (c) => c.id
      );
    } else {
      upvotedComments = upvotedComments.filter((c) => c.id !== data.comment.id);
    }
    apolloCache.writeFragment({
      id: 'Vote:' + data.voteId,
      data: { upvotedComments },
      fragment: upvoteFragment,
    });
  } catch (err) {
    console.error('modifyUpvotes error', err);
  }
}

export async function loadComments(
  questionId: string,
  page: number,
  fetchMore: QueryResult<VotingVariables>['fetchMore']
) {
  await fetchMore({
    query: VotingDocument,
    variables: {
      questionId,
      pagination: {
        page: page,
        perPage: 30,
      },
      recommendedPage: 0,
    },
    updateQuery: updateQuery<Voting, 'openQuestion'>(
      'openQuestion',
      (oldData, newData) => {
        const { totalCount } = newData.commentsData;
        const comments = uniqBy(
          concat(
            oldData.commentsData.comments || [],
            newData.commentsData.comments || []
          ),
          (c) => c.id
        );
        return {
          ...oldData,
          commentsData: {
            ...oldData.commentsData,
            comments,
            totalCount,
          },
        };
      }
    ),
  });
}

export async function vote(
  upvoteCommentsOnVote: Type['upvoteCommentsOnVote'],
  voteForQuestion: ToMutationReturn<typeof MutationInfos.voteForQuestion>,
  data: VotingProps.Vote,
  questionMetadata: readonly VoteMetadataEntry[]
): Promise<PollSetVoteResult> {
  const upvoteComments = upvoteCommentsOnVote as readonly string[];
  try {
    const voteRes = await wrap(async () => {
      switch (data.type) {
        case QuestionType.FREE_TEXT:
          const comment = data.comment?.comment;
          if (!comment?.length) {
            return null;
          } else {
            return await voteForQuestion.fn({
              variables: {
                questionId: data.questionId,
                voteInput: {
                  type: InputVoteType.VOTE_FREE_TEXT,
                  VOTE_FREE_TEXT: {
                    comment: comment,
                  },
                  VOTE_MULTIPLE_CHOICES: null,
                  VOTE_POINT_ALLOCATION: null,
                },
                upvoteComments,
                voteMetadata: questionMetadata,
              },
            });
          }

        case QuestionType.MULTIPLE_CHOICE:
          return await voteForQuestion.fn({
            variables: {
              questionId: data.questionId,
              voteInput: {
                type: InputVoteType.VOTE_MULTIPLE_CHOICES,
                VOTE_FREE_TEXT: null,
                VOTE_MULTIPLE_CHOICES: {
                  choices: data.choices,
                },
                VOTE_POINT_ALLOCATION: null,
              },
              upvoteComments,
              voteMetadata: questionMetadata,
            },
          });

        case QuestionType.POINT_ALLOCATION:
          return await voteForQuestion.fn({
            variables: {
              questionId: data.questionId,
              voteInput: {
                type: InputVoteType.VOTE_POINT_ALLOCATION,
                VOTE_FREE_TEXT: null,
                VOTE_MULTIPLE_CHOICES: null,
                VOTE_POINT_ALLOCATION: {
                  choices: data.choices,
                },
              },
              upvoteComments,
              voteMetadata: questionMetadata,
            },
          });

        default:
          return null;
      }
    });

    if (voteRes?.data?.voteForQuestion.id) {
      return {
        type: VoteResultType.SUCCESS,
        vote: voteRes?.data,
      };
    }
    return {
      type: VoteResultType.ERROR,
      message:
        voteRes?.errors && voteRes.errors.length > 0
          ? voteRes.errors[0].message
          : PollSetVoteFailureMessage.ERROR,
    };
  } catch (e) {
    console.error('error', e);
    return {
      type: VoteResultType.ERROR,
      message: PollSetVoteFailureMessage.ERROR,
    };
  }
}
export async function addComment(
  addCommentMut: ToMutationReturn<typeof MutationInfos.addComment>,
  questionId: ClientQuestionId,
  comment: string
) {
  return await addCommentMut.fn({
    variables: {
      questionId,
      comment,
    },
  });
}

export async function toggleCommentUpvote(
  ctx: QuestionDispatchContext,
  commentId: string,
  up: boolean
): Promise<void> {
  //TODO - this prevents saving an upvote until you've voted on the question. is that what we want?
  if (!ctx.data.openQuestion?.previousVote) {
    if (up) {
      ctx.dispatch(RespondentActions.prevoteStoreCommentUpvote(commentId));
    } else {
      ctx.dispatch(RespondentActions.prevoteRemoveCommentUpvote(commentId));
    }
    return;
  }
  try {
    if (up && ctx.mut.upvoteComment) {
      await mutate(
        ctx.mut.upvoteComment.fn,
        {
          questionId: ctx.id,
          commentId,
        },
        'upvoteComment'
      );
    } else if (!up && ctx.mut.unUpvoteComment) {
      await mutate(
        ctx.mut.unUpvoteComment.fn,
        {
          questionId: ctx.id,
          commentId,
        },
        'unUpvoteComment'
      );
    }
  } catch (e) {
    console.error('error', e);
    //FIXME - update when Flash is added back
  }
}

export const getVoteData = (
  inProcessVote:
    | VotingProps.Vote_FreeText
    | VotingProps.Vote_MultipleChoice
    | VotingProps.Vote_PointAllocation
): VotingProps.Vote => {
  return inProcessVote.type === QuestionType.FREE_TEXT
    ? {
        comment: inProcessVote.comment
          ? { id: null, comment: inProcessVote.comment.comment }
          : null,
        upvotedCommentIds: inProcessVote.upvotedCommentIds || [],
        type: inProcessVote.type,
        questionId: inProcessVote.questionId,
      }
    : inProcessVote.type === QuestionType.MULTIPLE_CHOICE
      ? {
          choices: inProcessVote.choices,
          comment: inProcessVote.comment
            ? { id: null, comment: inProcessVote.comment.comment }
            : null,
          upvotedCommentIds: inProcessVote.upvotedCommentIds || [],
          type: inProcessVote.type,
          questionId: inProcessVote.questionId,
        }
      : {
          choices: inProcessVote.choices,
          comment: inProcessVote.comment
            ? { id: null, comment: inProcessVote.comment.comment }
            : null,
          upvotedCommentIds: inProcessVote.upvotedCommentIds || [],
          type: inProcessVote.type,
          questionId: inProcessVote.questionId,
        };
};

export function submitVotes_clientToGql(
  surveyInProcessVotes: VotingProps.VotesByQuestionId,
  surveyItems: readonly SavedSurveyItem[]
): readonly Gql.SurveyVoteInputWithId[] {
  return compact(
    surveyItems.map((item): Gql.SurveyVoteInputWithId | null => {
      if (!item.data.id) {
        return null;
      }
      const inProcessVote = surveyInProcessVotes[item.data.id];
      if (!inProcessVote) {
        return null;
      }

      const result = wrap((): Gql.SurveyVoteInputWithId | null => {
        switch (inProcessVote.type) {
          case QuestionType.MULTIPLE_CHOICE:
            if (inProcessVote.choices.length === 0) {
              return null;
            }
            return {
              questionOrGridId: inProcessVote.questionId,
              input: {
                type: Gql.SurveyVoteInputType.QUESTION,
                QUESTION: {
                  type: Gql.InputVoteType.VOTE_MULTIPLE_CHOICES,
                  VOTE_MULTIPLE_CHOICES: {
                    choices: inProcessVote.choices,
                  },
                },
              },
            };
          case QuestionType.POINT_ALLOCATION:
            if (inProcessVote.choices.length === 0) {
              return null;
            }
            return {
              questionOrGridId: inProcessVote.questionId,
              input: {
                type: Gql.SurveyVoteInputType.QUESTION,
                QUESTION: {
                  type: Gql.InputVoteType.VOTE_POINT_ALLOCATION,
                  VOTE_POINT_ALLOCATION: {
                    choices: inProcessVote.choices.map((ch) => ({
                      id: ch.id,
                      point: ch.point,
                    })),
                  },
                },
              },
            };
          case QuestionType.FREE_TEXT:
            if (!inProcessVote.comment?.comment) {
              return null;
            }
            return {
              questionOrGridId: inProcessVote.questionId,
              input: {
                type: Gql.SurveyVoteInputType.QUESTION,
                QUESTION: {
                  type: Gql.InputVoteType.VOTE_FREE_TEXT,
                  VOTE_FREE_TEXT: {
                    comment: inProcessVote.comment.comment,
                  },
                },
              },
            };
          case QuestionType.GRID_CHOICE:
            if (
              item.type !== SurveyItemType.QUESTION ||
              item.data.typedData.type !== QuestionType.GRID_CHOICE
            ) {
              return null;
            }
            const gridVotes = compact(
              item.data.typedData.rows.map((r) => {
                const choiceId = inProcessVote.gridChoiceByRowId[r.questionId];
                return choiceId ? { questionId: r.questionId, choiceId } : null;
              })
            );

            return {
              questionOrGridId: inProcessVote.questionId,
              input: {
                type: Gql.SurveyVoteInputType.GRID,
                GRID: gridVotes,
              },
            };
          default:
            throw new Error('not supported question type');
        }
      });

      return result;
    })
  );
}

function getClientSurveyItems(
  surveyItems: GqlSurvey['contents'],
  tx: SelectLanguageTextFunction
): readonly SavedSurveyItem[] {
  const questions = compact(
    surveyItems.map((i, idx) => {
      return gqlToSavedSurveyItem(i, tx, idx + 1);
    })
  );
  return questions;
}

function gqlToClientSurveyResponse(
  survey: GqlSurvey | GqlContentPost,
  selectLanguageText: SelectLanguageTextFunction
): SurveyResponseProps {
  const result: SurveyResponseProps = {
    surveyTitle: selectLanguageText(survey.name),
    expireDate: ApiDate.fromApi(survey.schedule.closeDate),
    description: selectLanguageText(survey.description) ?? '',
    surveyItems: getClientSurveyItems(survey.contents, selectLanguageText),
    alternateLanguages: compact(
      survey.alternateLanguages.map((language) =>
        isEnum(LanguageKey, language.key)
          ? {
              key: language.key,
              name: language.name,
            }
          : null
      )
    ),
  };
  return result;
}

function gqlToSavedSurveyItem(
  item: GqlSurvey['contents'][0],
  selectLanguageText: SelectLanguageTextFunction,
  defaultQuestionNumber: number
): SavedSurveyItem | null {
  switch (item.__typename) {
    case 'QuestionHierarchyParentNode':
      return wrap(() => {
        switch (item.nodeType) {
          case Gql.QuestionHierarchyParentNodeType.HEADER:
            const typedData: PositionTypedData = {
              type: PositionType.SECTION_HEADER,
              label: selectLanguageText(item.label) ?? '',
              description: selectLanguageText(item.description) ?? '',
            };
            const hierarchy: SavedSurveyItem = {
              type: SurveyItemType.HEADER,
              data: {
                id: item.id,
                title: selectLanguageText(item.label) ?? '',
                descriptionHtml: selectLanguageText(item.description) ?? null,
                typedData,
                questions: [],
                conditions: gqlToClient_conditions(item.conditions),
              },
            };
            return hierarchy;
          case Gql.QuestionHierarchyParentNodeType.GRID:
            const firstRow = first(item.questions);
            if (!firstRow) {
              throw new Error('empty grid');
            }
            const gridTypedData: QuestionTypedData = {
              type: QuestionType.GRID_CHOICE,
              randomizeChoices: item.questions[0].choiceSet.randomizeChoices,
              rows: item.questions.map((q) => ({
                questionId: q.id,
                label: selectLanguageText(q.title),
                conditions: gqlToClient_conditions(q.conditions),
              })),
              columns: firstRow.choiceSet.choices.map((ch) => ({
                id: ch.id,
                label: selectLanguageText(ch.text),
              })),
              shareableQuestionChoiceSetId:
                firstRow.choiceSet.sharedChoiceSetData?.id ?? null,
            };
            const gridHierarchy: SavedSurveyItem = {
              type: SurveyItemType.QUESTION,
              questionNumber: item.questionNumber ?? defaultQuestionNumber,
              data: {
                id: item.id,
                title: selectLanguageText(item.label) ?? '',
                description: selectLanguageText(item.description) ?? null,
                images: [],
                typedData: gridTypedData,
                status: firstRow.schedule.status,
                shortId: null,
                optional: firstRow.optional,
                demographicAttribute: null, // Grids cannot be demographic questions
                conditions: gqlToClient_conditions(item.conditions),
              },
            };
            return gridHierarchy;
        }
      });
    case 'Question':
      return {
        type: SurveyItemType.QUESTION,
        questionNumber: item.questionNumber ?? defaultQuestionNumber,
        data: gqlToClient_respondent(
          {
            ...item,
            schedule: {
              ...item.schedule,
            },
          },
          selectLanguageText
        ),
      };
    case 'QuestionHierarchyVisualizationNode':
      return {
        type: SurveyItemType.VISUALIZATION,
        data: {
          id: item.id,
          visualization: item.trackVariableVisualization
            ? {
                ...PerformanceDataTx.gqlVisualization_toClient(
                  item.trackVariableVisualization
                ),
                plans: item.trackVariableVisualization.plans,
              }
            : null,
          conditions: gqlToClient_conditions(item.conditions),
          label: item.altLabel,
        },
      };
    case 'QuestionHierarchySimulationNode':
      return {
        type: SurveyItemType.SIMULATION,
        data: {
          id: item.id,
          conditions: gqlToClient_conditions(item.conditions),
          simulation: item.simulation
            ? {
                id: item.simulation.id,
                url: item.simulation.url,
                adminData: null,
              }
            : null,
          simulationType: gqlSimulationNodeTypeToClient(item.simulationType),
        },
      };
  }
}

export function convertToSurveyProps(
  state: Type,
  dispatch: React.Dispatch<any>,
  query: ToQueryResult<typeof QueryInfos.respondentVotingPage>,
  mut: SurveyVotingMutationReturns,
  redirect: RedirectFunction,
  set: {
    readonly id: ClientQuestionSetId;
    readonly slug: string | null;
    readonly pubSlug: string;
  },
  resp: CurrentRespondentData | null,
  goBack: () => void,
  goToFeed: () => void,
  showingSidebars: boolean,
  contextPublishingEntityId: ClientPublishingEntityId | null,
  selectLanguageText: SelectLanguageTextFunction
): VotingProps.SurveyProps {
  const respIsGuest = resp?.guest ?? true;
  const respId = resp?.id ?? null;
  // questions
  const previousSurveyVote: VotingProps.SurveyPrevVotes | null =
    query.data?.openContentSetBySlug?.__typename === 'Survey' ||
    query.data?.openContentSetBySlug?.__typename === 'ContentPost'
      ? compact(
          query.data.openContentSetBySlug.contents.map((c) => {
            // questions
            if (c.__typename === 'Question') {
              if (c.previousVote) {
                const voteData: VotingProps.SurveyPrevQuestionVote = {
                  type: SurveyItemType.QUESTION,
                  vote: c.previousVote,
                };
                return voteData;
              }
              return null;
            }
            if (c.__typename === 'QuestionHierarchyVisualizationNode') {
              return null;
            }
            if (c.__typename === 'QuestionHierarchySimulationNode') {
              return null;
            }
            // questionHierarchy - grid
            if (c.nodeType === Gql.QuestionHierarchyParentNodeType.GRID) {
              const gridHasVote = c.questions.find((g) => !!g.previousVote);
              if (!gridHasVote) {
                return null;
              }
              const voteData: VotingProps.SurveyPrevGridVote = {
                type: SurveyItemType.HEADER,
                gridId: c.id,
                votes: c.questions.map((g) => g.previousVote),
              };
              return voteData;
            }
            return null;
          })
        )
      : [];

  const previousVotes = gqlPrevVoteToProps(previousSurveyVote);
  const voted = previousVotes.length !== 0;

  const openSurvey =
    query.data?.openContentSetBySlug?.__typename === 'Survey' ||
    query.data?.openContentSetBySlug?.__typename === 'ContentPost'
      ? query.data.openContentSetBySlug
      : null;

  const loading = () => {
    const ret: VotingProps.Loading = {
      type: VotingProps.VotingPropsType.LOADING,
      id: set.id || 'loading',
      answerChangeability: AnswerChangeability.CAN_CHANGE_COMMENT,
    };
    return ret;
  };
  const votingTypeResult = determineSurveyVotingType(set.id, query);

  switch (votingTypeResult.type) {
    case VotingProps.VotingPropsType.LOADING:
      return loading();
    case VotingProps.VotingPropsType.ERROR_LOADING: {
      const errs = votingTypeResult.error.graphQLErrors;
      let err: ModalErrorData = {
        title: 'SERVER ERROR',
        description:
          "We're sorry, something went wrong while loading your question.",
      };
      if (errs.length > 0) {
        const rawErr = errs[0];
        err = VotingRules.parseError(rawErr);
      }

      return as<VotingProps.ErrorLoading>({
        id: set.id || 'invalid',
        type: votingTypeResult.type,
        errorTitle: err.title,
        errorDescription: err.description,
        answerChangeability: AnswerChangeability.CAN_CHANGE_COMMENT,
      });
    }
    case VotingProps.VotingPropsType.NOT_FOUND: {
      return as<VotingProps.ErrorLoading>({
        id: set.id || 'not found',
        type: VotingProps.VotingPropsType.NOT_FOUND,
        errorTitle: 'Question not found',
        errorDescription: "we don't have that one",
        answerChangeability: AnswerChangeability.CAN_CHANGE_COMMENT,
      });
    }
    case VotingProps.VotingPropsType.CLOSED: {
      if (!openSurvey) {
        throw new Error("couldn't find openSurvey");
      }
      const fromGql = gqlToClientSurveyResponse(openSurvey, selectLanguageText);
      const slugs = {
        publishingEntity: openSurvey.publishingEntity.slug,
        questionSet: openSurvey.slug ?? openSurvey.id,
      };

      const ctxPubOrOriginalPub =
        query.data?.contextPublishingEntity ?? openSurvey.publishingEntity;
      const hasQuestions =
        openSurvey.contents.flatMap((c) => {
          switch (c.__typename) {
            case 'Question':
              return c;
            case 'QuestionHierarchyParentNode':
              return c.questions;
            default:
              return [];
          }
        }).length > 0;
      const events = mapContextToSurveyEvents(
        slugs,
        redirect,
        goBack,
        goToFeed,
        openSurvey.id,
        dispatch,
        state,
        mut.voteForSurvey,
        mut.followOnVoting,
        mut.logout,
        contextPublishingEntityId
      );

      const closed: VotingProps.SurveyClosed = {
        ...fromGql,
        publisher: selectLanguageText(ctxPubOrOriginalPub.name),
        repostData:
          query.data?.contextPublishingEntity &&
          query.data.contextPublishingEntity.id !== openSurvey.publishingEntity.id
            ? {
                reposterName:
                  selectLanguageText(query.data?.contextPublishingEntity?.name) ??
                  null,
                reposterId: query.data?.contextPublishingEntity?.id,
              }
            : null,
        publisherId: openSurvey.publishingEntity.id,
        publisherSlug: openSurvey.publishingEntity.slug,
        logoUrl: publishingEntityPrimaryLogoUrl(openSurvey.publishingEntity.assets),
        type: VotingProps.VotingPropsType.CLOSED,
        questionSetId: openSurvey.id,
        questionSetSlug: openSurvey.slug,
        id: openSurvey.id,
        datePublished: ApiDate.fromApi(openSurvey.schedule.openDate) ?? null,
        answerChangeability: null,
        errMessage: mut.voteForSurvey.result.error?.message ?? null,
        previousVotes,
        votingInteraction: state.interaction,
        showingSidebars,
        voted,
        respId,
        allowGuestRespondents: openSurvey.allowGuestRespondents,
        surveyInProcessVotes: null,
        preLoginAction: null,
        randomizedSurveyItems: null,
        events,
        closed: true,
        requiredToLogin:
          hasQuestions &&
          !openSurvey.allowGuestRespondents &&
          (!respId || respIsGuest),
        subscriptionType: openSurvey.publishingEntity.subscriptionType,
        isGuest: respIsGuest,
        status: openSurvey.schedule.status,
        estCompletionTime: openSurvey.estCompletionTime,
        requiresVerificationPrompt: resp?.requiresVerificationPrompt ?? true,
        showVerification: !resp?.firstName || !resp?.lastName,
        disabledSubmit: true,
        hasOutcome: openSurvey.outcome?.state === Gql.ContentOutcomeState.PUBLISHED,
        allowMultipleResponses: openSurvey.allowMultipleResponses,
        isShareable: openSurvey.shareable,
        contentType: gqlToSetType(openSurvey.__typename),
      };

      return closed;
    }
    case VotingProps.VotingPropsType.LOADED: {
      if (!openSurvey) {
        throw new Error("couldn't find openSurvey");
      }

      const hasQuestions =
        openSurvey.contents.flatMap((c) => {
          switch (c.__typename) {
            case 'Question':
              return c;
            case 'QuestionHierarchyParentNode':
              return c.questions;
            default:
              return [];
          }
        }).length > 0;

      const fromGql = gqlToClientSurveyResponse(openSurvey, selectLanguageText);
      const slugs = {
        publishingEntity: openSurvey.publishingEntity.slug,
        questionSet: openSurvey.slug ?? openSurvey.id,
      };

      // prefer context pub first, if it doesn't exist, use original pub
      const ctxPubOrOriginalPub =
        query.data?.contextPublishingEntity ?? openSurvey.publishingEntity;

      const events = mapContextToSurveyEvents(
        slugs,
        redirect,
        goBack,
        goToFeed,
        openSurvey.id,
        dispatch,
        state,
        mut.voteForSurvey,
        mut.followOnVoting,
        mut.logout,
        contextPublishingEntityId
      );

      /* The survey is disabled if you -
      1. Have not answered any of the questions
      2. Have already submitted your vote
      3. The survey is closed
      4. The survey is not yet published (visible to admins of the survey)
      */
      const disabledSubmit =
        voted ||
        !compact(
          (state.surveyItems ?? []).map(
            (item) =>
              state.inProcessVotesByQuestionSet?.[openSurvey.id]?.[item.data.id]
          )
        ).some(hasVote) ||
        openSurvey.schedule.status !== Gql.QuestionSetStatus.IN_PROGRESS;

      const loaded: VotingProps.SurveyLoaded = {
        ...fromGql,
        publisher: selectLanguageText(ctxPubOrOriginalPub.name),
        repostData:
          query.data?.contextPublishingEntity &&
          query.data.contextPublishingEntity.id !== openSurvey.publishingEntity.id
            ? {
                reposterName:
                  selectLanguageText(query.data.contextPublishingEntity.name) ??
                  null,
                reposterId: query.data.contextPublishingEntity.id,
              }
            : null,
        publisherSlug: ctxPubOrOriginalPub.slug,
        type: VotingProps.VotingPropsType.LOADED,
        questionSetId: openSurvey.id,
        questionSetSlug: openSurvey.slug,
        id: openSurvey.id,
        logoUrl: publishingEntityPrimaryLogoUrl(ctxPubOrOriginalPub.assets),
        events,
        datePublished: ApiDate.fromApi(openSurvey.schedule.openDate) ?? null,
        answerChangeability: null,
        surveyInProcessVotes:
          state.inProcessVotesByQuestionSet?.[openSurvey.id] ?? null,
        errMessage: mut.voteForSurvey.result.error?.message ?? null,
        previousVotes,
        allowGuestRespondents: openSurvey.allowGuestRespondents,
        voted,
        respId,
        preLoginAction: state.preLoginAction,
        randomizedSurveyItems: state.randomizedSurveyItems,
        votingInteraction: state.interaction,
        publisherId: ctxPubOrOriginalPub.id,
        showingSidebars,
        closed: false,
        requiredToLogin:
          hasQuestions &&
          !openSurvey.allowGuestRespondents &&
          (!respId || respIsGuest),
        subscriptionType: ctxPubOrOriginalPub.subscriptionType,
        isGuest: respIsGuest,
        status: openSurvey.schedule.status,
        estCompletionTime: hasQuestions ? openSurvey.estCompletionTime : '',
        requiresVerificationPrompt: resp?.requiresVerificationPrompt ?? true,
        showVerification: !resp?.firstName || !resp?.lastName,
        disabledSubmit,
        showConversionPrompts: openSurvey.showConversionPrompts,
        hasOutcome: openSurvey.outcome?.state === Gql.ContentOutcomeState.PUBLISHED,
        allowMultipleResponses: openSurvey.allowMultipleResponses,
        isShareable: openSurvey.shareable,
        contentType: gqlToSetType(openSurvey.__typename),
      };
      return loaded;
    }
    default:
      return loading();
  }
}

function mapContextToSurveyEvents(
  slugs: {
    readonly publishingEntity: string;
    readonly questionSet: string;
  },
  redirect: RedirectFunction,
  goBack: () => void,
  goToFeed: () => void,
  setId: ClientQuestionSetId,
  dispatch: React.Dispatch<any>,
  state: Type,
  voteForSurvey: SurveyVotingMutationReturns['voteForSurvey'],
  followPublisher: SurveyVotingMutationReturns['followOnVoting'],
  logout: SurveyVotingMutationReturns['logout'],
  contextPublishingEntityId: ClientPublishingEntityId | null
): SurveyLoadedEvents {
  return {
    setRandomizedSurveyItems: (randomizedSurveyItems: RandomizedSurveyItems) => {
      dispatch(RespondentActions.setRandomizedSurveyItems(randomizedSurveyItems));
    },
    clearRandomizedSurveyItems: (
      choicesIds: readonly string[] | readonly ClientQuestionId[]
    ) => {
      dispatch(RespondentActions.clearRandomizedSurveyItems(choicesIds));
    },
    surveySelectPointAllocation: (
      choices: readonly PointAllocationChoice[],
      questionId: ClientQuestionId
    ) => {
      dispatch(RespondentActions.surveySelectedPointAllocation(choices, questionId));
    },
    surveySelectMultipleChoice: (
      choices: readonly MCChoice[],
      questionId: ClientQuestionId
    ) => {
      dispatch(RespondentActions.surveySelectedMultipleChoice(choices, questionId));
    },
    surveyUpdateFreeTextComment: (questionId: ClientQuestionId, comment: string) => {
      dispatch(RespondentActions.surveyUpdateFreeTextComment(comment, questionId));
    },
    startSurveyInProcessVote: (
      id: ClientQuestionSetId,
      surveyItems: readonly SavedSurveyItem[]
    ) => {
      dispatch(RespondentActions.startSurveyInProcessVote(id, surveyItems));
    },
    surveySubmitClearVote: (id: ClientQuestionSetId) => {
      dispatch(RespondentActions.surveySubmitClearVote(id));
    },
    surveySelectGridChoice: (surveyGridChoiceArgs: {
      readonly questionId: ClientQuestionId;
      readonly gridChoice: GridChoice;
      readonly numChoices: number;
    }) => {
      const { questionId, gridChoice, numChoices } = surveyGridChoiceArgs;
      dispatch(
        RespondentActions.surveySelectedGridChoice(
          questionId,
          gridChoice,
          numChoices
        )
      );
    },
    submitVotes: async () => {
      if (!state.inProcessVotesByQuestionSet?.[setId]) {
        throw 'Cannot find survey vote';
      } else {
        return await handleVoteForSurvey({
          surveyInProcessVotes: state.inProcessVotesByQuestionSet[setId],
          surveyInProcessId: setId,
          surveyItems: state.surveyItems ?? [],
          preLoginAction: state.preLoginAction,
          voteForSurvey,
          dispatch,
          questionMetadata: state.questionMetadata ?? [],
        });
      }
    },
    goToFeed,
    goBack: goBack,
    bookmark: () => {
      console.log('bookmark');
    },
    promptRegistration: () => {
      dispatch(
        RespondentActions.promptRegistration({
          actionType: NotLoggedInActions.SURVEY_VOTE,
          redirectLink: ClientUrlUtils.respondent.set.path({
            pubSlug: slugs.publishingEntity,
            setIdOrSlug: slugs.questionSet,
            setType: QuestionSetType.SURVEY,
          }),
          data: {
            setId: setId,
          },
          status: NotLoggedInActionStatus.PRE_REGISTRATION,
          registrationType: null,
        })
      );
    },
    login: (redirectUrl: string) => {
      dispatch(
        RespondentActions.promptRegistration({
          actionType: NotLoggedInActions.LINK_ONLY,
          redirectLink: redirectUrl,
          data: {},
          status: NotLoggedInActionStatus.PRE_REGISTRATION,
          registrationType: null,
        })
      );
      redirect(ClientUrlUtils.respondent.login.path(), {
        push: true,
      });
    },
    cancelInteraction: () => dispatch(RespondentActions.cancelInteraction()),
    followPublisher: async (respondentId) => {
      if (respondentId && contextPublishingEntityId) {
        await followPublisher.fn({
          variables: {
            follow: true,
            publishingEntityId: contextPublishingEntityId,
            respondentId,
          },
        });
      }
    },
    logout: async () => {
      await logout.fn();
      if (localStorage) {
        localStorage.removeItem(RESPONDENT_STATE_IN_LOCAL_STORAGE);
      }
    },
  };
}

async function handleVoteForSurvey(args: {
  readonly surveyInProcessVotes: VotingProps.VotesByQuestionId;
  readonly surveyInProcessId: ClientQuestionSetId;
  readonly surveyItems: readonly SavedSurveyItem[];
  readonly preLoginAction: PreLoginAction | null;
  readonly voteForSurvey: SurveyVotingMutationReturns['voteForSurvey'];
  readonly dispatch: React.Dispatch<any>;
  readonly questionMetadata: readonly QuestionMetadataEntry[];
}): Promise<boolean> {
  try {
    await args.voteForSurvey.fn({
      variables: {
        questionSetId: args.surveyInProcessId,
        surveyInput: submitVotes_clientToGql(
          args.surveyInProcessVotes,
          args.surveyItems
        ),
        voteMetadata: args.questionMetadata,
      },
      // wait for user refresh, so that value is available if subsequent
      // events depend on that context
      awaitRefetchQueries: true,
    });
    if (
      args.preLoginAction?.actionType === NotLoggedInActions.SURVEY_VOTE &&
      args.preLoginAction.data.setId === args.surveyInProcessId
    ) {
      args.dispatch(RespondentActions.completePreLoginAction());
    }
    return true;
  } catch {
    return false;
  }
}

function gqlPrevVoteToProps(
  prevQuestionVotes: VotingProps.SurveyPrevVotes
): readonly VotingProps.Vote[] {
  const result: readonly VotingProps.Vote[] = compact(
    prevQuestionVotes?.map((v) => {
      if (v.type === SurveyItemType.QUESTION) {
        if (!v.vote) {
          return null;
        }
        const question = v.vote.question;
        switch (question.choiceSet.type) {
          case Gql.QuestionType.MULTIPLE_CHOICE:
            const mc: VotingProps.Vote_MultipleChoice = {
              type: QuestionType.MULTIPLE_CHOICE,
              choices: compact(
                v.vote.choices?.map((c) => ({
                  id: c.questionChoice.id,
                }))
              ),
              comment: null,
              questionId: question.id,
              upvotedCommentIds: [],
            };

            return mc;
          case Gql.QuestionType.POINT_ALLOCATION:
            const pa: VotingProps.Vote_PointAllocation = {
              type: QuestionType.POINT_ALLOCATION,
              choices: compact(
                v.vote.choices?.map((c) => ({
                  id: c.questionChoice.id,
                  point: c.allocation * 10,
                }))
              ),
              comment: null,
              questionId: question.id,
              upvotedCommentIds: [],
            };
            return pa;
          case Gql.QuestionType.FREE_TEXT:
            const free: VotingProps.Vote_FreeText = {
              type: QuestionType.FREE_TEXT,
              comment: v.vote.comment,
              questionId: question.id,
              upvotedCommentIds: [],
            };
            return free;
          default:
            break;
        }
      } else if (v.type === SurveyItemType.HEADER) {
        const grid: VotingProps.Vote_GridChoice = {
          type: QuestionType.GRID_CHOICE,
          comment: null,
          questionId: v.gridId,
          gridChoiceByRowId: fromPairs(
            compact(
              v.votes.map((gVote) =>
                gVote?.question && gVote.choices?.[0]
                  ? [gVote.question.id, gVote.choices[0].questionChoice.id]
                  : null
              )
            )
          ),
          upvotedCommentIds: [],
        };
        return grid;
      } else {
        return null;
      }
    })
  );

  return result;
}

function determineSurveyVotingType(
  setId: ClientQuestionSetId,
  result: ToQueryResult<typeof QueryInfos.respondentVotingPage>
): SetVotingTypeResult {
  const openSurvey =
    result.data?.openContentSetBySlug?.__typename === 'Survey' ||
    result.data?.openContentSetBySlug?.__typename === 'ContentPost'
      ? result.data.openContentSetBySlug
      : null;

  if (!result.data && result.loading) {
    return { type: VotingProps.VotingPropsType.LOADING };
  } else if (result.data) {
    if (result.error && surveyErrorsNotForMissingNodes(result.error.graphQLErrors)) {
      return {
        type: VotingProps.VotingPropsType.ERROR_LOADING,
        error: result.error,
      };
    } else if (
      openSurvey &&
      (openSurvey.schedule.status === Gql.QuestionSetStatus.CLOSED ||
        openSurvey.schedule.status === Gql.QuestionSetStatus.ARCHIVED)
    ) {
      return {
        type: VotingProps.VotingPropsType.CLOSED,
        result,
        data: result.data,
      };
    } else if (openSurvey && setId === openSurvey.id) {
      return {
        type: VotingProps.VotingPropsType.LOADED,
        result,
        data: result.data,
      };
    } else if (openSurvey && openSurvey.id !== setId) {
      return { type: VotingProps.VotingPropsType.LOADING };
    } else {
      return { type: VotingProps.VotingPropsType.NOT_FOUND };
    }
  } else {
    return { type: VotingProps.VotingPropsType.LOADING };
  }
}

function surveyErrorsNotForMissingNodes(
  gqlErrors: readonly GraphQLError[]
): boolean {
  return gqlErrors.some((e) => {
    return (
      (!isPolcoGqlError<ApiError>(e) ||
        e.extra.errors.name !== 'VISUALIZATION_NOT_FOUND') &&
      (!isPolcoGqlError<PolcoGqlErrors.BalancingActSimulationResponseError>(e) ||
        e.extra.errors !== 'NO_SIMS_FOUND') &&
      (!isPolcoGqlError<PolcoGqlErrors.BalancingActSimulationResponseError>(e) ||
        e.extra.errors !== 'NO_TAX_RECEIPT_FOUND')
    );
  });
}

function determineQuestionVotingType(
  questionId: ClientQuestionId | null,
  result: QueryResult<Gql.Voting, Gql.VotingVariables>,
  questionSetQueryResult: QueryResult<
    Gql.RespondentVotingPage,
    Gql.RespondentVotingPageVariables
  >,
  selectLanguageText: SelectLanguageTextFunction
): QuestionVotingTypeResult {
  const questionSetQueryData =
    questionSetQueryResult.data?.openContentSetBySlug?.__typename ===
      'QuestionSet' ||
    questionSetQueryResult.data?.openContentSetBySlug?.__typename === 'PolcoLive'
      ? questionSetQueryResult.data?.openContentSetBySlug
      : null;
  const subscriptionType =
    questionSetQueryResult.data?.openContentSetBySlug?.publishingEntity
      .subscriptionType ?? null;

  if (
    result.data &&
    ((result.loading && !result.data.openQuestion) ||
      (questionSetQueryResult.loading && !questionSetQueryResult.data))
  ) {
    return { type: VotingProps.VotingPropsType.LOADING };
  } else if (result.data) {
    if (result.error) {
      return {
        type: VotingProps.VotingPropsType.ERROR_LOADING,
        error: result.error,
      };
    } else if (
      result.data.openQuestion &&
      result.data.openQuestion.id === questionId &&
      questionSetQueryData
    ) {
      const setType =
        questionSetQueryData.__typename === 'PolcoLive'
          ? VotingProps.SetType.POLCO_LIVE
          : VotingProps.SetType.POLL_SET;

      const hasLiveVideo =
        questionSetQueryResult.data?.openContentSetBySlug?.__typename ===
          'PolcoLive' &&
        questionSetQueryResult.data.openContentSetBySlug.settings.liveVideoLink
          ? true
          : false;

      return {
        type: VotingProps.VotingPropsType.LOADED,
        result,
        data: result.data,
        questionSetQueryData: questionSetQueryData.setForRespondentVote,
        set: {
          id: questionSetQueryData.id,
          name: selectLanguageText(questionSetQueryData.name),
          setType,
          slug: questionSetQueryData.slug,
          pubSlug: questionSetQueryData.publishingEntity.slug,
          shareable: questionSetQueryData.shareable,
        },
        subscriptionType,
        hasLiveVideo,
        contextPublishingEntity:
          questionSetQueryResult.data?.contextPublishingEntity ?? null,
      };
    } else if (
      result.data.openQuestion &&
      result.data.openQuestion.id !== questionId &&
      result.loading
    ) {
      return { type: VotingProps.VotingPropsType.LOADING };
    } else {
      return { type: VotingProps.VotingPropsType.NOT_FOUND };
    }
  } else {
    return { type: VotingProps.VotingPropsType.LOADING };
  }
}

export function convertToQuestionVotingProps(
  state: Type,
  dispatch: React.Dispatch<any>,
  qResult: ToQueryResult<typeof QueryInfos.votingPage>,
  mut: QuestionVotingMutationReturns,
  redirect: RedirectFunction,
  questionSetQueryResult: ToQueryResult<typeof QueryInfos.respondentVotingPage>,
  currentRespondent: CurrentUser | null,
  questionId: ClientQuestionId | null,
  goBack: () => void,
  selectLanguageText: SelectLanguageTextFunction,
  localStorage: Storage | null,
  redirectedFrom?: QuestionPageRedirectLocation
): VotingProps.QuestionProps {
  const loading = () => {
    const ret: VotingProps.Loading = {
      type: VotingProps.VotingPropsType.LOADING,
      id: questionId || 'loading',
      answerChangeability: AnswerChangeability.CAN_CHANGE_COMMENT,
    };
    return ret;
  };

  const votingTypeResult = determineQuestionVotingType(
    questionId,
    qResult,
    questionSetQueryResult,
    selectLanguageText
  );

  switch (votingTypeResult.type) {
    case VotingProps.VotingPropsType.LOADING:
      return loading();

    case VotingProps.VotingPropsType.ERROR_LOADING: {
      const errs = votingTypeResult.error.graphQLErrors;
      let err: ModalErrorData = {
        title: 'SERVER ERROR',
        description:
          "We're sorry, something went wrong while loading your question.",
      };
      if (errs.length > 0) {
        const rawErr = errs[0];
        err = VotingRules.parseError(rawErr);
      }
      return as<VotingProps.ErrorLoading>({
        id: questionId || 'invalid',
        type: votingTypeResult.type,
        errorTitle: err.title,
        errorDescription: err.description,
        answerChangeability: AnswerChangeability.CAN_CHANGE_COMMENT,
      });
    }
    case VotingProps.VotingPropsType.LOADED: {
      const inFlight = votingTypeResult.result.networkStatus < 7;
      const fromGql = VotingRules.graphqlToProps(
        votingTypeResult.subscriptionType,
        votingTypeResult.contextPublishingEntity?.name ?? null,
        votingTypeResult.contextPublishingEntity?.id ?? null,
        currentRespondent,
        votingTypeResult.data,
        inFlight,
        state,
        votingTypeResult.set.shareable,
        votingTypeResult.set.setType,
        votingTypeResult.questionSetQueryData.status,
        selectLanguageText
      );

      const ctx: QuestionDispatchContext = {
        id: questionId || 'fail',
        mut,
        dispatch,
        state,
        query: votingTypeResult.result,
        data: votingTypeResult.data,
        user: currentRespondent,
        localStorage,
      };
      const events = mapContextToEvents(
        ctx,
        redirect,
        goBack,
        votingTypeResult.set,
        votingTypeResult.contextPublishingEntity
      );

      // used to calculate current place in the set to move forwards and backwards
      const index = votingTypeResult.questionSetQueryData.questions.findIndex(
        (q) => q.id === questionId
      );

      const setData: VotingProps.SetData = {
        questions: votingTypeResult.questionSetQueryData.questions.map((q) => ({
          ...q,
          title: selectLanguageText(q.title),
          previousVote: q.previousVote?.id ?? null,
          schedule: {
            openDate: ApiDate.fromApi(q.schedule.openDate),
            closeDate: ApiDate.fromApi(q.schedule.closeDate),
            status: q.schedule.status,
          },
        })),
        setName: votingTypeResult.set.name,
        hasLiveVideo: votingTypeResult.hasLiveVideo,
        currentIndex: Math.max(0, index),
      };

      return as<VotingProps.QuestionSetLoaded>({
        ...fromGql,
        events,
        setData,
        questionSetId: votingTypeResult.set.id,
        redirectedFrom,
      });
    }
    case VotingProps.VotingPropsType.NOT_FOUND: {
      return as<VotingProps.ErrorLoading>({
        id: questionId || 'not found',
        type: VotingProps.VotingPropsType.NOT_FOUND,
        errorTitle: 'Question not found',
        errorDescription: "we don't have that one",
        answerChangeability: AnswerChangeability.CAN_CHANGE_COMMENT,
      });
    }
    default:
      return loading();
  }
}
