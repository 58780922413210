import { publishingEntityPrimaryLogoUrl } from 'client/shared/core/publishing-entity';
import {
  RespondentActiveSubscriptionsForIdea,
  RespondentSubscriptions,
  SubscriptionType,
} from 'client/shared/graphql-client/graphql-operations.g';
import { SelectLanguageTextFunction } from 'client/shared/hooks';

import { FollowingPublisher } from '../core/types';

export enum RespondentSubscriptionsQueryType {
  SUBSCRIBED_PUBS = 'SUBSCRIBED_PUBS',
  SUBSCRIBABLE_PUBS = 'SUBSCRIBABLE_PUBS',
  CAN_PUBLISH_IDEA_PUBS = 'CAN_PUBLISH_IDEA_PUBS',
}

type RespondentSubscriptionsQuery =
  | {
      readonly type: RespondentSubscriptionsQueryType.SUBSCRIBED_PUBS;
      readonly respondent: RespondentSubscriptions['openRespondent'];
    }
  | {
      readonly type: RespondentSubscriptionsQueryType.SUBSCRIBABLE_PUBS;
      readonly respondent: RespondentSubscriptions['openRespondent'];
    }
  | {
      readonly type: RespondentSubscriptionsQueryType.CAN_PUBLISH_IDEA_PUBS;
      readonly respondent: RespondentActiveSubscriptionsForIdea['openRespondent'];
    };

export function gqlToClient_FollowingPublisher(
  respondentQuery: RespondentSubscriptionsQuery,
  selectLanguageText: SelectLanguageTextFunction
): readonly FollowingPublisher[] {
  const subs = getSubscriptions(respondentQuery);

  const isSubscribed = (subType: SubscriptionType | null): boolean => {
    switch (subType) {
      case SubscriptionType.AUTOMATIC:
        return true;
      case SubscriptionType.MANUAL:
        return true;
      case SubscriptionType.MANUAL_UNFOLLOWED:
        return false;
      default:
        return false;
    }
  };

  return subs.map((p) => ({
    id: p.id,
    name: selectLanguageText(p.name),
    slug: p.slug,
    location: null,
    seal: publishingEntityPrimaryLogoUrl(p.assets),
    currentRespondentFollowing: isSubscribed(p.subscriptionType),
  }));
}

function getSubscriptions(respondentQuery: RespondentSubscriptionsQuery) {
  switch (respondentQuery.type) {
    case RespondentSubscriptionsQueryType.SUBSCRIBED_PUBS:
      return respondentQuery.respondent?.__typename === 'PrivateRespondent'
        ? respondentQuery.respondent.subscriptions
        : [];
    case RespondentSubscriptionsQueryType.SUBSCRIBABLE_PUBS:
      return respondentQuery.respondent?.__typename === 'PrivateRespondent'
        ? respondentQuery.respondent.subscribablePublishers
        : [];
    case RespondentSubscriptionsQueryType.CAN_PUBLISH_IDEA_PUBS:
      return respondentQuery.respondent?.__typename === 'PrivateRespondent'
        ? respondentQuery.respondent.subscriptionsCanPublishIdeas
        : [];
  }
}
