import { MIN_PASSWORD_LENGTH, SUPPORT_EMAIL } from 'core';

export const ACCESS_COPY = {
  or: 'or',
  emailLogin: {
    title: 'Login to Polco',
    adminNote: '  This page is for residents and community members.',
    loginAsAdmin: 'Login as Admin',
  },
  errorMessages: {
    email: {
      required: 'This field is required',
      pattern: 'Email must be in valid format',
    },
    password: {
      required: 'This field is required',
      minLength: `password must be at least ${MIN_PASSWORD_LENGTH} characters`,
    },
    passwordReset: {
      server: `We were not able to reset your password. Please make sure you used the link that was emailed to you. If the problem persists reach out at ${SUPPORT_EMAIL}`,
      expiration: `The reset password link you are using has expired. We have emailed you a new link to set your password. Please check your email and use the newest link that was emailed to you.  If the problem persists reach out at ${SUPPORT_EMAIL}`,
    },
    general: 'Invalid email or password',
    server: 'Something went wrong',
  },
  actions: {
    login: 'Login',
    forgotPassword: 'Forgot password?',
    signUp: 'Sign Up',
    googleSignin: 'Login with Google',
    facebookSignin: 'Login with Facebook',
    requestPasswordReset: 'Request Password Reset',
  },
  labels: {
    email: 'Email Address',
    password: 'Password',
    newPassword: 'New Password',
  },
  requestPasswordReset: {
    title: 'Reset Password',
    notice:
      "Password reset instructions have been sent to your inbox! (If you don't see the email, please check your spam folder)",
    back: '⏎ Back to Login',
    trySocial: 'or try logging in via social',
  },
  alreadyHaveAnAccount: 'Already have an account?',
  noAccount: "Don't have an account?",
  resetPassword: 'Reset Password',
};
