import { EmailLoginType } from 'client/respondent/core/types';
import * as React from 'react';
import { SocialSignupButton } from '../social';

export const EMAIL_SIGNUP_BUTTON_COPY = {
  login: 'Log in with Email',
  signup: 'Sign up with Email',
};

export const EmailSignupButton: React.FC<{
  readonly context: 'login' | 'signup';
  readonly action: () => void | Promise<any>;
}> = (p) => {
  return (
    <SocialSignupButton
      action={p.action}
      cta={
        p.context === 'login'
          ? EMAIL_SIGNUP_BUTTON_COPY.login
          : EMAIL_SIGNUP_BUTTON_COPY.signup
      }
      loginType={EmailLoginType.EMAIL}
    />
  );
};
