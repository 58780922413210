import { BannerType } from '.';

export const QUESTION_PAGE_COPY = {
  moreInfo: 'View poll info',
  comments: 'Comments',
  view: 'View',
  registrationSuccessful: 'Thanks for registering!',
  noComments: 'No Comments',
  previous: 'Previous',
  pollSetOverview: 'Poll Set Overview',
  guestModalVoting: {
    title: 'We’ve recorded your response!',
    description: (publisher: string) =>
      `If you’d like to stay connected and see the outcome of this Poll Set, please consider signing up for Polco and adding your voice to the growing resident community of ${publisher}.`,
  },
  createAccountSlider: {
    title: 'Join the conversation on Polco',
    description: 'Once you join Polco, you can comment on this poll.',
  },
  submitVoting: {
    title: 'We’re almost ready to submit!',
    description:
      'The poster of this Poll Set requires you to have an account in order to respond. Once you join Polco, your response will be submitted.',
  },
  alertText: (type: BannerType) => {
    switch (type) {
      case BannerType.VIEW_COMMENTS:
        return 'Make an informed decision - click here to read the most upvoted comments.';
      case BannerType.COMMENT:
        return 'Make your voice heard - click here to add a comment';
      case BannerType.SHARE:
        return 'Help spread the word - click here to share';
      case BannerType.CREATE_ACCOUNT:
        return 'Create an account to add a comment';
      case BannerType.CLOSED:
        return 'This poll is closed';
    }
  },
};
