import { ClientRespondentId } from 'client/respondent/core';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { useQueryInfo } from 'client/shared/containers/query';
import { ClientPublishingEntityId } from 'client/shared/core/publishing-entity';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { QueryInfos } from 'client/shared/graphql-queries';
import { RespondentSubscriptions } from 'client/shared/graphql-client/graphql-operations.g';
import { useLanguageByQueryParam } from 'client/shared/hooks';

import { QueryResult } from '@apollo/client';

import { FollowingEvent, FollowingPublisher } from '../core/types';
import {
  RespondentSubscriptionsQueryType,
  gqlToClient_FollowingPublisher,
} from './respondent-helpers';

export function usePublisherSubscriptionData(respId?: ClientRespondentId): {
  readonly subscribablePubs: readonly FollowingPublisher[];
  readonly subscribedPubs: readonly FollowingPublisher[];
  readonly events: FollowingEvent | null;
  readonly query: QueryResult<RespondentSubscriptions>;
} {
  const query = useQueryInfo(QueryInfos.respondentSubscriptions, {
    variables: {
      respondentId: respId ?? 'skip-protected',
    },
    skip: !respId,
  });

  const followMut = useMutationInfo(MutationInfos.followPublishingEntity);
  const { selectLanguageText } = useLanguageByQueryParam();

  const respondent = query.data?.openRespondent;

  const subscribablePubs = respondent
    ? gqlToClient_FollowingPublisher(
        { type: RespondentSubscriptionsQueryType.SUBSCRIBABLE_PUBS, respondent },
        selectLanguageText
      )
    : [];
  const subscribedPubs = respondent
    ? gqlToClient_FollowingPublisher(
        { type: RespondentSubscriptionsQueryType.SUBSCRIBED_PUBS, respondent },
        selectLanguageText
      )
    : [];

  const events: FollowingEvent | null = respId
    ? {
        unfollow: async (pubId: ClientPublishingEntityId) => {
          await followMut.fn({
            variables: {
              publishingEntityId: pubId,
              follow: false,
              respondentId: respId,
            },
          });
        },
        follow: async (pubId: ClientPublishingEntityId) => {
          await followMut.fn({
            variables: {
              publishingEntityId: pubId,
              follow: true,
              respondentId: respId,
            },
          });
        },
      }
    : null;
  return {
    subscribablePubs,
    subscribedPubs,
    events,
    query,
  };
}
