import * as React from 'react';
import celebration from './assets/celebration.png';
import CONFIRMATION_COPY from './copy';
import logo from 'client/assets/polco-logo-dark-text.svg';
import { Btn, ButtonTypes } from 'client/shared/components/base';

export interface Props {
  readonly onConfirm: () => void;
}

export const baseClass = 'pn-registration-confirmation';

export const RegistrationConfirmation: React.FC<Props> = ({ onConfirm }) => {
  return (
    <div className={`${baseClass} px-3 text-center`}>
      <div className={`${baseClass}-img d-flex justify-content-center mb-5`}>
        <img alt={CONFIRMATION_COPY.title} src={celebration} />
      </div>
      <div className={`${baseClass}-logo d-flex justify-content-center mb-5`}>
        <img alt={CONFIRMATION_COPY.polco} src={logo} />
      </div>
      <div className={`${baseClass}-content my-5 text-gray-60`}>
        <h2 className={`${baseClass}-title font-weight-bold mb-5 font-size-xl`}>
          {CONFIRMATION_COPY.title}
        </h2>
        <p className="font-size-md mb-5">{CONFIRMATION_COPY.description}</p>
      </div>
      <div className={`${baseClass}-actions`}>
        <Btn
          action={onConfirm}
          ariaLabel={CONFIRMATION_COPY.continue}
          className={`${baseClass}-button btn-sm w-100 mb-3`}
          type={ButtonTypes.PRIMARY}
        >
          {CONFIRMATION_COPY.continue}
        </Btn>
      </div>
    </div>
  );
};

RegistrationConfirmation.displayName = 'RegistrationConfirmation';
