import * as React from 'react';
import './styles.scss';

import { VotingProps } from 'client/respondent/core/types';
import { includes } from 'lodash';
import { LoadedEvents } from 'client/shared/core/types';
import { ActionLink } from 'client/shared/components/base';
import {
  CommentsType,
  SharedCommentsInner,
} from 'client/shared/components/comments';
import { QuestionType } from 'client/shared/core/question';

export interface CommentsProps {
  readonly id: VotingProps.QuestionSetLoaded['id'];
  readonly upvoteCommentsOnVote: VotingProps.QuestionSetLoaded['upvoteCommentsOnVote'];
  readonly choices: VotingProps.QuestionSetLoaded['choices'];
  readonly comments: VotingProps.QuestionSetLoaded['comments'];
  readonly currentUserId: VotingProps.QuestionSetLoaded['currentUserId'];
  readonly typedData: VotingProps.QuestionSetLoaded['typedData'];
  readonly closed: boolean;
  readonly isGuest: boolean;
  readonly events: {
    readonly upvoteComment: LoadedEvents['upvoteComment'];
    readonly unUpvoteComment: LoadedEvents['unUpvoteComment'];
    readonly createAccount: () => void;
  };
}

const COMMENTS_COPY = {
  noResponse: 'You can comment after answering the poll',
  createAccount: (action: () => void) => (
    <div>
      You can comment after{' '}
      <ActionLink action={action}>creating an account</ActionLink>
    </div>
  ),
};

export const Comments: React.FC<CommentsProps> = (props) => {
  const upvotedCommentIds = props.typedData.previousVote
    ? props.typedData.previousVote.upvotedCommentIds
    : props.upvoteCommentsOnVote;

  const getComments = () => {
    return props.comments.loadedQuestion.map((comment) => {
      if (
        !props.typedData.previousVote &&
        includes(props.upvoteCommentsOnVote, comment.id)
      ) {
        return {
          ...comment,
          upvoteCount: comment.upvoteCount + 1,
        };
      } else {
        return comment;
      }
    });
  };
  const comments = getComments();

  return (
    <div className="w-100 p-2">
      <SharedCommentsInner
        choices={props.choices.map((ch) => ({
          id: ch.choice.id,
          text: ch.choice.text,
        }))}
        comments={comments}
        events={props.events}
        id={props.id}
        isClosedQuestion={props.closed}
        isFreeTextQuestion={props.typedData.questionType === QuestionType.FREE_TEXT}
        type={CommentsType.RESPONDENT}
        upvoteCommentIds={upvotedCommentIds}
      />
      {!props.closed &&
        props.typedData.previousVote &&
        !props.typedData.previousVote.comment &&
        props.isGuest && (
          <div className="text-gray-40">
            {COMMENTS_COPY.createAccount(props.events.createAccount)}
          </div>
        )}
      {!props.closed && !props.typedData.previousVote && (
        <div className="text-gray-40">{COMMENTS_COPY.noResponse}</div>
      )}
    </div>
  );
};
