import * as React from 'react';
import {
  useCurrentRespondent,
  CurrentRespondent,
  CurrentUser,
} from 'client/respondent/hooks/use-respondent';
import { TopNav } from 'client/respondent/shared/containers/top-nav';
import './styles.scss';
import { useScrollToTop } from 'client/shared/helpers/hooks';
import { Sidebar, SideBarProps } from 'client/respondent/shared/components/sidebar';
import * as RespondentContext from 'client/respondent/core/reducers/context';
import { RespondentActions } from 'client/respondent/core/reducers/actions';
import { LocationSource, QuestionMetadataEntry } from 'client/respondent/core/types';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { isEqual, sortBy } from 'lodash';
import { SearchPublisherContainer } from 'client/respondent/subscriptions/containers/search-publisher';
import { RideSidebarContentsUnauthenticated } from 'client/respondent/shared/components/right-sidebar';
import { WelcomeBackModalContainer } from 'client/respondent/shared/containers/welcome-back-modal';
import { ApiDate, DEFAULT_LANGUAGE, LANGUAGE_PARAM } from 'core';
import { mergeQuestionMetadata } from 'client/respondent/core/reducers';
import { TermsAndPrivacy } from '../../components/terms-and-privacy';
import { RespondentLayout, useLayout } from 'client/shared/hooks';
import { LocationSliderModal } from '../../account/containers/sliders/location-slider';
import { KnownFlag, useFlagEnabled } from 'client/shared/contexts/flags-context';
import moment from 'moment';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { ErrorRestrictedAccess } from '../../components/error-restricted-access';
import { Helmet } from 'react-helmet';

export interface Props {
  readonly children: (
    currentRespondent: CurrentUser | null,
    sidebarsShowing: boolean
  ) => React.ReactElement | null;
  readonly hideTopNav?: boolean;
  readonly hideRightSidebar?: boolean;
  readonly hideLeftSidebar?: boolean;
  readonly hideLeftSidebarWhyCreateAccountSection?: boolean;
  readonly hideLeftSidebarZipCodeSection?: boolean;
  readonly hideLeftSidebarAuthButtons?: boolean;
  readonly showTermsAndPrivacyDesktop?: boolean;
  readonly showTermsAndPrivacyMobile?: boolean;
  readonly simulationPage?: boolean;
}

export interface MainPageInner extends Props {
  readonly showSidebars: boolean;
}

const baseClass = 'pn-respondent-main-page';

/**
 * Constant value used for the query string field that we use for the area metadata.
 */
const areaKey = 'pn-area';
/*
 A temporary value to check against survey links that still have the old iid set instead of pn-area
*/
const iidKey = 'iid';

export const TODAY_API_DATE = ApiDate.toApi(moment().startOf('day').toDate());
export const SHOW_MODAL_AFTER_DAYS = 30;

/**
 * Provides the base respondent page component including sidebar and topbar
 */
export const MainPage: React.FC<Props & { readonly title?: string }> = (props) => {
  const { isMobile } = useLayout({
    mobileBreakingPoint: RespondentLayout.ContentWidth,
  });

  return (
    <>
      {props.title && (
        <Helmet>
          <title>{props.title}</title>
        </Helmet>
      )}
      <MainPageInner {...props} showSidebars={!isMobile} />
    </>
  );
};
MainPage.displayName = 'MainPage';

/**
 * Returns questionMetadata always in sorted order based on metadata key
 * @param qSearch
 */
export const getQuestionMetadata = (qSearchIn: { readonly [key: string]: any }) => {
  const questionMetadata: readonly QuestionMetadataEntry[] = [];
  const qSearch = { ...qSearchIn };
  qSearch[areaKey] ||= qSearch[iidKey];
  qSearch[LANGUAGE_PARAM] ||= DEFAULT_LANGUAGE; // make sure language is always specified

  return sortBy(Object.entries(qSearch), ([type]) => type).reduce(
    (metadata, [type, value]) => {
      if (typeof value === 'string' && type.startsWith('pn-')) {
        return [...metadata, { type, value }];
      }
      return metadata;
    },
    questionMetadata
  );
};

const MainPageInner: React.FC<MainPageInner> = (props) => {
  useScrollToTop();

  const curUser = useCurrentRespondent();
  const isLoadingUser = CurrentRespondent.LOADING.isA(curUser);

  const state = RespondentContext.useRespondentState();
  const dispatch = RespondentContext.useRespondentDispatch();
  const showSidebars = props.showSidebars;

  const userResp =
    CurrentRespondent.RESPONDENT.isA(curUser) ||
    CurrentRespondent.GUEST_RESPONDENT.isA(curUser)
      ? curUser
      : null;

  // For the purposes of the menu, an invitedPlaceholderAccount should be treated the same as a guest account.
  const isGuest =
    CurrentRespondent.GUEST_RESPONDENT.isA(curUser) ||
    ((CurrentRespondent.RESPONDENT.isA(curUser) &&
      curUser.user?.respondent?.invitedPlaceholderAccount) ??
      false);

  const isEnvisioUser = userResp?.user?.admin?.isEnvisioUser ?? false;
  const location = useLocation();

  const setId = state.surveyInProcessId ?? null;
  const userRespLoc = userResp?.location;
  React.useEffect(() => {
    if (!state.currentLocation && userRespLoc) {
      dispatch(RespondentActions.setCurrentLocation(userRespLoc, LocationSource.IP));
    }
  }, [state.currentLocation, userRespLoc, dispatch]);

  React.useEffect(() => {
    const questionMetadata = getQuestionMetadata(qs.parse(location.search));
    const action = { questionMetadata, questionMetadataSetId: setId };
    const statePart = {
      questionMetadata: state.questionMetadata,
      questionMetadataSetId: state.questionMetadataSetId,
    };
    const merged = mergeQuestionMetadata(statePart, action);

    if (!isEqual(statePart, merged)) {
      dispatch(RespondentActions.setQuestionMetadata(questionMetadata, setId));
    }
  }, [
    dispatch,
    location.search,
    state.questionMetadata,
    state.questionMetadataSetId,
    setId,
  ]);

  const isLocationFlowActivated = useFlagEnabled(KnownFlag.RESIDENTS_STORE_LOCATION);

  const updateProfileMut = useMutationInfo(MutationInfos.updateRespondentProfile);

  const [isLocationSliderModalOpen, setIsLocationSliderModalOpen] =
    React.useState(false);
  const [hasUserDismissedModal, setHasUserDismissedModal] = React.useState(false);

  const userRespData = React.useMemo(() => userResp?.user?.respondent, [userResp]);

  React.useEffect(() => {
    if (!userRespData || !isLocationFlowActivated || hasUserDismissedModal) {
      return;
    }

    const { inputtedLat, inputtedLon, requestLocationLastShown } = userRespData;
    const differenceInDays = moment().diff(
      ApiDate.fromApi(requestLocationLastShown),
      'days'
    );
    const showModal =
      !inputtedLat && !inputtedLon && differenceInDays >= SHOW_MODAL_AFTER_DAYS;

    if (showModal) {
      setIsLocationSliderModalOpen(true);
    }
  }, [userRespData, isLocationFlowActivated, hasUserDismissedModal]);

  const closeLocationModal = () => {
    setIsLocationSliderModalOpen(false);
    setHasUserDismissedModal(true);
  };

  const onLocationModalClose = async () => {
    closeLocationModal();

    await updateProfileMut.fn({
      variables: {
        respondentId: userRespData?.id || '',
        input: {
          requestLocationLastShown: TODAY_API_DATE,
        },
      },
    });
  };

  if (isEnvisioUser) {
    return <ErrorRestrictedAccess redirectPath={location.pathname} />;
  }

  return (
    <>
      <div className={`${baseClass} d-flex flex-column`}>
        {
          <TopNav
            hideSideNavAuthButtons={props.hideLeftSidebarAuthButtons}
            isGuest={isGuest}
            respondent={userResp}
            showMenu={!showSidebars}
            showSimulationLink={props.simulationPage}
          />
        }
        <div className={`${baseClass}-content d-flex flex-grow-1 flex-row w-100`}>
          {showSidebars && !isLoadingUser && !props.hideLeftSidebar && (
            <div
              className={`${baseClass}-left-content position-sticky flex-shrink-0 mr-4 ${
                props.showTermsAndPrivacyMobile ||
                (showSidebars && props.showTermsAndPrivacyDesktop)
                  ? 'mod-terms'
                  : ''
              } `}
            >
              <Sidebar
                {...SideBarProps.PERMANENT({
                  respondent: userResp,
                  isGuest,
                  hideWhyCreateAccountSection:
                    props.hideLeftSidebarWhyCreateAccountSection,
                  hideZipCodeSection: props.hideLeftSidebarZipCodeSection,
                  hideAuthButtons: props.hideLeftSidebarAuthButtons,
                })}
              />
            </div>
          )}
          <main
            className={`${baseClass}-content-main pt-0 mt-2 pt-xl-4 ${
              props.hideRightSidebar && props.showSidebars && 'mr-3 pr-2'
            }`}
          >
            {isLoadingUser ? null : props.children(userResp, showSidebars)}
            {(props.showTermsAndPrivacyMobile ||
              (showSidebars && props.showTermsAndPrivacyDesktop)) && (
              <div
                className={`${baseClass}-container d-flex justify-content-center`}
              >
                <TermsAndPrivacy />
              </div>
            )}
          </main>
          {showSidebars && !isLoadingUser && !props.hideRightSidebar && userResp && (
            <>
              <aside
                className={`${baseClass}-right-content position-sticky flex-shrink-0 border border-grayscale-3 ml-4`}
              >
                {/* We will certainly want to make this value configurable so different pages
                     can have different sidebars */}
                {/* <SearchPublisherContainer
                  includeActions={false}
                  respondent={userResp.user?.respondent}
                /> */}
                {!!userResp?.user?.respondent?.id ? (
                  <SearchPublisherContainer
                    includeActions={false}
                    respondent={userResp.user?.respondent}
                  />
                ) : (
                  <RideSidebarContentsUnauthenticated respondent={userResp} />
                )}
              </aside>
            </>
          )}
        </div>
      </div>

      <WelcomeBackModalContainer />

      {isLocationSliderModalOpen && (
        <LocationSliderModal
          isOpen={isLocationSliderModalOpen}
          onClose={onLocationModalClose}
          onSkip={closeLocationModal}
        />
      )}
    </>
  );
};
