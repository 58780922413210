import { Modal } from 'client/shared/components/modal';
import * as React from 'react';
import { VOTE_SUCCESS_MODAL } from './copy';
import {
  MaterialIcon,
  MaterialIconName,
  ButtonTypes,
  Btn,
} from 'client/shared/components/base';
import './styles.scss';
import { SurveyLoadedEvents } from 'client/shared/core/types';

export interface VotingSuccessModalProps {
  readonly events: {
    readonly goToFeed: SurveyLoadedEvents['goToFeed'];
  };
  readonly isOpen: boolean;
  readonly setOpen: (open: boolean) => void;
  readonly opt?: {
    readonly pubName: string | null;
    readonly showFollowText: boolean;
  };
}

export const VotingSuccessModal: React.FC<VotingSuccessModalProps> = (props) => {
  return (
    <div>
      <Modal
        className="d-flex justify-center align-items-center h-100"
        isCloseable
        isOpen={props.isOpen}
        onRequestClose={() => props.setOpen(false)}
      >
        <div className="text-center">
          <div className="my-3 py-3">
            {/* we might want to use svg image instead of material icon  */}
            <MaterialIcon
              className="pn-voting-success-modal-icon text-jungle"
              icon={MaterialIconName.CHECK_CIRCLE}
            />
          </div>
          <h2 className="font-weight-bold">{VOTE_SUCCESS_MODAL.title}</h2>
          <div className="font-size-sm">{VOTE_SUCCESS_MODAL.desc}</div>
          {props.opt?.showFollowText && props.opt.pubName && (
            <div className="font-size-sm">
              {VOTE_SUCCESS_MODAL.follow(props.opt.pubName)}
            </div>
          )}
        </div>

        <div className="d-flex justify-content-center m-4">
          <Btn
            action={props.events.goToFeed}
            className="py-1 px-4 font-size-sm mx-1"
            type={ButtonTypes.PRIMARY}
          >
            {VOTE_SUCCESS_MODAL.goToFeed}
          </Btn>
        </div>
      </Modal>
    </div>
  );
};
VotingSuccessModal.displayName = 'VotingSuccessModal';
