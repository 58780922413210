import * as React from 'react';
import { SurveyLoaded } from '../survey-loaded';
import { VotingProps } from 'client/respondent/core/types';
import { ClosedSurvey } from '../survey-closed';
import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';
import { Redirect } from 'react-router';
import { Helmet } from 'react-helmet';

function switchRender(props: VotingProps.SurveyProps) {
  switch (props.type) {
    case VotingProps.VotingPropsType.LOADING:
      return (
        <>
          <Helmet>
            <title>Polco</title>
          </Helmet>
          <RespondentPageLoader />;
        </>
      );
    case VotingProps.VotingPropsType.CLOSED:
      return <ClosedSurvey {...props} />;
    case VotingProps.VotingPropsType.LOADED:
      return <SurveyLoaded {...props} />;
    case VotingProps.VotingPropsType.ERROR_LOADING:
    case VotingProps.VotingPropsType.NOT_FOUND:
      return <Redirect to="/res/not-found" />;
  }
}

export const SurveyVoting: React.FC<VotingProps.SurveyProps> = (props) => (
  <div>{switchRender(props)}</div>
);
