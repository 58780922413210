import { SUPPORT_EMAIL } from 'core';

export const EDIT_PROFILE_COPY = {
  account: 'Account',
  uploadProfile: 'Upload profile picture',
  interestArea: 'Interest Area',
  profileSetting: 'Profile Setting',
  changePassword: 'Change Password',
  notifications: `I'd like to receive email notifications for:`,
  fields: {
    firstName: {
      id: 'firstName',
      label: 'Name',
      placeholder: 'Legal first name',
      note: '(Your name is never shared)',
    },
    lastName: {
      id: 'lastName',
      label: 'Last name',
      placeholder: 'Legal last name',
    },
    zipCode: {
      id: 'zip',
      label: 'Zip code',
      placeholder: undefined,
    },
    email: {
      id: 'email',
      label: 'Email',
      placeholder: undefined,
    },
    weeklyEmailEnabled: {
      id: 'weeklyEmailEnabled',
      label: 'Weekly digest of available surveys',
      placeholder: undefined,
    },
    outreachEmailEnabled: {
      id: 'outreachEmailEnabled',
      label: 'New surveys from profiles I follow',
      placeholder: undefined,
    },
    outcomeEmailEnabled: {
      id: 'outcomeEmailEnabled',
      label: `Results and outcomes for surveys I've completed`,
      placeholder: undefined,
    },
    location: {
      action: 'Update Location',
      notSet: 'Location not set',
      label: 'Location',
      description:
        'Please set your location so that we can provide you with the most accurate results and relevant content.',
    },
  },
  errorMessages: {
    required: 'This field is required',
    zip: 'Zip must be exactly 5 numbers',
    email: 'Email must be in valid format',
    serverError: `Please contact ${SUPPORT_EMAIL} if the problem persists.`,
  },
};
