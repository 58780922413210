import * as React from 'react';
import { useScrollPositionInfo } from 'client/respondent/hooks';
import {
  ClickableDiv,
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import './styles.scss';
import { SurveyLoadedEvents } from 'client/shared/core/types';
import { SubscriptionType } from 'client/shared/graphql-client/graphql-operations.g';
import { DotsActions } from 'client/respondent/voting/shared/containers/dots-actions';
import { useRedirect } from 'client/shared/hooks';
import {
  ExitConfirmationModal,
  setupEventListeners,
} from 'client/shared/components/exit-confirmation-modal';
import { useRespondentState } from 'client/respondent/core/reducers/context';
import { isEqual } from 'lodash';
import { QuestionSetType } from 'core';

export enum SurveyNavType {
  PUBLISHER = 'PUBLISHER',
  SURVEY_TITLE = 'SURVEY_TITLE',
}

interface Events {
  readonly bookmark: SurveyLoadedEvents['bookmark'];
  readonly goToFeed: SurveyLoadedEvents['goToFeed'];
  readonly goBack: SurveyLoadedEvents['goBack'];
  readonly goToPublishingEntity: () => void;
  readonly openShareModal: () => void;
  readonly publisherAction: () => Promise<void>;
}

export interface SurveyNavProps {
  readonly closed?: boolean;
  readonly surveyTitle: string;
  readonly events: Events;
  readonly publisher: string;
  readonly subscriptionType: SubscriptionType | null;
  readonly logoUrl: string | null;
  readonly voted?: boolean;
  readonly shareable: boolean;
  readonly contentType: QuestionSetType;
}

interface SurveyNavButtonGroupProps {
  readonly closed?: boolean;
  readonly events: Events;
  readonly voted?: boolean;
  readonly publisher: string;
  readonly subscriptionType: SubscriptionType | null;
  readonly shareable: boolean;
  readonly currentVotingIsDirty: boolean;
}

interface NavProgressBarProps {
  readonly percentage: number;
}

const copy = {
  ariaLabelCloseButton: 'Go back to survey',
  ariaLabelLeaveButton: 'Leave survey',
};

const baseClass = 'pn-survey-response';

const SHOW_SURVEY_TITLE_THRESHOLD = -130;
const HIDE_SURVEY_TITLE_THRESHOLD = -40;
const SQUARE_IMAGE_RATIO_THRESHOLD = 0.1;

export const SurveyNav: React.FC<SurveyNavProps> = (props) => {
  const {
    closed,
    voted,
    events,
    logoUrl,
    surveyTitle,
    publisher,
    subscriptionType,
    shareable,
    contentType,
  } = props;
  const scrollPositionInfo = useScrollPositionInfo();
  const { percentage, height } = scrollPositionInfo;

  const [leaveConfirmationIsOpen, setLeaveConfirmationIsOpen] =
    React.useState(false);
  const respondentState = useRespondentState();
  const initialVoting = React.useMemo(
    () =>
      respondentState.inProcessVotesByQuestionSet?.[
        respondentState.surveyInProcessId || ''
      ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [respondentState.surveyInProcessId]
  );
  const currentVoting =
    respondentState.inProcessVotesByQuestionSet?.[
      respondentState.surveyInProcessId || ''
    ];
  // Current voting is dirty when it is different from its "initial" state
  // (When the page was loaded at the first time before any user interaction or vote changes)
  const currentVotingIsDirty = !isEqual(currentVoting, initialVoting);

  // About this Effect: We are changing the header type based on the scroll position.
  // The problem: The header we show after some scrolling is shorter than the
  // header we show initially. This shorter header then changes the page height resulting in
  // a different scroll value, resulting in a different header that should be shown. The end
  // result is a non-stop flickering header (if we use a single threshold value).
  // The Fix: Use two different thresholds, So it is easier to start showing the new header then
  // it is to hide the new header
  // Also note: Scroll `height` is a "increasingly negative" number for some reason. So top of
  // page is 0, and bottom of page is -Inf
  const surveyTitleType = React.useRef(SurveyNavType.PUBLISHER);
  if (height > HIDE_SURVEY_TITLE_THRESHOLD) {
    surveyTitleType.current = SurveyNavType.PUBLISHER;
  } else if (height < SHOW_SURVEY_TITLE_THRESHOLD) {
    surveyTitleType.current = SurveyNavType.SURVEY_TITLE;
  }

  // We only want to render square logos in SURVEY_TITLE view
  // Once image has loaded and rendered, determine whether or not it should be displayed
  const imageRef = React.useRef<HTMLImageElement | null>(null);
  const imageRatio = React.useRef<number | null>(null);
  if (
    imageRef.current?.offsetWidth &&
    imageRef.current?.offsetHeight &&
    imageRatio.current === null
  ) {
    imageRatio.current = Math.abs(
      1 - imageRef.current.offsetWidth / imageRef.current.offsetHeight
    );
  }
  const shouldRenderLogo =
    imageRatio.current !== null && imageRatio.current < SQUARE_IMAGE_RATIO_THRESHOLD;

  return (
    <div className={`sticky-top ${baseClass}-top`}>
      <div className={`${baseClass}-nav pt-2 px-2`}>
        <div className="d-flex flex-row justify-content-between align-items-center">
          {surveyTitleType.current === SurveyNavType.PUBLISHER && (
            <div className={`${baseClass}-nav-btns-pub flex-shrink-0`}>
              {/*Spacer to center pub logo. [][image][x] */}
              {/* same as x button width*/}
            </div>
          )}
          <div
            className={`h-100 ${
              surveyTitleType.current !== SurveyNavType.SURVEY_TITLE ? 'w-100' : ''
            }`}
          >
            <ClickableDiv
              action={() =>
                voted || !currentVotingIsDirty
                  ? events.goToPublishingEntity()
                  : setLeaveConfirmationIsOpen(true)
              }
              className={`${baseClass}-nav-logo ${baseClass}-nav-logo-link ${
                surveyTitleType.current === SurveyNavType.SURVEY_TITLE
                  ? shouldRenderLogo
                    ? 'mod-smaller'
                    : 'd-none'
                  : ''
              }`}
            >
              {logoUrl && (
                <img
                  alt={`${publisher} Profile`}
                  className={`${baseClass}-nav-logo d-flex align-items-center ${
                    surveyTitleType.current === SurveyNavType.SURVEY_TITLE
                      ? 'mod-smaller'
                      : ''
                  }`}
                  ref={imageRef}
                  src={logoUrl}
                />
              )}
            </ClickableDiv>
          </div>

          {surveyTitleType.current === SurveyNavType.SURVEY_TITLE && (
            <h1
              className={`${baseClass}-nav-survey-title mb-0 text-gray-40 font-size-sm px-2 text-center`}
            >
              {surveyTitle}
            </h1>
          )}
          <SurveyNavButtonGroup
            closed={closed}
            currentVotingIsDirty={currentVotingIsDirty}
            events={events}
            publisher={publisher}
            shareable={shareable}
            subscriptionType={subscriptionType}
            voted={voted}
          />
        </div>
        <div className="d-flex flex-row">
          <div
            className={`${baseClass}-nav-pub-title font-size-lg text-gray-40 text-center w-100 mb-2 ${
              surveyTitleType.current === SurveyNavType.PUBLISHER ? '' : 'mod-hidden'
            }`}
          >
            {publisher}
          </div>
        </div>
      </div>

      {contentType === QuestionSetType.SURVEY ? (
        <NavProgressBar percentage={percentage} />
      ) : (
        <div className="px-2">
          <div className="border-bottom"></div>
        </div>
      )}

      <ExitConfirmationModal
        ariaLabelCloseButton={copy.ariaLabelCloseButton}
        ariaLabelLeaveButton={copy.ariaLabelLeaveButton}
        events={{
          leave: events.goToPublishingEntity,
          cancel: () => setLeaveConfirmationIsOpen(false),
        }}
        isOpen={leaveConfirmationIsOpen}
      />
    </div>
  );
};

const SurveyNavButtonGroup: React.FC<SurveyNavButtonGroupProps> = (props) => {
  const redirect = useRedirect();
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedLink, setSelectedLink] = React.useState<string | null>(null);
  const {
    closed,
    voted,
    events,
    publisher,
    subscriptionType,
    shareable,
    currentVotingIsDirty,
  } = props;

  const redirectToUrl = () => {
    if (selectedLink) {
      redirect(selectedLink, {
        push: true,
      });
    }
  };

  React.useEffect(() => {
    const { onUnload } = setupEventListeners(
      ['pn-top-nav-link', 'pn-respondent-sidebar-link-anchor'],
      setSelectedLink,
      setIsOpen,
      currentVotingIsDirty,
      isOpen
    );
    if (closed || voted) {
      onUnload();
      return;
    }
    return () => {
      onUnload();
    };
  }, [closed, voted, currentVotingIsDirty, isOpen]);

  return (
    <div className="flex-shrink-0">
      <div className={`${baseClass}-nav-btns-pub d-flex align-items-center`}>
        {!!events.publisherAction && events.openShareModal && (
          <DotsActions
            executePublisherAction={events.publisherAction}
            executeShareModalAction={events.openShareModal}
            publisher={publisher}
            shareable={shareable}
            subscriptionType={subscriptionType}
          />
        )}
        <Btn
          action={() =>
            voted || !currentVotingIsDirty ? events.goToFeed() : setIsOpen(true)
          }
          ariaLabel={voted || closed ? 'Return to feed' : 'Leave survey'}
          className={`${baseClass}-close-button ml-1`}
          iconOnly
          type={ButtonTypes.SEAMLESS}
        >
          <MaterialIcon icon={MaterialIconName.CLOSE} />
        </Btn>
      </div>

      <ExitConfirmationModal
        ariaLabelCloseButton={copy.ariaLabelCloseButton}
        ariaLabelLeaveButton={copy.ariaLabelLeaveButton}
        events={{
          leave: selectedLink ? redirectToUrl : events.goToFeed,
          cancel: () => {
            setIsOpen(false);
            setSelectedLink(null);
          },
        }}
        isOpen={isOpen}
      />
    </div>
  );
};

const NavProgressBar: React.FC<NavProgressBarProps> = (props) => {
  return (
    <div className="px-2 bg-white">
      <div
        className={`${baseClass}-nav-progress px-2`}
        style={{ width: `${props.percentage}%` }}
      />
      <div className="border-bottom"></div>
    </div>
  );
};
