import * as MutationInfos from 'client/shared/graphql-mutations/mutation-infos';
import { RespondentActions } from 'client/respondent/core/reducers/actions';
import { getFingerprint } from 'client/shared/integrations';
import { QuestionType } from 'client/shared/core/question';
import {
  VotingProps,
  RegistrationResult,
  SocialLoginData,
  NotLoggedInActions,
  PreLoginAction,
  NotLoggedInActionStatus,
} from 'client/respondent/core/types';
import { ToMutationReturn } from 'client/shared/containers/mutation';
import { Dispatch } from 'react';
import { QueryInfos } from 'client/shared/graphql-queries';
import { sumBy } from 'lodash';

export async function socialLogin(
  mut: ToMutationReturn<typeof MutationInfos.socialLogin>,
  triggerExternalSocialLogin: () => Promise<SocialLoginData>
): Promise<RegistrationResult<SocialLoginData>> {
  try {
    const data = await triggerExternalSocialLogin();
    return await postSocialLogin(mut, data);
  } catch (e: any) {
    return {
      type: 'ERROR',
      data: {
        title: 'Could not log in',
        description: e.message,
      },
    };
  }
}

export async function postSocialLogin(
  mut: ToMutationReturn<typeof MutationInfos.socialLogin>,
  login: SocialLoginData
): Promise<RegistrationResult<SocialLoginData>> {
  try {
    const data = {
      data: login,
      fingerprint: getFingerprint(),
    };
    const mutRes = await mut.fn({
      variables: data,
      refetchQueries: [QueryInfos.currentRespondent.refetchInfo({})],
    });
    const res = mutRes.data?.socialLogin;

    if (res?.currentUser.user?.respondent) {
      return {
        type: 'SUCCESS',
        isNewUser: res.isNewUser,
        respId: res.currentUser.user.respondent.id,
        userId: res.currentUser.user.id,
        email:
          res.currentUser.user.respondent.__typename === 'PrivateRespondent'
            ? res.currentUser.user.respondent.email
            : null,
      };
    }
  } catch (e: any) {
    return {
      type: 'ERROR',
      data: {
        title: 'SOCIAL LOGIN UNSUCCESSFUL',
        description: e.toString(),
      },
    };
  }
  return {
    type: 'ERROR',
    data: {
      title: 'SOCIAL LOGIN UNSUCCESSFUL',
      description: 'NO RESPONDENT',
    },
  };
}

export function updateQuery<T extends {}, K extends keyof T>(
  queryPart: K,
  merge: (
    oldData: NonNullable<T[K]>,
    newData: NonNullable<T[K]>
  ) => NonNullable<T[typeof queryPart]>
) {
  return (prev: T, opts: { readonly fetchMoreResult?: T }): T => {
    const more = opts.fetchMoreResult as T;
    const prevQuery = prev[queryPart];
    const moreQuery = more ? more[queryPart] : null;
    if (prevQuery && moreQuery) {
      const merged = merge(
        prevQuery as NonNullable<T[K]>,
        moreQuery as NonNullable<T[K]>
      );
      const ret = {
        ...(prev as any),
      };
      ret[queryPart] = merged;
      return ret;
    }
    return prev;
  };
}

export function processPreLoginAction<T extends NotLoggedInActions>(
  preLoginAction: PreLoginAction | null,
  fireOnActionType: T,
  dispatch: Dispatch<RespondentActions.Types>,
  callback: (
    act: Extract<PreLoginAction, { readonly actionType: T }>
  ) => Promise<any> | null
) {
  if (
    preLoginAction?.status === NotLoggedInActionStatus.REGISTRATION_SUCCESS &&
    preLoginAction.actionType === fireOnActionType
  ) {
    // TypeScript is not smart enough to get the type from the generic specified above, but it is windowed when called so you can access the correct data in the callback
    const act: Extract<PreLoginAction, { readonly actionType: T }> =
      preLoginAction as any;

    const promise = callback(act);
    promise
      ?.then(() => dispatch(RespondentActions.completePreLoginAction()))
      .catch((err) => dispatch(RespondentActions.preLoginActionFailure(err)));
  }
}

export function checkIfInProcessVoteIsValid(
  typedData: VotingProps.QuestionTypedData,
  inProcessComment: VotingProps.InProcessComment | null
): boolean {
  if (!typedData.inProcessVote) return false;
  switch (typedData.questionType) {
    case QuestionType.FREE_TEXT:
      return inProcessComment ? inProcessComment.comment.length > 0 : false;
    case QuestionType.MULTIPLE_CHOICE:
      return (
        typedData.inProcessVote.choices.length > 0 &&
        typedData.maxSelection >= typedData.inProcessVote.choices.length
      );
    case QuestionType.POINT_ALLOCATION:
      const totalPoints = sumBy(typedData.inProcessVote.choices, (sc) => sc.point);
      return totalPoints === 10;
    default:
      return false;
  }
}
