import * as React from 'react';
import './styles.scss';
import {
  ButtonTypes,
  MaterialIcon,
  Pill,
  PillTypes,
} from 'client/shared/components/base';
import { BannerAction, ActionButton } from '../common';
import { NoLocalGovFeedCard } from '../no-local-gov-card';
import { MaterialIconName } from 'client/shared/components/base/material-icon';
import {
  BottomIcon,
  RespondentFeed_Item,
} from 'client/shared/graphql-client/graphql-operations.g';
import liveEventSymbol from 'client/assets/live-event-symbol.svg';
import balancingActBadge from 'client/assets/balancing-act-badge-logo.svg';
import { BalancingActSimulationType, isEnum } from 'core';
import {
  AuthnVoteSection,
  AuthnVoteSectionProps,
} from 'client/respondent/shared/account/components/authn-vote-section';

export enum BannerBadgeType {
  POLL_SET = 'POLL_SET',
  SURVEY = 'SURVEY',
  REPOST = 'REPOST',
  QUESTION = 'QUESTION',
  POLCO_LIVE = 'POLCO_LIVE',
  CONTENT_POST = 'CONTENT_POST',
  BALANCING_ACT_SIMULATION = 'BALANCING_ACT_SIMULATION',
  IDEA = 'IDEA',
}

export interface BannerBadge {
  readonly type: BannerBadgeType;
  readonly label: string;
}

export interface FeedCardBannerProps {
  readonly postImage?: string;
  readonly postTitle: string;
  readonly postBottomText?: string;
  readonly postBottomIcon?: BottomIcon;
  readonly badges: readonly BannerBadge[];
  readonly pills?: readonly string[];
  readonly primaryAction: BannerAction;
  readonly bottomActions: readonly BannerAction[];
  readonly contentClosed?: boolean;
  readonly singleQuestion: boolean;
  readonly key: string;
  readonly typedData: RespondentFeed_Item['typedData'];
  readonly voteButton?: AuthnVoteSectionProps;
}

export const baseClass = 'pn-respondent-feed-card';

export const FeedCardBanner: React.FC<FeedCardBannerProps> = (
  props: FeedCardBannerProps
) => {
  const CardBanner = props.postImage ? FeedCardWithImage : ImagelessFeedCard;

  return (
    <div className="bg-white">
      {props.singleQuestion ? (
        <NoLocalGovFeedCard {...props} />
      ) : (
        <>
          <CardBanner {...props} />
          <FeedCardActions {...props} />
        </>
      )}
    </div>
  );
};

const CardTitle: React.FC<{
  readonly title: string;
  readonly extraMargin?: boolean;
  readonly additionalClassName?: string;
}> = ({ title, extraMargin, additionalClassName }) => (
  <h2
    className={`${baseClass}-title${
      additionalClassName ? `-${additionalClassName}` : ''
    } mb-0 ${
      extraMargin ? 'ml-4' : ''
    } font-size-lg font-weight-bold d-inline text-break`}
  >
    {title}
  </h2>
);

const FeedCardWithImage: React.FC<FeedCardBannerProps> = (
  p: FeedCardBannerProps
) => {
  return (
    <>
      <div className={`${baseClass}-image-wrapper`}>
        <img
          alt=""
          className="w-100 h-100"
          src={p.postImage}
          style={{ objectFit: 'cover' }}
        />
      </div>
      <FeedBannerBadges badges={p.badges} typedData={p.typedData} />

      <div className={`pl-3 ml-3 font-weight-bold text-left pb-3`}>
        <FeedBannerPills pills={p.pills} />
      </div>
    </>
  );
};

const bannerTypeIcons: Record<BannerBadgeType | string, MaterialIconName> = {
  [BannerBadgeType.REPOST]: MaterialIconName.REPEAT,
  [BannerBadgeType.POLCO_LIVE]: MaterialIconName.LIVE_TV,
  [BannerBadgeType.POLL_SET]: MaterialIconName.RULE,
  [BannerBadgeType.SURVEY]: MaterialIconName.ASSIGNMENT,
  [BannerBadgeType.IDEA]: MaterialIconName.LIGHTBULB_OUTLINE,
  [BannerBadgeType.CONTENT_POST]: MaterialIconName.BALLOT,
  default: MaterialIconName.FORUM,
};

const balancingActBannerTypeIcons: Record<
  BalancingActSimulationType,
  MaterialIconName
> = {
  [BalancingActSimulationType.BUDGET]: MaterialIconName.LOCAL_ATM,
  [BalancingActSimulationType.HOUSING]: MaterialIconName.HOME,
  [BalancingActSimulationType.RECEIPT]: MaterialIconName.RECEIPT,
  [BalancingActSimulationType.POINTS]: MaterialIconName.HOW_TO_VOTE,
  [BalancingActSimulationType.CLIMATE]: MaterialIconName.HOW_TO_VOTE,
  [BalancingActSimulationType.PROJECT_MANAGEMENT]: MaterialIconName.HOW_TO_VOTE,
  [BalancingActSimulationType.NON_NUMERICAL]: MaterialIconName.HOW_TO_VOTE,
};

const BadgeIcon: React.FC<{
  readonly icon: MaterialIconName;
}> = ({ icon }) => (
  <MaterialIcon
    className={`${baseClass}-status-icon text-center pt-1`}
    icon={icon}
  />
);

const CalloutBubble: React.FC<{
  readonly type: BannerBadgeType;
  readonly label: string;
  readonly typedData: RespondentFeed_Item['typedData'];
}> = (p) => {
  const renderBadgeIcon = () => {
    if (
      p.type === BannerBadgeType.BALANCING_ACT_SIMULATION &&
      p.typedData.__typename === 'RespondentFeedItemTypedData_BalancingActSimulation'
    ) {
      return (
        <>
          {isEnum(BalancingActSimulationType, p.typedData.simulationType) ? (
            <BadgeIcon
              icon={balancingActBannerTypeIcons[p.typedData.simulationType]}
            />
          ) : (
            <div>
              <img alt="balancingActBadge" src={balancingActBadge} />
            </div>
          )}
        </>
      );
    }

    return (
      <BadgeIcon icon={bannerTypeIcons[p.type] || bannerTypeIcons['default']} />
    );
  };
  return (
    <div
      className={`${baseClass}-status font-weight-bold font-size-sm d-flex position-relative align-items-center mod-content-type-${p.type.toLowerCase()}`}
    >
      <div
        className={`text-white
     rounded-circle ${baseClass}-status-icon`}
      >
        {renderBadgeIcon()}
      </div>
      <div className={`${baseClass}-status-text font-size-sm ml-2`}>{p.label}</div>
    </div>
  );
};

export const ImagelessFeedCard: React.FC<FeedCardBannerProps> = (
  p: FeedCardBannerProps
) => {
  return (
    <div>
      <FeedBannerPills pills={p.pills} />
      <div
        className="py-5 px-3 font-weight-bold text-left bg-polco-green-l"
        style={{ minHeight: '85px' }}
      >
        <CardTitle additionalClassName="top" extraMargin title={p.postTitle} />
      </div>
      <FeedBannerBadges badges={p.badges} typedData={p.typedData} />
    </div>
  );
};

const FeedBannerPills: React.FC<Pick<FeedCardBannerProps, 'pills'>> = ({
  pills,
}) => {
  return pills?.length ? (
    <div className={`${baseClass}-pills-container d-flex my-3 flex-wrap`}>
      {pills.map((pill, index) => (
        <Pill key={index} type={PillTypes.FACET}>
          {pill}
        </Pill>
      ))}
    </div>
  ) : null;
};

const FeedBannerBadges: React.FC<
  Pick<FeedCardBannerProps, 'badges' | 'typedData'>
> = (p) => {
  return p.badges.length > 0 ? (
    <div className="d-flex flex-row">
      {p.badges.map((badge) => (
        <CalloutBubble
          // label is more likely to be unique than type, i think?
          key={badge.label}
          label={badge.label}
          type={badge.type}
          typedData={p.typedData}
        />
      ))}
    </div>
  ) : null;
};

export const FeedCardActions: React.FC<FeedCardBannerProps> = (
  props: FeedCardBannerProps
) => {
  return (
    <div
      className={`${baseClass}-bottom d-flex align-items-center justify-content-between`}
    >
      <div className={`${baseClass}-bottom-title d-flex flex-column`}>
        <CardTitle title={props.postTitle} />
        <p
          className={`${
            props.contentClosed ? 'text-danger' : ''
          } flex-grow-1 font-size-sm`}
        >
          {props.postBottomText}
        </p>
        {props.voteButton ? (
          <AuthnVoteSection
            className={`${baseClass}-vote-button`}
            props={props.voteButton}
          />
        ) : null}
      </div>

      <div className={`${baseClass}-dot-canvans-box mr-1`}>
        {props.postBottomIcon && renderBottomIcon(props.postBottomIcon)}
      </div>

      <div>
        {props.bottomActions.map((act) => (
          <ActionButton act={act} btnType={ButtonTypes.SECONDARY} key={act.key} />
        ))}
        <ActionButton
          act={props.primaryAction}
          btnType={props.contentClosed ? ButtonTypes.SECONDARY : ButtonTypes.PRIMARY}
          key={props.primaryAction.key}
        />
      </div>
    </div>
  );
};

export const renderBottomIcon = (bottomIcon: BottomIcon) => {
  switch (bottomIcon) {
    case BottomIcon.LIVE_TV:
      return <img alt="" src={liveEventSymbol} />;
  }
};
