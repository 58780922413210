import * as React from 'react';
import './styles.scss';
import {
  Btn,
  MaterialIcon,
  MaterialIconName,
  ButtonTypes,
  ClickableDiv,
} from 'client/shared/components/base';
import { LoadedEvents } from 'client/shared/core/types';
import liveEventSymbol from 'client/assets/live-event-symbol.svg';
import { SubscriptionType } from 'client/shared/graphql-client/graphql-operations.g';
import { DotsActions } from '../../containers/dots-actions';
import {
  ExitConfirmationModal,
  setupEventListeners,
} from 'client/shared/components/exit-confirmation-modal';
import { useRedirect } from 'client/shared/hooks';
import { useRespondentState } from 'client/respondent/core/reducers/context';
import { isEqual } from 'lodash';

const baseClass = 'pn-res-header';

interface Props {
  readonly publisherName: string;
  readonly subscriptionType?: SubscriptionType | null;
  readonly primaryLogo: string | null;
  readonly navPercentage: number;
  readonly isEventCurrentlyRunning?: boolean;
  readonly shareable: boolean;
  readonly voted?: boolean;
  readonly closed?: boolean;
  readonly events: {
    readonly goToFeed: LoadedEvents['goToFeed'];
    readonly goBack: LoadedEvents['goBack'];
    readonly openShareModal?: () => void;
    readonly goToPublishingEntity: () => void;
    readonly publisherAction?: () => Promise<void>;
  };
}

export const VotingHeader: React.FC<Props> = (props) => {
  const {
    publisherName,
    events,
    primaryLogo,
    navPercentage,
    isEventCurrentlyRunning,
    subscriptionType,
    shareable,
    voted = false,
    closed = false,
  } = props;

  const redirect = useRedirect();
  const [leaveConfirmationIsOpen, setLeaveConfirmationIsOpen] =
    React.useState(false);
  const [selectedLink, setSelectedLink] = React.useState<string | null>(null);
  const [leaveAction, setLeaveAction] = React.useState<() => void>(() => {});
  const respondentState = useRespondentState();
  const initialVoting = React.useMemo(
    () => respondentState.inProcessVote,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [respondentState.surveyInProcessId]
  );
  const currentVoting = respondentState.inProcessVote;
  // Current voting is dirty when it is different from its "initial" state
  // (When the page was loaded at the first time before any user interaction or vote changes)
  const currentVotingIsDirty = !isEqual(currentVoting, initialVoting);

  const handleAction = (action: () => void) => {
    setLeaveConfirmationIsOpen(true);
    setLeaveAction(() => action);
  };

  const closeModal = () => {
    setLeaveConfirmationIsOpen(false);
    setLeaveAction(() => {});
  };

  const redirectToUrl = () => {
    if (selectedLink) {
      redirect(selectedLink, {
        push: true,
      });
    }
  };

  React.useEffect(() => {
    const { onUnload } = setupEventListeners(
      ['pn-top-nav-link', 'pn-respondent-sidebar-link-anchor'],
      setSelectedLink,
      setLeaveConfirmationIsOpen,
      currentVotingIsDirty,
      leaveConfirmationIsOpen
    );

    if (closed || voted) {
      onUnload();
      return;
    }

    return () => {
      onUnload();
    };
  }, [closed, currentVotingIsDirty, leaveConfirmationIsOpen, voted]);

  return (
    <>
      <ExitConfirmationModal
        ariaLabelCloseButton="Go back to current page"
        ariaLabelLeaveButton="Leave this page"
        events={{
          leave: selectedLink ? redirectToUrl : leaveAction,
          cancel: closeModal,
        }}
        isOpen={leaveConfirmationIsOpen}
      />

      <div className={`${baseClass} sticky-top`}>
        <div className="d-flex justify-content-between align-items-center px-2 shadow-sm bg-white">
          <div
            className={`${baseClass}-left-side d-flex justify-content-start align-items-center pb-1`}
          >
            <div className="d-flex flex-row align-items-center pl-3">
              {isEventCurrentlyRunning && (
                <img
                  alt=""
                  className={`${baseClass}-left-side-live`}
                  src={liveEventSymbol}
                />
              )}

              <div
                className={`${baseClass}-left-side-text  font-size-sm pl-2 text-gray-60 d-flex align-items-center`}
              >
                {publisherName}
              </div>
            </div>
          </div>
          <ClickableDiv
            action={() =>
              voted || !currentVotingIsDirty
                ? events.goToPublishingEntity()
                : handleAction(events.goToPublishingEntity)
            }
            className={`${baseClass}-logo-wrapper d-flex justify-content-center align-items-center pb-1`}
          >
            {primaryLogo && (
              <img
                alt={`${publisherName} Profile`}
                src={primaryLogo}
                style={{ height: '30px' }}
              />
            )}
          </ClickableDiv>
          <div
            className={`${baseClass}-icons-wrapper d-flex justify-content-end py-1`}
          >
            {events.openShareModal && events.publisherAction && (
              <DotsActions
                executePublisherAction={events.publisherAction}
                executeShareModalAction={events.openShareModal}
                publisher={publisherName}
                shareable={shareable}
                subscriptionType={subscriptionType ?? null}
              />
            )}
            <Btn
              action={() =>
                voted || !currentVotingIsDirty
                  ? events.goToFeed()
                  : handleAction(events.goToFeed)
              }
              ariaLabel="Close"
              className={`${baseClass}-close-button ml-1`}
              iconOnly
              type={ButtonTypes.SEAMLESS}
            >
              <MaterialIcon
                className={`${baseClass}-icon`}
                icon={MaterialIconName.CLOSE}
              />
            </Btn>
          </div>
        </div>
        {/* scroll progress bar */}
        <div className={`${baseClass}-nav px-2 bg-white`}>
          <div
            className={`${baseClass}-nav-progress px-2`}
            role="progressbar"
            style={{ width: `${navPercentage}%` }}
          />
          <div className="border-bottom"></div>
        </div>
      </div>
    </>
  );
};
