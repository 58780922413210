import * as React from 'react';
import { Modal } from 'client/shared/components/modal';
import { ButtonTypes, Btn } from 'client/shared/components/base';
import './styles.scss';
import successImage from './success-illustration.svg';

export interface ThankYouModalProps {
  readonly events: {
    readonly cancel: () => void;
  };
  readonly isOpen: boolean;
  readonly isSurvey?: boolean;
}

const copy = {
  title: 'Your Response Has Been Submitted!',
  thankYou: 'Thank You!',
  desc: (isSurvey: boolean) =>
    `Your response has been successfully submitted. We appreciate you taking the time to complete this ${
      isSurvey ? 'survey' : 'poll'
    }.`,
  done: 'Done',
};

const baseClass = 'pn-thank-you-modal';

export const ThankYouModal: React.FC<ThankYouModalProps> = (props) => {
  return (
    <div>
      <Modal
        className="d-flex justify-center align-items-center h-100"
        isCloseable={true}
        isOpen={props.isOpen}
        onRequestClose={props.events.cancel}
        size="md"
      >
        <div className="text-center mb-4">
          <div className="mb-3 pb-3">
            <img alt="check" className={`${baseClass}-image`} src={successImage} />
          </div>
          <div className={`text-primary font-weight-bold ${baseClass}-title`}>
            {copy.title}
          </div>
          <div className={`text-primary font-weight-bold ${baseClass}-title`}>
            {copy.thankYou}
          </div>
          <div className="font-size-sm mt-4">
            {copy.desc(props.isSurvey ?? false)}
          </div>
        </div>
        <Btn
          action={props.events.cancel}
          className={`w-100 my-4 ${baseClass}-button`}
          type={ButtonTypes.PRIMARY}
        >
          {copy.done}
        </Btn>
      </Modal>
    </div>
  );
};
ThankYouModal.displayName = 'ThankYouModal';
