import {
  Description,
  Coordinate,
  AssertAssignable,
  PartialRecord,
  Result,
  Language,
  RespondentsSetStatus,
  ExtractGql,
  QuestionSetType,
} from 'core';
import {
  QuestionType,
  VotingChoice,
  ClientQuestionId,
  SavedSurveyItem,
  RandomizedSurveyItems,
  SurveyItemType,
  ClientVoteId,
  QuestionStatus,
} from 'client/shared/core/question';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import { ClientRespondentId, ClientUserId } from '.';
import { RespondentActions } from './reducers/actions';
import { CurrentUser } from '../hooks/use-respondent';
import { QuestionVotingMutationReturns } from 'client/shared/graphql-mutations/mutation-infos';
import {
  ClientPublishingEntityId,
  PublishingEntityAssetType,
} from 'client/shared/core/publishing-entity';
import PropTypes from 'client/respondent/core/production-prop-types';
import { InferProps } from 'prop-types';
import {
  MCChoice,
  PointAllocationChoice,
  VotingInteraction,
  LoadedEvents,
  SurveyLoadedEvents,
  AnswerChangeability,
  GridChoiceByRowId,
  LoadableComment,
} from 'client/shared/core/types';
import { ApolloError, QueryResult } from '@apollo/client';
import { ClientQuestionSetId } from 'client/shared/core/question-set';

type SetForRespondentVote = ExtractGql<
  NonNullable<Gql.RespondentVotingPage['openContentSetBySlug']>,
  'PolcoLive'
>['setForRespondentVote'];

export enum SocialLoginType {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
}

export enum EmailLoginType {
  EMAIL = 'EMAIL',
}

export type LoginType = SocialLoginType | EmailLoginType;

export enum SocialLoginMode {
  CLIENT = 'CLIENT',
  //  SERVER = 'SERVER', TODO add support
}

export enum QuestionPageRedirectLocation {
  OVERVIEW = 'overview',
}

export interface ModalErrorData {
  readonly title: string;
  readonly description: string;
}

export enum RegistrationMode {
  START_OR_SOCIAL = 'START_OR_SOCIAL',
  EMAIL_LOGIN = 'EMAIL_LOGIN',
  EMAIL_SIGNUP = 'EMAIL_SIGNUP',
}

export interface QuestionForWaitingPage {
  readonly id: ClientQuestionId;
  readonly previousVote: ClientVoteId | null;
  readonly title: string;
  readonly state: QuestionStateForWaitingPage;
}

export enum OverviewItemType {
  QUESTION_RESULT = 'QUESTION_RESULT',
  REPORT = 'REPORT',
}
export interface OverviewItem {
  readonly type: OverviewItemType;
  readonly id: string;
  readonly title: string;
}

export enum QuestionStateForWaitingPage {
  CLOSED = 'CLOSED',
  ANSWER_NOW = 'ANSWER_NOW',
  OPEN = 'OPEN',
}

export interface RegistrationResultFieldErrors<TData> {
  readonly type: 'FIELD_ERRORS';
  readonly errors: Partial<Description<TData>>;
}

export interface RegistrationResultSuccess {
  readonly type: 'SUCCESS';
  readonly isNewUser: boolean;
  readonly respId: ClientRespondentId;
  readonly userId: ClientUserId;
  readonly email: string | null;
  readonly requiresVerification?: boolean;
}

export interface RegistrationResultError {
  readonly type: 'ERROR';
  readonly data: ModalErrorData;
}

export type RegistrationResult<T> =
  | RegistrationResultFieldErrors<T>
  | RegistrationResultSuccess
  | RegistrationResultError;

export type RegistrationErrors = PartialRecord<SocialLoginType, string | null>;

export interface EmailLoginData {
  readonly email: string;
  readonly password: string;
}

export interface EmailSignupData {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly zipCode: string;
  readonly password: string;
}

export interface SocialLoginData {
  readonly type: SocialLoginType;
  readonly token: string;
}

export interface PublishingEntityAsset {
  readonly id: string;
  readonly type: PublishingEntityAssetType;
  readonly url: string;
}

export interface PublishingEntity {
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly assets: ReadonlyArray<PublishingEntityAsset>;
  readonly subscriptionType: Gql.SubscriptionType | null;
}

export namespace VotingProps {
  export enum Tab {
    TITLE = 'TITLE',
    COMMENTS = 'COMMENTS',
    DETAILS = 'DETAILS',
    RECOMMENDED_QUESTION = 'RECOMMENDED_QUESTION',
  }

  export enum VotingPropsType {
    LOADING = 'LOADING',
    ERROR_LOADING = 'ERROR_LOADING',
    LOADED = 'LOADED',
    NOT_FOUND = 'NOT_FOUND',
    CLOSED = 'CLOSED',
  }

  export enum SetType {
    POLL_SET = 'POLL_SET',
    POLCO_LIVE = 'POLCO_LIVE',
  }
  export interface Common {
    readonly id: string;
    readonly answerChangeability: AnswerChangeability | null;
  }

  export interface Loading extends Common {
    readonly type: VotingPropsType.LOADING;
  }

  export interface ErrorLoading extends Common {
    readonly type: VotingPropsType.ERROR_LOADING | VotingPropsType.NOT_FOUND;
    readonly errorTitle: string;
    readonly errorDescription: string;
  }
  export interface Comment {
    readonly id: string;
    readonly commenterChoiceId: string | null;
    readonly comment: string;
    readonly commenterId: string;
    readonly commenterFirstName: string | null;
    readonly commenterLastName: string | null;
    readonly commenterImageUrl: string | null;
    readonly upvoteCount: number;
    readonly isOwnComment?: boolean;
    readonly updatedAt: Date;
  }

  export interface RecommendedQuestion {
    readonly id: string;
    readonly title: string;
    readonly publishingEntity: PublishingEntity | null;
  }

  export interface VoteComment {
    readonly id: string | null; // might not have an id if unsaved
    readonly comment: string;
  }
  export interface InProcessComment {
    readonly questionId: ClientQuestionId;
    readonly comment: string;
  }
  export interface Vote_FreeText {
    readonly type: QuestionType.FREE_TEXT;
    readonly comment: VoteComment | null;
    readonly upvotedCommentIds: ReadonlyArray<Comment['id']>;
    readonly questionId: ClientQuestionId;
  }

  export interface Vote_MultipleChoice {
    readonly type: QuestionType.MULTIPLE_CHOICE;
    readonly choices: readonly MCChoice[];
    readonly comment: VoteComment | null;
    readonly upvotedCommentIds: ReadonlyArray<Comment['id']>;
    readonly questionId: ClientQuestionId;
  }

  export interface Vote_PointAllocation {
    readonly type: QuestionType.POINT_ALLOCATION;
    readonly choices: readonly PointAllocationChoice[];
    readonly comment: VoteComment | null;
    readonly upvotedCommentIds: ReadonlyArray<Comment['id']>;
    readonly questionId: ClientQuestionId;
  }

  export interface Vote_GridChoice {
    readonly type: QuestionType.GRID_CHOICE;
    readonly comment: VoteComment | null;
    readonly upvotedCommentIds: ReadonlyArray<Comment['id']>;
    readonly gridChoiceByRowId: GridChoiceByRowId;
    readonly questionId: ClientQuestionId;
  }

  export type QuestionSetVote =
    | Vote_FreeText
    | Vote_MultipleChoice
    | Vote_PointAllocation;

  export type Vote =
    | Vote_FreeText
    | Vote_MultipleChoice
    | Vote_PointAllocation
    | Vote_GridChoice;

  export interface CommentUpvote {
    readonly commentId: string;
    readonly up: boolean;
  }

  export interface SurveyInProcessVotes {
    readonly setId: ClientQuestionSetId;
    readonly votesByQuestionId: VotesByQuestionId;
  }

  export type VotesByQuestionId = Record<string, VotingProps.Vote | undefined>;

  export interface VotingError {
    readonly errorType: readonly VotingErrorType[];
  }
  export enum VotingErrorType {
    NO_INPUT = 'NO_INPUT',
    MC_OVER_MAX_SELECTION = 'MC_OVER_MAX_SELECTION',
    PA_POINT_NOT_TEN = 'PA_POINT_NOT_TEN',
    GRID_MISSING_ROW = 'GRID_MISSING_ROW',
  }

  export interface QuestionTypedData_MultipleChoice {
    readonly questionType: QuestionType.MULTIPLE_CHOICE;
    readonly selectedChoices: readonly MCChoice[];
    readonly inProcessVote: Vote_MultipleChoice | null;
    readonly previousVote: Vote_MultipleChoice | null;
    readonly maxSelection: number;
  }
  interface QuestionTypedData_FreeText {
    readonly questionType: QuestionType.FREE_TEXT;
    readonly inProcessVote: Vote_FreeText | null;
    readonly previousVote: Vote_FreeText | null;
  }
  interface QuestionTypedData_PointAllocation {
    readonly questionType: QuestionType.POINT_ALLOCATION;
    readonly selectedChoices: readonly PointAllocationChoice[];
    readonly inProcessVote: Vote_PointAllocation | null;
    readonly previousVote: Vote_PointAllocation | null;
  }

  export type QuestionTypedData =
    | QuestionTypedData_MultipleChoice
    | QuestionTypedData_FreeText
    | QuestionTypedData_PointAllocation;

  export interface SetData {
    readonly questions: readonly SetDataQuestion[];
    readonly setName: string;
    readonly currentIndex: number;
    readonly hasLiveVideo: boolean;
  }

  export interface QuestionSchedule {
    readonly openDate: Date | null;
    readonly closeDate: Date | null;
    readonly status: QuestionStatus;
  }
  export interface SetDataQuestion {
    readonly id: ClientQuestionId;
    readonly previousVote: ClientVoteId | null;
    readonly title: string;
    readonly schedule: QuestionSchedule;
  }

  export interface QuestionSetLoaded extends Common {
    readonly setType: SetType;
    readonly respId: ClientRespondentId | null;
    readonly type: VotingPropsType.LOADED;
    readonly publishingEntity: PublishingEntity;
    readonly repostData: {
      readonly reposterName: string | null;
      readonly reposterId: ClientPublishingEntityId;
    } | null; // null means not reposted
    readonly datePublished: Date | null;
    readonly title: string;
    readonly typedData: QuestionTypedData;
    readonly choices: ReadonlyArray<VotingChoice>;
    readonly closed: boolean;
    readonly details: {
      readonly summary?: string;
      readonly description?: string; // is HTML
    };
    readonly comments: LoadableComment<readonly Comment[]>;
    readonly votingInteraction: VotingInteraction | null;
    readonly inProcessComment: InProcessComment | null;
    readonly upvoteCommentsOnVote: ReadonlyArray<Comment['id']>;
    readonly showFullDetails: boolean;
    readonly events: LoadedEvents;
    readonly setData: SetData;
    readonly questionSetId: string;
    readonly questionSetSlug: string | null;
    readonly currentUserId: string | null;
    readonly allowGuestRespondents: boolean;
    readonly preLoginAction: PreLoginAction | null;
    readonly subscriptionType: Gql.SubscriptionType | null;
    readonly isGuest: boolean;
    readonly isShareable: boolean;
    readonly showConversionPrompts: boolean;
    readonly showVerification: boolean;
    readonly redirectedFrom?: string;
    readonly status: RespondentsSetStatus;
    readonly allowMultipleResponses: boolean;
  }

  type PrevQuestionVote =
    | ExtractGql<
        ExtractGql<
          NonNullable<Gql.RespondentVotingPage['openContentSetBySlug']>,
          'Survey'
        >['contents'][0],
        'Question'
      >['previousVote']
    | null;

  type PrevGridVote =
    | ExtractGql<
        ExtractGql<
          NonNullable<Gql.RespondentVotingPage['openContentSetBySlug']>,
          'Survey'
        >['contents'][0],
        'QuestionHierarchyParentNode'
      >['questions'][0]['previousVote']
    | null;

  export interface SurveyPrevQuestionVote {
    readonly type: SurveyItemType.QUESTION;
    readonly vote: PrevQuestionVote;
  }
  export interface SurveyPrevGridVote {
    readonly type: SurveyItemType.HEADER;
    readonly votes: readonly PrevGridVote[];
    readonly gridId: ClientQuestionId;
  }
  export type SurveyPrevVotes = ReadonlyArray<
    SurveyPrevQuestionVote | SurveyPrevGridVote
  > | null;

  export interface CommonSurveyProps extends Common {
    readonly questionSetId: ClientQuestionSetId;
    readonly questionSetSlug: string | null;
    readonly datePublished: Date | null;
    readonly surveyTitle: string;
    readonly logoUrl: string | null;
    readonly publisher: string;
    readonly repostData: {
      readonly reposterName: string | null;
      readonly reposterId: ClientPublishingEntityId;
    } | null; // null means not reposted
    readonly publisherId: ClientPublishingEntityId;
    readonly publisherSlug: string;
    readonly expireDate: Date | null;
    readonly description?: string;
    readonly surveyItems: readonly SavedSurveyItem[];
    readonly randomizedSurveyItems: readonly RandomizedSurveyItems[] | null;
    readonly errMessage: string | null;
    readonly previousVotes: readonly VotingProps.Vote[] | null;
    readonly voted: boolean;
    readonly respId: ClientRespondentId | null;
    readonly votingInteraction: VotingInteraction | null;
    readonly allowGuestRespondents: boolean;
    readonly showingSidebars: boolean;
    readonly surveyInProcessVotes: VotesByQuestionId | null;
    readonly events: SurveyLoadedEvents;
    readonly preLoginAction: PreLoginAction | null;
    readonly closed: boolean;
    readonly requiredToLogin: boolean;
    readonly subscriptionType: Gql.SubscriptionType | null;
    readonly isGuest: boolean;
    readonly status: Gql.QuestionSetStatus;
    readonly estCompletionTime: string;
    readonly requiresVerificationPrompt: boolean;
    readonly showVerification: boolean;
    readonly disabledSubmit: boolean;
    readonly hasOutcome: boolean;
    readonly alternateLanguages: readonly Omit<Language, 'id'>[];
    readonly allowMultipleResponses: boolean;
    readonly isShareable: boolean;
    readonly contentType: QuestionSetType;
  }
  export interface SurveyLoaded extends CommonSurveyProps {
    readonly type: VotingPropsType.LOADED;
    readonly showConversionPrompts: boolean;
  }

  export interface SurveyClosed extends CommonSurveyProps {
    readonly type: VotingPropsType.CLOSED;
  }

  export type QuestionProps = Loading | QuestionSetLoaded | ErrorLoading;

  export type SurveyProps = Loading | SurveyLoaded | ErrorLoading | SurveyClosed;
}

export interface CurrentQuestion {
  readonly id: string;
  readonly status: 'LOADING' | 'LOADED';
  readonly questionType: QuestionType;
}

// Used to complete action upon conversion. These are the actions you could be doing while not logged in, then login/signup and auto complete these actions
export enum NotLoggedInActions {
  FEED = 'FEED',
  SET_VOTE = 'SET_VOTE',
  SURVEY_VOTE = 'SURVEY_VOTE',
  FOLLOW = 'FOLLOW',
  COMMENT = 'COMMENT',
  LINK_ONLY = 'LINK_ONLY', //used for redirecting after login but not trying to fire an action
  QUESTION_VOTE = 'QUESTION_VOTE',
}

export enum NotLoggedInActionStatus {
  PRE_REGISTRATION = 'PRE_REGISTRATION',
  REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',
  REGISTRATION_FAILED = 'REGISTRATION_FAILED',
  ACTION_COMPLETE = 'ACTION_COMPLETE',
  ACTION_FAILED = 'ACTION_FAILED',
}

export enum ConversionType {
  EMAIL_LOGIN = 'EMAIL_LOGIN',
  EMAIL_SIGNUP = 'EMAIL_SIGNUP',
  SOCIAL_LOGIN = 'SOCIAL_LOGIN',
  SOCIAL_SIGNUP = 'SOCIAL_SIGNUP',
}

export enum BannerBadgeType {
  BALANCING_ACT_SIMULATION = 'BALANCING_ACT_SIMULATION',
  POLL_SET = 'POLL_SET',
  SURVEY = 'SURVEY',
  REPOST = 'REPOST',
  QUESTION = 'QUESTION',
  POLCO_LIVE = 'POLCO_LIVE',
}

interface PreLoginActionBase<
  TActionType extends NotLoggedInActions,
  TData extends object | null = null
> {
  readonly actionType: TActionType;
  readonly redirectLink: string;
  readonly status: NotLoggedInActionStatus;
  readonly registrationType: ConversionType | null;
  readonly data: TData;
}

export interface FeedAction extends PreLoginActionBase<NotLoggedInActions.FEED> {}
export interface SetVoteAction
  extends PreLoginActionBase<
    NotLoggedInActions.SET_VOTE,
    {
      readonly setId: ClientQuestionSetId;
      readonly questionId: ClientQuestionId;
    }
  > {}

export interface SurveyVoteAction
  extends PreLoginActionBase<
    NotLoggedInActions.SURVEY_VOTE,
    {
      readonly setId: ClientQuestionSetId;
    }
  > {}

export interface FollowAction
  extends PreLoginActionBase<
    NotLoggedInActions.FOLLOW,
    {
      readonly pubId: string;
      readonly publisher: Gql.RespondentFeed_Item['publishingEntity'];
    }
  > {}

export interface CommentAction
  extends PreLoginActionBase<
    NotLoggedInActions.COMMENT,
    {
      readonly comment: string;
      readonly questionId: ClientQuestionId;
    }
  > {}

export interface QuestionVoteAction
  extends PreLoginActionBase<
    NotLoggedInActions.QUESTION_VOTE,
    {
      readonly questionId: ClientQuestionId;
      readonly publisher: Gql.RespondentFeed_Item['publishingEntity'];
    }
  > {}

export interface LinkOnly
  extends PreLoginActionBase<NotLoggedInActions.LINK_ONLY, {}> {}

export type PreLoginAction =
  | FeedAction
  | SetVoteAction
  | SurveyVoteAction
  | FollowAction
  | CommentAction
  | LinkOnly
  | QuestionVoteAction;

export interface Type {
  // explicitly checked on deserialize - safe
  readonly answerChangeability: AnswerChangeability;
  readonly surveyInProcessId: ClientQuestionSetId | null;
  readonly upvoteCommentsOnVote: readonly VotingProps.Comment['id'][];
  readonly mostRecentContentSetThatPromptedConversion: ClientQuestionSetId | null;
  // not yet explicitly checked on deserialize - unsafe
  readonly interaction: VotingInteraction | null;
  readonly inProcessVote: VotingProps.Vote | null;
  readonly inProcessComment: VotingProps.InProcessComment | null;
  readonly showFullDetails: boolean | undefined;
  readonly currentQuestion: CurrentQuestion | null;
  readonly lastFeedLocation: string | null;
  readonly preLoginAction: PreLoginAction | null;
  readonly currentLocation: UserLocationWithSource | null;
  readonly surveyItems: readonly SavedSurveyItem[] | undefined;
  readonly randomizedSurveyItems: readonly RandomizedSurveyItems[] | null;
  readonly questionMetadataSetId: string | null;
  readonly questionMetadata: readonly QuestionMetadataEntry[] | undefined;
  readonly isEmbed: boolean | undefined;
  readonly inProcessVotesByQuestionSet: Record<
    string,
    VotingProps.VotesByQuestionId
  > | null;
}

export type SetVotingTypeResult =
  | SetVotingTypeResultData
  | VotingTypeResultError
  | VotingTypeResultNoData;

export type QuestionVotingTypeResult =
  | QuestionVotingTypeResultData
  | VotingTypeResultError
  | VotingTypeResultNoData;

interface VotingTypeResultNoData {
  readonly type:
    | VotingProps.VotingPropsType.NOT_FOUND
    | VotingProps.VotingPropsType.LOADING;
}

interface SetVotingTypeResultData {
  readonly type:
    | VotingProps.VotingPropsType.LOADED
    | VotingProps.VotingPropsType.CLOSED;
  readonly result: QueryResult<
    Gql.RespondentVotingPage,
    Gql.RespondentVotingPageVariables
  >;
  readonly data: Gql.RespondentVotingPage;
}

interface QuestionVotingTypeResultData {
  readonly type: VotingProps.VotingPropsType.LOADED;
  readonly result: QueryResult<Gql.Voting, Gql.VotingVariables>;
  readonly data: Gql.Voting;
  readonly set: {
    readonly id: ClientQuestionSetId;
    readonly name: string;
    readonly setType: VotingProps.SetType;
    readonly slug: string | null;
    readonly pubSlug: string;
    readonly shareable: boolean;
  };
  readonly questionSetQueryData: SetForRespondentVote;
  readonly subscriptionType: Gql.SubscriptionType | null;
  readonly contextPublishingEntity:
    | Gql.RespondentVotingPage['contextPublishingEntity']
    | null;
  readonly hasLiveVideo: boolean;
}
interface VotingTypeResultError {
  readonly type: VotingProps.VotingPropsType.ERROR_LOADING;
  readonly error: ApolloError;
}

export type InProcessVote =
  | VotingProps.Vote_FreeText
  | VotingProps.Vote_MultipleChoice
  | VotingProps.Vote_PointAllocation
  | VotingProps.Vote_GridChoice
  | null;

export type VotingPublisher = Omit<
  NonNullable<Gql.Voting['openQuestion']>['questionSet']['publishingEntity'] &
    Gql.PublishingEntityById['openPublishingEntityById'] &
    Gql.AdminDashboard['openPublishingEntityById'] &
    Gql.AdminPublishingEntityContentList['openPublishingEntityById'] &
    Gql.AdminPublishingEntityOutreach['openPublishingEntityById'],
  'shareableContentSets' | 'ncsFacetSummaries'
>;

export type VotingQuestionSet = NonNullable<
  Gql.Voting['openQuestion']
>['questionSet'] &
  Gql.AdminOpenQuestionSet['openContentSetById'];

export type VotingQuestion = Gql.Voting['openQuestion'];

export type VotingComment = NonNullable<
  Gql.Voting['openQuestion']
>['commentsData']['comments'];

export interface RegistrationEvents {
  readonly emailLogin: (data: EmailLoginData) => Promise<
    Result.Type<
      {
        readonly userId: string;
        readonly respondentId: string;
        readonly email: string | null;
      },
      null
    >
  >;
  readonly emailSignup: (
    data: EmailSignupData
  ) => Promise<RegistrationResult<EmailSignupData>>;
  readonly socialLogin: (provider: SocialLoginType) => Promise<void>;
}

export interface RequestResetPasswordEvents {
  readonly requestReset: (data: { readonly email: string }) => Promise<void>;
  readonly socialLogin: RegistrationEvents['socialLogin'];
}

export enum PasswordResetResult {
  SUCCESS = 'SUCCESS',
  ERROR_EXPIRED = 'ERROR_EXPIRED',
  ERROR = 'ERROR',
}

export interface RequestPasswordEvents {
  readonly reset: (data: {
    readonly password: string;
  }) => Promise<PasswordResetResult>;
}

export interface QuestionDispatchContext {
  readonly id: string;
  readonly mut: QuestionVotingMutationReturns;
  readonly dispatch: React.Dispatch<RespondentActions.Types>;
  readonly state: Type;
  readonly query: QueryResult<Gql.Voting>;
  readonly user: CurrentUser | null;
  readonly data: Gql.Voting;
  readonly localStorage: Storage | null;
}

export interface UserLocation {
  readonly zip: string | null;
  readonly latLng: Coordinate | null;
}

// adding this because if we want to save for results, different sources have different levels of accuracy
export enum LocationSource {
  IP = 'IP',
  manualZip = 'manualZip',
  GPS = 'GPS',
}

export type UserLocationWithSource = UserLocation & {
  readonly source: LocationSource;
};

export interface QuestionMetadataEntry {
  readonly type: string;
  readonly value: string;
}

module _PropTypes {
  export const FreeVoteShape = {
    // note: We have to specify exact string. Otherwise, checker can compare free vs other question types and free can be passed the test
    type: PropTypes.oneOf([QuestionType.FREE_TEXT as const]).isRequired,
    comment: PropTypes.nullable(
      PropTypes.exact({
        id: PropTypes.nullable(PropTypes.string.isRequired),
        comment: PropTypes.string.isRequired,
      }).isRequired
    ),
    upvotedCommentIds: PropTypes.readonlyArrayOf(PropTypes.string.isRequired)
      .isRequired,
    questionId: PropTypes.string.isRequired,
  };
  type PropsFreeVoteShape = InferProps<typeof FreeVoteShape>;
  export type _CheckPropsFreeVoteShapeA = AssertAssignable<
    PropsFreeVoteShape,
    VotingProps.Vote_FreeText
  >;
  export type _CheckPropsFreeVoteShapeB = AssertAssignable<
    VotingProps.Vote_FreeText,
    PropsFreeVoteShape
  >;
  export const McVoteShape = {
    type: PropTypes.oneOf([QuestionType.MULTIPLE_CHOICE as const]).isRequired,
    comment: PropTypes.nullable(
      PropTypes.exact({
        id: PropTypes.nullable(PropTypes.string.isRequired),
        comment: PropTypes.string.isRequired,
      }).isRequired
    ),
    choices: PropTypes.readonlyArrayOf(
      PropTypes.exact({
        id: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    upvotedCommentIds: PropTypes.readonlyArrayOf(PropTypes.string.isRequired)
      .isRequired,
    questionId: PropTypes.string.isRequired,
  };
  type PropsMcVoteShape = InferProps<typeof McVoteShape>;
  export type _CheckPropsMcVoteShapeA = AssertAssignable<
    PropsMcVoteShape,
    VotingProps.Vote_MultipleChoice
  >;
  export type _CheckPropsMcVoteShapeB = AssertAssignable<
    VotingProps.Vote_MultipleChoice,
    PropsMcVoteShape
  >;
  export const PaVotePropType = {
    type: PropTypes.oneOf([QuestionType.POINT_ALLOCATION as const]).isRequired,
    comment: PropTypes.nullable(
      PropTypes.exact({
        id: PropTypes.nullable(PropTypes.string.isRequired),
        comment: PropTypes.string.isRequired,
      }).isRequired
    ),
    choices: PropTypes.readonlyArrayOf(
      PropTypes.exact({
        id: PropTypes.string.isRequired,
        point: PropTypes.number.isRequired,
      }).isRequired
    ).isRequired,
    upvotedCommentIds: PropTypes.readonlyArrayOf(PropTypes.string.isRequired)
      .isRequired,
    questionId: PropTypes.string.isRequired,
  };
  type PropsPaVoteType = InferProps<typeof PaVotePropType>;
  export type _CheckPropsPaVoteTypeA = AssertAssignable<
    PropsPaVoteType,
    VotingProps.Vote_PointAllocation
  >;
  export type _CheckPropsPaVoteTypeB = AssertAssignable<
    VotingProps.Vote_PointAllocation,
    PropsPaVoteType
  >;
  export const GridVotePropType = {
    type: PropTypes.oneOf([QuestionType.GRID_CHOICE as const]).isRequired,
    comment: PropTypes.nullable(
      PropTypes.exact({
        id: PropTypes.nullable(PropTypes.string.isRequired),
        comment: PropTypes.string.isRequired,
      }).isRequired
    ),
    gridChoiceByRowId: PropTypes.objectOf(
      PropTypes.undefineable(PropTypes.string.isRequired)
    ).isRequired,
    upvotedCommentIds: PropTypes.readonlyArrayOf(PropTypes.string.isRequired)
      .isRequired,
    questionId: PropTypes.string.isRequired,
  };
  type PropsGridVoteType = InferProps<typeof GridVotePropType>;
  export type _CheckPropsGridVoteTypeA = AssertAssignable<
    PropsGridVoteType,
    VotingProps.Vote_GridChoice
  >;
  export type _CheckPropsGridVoteTypeB = AssertAssignable<
    VotingProps.Vote_GridChoice,
    PropsGridVoteType
  >;

  export const _LocalStoragePropTypes = {
    answerChangeability: PropTypes.oneOf(Object.values(AnswerChangeability))
      .isRequired,
    upvoteCommentsOnVote: PropTypes.readonlyArrayOf(PropTypes.string.isRequired)
      .isRequired,
    mostRecentContentSetThatPromptedConversion: PropTypes.nullable(
      PropTypes.string.isRequired
    ),
    surveyInProcessId: PropTypes.nullable(PropTypes.string.isRequired),
    inProcessVotesByQuestionSet: PropTypes.nullable(
      PropTypes.objectOf(
        PropTypes.objectOf(
          PropTypes.undefineable(
            PropTypes.oneOfType([
              PropTypes.exact(FreeVoteShape).isRequired,
              PropTypes.exact(McVoteShape).isRequired,
              PropTypes.exact(PaVotePropType).isRequired,
              PropTypes.exact(GridVotePropType).isRequired,
            ]).isRequired
          )
        ).isRequired
      ).isRequired
    ),
  };
  type PropsType = InferProps<typeof _LocalStoragePropTypes>;
  export type _CheckPropsTypeA = AssertAssignable<PropsType, Type>;
  export type _CheckPropsTypeB = AssertAssignable<
    Pick<Type, keyof PropsType>,
    PropsType
  >;
}

// currently, it checkes partial state.
export const LocalStoragePropTypes = _PropTypes._LocalStoragePropTypes;

export interface FollowingEvent {
  readonly unfollow: (pubId: ClientPublishingEntityId) => Promise<any> | void;
  readonly follow: (pubId: ClientPublishingEntityId) => Promise<any> | void;
}

export interface FollowingPublisher {
  readonly id: string;
  readonly name: string;
  readonly location: string | null;
  readonly seal: string | null;
  readonly slug: string;
  readonly currentRespondentFollowing: boolean;
}

export enum SubscriptionsTabs {
  FOLLOWING = 'FOLLOWING',
  SEARCHING = 'SEARCHING',
}

export const DISMISSED_VERIFICATION_PROMPT = 'DISMISSED_VERIFICATION_PROMPT';
