import * as React from 'react';
import './styles.scss';

import AddToCalendarHOC, { AddToCalendarEvent } from 'react-add-to-calendar-hoc';
import { Button } from 'react-bootstrap';
import { Btn, ButtonTypes } from 'client/shared/components/base';
import { Modal } from 'client/shared/components/modal';

interface CalendarButtonProps {
  readonly event: AddToCalendarEvent;
}

interface CalendarModalProps {
  readonly isOpen: boolean;
  readonly onRequestClose: (event?: React.MouseEvent | React.KeyboardEvent) => void;
}

const copy = {
  modalHeader: 'Add to Calendar',
  cancel: 'Cancel',
};

const CalendarModal: React.FC<CalendarModalProps> = (props) => {
  return (
    <Modal
      centered={true}
      header={{ title: copy.modalHeader }}
      isCloseable={false}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      size={'sm'}
    >
      {props.children}
      <Btn
        action={(evt) => props.onRequestClose(evt)}
        className="mt-3"
        type={ButtonTypes.SECONDARY}
      >
        {copy.cancel}
      </Btn>
    </Modal>
  );
};

const AddToCalendarDropdownWrapper = AddToCalendarHOC(Button, CalendarModal);

export const AddToCalendarDropdown: React.FC<CalendarButtonProps> = (props) => {
  return (
    <AddToCalendarDropdownWrapper
      buttonProps={{
        variant: 'primary',
        className: 'rounded',
      }}
      event={props.event}
      filename={props.event.title}
      linkProps={{
        className: 'text-left p-1 d-block font-weight-bold',
      }}
    />
  );
};
