import * as React from 'react';
import './styles.scss';
import { handlePressEnter } from 'client/shared/core/helpers';
import { Form, Col } from 'react-bootstrap';
import { Btn, ButtonTypes } from 'client/shared/components/base/btn';
import { useForm, RegisterOptions } from 'react-hook-form';
import { RBRef } from 'client/shared/core/types';
import { VerificationFormInputs } from 'client/respondent/shared/account/types';
import { InputType } from 'client/shared/components/base/text-input';
import { EDIT_PROFILE_COPY } from '../account/copy';
import {
  NOT_EMPTY_STRING_REGEX,
  ZIP_VALIDATION_REGEX,
} from 'client/shared/core/constants';
import { useId } from 'react-use-id-hook';
import {
  Label,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { copy as SIGNUP_COPY } from 'client/respondent/shared/account/components/access/email-signup';

interface Props {
  readonly initialProfile: VerificationFormInputs;
  readonly isFeed: boolean;
  submitForm(fields: VerificationFormInputs): void;
  readonly cancel: () => void;
  readonly tabIndex?: 0 | -1;
}

export const baseClass = 'pn-verification-step';

export const copy = {
  save: 'Verify me',
  skip: `I'll do this later`,
  subtitle: 'Thanks for your input! Let’s get you verified.',
  feedSubtitle: `Welcome to Polco! Let's get you verified.`,
  description: `In an effort to provide the most accurate and reliable data to local leaders, you can verify your residency by submitting your full legal name. You'll always remain anonymous and we'll never share this information with your local officials or anyone else.`,
  description2:
    'This step is optional, but can make your input even more meaningful - responses from verified residents are included in a separate set of results and often treated as more reliable than the overall results.',
};

type ValidationSet<FormFields> = Record<keyof FormFields, RegisterOptions>;
const validations: ValidationSet<VerificationFormInputs> = {
  firstName: {
    required: {
      value: true,
      message: EDIT_PROFILE_COPY.errorMessages.required,
    },
    pattern: {
      value: NOT_EMPTY_STRING_REGEX,
      message: EDIT_PROFILE_COPY.errorMessages.required,
    },
  },
  lastName: {
    required: {
      value: true,
      message: EDIT_PROFILE_COPY.errorMessages.required,
    },
    pattern: {
      value: NOT_EMPTY_STRING_REGEX,
      message: EDIT_PROFILE_COPY.errorMessages.required,
    },
  },
  zipCode: {
    required: {
      value: true,
      message: EDIT_PROFILE_COPY.errorMessages.required,
    },
    pattern: {
      value: ZIP_VALIDATION_REGEX,
      message: EDIT_PROFILE_COPY.errorMessages.required,
    },
  },
};

export const VerificationStep: React.FC<Props> = (p) => {
  const { register, handleSubmit, errors, formState } =
    useForm<VerificationFormInputs>({
      mode: 'onChange',
      defaultValues: {
        firstName: p.initialProfile.firstName,
        lastName: p.initialProfile.lastName,
        zipCode: p.initialProfile.zipCode,
      },
    });

  const createRef = (opts: RegisterOptions): RBRef => {
    return register(opts) as RBRef;
  };

  const text = (
    label: string,
    k: keyof VerificationFormInputs,
    id: string,
    feedbackId: string,
    opts: {
      readonly autoComplete?: string;
      readonly ariaLabel?: string;
      readonly placeholder?: string;
      readonly disabled?: boolean;
      readonly hideLabel?: boolean;
      readonly required?: boolean;
      readonly tabIndex?: 0 | -1;
    }
  ) => {
    const isInvalid = !!errors[k];
    return (
      <Form.Group as={Col} className="p-0">
        <Label
          className={`font-size-sm ${!!opts?.hideLabel ? 'd-none' : ''}`}
          htmlFor={id}
          text={label}
        />
        <Form.Control
          aria-errormessage={isInvalid ? feedbackId : undefined}
          aria-invalid={isInvalid}
          aria-label={opts.ariaLabel ?? label}
          autoComplete={opts?.autoComplete}
          className={`${
            label ? '' : `${baseClass}-no-margin`
          } rounded accessible-input ${
            errors[k]?.message && formState.isSubmitted ? 'has-error' : ''
          }`}
          disabled={opts?.disabled}
          id={id}
          isInvalid={isInvalid}
          name={k}
          placeholder={opts?.placeholder}
          ref={createRef(validations[k] ?? {})}
          required={opts?.required}
          tabIndex={opts?.tabIndex ?? 0}
          type={InputType.TEXT}
        />
        <Form.Control.Feedback
          className={formState.isSubmitted ? 'd-block' : ''}
          id={feedbackId}
          type="invalid"
        >
          {errors[k]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  };
  const zipCodeId = useId();
  const zipCodeFeedbackId = useId();

  return (
    <Form
      onKeyUp={handlePressEnter(handleSubmit(p.submitForm))}
      onSubmit={handleSubmit(p.submitForm)}
    >
      <div className={`${baseClass}-form d-flex flex-column p-3 align-items-center`}>
        <MaterialIcon
          className={`${baseClass}-icon mb-4`}
          icon={MaterialIconName.VERIFIED_USER}
        />
        <h4
          className={`${baseClass}-subtitle font-weight-bold mb-4 text-left w-100`}
        >
          {p.isFeed ? copy.feedSubtitle : copy.subtitle}
        </h4>
        <p className="w-100 mb-4">{copy.description}</p>
        <p className="w-100 mb-4">{copy.description2}</p>
        {text('Full Legal Name', 'firstName', useId(), useId(), {
          autoComplete: 'given-name',
          ariaLabel: EDIT_PROFILE_COPY.fields.firstName.placeholder,
          placeholder: EDIT_PROFILE_COPY.fields.firstName.placeholder,
          required: true,
          tabIndex: p.tabIndex,
        })}
        {text(
          EDIT_PROFILE_COPY.fields.lastName.label,
          'lastName',
          useId(),
          useId(),
          {
            autoComplete: 'family-name',
            ariaLabel: EDIT_PROFILE_COPY.fields.lastName.placeholder,
            placeholder: EDIT_PROFILE_COPY.fields.lastName.placeholder,
            hideLabel: true,
            required: true,
            tabIndex: p.tabIndex,
          }
        )}
        {p.isFeed &&
          !p.initialProfile.zipCode &&
          text(
            EDIT_PROFILE_COPY.fields.zipCode.label,
            'zipCode',
            zipCodeId,
            zipCodeFeedbackId,
            {
              autoComplete: 'postal-code',
              ariaLabel: SIGNUP_COPY.labels.zip.label,
              placeholder: SIGNUP_COPY.labels.zip.placeholder,
              required: true,
              tabIndex: p.tabIndex,
            }
          )}
        <Btn
          action={handleSubmit(p.submitForm)}
          className="w-100 mt-4"
          disabled={
            !!errors.firstName ||
            !!errors.lastName ||
            (p.isFeed ? !!errors.zipCode : false)
          }
          type={ButtonTypes.PRIMARY}
        >
          {copy.save}
        </Btn>
        <Btn
          action={p.cancel}
          ariaLabel="Skip update"
          className="w-100 mt-3"
          type={ButtonTypes.SEAMLESS}
        >
          {copy.skip}
        </Btn>
      </div>
    </Form>
  );
};
