import * as React from 'react';
import './styles.scss';
import {
  Btn,
  MaterialIcon,
  MaterialIconName,
  ButtonTypes,
} from 'client/shared/components/base';
import { ACTION_BUTTON_COPY } from './copy';
import { ClientQuestionId } from 'client/shared/core/question';
import { LoadedEvents, VotingInteraction } from 'client/shared/core/types';

interface Props {
  readonly events: {
    readonly setInteraction: LoadedEvents['setInteraction'];
    readonly goToQuestion: LoadedEvents['goToQuestion'];
    readonly submit: () => Promise<void>;
    readonly doneAnsweringQuestions: LoadedEvents['doneAnsweringQuestions'];
    readonly followPublisher: LoadedEvents['followPublisher'];
    readonly restart: () => Promise<void>;
    readonly done: () => Promise<void>;
  };
  readonly voted: boolean;
  readonly nextQuestionId: ClientQuestionId | null;
  readonly closed: boolean;
  readonly showAddComment: boolean;
  readonly allowGuestRespondents: boolean;
  readonly allowSubmit: boolean;
  readonly error: string | null;
  readonly interaction: VotingInteraction | null;
  readonly fromOverview: boolean;
  readonly allowMultipleResponses: boolean;
  readonly isGuest: boolean;
}

const baseClass = 'pn-action-buttons';
const buttonClass = 'pn-respondent-voting-button-secondary';

export const ActionButtons: React.FC<Props> = (props) => {
  const {
    nextQuestionId,
    voted,
    events,
    closed,
    showAddComment,
    allowSubmit,
    error,
    allowMultipleResponses,
    isGuest,
  } = props;

  const getPrimaryAction = () => {
    if (
      !voted &&
      !closed &&
      props.interaction !== VotingInteraction.COMMENTING &&
      props.interaction !== VotingInteraction.VIEWING_COMMENTS
    ) {
      return (
        <Btn
          action={events.submit}
          className={`${buttonClass} p-2`}
          disabled={!allowSubmit}
          type={ButtonTypes.PRIMARY}
        >
          {ACTION_BUTTON_COPY.submit}
          <MaterialIcon className="ml-1" icon={MaterialIconName.CHECK} />
        </Btn>
      );
    } else if (
      props.interaction === VotingInteraction.VIEWING_COMMENTS &&
      !voted &&
      !closed
    ) {
      return (
        <Btn
          action={() => events.setInteraction(VotingInteraction.SELECTING_CHOICE)}
          className={`${buttonClass} p-2`}
          type={ButtonTypes.PRIMARY}
        >
          {ACTION_BUTTON_COPY.answerPoll}
        </Btn>
      );
    }
  };

  const showRestartPoll = voted && isGuest && allowMultipleResponses;

  const showNextOrExit =
    closed || (voted && (nextQuestionId || !isGuest || !allowMultipleResponses));

  const showDone =
    isGuest && allowMultipleResponses && !closed && voted && !nextQuestionId;

  return (
    <div className={`${baseClass} w-100`}>
      <div className="pt-4">
        {!voted && error && <div className="text-danger font-size-sm">{error}</div>}
        <div className="d-flex p-4 justify-content-between align-items-center">
          <div>
            {!closed && (
              <div>
                {showAddComment && (
                  <Btn
                    action={() =>
                      events.setInteraction(VotingInteraction.COMMENTING)
                    }
                    className="p-2 mr-1"
                    type={ButtonTypes.SECONDARY}
                  >
                    {ACTION_BUTTON_COPY.comment}
                  </Btn>
                )}
                {showRestartPoll && (
                  <Btn
                    action={events.restart}
                    className="p-2 mx-1"
                    type={ButtonTypes.SECONDARY}
                  >
                    {ACTION_BUTTON_COPY.restartPoll}
                  </Btn>
                )}
                {!voted && (
                  <Btn
                    action={() => {
                      nextQuestionId
                        ? events.goToQuestion(nextQuestionId)
                        : events.doneAnsweringQuestions();
                    }}
                    className="p-2 mr-1"
                    type={ButtonTypes.SECONDARY}
                  >
                    {nextQuestionId
                      ? ACTION_BUTTON_COPY.skip
                      : ACTION_BUTTON_COPY.exit}
                    <MaterialIcon
                      className="ml-1"
                      icon={MaterialIconName.ARROW_FORWARD}
                    />
                  </Btn>
                )}
              </div>
            )}
          </div>
          {getPrimaryAction()}

          {showNextOrExit && (
            <Btn
              action={() => {
                nextQuestionId
                  ? events.goToQuestion(nextQuestionId, props.fromOverview)
                  : events.doneAnsweringQuestions();
              }}
              className={`${buttonClass} p-2`}
              type={ButtonTypes.PRIMARY}
            >
              {nextQuestionId ? ACTION_BUTTON_COPY.next : ACTION_BUTTON_COPY.exit}
              <MaterialIcon className="ml-1" icon={MaterialIconName.ARROW_FORWARD} />
            </Btn>
          )}

          {showDone && (
            <Btn
              action={events.done}
              className={`${buttonClass} p-2`}
              type={ButtonTypes.PRIMARY}
            >
              {ACTION_BUTTON_COPY.done}
            </Btn>
          )}
        </div>
      </div>
    </div>
  );
};
