import * as React from 'react';

import { MaterialIcon, MaterialIconName } from 'client/shared/components/base';
import { AuthnFlowSliderModal } from '../../account/containers/authn-flow-slider-modal';
import { ClientRespondentId } from 'client/respondent/core';

const copy = {
  description: (publisherName: string) =>
    `In order to follow ${publisherName}, you’ll need a Polco account.`,
  title: (publisherName: string) => `Follow ${publisherName}`,
  secondaryButtonLabel: 'Account Setup',
};

export const FollowPublisherAccountPrompt: React.FC<{
  readonly publisherName: string;
  readonly onClose: () => void;
  readonly onAuthenticationSuccess: (args: {
    readonly respondentId: ClientRespondentId;
  }) => void;
}> = (p) => {
  return (
    <AuthnFlowSliderModal
      isOpen
      onAuthenticationSuccess={p.onAuthenticationSuccess}
      onClose={p.onClose}
      registrationPrompt={{
        description: copy.description(p.publisherName),
        image: <MaterialIcon icon={MaterialIconName.BOOKMARK} />,
        title: copy.title(p.publisherName),
        secondaryButtonLabel: copy.secondaryButtonLabel,
        switchButtons: true,
      }}
    />
  );
};
