import * as React from 'react';
import './styles.scss';
import {
  MaterialIcon,
  MaterialIconName,
  TextInput,
} from 'client/shared/components/base';
import { MailTo } from 'client/shared/components/mail-to';
import mapImg from './assets/map.svg';
import { SEARCH_PUBLISHER_COPY, MAIL_TO_COPY } from './copy';
import { FollowingCard, CardType } from '../following-card';
import { take } from 'lodash';
import { FollowingEvent, FollowingPublisher } from 'client/respondent/core/types';
import { useId } from 'react-use-id-hook';
import pluralize from 'pluralize';
import { AuthnFlowSliderModal } from 'client/respondent/shared/account/containers/authn-flow-slider-modal';

const baseClass = 'pn-subscriptions-search';
export interface SearchPublisherProps {
  readonly searchablePublishers: readonly FollowingPublisher[];
  readonly event: FollowingEvent | null;
  readonly noAccount?: boolean;
  readonly isGuest?: boolean;
}

export interface NoResultProps {
  readonly requestedPubName: string;
}

const MAX_SEARCH_RESULT = 20;

const getSearchResultText = (
  searchString: string,
  filteredPubs: ReadonlyArray<FollowingPublisher>,
  totalSearchResultCount: number
) => {
  if (!searchString.trim().length) return '';
  const listTotalResults = totalSearchResultCount > filteredPubs.length;
  return `Showing ${filteredPubs.length} ${
    listTotalResults ? ` of ${totalSearchResultCount}` : ''
  } ${pluralize('result', filteredPubs.length)}. Keep trying to refine your search.`;
};

export const SearchPublisher: React.FC<SearchPublisherProps> = (props) => {
  const { searchablePublishers, event } = props;
  const [curText, setCurText] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const profileControlId = useId();
  const [searchResult, setSearchResult] = React.useState<
    readonly FollowingPublisher[]
  >([]);
  const [totalSearchResultCount, setTotalSearchResultCount] =
    React.useState<number>(0);

  const searchPub = (pubs: readonly FollowingPublisher[], searchKeyword: string) => {
    // for performance reasons, we show only first 20 results
    const totalFilteredPubs = pubs.filter((pub) => {
      const pubName = pub.name;
      return pubName.toLowerCase().includes(searchKeyword.toLowerCase());
    });
    const filteredPubs = take(totalFilteredPubs, MAX_SEARCH_RESULT);
    return {
      totalFilteredPubs,
      filteredPubs,
    };
  };

  const handleInputFocus = (emittedEvent: any) => {
    if (props.isGuest) {
      (emittedEvent.target as HTMLElement)?.blur();
      setIsOpen(true);
    }
  };

  // this update search result whenever a list of subscribablePublisher is changed
  // e.g, when a user follows a pub from search result.
  React.useEffect(() => {
    const { filteredPubs, totalFilteredPubs } = searchPub(
      searchablePublishers,
      curText
    );
    setSearchResult(filteredPubs);
    setTotalSearchResultCount(totalFilteredPubs.length);
  }, [searchablePublishers, curText]);

  return (
    <div className="d-flex flex-column" role="search">
      <h2 className="my-2 px-3 py-1 font-weight-bold font-size-lg">
        {SEARCH_PUBLISHER_COPY.search}
      </h2>
      <div className={`${baseClass}-inputbox mx-3`}>
        <label className="d-none" htmlFor={`${profileControlId}-form-control`}>
          {SEARCH_PUBLISHER_COPY.searchPlaceholer}
        </label>
        <TextInput
          ariaLabel={SEARCH_PUBLISHER_COPY.searchPlaceholer}
          className="mb-1"
          disabled={props.noAccount}
          id={profileControlId}
          inputClassName="accessible-input"
          onChange={setCurText}
          onFocus={handleInputFocus}
          placeholder={SEARCH_PUBLISHER_COPY.searchPlaceholer}
          rounded
          value={curText}
        />
        <div
          aria-live={props.noAccount ? 'off' : 'assertive'}
          className="text-gray-50 mb-2 font-size-xs"
        >
          {props.noAccount
            ? SEARCH_PUBLISHER_COPY.noAccount
            : getSearchResultText(curText, searchResult, totalSearchResultCount)}
        </div>
      </div>
      {curText ? (
        searchResult.length !== 0 ? (
          searchResult.map((r) => {
            return (
              <FollowingCard
                cardType={
                  r.currentRespondentFollowing ? CardType.FOLLOWING : CardType.FOLLOW
                }
                event={event}
                key={r.id}
                publisher={r}
              />
            );
          })
        ) : (
          <NoResult requestedPubName={curText} />
        )
      ) : (
        <img alt={'map'} className="my-5 mx-3" src={mapImg} />
      )}

      {searchResult.length === MAX_SEARCH_RESULT && curText && (
        <div className="d-flex justify-content-center align-items-center my-3 py-3 text-gray-50 text-center px-1">
          {SEARCH_PUBLISHER_COPY.accurateResult}
        </div>
      )}

      {isOpen && (
        <AuthnFlowSliderModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          registrationPrompt={{
            description: SEARCH_PUBLISHER_COPY.modal.description,
            image: <MaterialIcon icon={MaterialIconName.SEARCH} />,
            title: SEARCH_PUBLISHER_COPY.modal.title,
            secondaryButtonLabel: SEARCH_PUBLISHER_COPY.modal.secondaryButtonLabel,
            switchButtons: true,
          }}
        />
      )}
    </div>
  );
};

const NoResult: React.FC<NoResultProps> = (p) => {
  return (
    <div className="d-flex flex-column justify-content-center mx-4 px-4">
      <p className="font-weight-bold my-3">{SEARCH_PUBLISHER_COPY.noResultTitle}</p>
      <div>
        <span className="text-gray-50">{SEARCH_PUBLISHER_COPY.noResultDesc1}</span>
        <MailTo
          body={`${MAIL_TO_COPY.body}${p.requestedPubName}`}
          email={MAIL_TO_COPY.email}
          subject={`${MAIL_TO_COPY.subject}${p.requestedPubName}`}
        >
          <span className="text-jungle font-weight-bold">{` ${SEARCH_PUBLISHER_COPY.notifyUs} `}</span>
        </MailTo>
        <span className="text-gray-50 font-size-sm">
          {SEARCH_PUBLISHER_COPY.noResultDesc2}
        </span>
      </div>
      <img alt={'map'} className="my-5" src={mapImg} />
    </div>
  );
};
