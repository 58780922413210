import * as State from './context';
import { ActionTypeKeys, RespondentActions } from './actions';
import {
  VotingProps,
  Type,
  NotLoggedInActionStatus,
} from 'client/respondent/core/types';
import { Lens } from '@atomic-object/lenses';
import { QuestionType, ClientQuestionId } from 'client/shared/core/question';
import { VotingInteraction, AnswerChangeability } from 'client/shared/core/types';
import _ from 'lodash';

export type Reducer = (
  state: Type | undefined,
  action: RespondentActions.Types
) => Type;

export const VotingState = Lens.from<Type>();

export function votingReducer(
  state: Type = State.initialState,
  action: RespondentActions.Types
): Type {
  switch (action.type) {
    case ActionTypeKeys.VOTING_START_IN_PROCESS_VOTE:
      const initialInProcessVote = getInitialVoteDataForType(
        action.questionType,
        action.questionId
      );
      return {
        ...state,
        interaction: VotingInteraction.SELECTING_CHOICE,
        inProcessVote: initialInProcessVote,
      };

    case ActionTypeKeys.VOTING_SELECTED_MULTIPLE_CHOICE:
      if (
        !state.inProcessVote ||
        state.inProcessVote.type !== QuestionType.MULTIPLE_CHOICE ||
        state.inProcessVote.questionId !== action.questionId
      ) {
        throw new Error(
          'Error- invalid question selection. You are tying to vote on a question that is different than the In Process Vote'
        );
      }

      const mcChoices = action.choices.map((ch) => ({ id: ch.id }));
      const inProcessMCVote = {
        ...state.inProcessVote,
        choices: mcChoices,
        type: QuestionType.MULTIPLE_CHOICE as const,
      };
      return {
        ...state,
        inProcessVote: inProcessMCVote,
        interaction: VotingInteraction.SELECTING_CHOICE,
      };

    case ActionTypeKeys.VOTING_SELECTED_POINT_ALLOCATION:
      if (
        !state.inProcessVote ||
        state.inProcessVote.type !== QuestionType.POINT_ALLOCATION ||
        state.inProcessVote.questionId !== action.questionId
      ) {
        throw new Error(
          'Error- invalid question selection. You are tying to vote on a question that is different than the In Process Vote'
        );
      }
      const paChoices = action.choices.map((ch) => ({
        id: ch.id,
        point: ch.point,
      }));
      const inProcessPAVote = {
        ...state.inProcessVote,
        choices: paChoices,
        type: QuestionType.POINT_ALLOCATION as const,
      };
      return {
        ...state,
        inProcessVote: inProcessPAVote,
        interaction: VotingInteraction.SELECTING_CHOICE,
      };

    case ActionTypeKeys.VOTING_UPDATE_COMMENT_TEXT:
      const comment =
        action.comment && action.comment.length > 0 ? action.comment : null;
      return {
        ...state,
        inProcessComment: comment
          ? { comment, questionId: action.questionId }
          : null,
      };

    case ActionTypeKeys.VOTING_CHANGE_ANSWER:
      const changeVote =
        action.which === AnswerChangeability.CAN_CHANGE_VOTE_AND_COMMENT;
      return {
        ...state,
        interaction: changeVote
          ? VotingInteraction.SELECTING_CHOICE
          : VotingInteraction.COMMENTING,
        inProcessVote: action.currentVote,
      };

    case ActionTypeKeys.VOTING_CLEAR_IN_PROCESS_VOTE:
      return {
        ...state,
        inProcessVote: null,
        inProcessComment: null,
        interaction: null,
      };

    case ActionTypeKeys.REGISTRATION_SUCCESSFUL:
      return {
        ...state,
        interaction: VotingInteraction.REGISTRATION_SUCCESSFUL,
        preLoginAction:
          state.preLoginAction &&
          state.preLoginAction.status !== NotLoggedInActionStatus.ACTION_COMPLETE
            ? {
                ...state.preLoginAction,
                status: NotLoggedInActionStatus.REGISTRATION_SUCCESS,
                registrationType: action.conversionType,
              }
            : null,
      };

    case ActionTypeKeys.PROMPT_REGISTRATION:
      return {
        ...state,
        interaction: VotingInteraction.PROMPT_TO_REGISTER,
        preLoginAction: action.preLoginAction,
      };

    case ActionTypeKeys.VOTING_CANCEL_INTERACTION: {
      return { ...state, interaction: null };
    }

    case ActionTypeKeys.VOTING_COMPLETE_INTERACTION: {
      return { ...state, interaction: null };
    }

    case ActionTypeKeys.SET_SHOW_FULL_DETAILS:
      return {
        ...state,
        showFullDetails: action.showFullDetails,
      };

    case ActionTypeKeys.PREVOTE_STORE_COMMENT_UPVOTE:
      return {
        ...state,
        upvoteCommentsOnVote: [...state.upvoteCommentsOnVote, action.commentId],
      };

    case ActionTypeKeys.PREVOTE_REMOVE_COMMENT_UPVOTE:
      const newUpvoteComments = state.upvoteCommentsOnVote.filter(
        (id) => id !== action.commentId
      );
      return {
        ...state,
        upvoteCommentsOnVote: newUpvoteComments,
      };

    case ActionTypeKeys.VOTING_QUESTION_CHANGED:
      return { ...state, currentQuestion: action.newQuestion };

    case ActionTypeKeys.VOTING_SET_INTERACTION: {
      return {
        ...state,
        interaction: action.interaction,
      };
    }

    // survey specific actions start
    case ActionTypeKeys.VOTING_SURVEY_START_IN_PROCESS_VOTE:
      return {
        ...state,
        surveyItems: action.surveyItems,
        surveyInProcessId: action.setId,
      };

    case ActionTypeKeys.CLEAR_RANDOMIZED_SURVEY_ITEMS: {
      const stateRandomizedSurveyItems = state.randomizedSurveyItems || [];

      return {
        ...state,
        randomizedSurveyItems: stateRandomizedSurveyItems.filter(
          ({ id }) => !action.choicesIds.includes(id)
        ),
      };
    }

    case ActionTypeKeys.SET_RANDOMIZED_SURVEY_ITEMS: {
      const stateRandomizedSurveyItems = state.randomizedSurveyItems || [];
      const { randomizedSurveyItems } = action;

      const element = stateRandomizedSurveyItems.find(
        ({ id }) => randomizedSurveyItems.id === id
      );

      if (element) {
        return {
          ...state,
          randomizedSurveyItems: [
            ...stateRandomizedSurveyItems.filter(
              ({ id }) => randomizedSurveyItems.id !== id
            ),
            randomizedSurveyItems,
          ],
        };
      } else {
        return {
          ...state,
          randomizedSurveyItems: [
            ...stateRandomizedSurveyItems,
            randomizedSurveyItems,
          ],
        };
      }
    }

    case ActionTypeKeys.SURVEY_SUBMIT_CLEAR_VOTES:
      const savedVotes = _.omit(state.inProcessVotesByQuestionSet, action.setId);
      return {
        ...state,
        inProcessVotesByQuestionSet: savedVotes,
      };

    case ActionTypeKeys.VOTING_SURVEY_SELECTED_MULTIPLE_CHOICE:
      if (!state.inProcessVotesByQuestionSet || !state.surveyInProcessId) {
        return state;
      }
      const newMcChoices = action.choices.map((ch) => ({ id: ch.id }));
      const mcVote: VotingProps.Vote = {
        questionId: action.questionId,
        choices: newMcChoices,
        comment: null,
        upvotedCommentIds: [],
        type: QuestionType.MULTIPLE_CHOICE as const,
      };

      return {
        ...state,
        inProcessVotesByQuestionSet: {
          ...state.inProcessVotesByQuestionSet,
          [state.surveyInProcessId]: {
            ...state.inProcessVotesByQuestionSet[state.surveyInProcessId],
            [mcVote.questionId]: mcVote,
          },
        },
        interaction: VotingInteraction.SELECTING_CHOICE,
      };

    case ActionTypeKeys.VOTING_SURVEY_SELECTED_POINT_ALLOCATION:
      if (!state.inProcessVotesByQuestionSet || !state.surveyInProcessId) {
        return state;
      }
      const newPaChoices = action.choices.map((ch) => ({
        id: ch.id,
        point: ch.point,
      }));

      const paVote: VotingProps.Vote = {
        questionId: action.questionId,
        choices: newPaChoices,
        comment: null,
        upvotedCommentIds: [],
        type: QuestionType.POINT_ALLOCATION as const,
      };

      return {
        ...state,
        inProcessVotesByQuestionSet: {
          ...state.inProcessVotesByQuestionSet,
          [state.surveyInProcessId]: {
            ...state.inProcessVotesByQuestionSet[state.surveyInProcessId],
            [paVote.questionId]: paVote,
          },
        },
        interaction: VotingInteraction.SELECTING_CHOICE,
      };

    case ActionTypeKeys.VOTING_SURVEY_FREE_TEXT_UPDATE_TEXT:
      if (!state.inProcessVotesByQuestionSet || !state.surveyInProcessId) {
        return state;
      }
      const newComment =
        action.comment && action.comment.length > 0 ? action.comment : null;

      const freeVote: VotingProps.Vote = {
        questionId: action.questionId,
        comment: {
          id: null,
          comment: newComment ?? '',
        },
        upvotedCommentIds: [],
        type: QuestionType.FREE_TEXT as const,
      };

      return {
        ...state,
        inProcessVotesByQuestionSet: {
          ...state.inProcessVotesByQuestionSet,
          [state.surveyInProcessId]: {
            ...state.inProcessVotesByQuestionSet[state.surveyInProcessId],
            [freeVote.questionId]: freeVote,
          },
        },
        interaction: VotingInteraction.COMMENTING,
      };

    case ActionTypeKeys.VOTING_SURVEY_SELECTED_GRID_CHOICE:
      if (!state.inProcessVotesByQuestionSet || !state.surveyInProcessId) {
        return state;
      }

      const foundGrid =
        state.inProcessVotesByQuestionSet?.[state.surveyInProcessId]?.[
          action.questionId
        ] ?? getInitialVoteDataForType(QuestionType.GRID_CHOICE, action.questionId);

      if (foundGrid?.type === QuestionType.GRID_CHOICE) {
        foundGrid.gridChoiceByRowId[action.gridChoice.rowId] =
          action.gridChoice.colId;
        return {
          ...state,
          inProcessVotesByQuestionSet: {
            ...state.inProcessVotesByQuestionSet,
            [state.surveyInProcessId]: {
              ...state.inProcessVotesByQuestionSet[state.surveyInProcessId],
              [action.questionId]: {
                ...foundGrid,
                gridChoiceByRowId: {
                  ...foundGrid.gridChoiceByRowId,
                  [action.gridChoice.rowId]: action.gridChoice.colId,
                },
              },
            },
          },
          interaction: VotingInteraction.COMMENTING,
        };
      }
      return {
        ...state,
      };

    case ActionTypeKeys.SET_LAST_FEED_LOCATION:
      return {
        ...state,
        lastFeedLocation: action.pubIdOrSlug,
      };
    case ActionTypeKeys.COMPLETE_PRE_LOGIN_ACTION:
      return {
        ...state,
        preLoginAction: state.preLoginAction
          ? {
              ...state.preLoginAction,
              status: NotLoggedInActionStatus.ACTION_COMPLETE,
            }
          : null,
      };
    case ActionTypeKeys.PRE_LOGIN_ACTION_FAILURE:
      return {
        ...state,
        preLoginAction: state.preLoginAction
          ? {
              ...state.preLoginAction,
              status: NotLoggedInActionStatus.ACTION_FAILED,
            }
          : null,
      };
    case ActionTypeKeys.SET_CURRENT_LOCATION:
      if (
        state.currentLocation?.latLng === action.location.latLng &&
        state.currentLocation.zip === action.location.zip
      ) {
        return state;
      }
      return {
        ...state,
        currentLocation: { ...action.location, source: action.source },
      };
    case ActionTypeKeys.SET_QUESTION_METADATA:
      return {
        ...state,
        ...mergeQuestionMetadata(state, action),
      };
    case ActionTypeKeys.SET_QUESTION_METADATA_ITEM:
      return {
        ...state,
        ...mergeQuestionMetadata(state, {
          questionMetadataSetId: action.setId,
          questionMetadata: [action.item],
        }),
      };
    case ActionTypeKeys.CLEAR_QUESTION_METADATA_ITEM:
      if (!state.questionMetadata) {
        return state;
      }
      const clearedMetadata = state.questionMetadata.filter(
        (m) => m.type !== action.metadataType
      );
      return {
        ...state,
        questionMetadata: clearedMetadata,
      };
    case ActionTypeKeys.SET_MOST_RECENT_CONTENT_SET_THAT_PROMPTED_CONVERSION:
      if (
        state.mostRecentContentSetThatPromptedConversion === action.questionSetId
      ) {
        return state;
      }
      return {
        ...state,
        mostRecentContentSetThatPromptedConversion: action.questionSetId,
      };
    // end
    default:
      return state;
  }
}

export function getInitialVoteDataForType(
  type: QuestionType,
  questionId: ClientQuestionId
): VotingProps.Vote {
  switch (type) {
    case QuestionType.FREE_TEXT:
      return {
        type,
        questionId,
        comment: null,
        upvotedCommentIds: [],
      };

    case QuestionType.POINT_ALLOCATION:
      return {
        type,
        questionId,
        choices: [],
        comment: null,
        upvotedCommentIds: [],
      };

    case QuestionType.MULTIPLE_CHOICE:
      return {
        type,
        questionId,
        choices: [],
        comment: null,
        upvotedCommentIds: [],
      };
    case QuestionType.GRID_CHOICE:
      return {
        type,
        questionId,
        gridChoiceByRowId: {},
        comment: null,
        upvotedCommentIds: [],
      };
    default:
      throw new Error(`Invalid type ${type}`);
  }
}

export function mergeQuestionMetadata(
  oldMetadata: Pick<Type, 'questionMetadata' | 'questionMetadataSetId'>,
  newMetadata: Pick<Type, 'questionMetadata' | 'questionMetadataSetId'>
): Pick<Type, 'questionMetadata' | 'questionMetadataSetId'> {
  const changedSet =
    !!oldMetadata.questionMetadataSetId &&
    newMetadata.questionMetadataSetId !== oldMetadata.questionMetadataSetId;
  const combined = changedSet
    ? newMetadata.questionMetadata
    : [
        ...(newMetadata.questionMetadata ?? []),
        ...(oldMetadata.questionMetadata ?? []),
      ];
  // prefer new value over old value
  const uniqCombined = _.sortBy(
    _.uniqBy(combined, (c) => c.type),
    (c) => c.type
  );
  return {
    questionMetadata: uniqCombined,
    questionMetadataSetId: newMetadata.questionMetadataSetId,
  };
}
