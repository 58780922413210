export const ADD_COMMENT_COPY = {
  headerTitle: 'Your comment',
  cancel: 'Cancel',
  submit: 'Add comment',
  placeholder: 'Add your comment',
  errorMessage: 'Please type your comment',
  viewComments: 'View Comments',
  youResponded: 'You responded',
  respond: 'Respond',
  respondBeforeCommenting: 'You must respond before adding a comment',
};
