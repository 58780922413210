import { UpVoteIcon } from 'client/shared/components/vote-icons';
import * as React from 'react';

export const AUTHN_CONTEXT = {
  ideaVote: {
    image: <UpVoteIcon className="text-jungle" height={104} width={104} />,
    description: 'Once you join Polco, you can interact with this idea.',
    title: 'Vote on ideas to express your opinion',
    secondaryButtonLabel: 'Account Setup',
  },
};
