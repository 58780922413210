import * as React from 'react';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { RegistrationEmailLoginForm } from 'client/respondent/shared/account/components/access/email-login';
import { RegistrationContainerProps } from 'client/respondent/shared/account/components/access';
import { generateEmailLoginAction } from 'client/respondent/shared/account/components/access/email-login-form';
import { RegistrationErrors, SocialLoginType } from 'client/respondent/core/types';
import {
  useRespondentAnalyticsViewEvent,
  useRespondentAnalyticsCallback,
  useSocialLogins,
} from 'client/respondent/hooks';
import { AnalyticsEventType } from 'client/respondent/core/analytics';
import { socialLoginHandler } from 'client/respondent/shared/account/containers/shared';

export const LoginContainer: React.FC<RegistrationContainerProps> = (props) => {
  const eventHandler = useRespondentAnalyticsCallback();

  useRespondentAnalyticsViewEvent(AnalyticsEventType.VIEWED_SIGN_IN);
  const logins = useSocialLogins();
  const muts = {
    emailLogin: useMutationInfo(MutationInfos.emailLogin),
    socialLogin: useMutationInfo(MutationInfos.socialLogin),
  };

  const [registrationErrors, setRegistrationErrors] =
    React.useState<RegistrationErrors>({
      [SocialLoginType.GOOGLE]: null,
      [SocialLoginType.FACEBOOK]: null,
    });

  return (
    <RegistrationEmailLoginForm
      events={{
        emailLogin: generateEmailLoginAction(muts.emailLogin, eventHandler),
        socialLogin: (provider) =>
          socialLoginHandler(
            provider,
            logins,
            muts.socialLogin,
            eventHandler,
            props.converted,
            setRegistrationErrors
          ),
        converted: props.converted,
      }}
      includeAdminRedirection={props.includeAdminRedirection}
      onClickSignupLink={props.onClickSignup}
      pendingConversion={
        muts.emailLogin.result.loading || muts.socialLogin.result.loading
      }
      registrationErrors={registrationErrors}
    />
  );
};
