import { CurrentRespondent } from 'client/respondent/hooks';

export function getRespondentUser(currentRespondent: CurrentRespondent) {
  if (CurrentRespondent.RESPONDENT.isA(currentRespondent)) {
    return currentRespondent.user;
  }

  if (CurrentRespondent.GUEST_RESPONDENT.isA(currentRespondent)) {
    return currentRespondent.user;
  }

  return null;
}
