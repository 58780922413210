export const WELCOME_BACK_MODAL_COPY = {
  imgAlt: 'welcome back',
  title: 'Welcome back!',
  description:
    'You are not logged in on this device, but we believe you have an account. Log in with your email or social media, or we can send you a magic link to automatically sign you in. The magic link will be sent to ',
  sendMagicLink: 'Send magic link',
  resend: 'Resend link',
  or: 'or',
  loginWithEmail: 'Log in with Email',
  linkSent:
    "The magic link is on its way. If you don't receive it within the next 10 minutes, please check your spam/junk folder.",
  back: 'More login options',
  emailLogin: {
    title: 'Login to Polco',
    forgotPassword: 'Forgot password?',
  },
};
