import * as React from 'react';
import './styles.scss';

import { PARSE_DATE_FORMAT, wrap } from 'core';
import {
  SurveyItemType,
  ClientQuestionId,
  QuestionType,
} from 'client/shared/core/question';
import { VotingProps } from 'client/respondent/core/types';
import { QuestionSetStatus, QuestionSetType } from 'client/shared/core/question-set';
import { useRedirect } from 'client/shared/hooks';
import { SurveyNav } from '../../components/survey-nav';
import { SurveyInfo } from '../../components/survey-info';
import { SurveyQuestion } from '../../components/survey-question';
import { SurveyHeader } from '../../components/survey-header';
import { LearnMorePrompt } from '../../components/learn-more-prompt';
import { SurveyQuestionResults } from '../../components/survey-question-results';
import moment from 'moment';
import { WellType, Well, AppLink } from 'client/shared/components/base';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { isSurveyItemVisible } from 'client/respondent/core/vote-validator';
import { LanguageSelectionDropdown } from 'client/shared/components/language-selection-dropdown';
import { WithDotsAction } from 'client/respondent/voting/shared/containers/dots-actions';
import { EmbedContext } from '../../../../../shared/contexts/embed-context';
import { SurveyVisualization } from '../../components/survey-visualization';
import { SurveySimulation } from '../../components/survey-simulation';
import { Helmet } from 'react-helmet';

export interface QuestionRefInfo {
  readonly questionId: ClientQuestionId;
  readonly questionNumber: number;
  readonly ref: HTMLDivElement | null;
}

const baseClass = 'pn-survey-response';

export interface SurveySharedProps extends VotingProps.CommonSurveyProps {
  readonly errorsByQuestionId: {
    readonly [questionId: string]: readonly VotingProps.VotingErrorType[];
  };
  readonly setShowOnboarding?: (onboarding: boolean) => void;
}

export const RESPONDENT_CONTENT_COPY = {
  [QuestionSetType.CONTENT_POST]: {
    preview:
      'This is a preview of your post. This is not visible publicly until it is published.',
  },
  [QuestionSetType.SURVEY]: {
    preview:
      'This is a preview of your survey. This is not yet visible to respondents.',
  },
  [QuestionSetType.SET]: {
    preview:
      'This is a preview of your poll. This is not yet visible to respondents.',
  },
  [QuestionSetType.POLCO_LIVE]: {
    preview:
      'This is a preview of your live event. This is not yet visible to respondents.',
  },
};

export const RESPONDENT_CONTENT_POST_COPY = {};

/**
 * Make each index take slightly longer than the previous.
 * It will never take longer than 1.1s to animate in a question
 *
 * 0 --> .5 + 0    --> 0.500s
 * 1 --> .5 + .25  --> 0.750s
 * 2 --> .5 + .333 --> 0.833s
 * 3 --> .5 + .375 --> 0.875s
 *
 * @param index
 */
function animationDuration(index: number) {
  return 0.5 + (index / (index + 1)) * 0.5;
}

const COPY = {
  viewOutcome: 'View outcome',
  here: 'here',
};

export const SurveySharedInner: React.FC<SurveySharedProps> = (props) => {
  const {
    surveyTitle,
    surveyItems,
    description,
    expireDate,
    voted,
    requiredToLogin,
    logoUrl,
    previousVotes,
    publisherSlug,
    publisherId,
    showingSidebars,
    questionSetId,
    questionSetSlug,
    setShowOnboarding,
    events,
    surveyInProcessVotes,
    closed,
    status,
    estCompletionTime,
    errorsByQuestionId,
    hasOutcome,
    alternateLanguages,
    randomizedSurveyItems,
    subscriptionType,
    publisher,
    isShareable,
    contentType,
  } = props;
  const redirect = useRedirect();
  const shareUrl = questionSetSlug
    ? ClientUrlUtils.respondent.set.url({
        setIdOrSlug: questionSetSlug,
        pubSlug: publisherSlug,
        setType: QuestionSetType.SET,
      })
    : null;
  let headerCount = 0;
  const isEmbedApp = React.useContext(EmbedContext);

  return (
    <div
      className={`${baseClass} d-flex flex-column h-100 text-break ${
        !showingSidebars && `${baseClass}-set-darker-bg-color`
      }`}
    >
      <Helmet>
        <title>
          {surveyTitle} - {publisher} - Polco
        </title>
      </Helmet>
      {!isEmbedApp && (
        <WithDotsAction
          isGuest={props.isGuest}
          publisherId={publisherId}
          publisherName={publisher}
          respondentId={props.respId}
          shareUrl={shareUrl}
          subscriptionType={subscriptionType}
        >
          {({ executePublisherAction, executeShareModalAction }) => (
            <SurveyNav
              closed={closed}
              contentType={contentType}
              events={{
                bookmark: props.events.bookmark,
                goToFeed: props.events.goToFeed,
                goBack: props.events.goBack,
                goToPublishingEntity: () =>
                  isEmbedApp
                    ? window.open(
                        ClientUrlUtils.respondent.pubProfile.url({
                          slug: publisherSlug,
                        })
                      )
                    : redirect(`/res/profile/${publisherSlug}`, {
                        push: true,
                      }),
                openShareModal: () => executeShareModalAction(true),
                publisherAction: executePublisherAction,
              }}
              logoUrl={logoUrl}
              publisher={publisher}
              shareable={isShareable}
              subscriptionType={subscriptionType}
              surveyTitle={surveyTitle}
              voted={voted}
            />
          )}
        </WithDotsAction>
      )}

      <div className={`${baseClass} d-flex-inline`}>
        <LanguageSelectionDropdown
          alternateLanguages={alternateLanguages}
          className={`${baseClass}-language-select mx-3 mb-3`}
        />
        <div className={`${baseClass}-slide-in bg-white`}>
          <SurveyInfo
            description={description ?? ''}
            expireDate={
              contentType !== QuestionSetType.CONTENT_POST ? expireDate : null
            }
            subheader={estCompletionTime}
            surveyTitle={surveyTitle}
          />
        </div>
        {requiredToLogin && setShowOnboarding && (
          <LearnMorePrompt
            login={() =>
              events.login(
                ClientUrlUtils.respondent.set.path({
                  pubSlug: publisherSlug,
                  setIdOrSlug: questionSetSlug ?? questionSetId,
                  setType: QuestionSetType.SURVEY,
                })
              )
            }
            setShowOnboarding={setShowOnboarding}
          />
        )}
        {closed && (
          <Well className="mx-3" type={WellType.INFO}>
            <>
              {wrap(() => {
                switch (contentType) {
                  case QuestionSetType.CONTENT_POST:
                    return RESPONDENT_CONTENT_COPY[contentType].preview;
                  default:
                    return props.expireDate
                      ? `This survey closed on ${moment(props.expireDate).format(
                          PARSE_DATE_FORMAT
                        )}`
                      : 'This survey has closed.';
                }
              })}
              {hasOutcome && (
                <div className="font-weight-bold">
                  <AppLink
                    to={ClientUrlUtils.respondent.setOutcome.path({
                      pubSlug: publisherSlug,
                      setIdOrSlug: questionSetSlug ?? questionSetId,
                    })}
                  >
                    {COPY.viewOutcome}
                  </AppLink>
                </div>
              )}
            </>
          </Well>
        )}
        {(status === QuestionSetStatus.NOT_STARTED ||
          status === QuestionSetStatus.SCHEDULED) && (
          <Well type={WellType.INFO}>
            {RESPONDENT_CONTENT_COPY[contentType].preview}
          </Well>
        )}
        {surveyItems.map((item, index) => {
          const passesConditionalCheck = isSurveyItemVisible(
            item.data.conditions,
            surveyInProcessVotes ?? {}
          );
          if (!passesConditionalCheck) {
            return null;
          }

          if (
            item.type === SurveyItemType.QUESTION &&
            item.data.typedData.type === QuestionType.GRID_CHOICE
          ) {
            const visibleGridRows = item.data.typedData.rows.filter((row) =>
              isSurveyItemVisible(row.conditions, surveyInProcessVotes ?? {})
            );
            if (visibleGridRows.length === 0) {
              return null;
            }
          }

          return wrap(() => {
            switch (item.type) {
              case SurveyItemType.HEADER:
                return (
                  <div
                    className={`${baseClass}-slide-in mb-2 bg-white`}
                    key={item.data.id}
                    style={{ animationDuration: `${animationDuration(index)}s` }}
                  >
                    <SurveyHeader header={item} headerCount={++headerCount} />
                  </div>
                );
              case SurveyItemType.VISUALIZATION:
                return (
                  <div
                    className={`${baseClass}-slide-in mb-2 bg-white`}
                    key={item.data.id}
                    style={{ animationDuration: `${animationDuration(index)}s` }}
                  >
                    <SurveyVisualization surveyItem={item} />
                  </div>
                );
              case SurveyItemType.SIMULATION:
                return (
                  <div
                    className={`${baseClass}-slide-in mb-2 bg-white`}
                    key={item.data.id}
                    style={{ animationDuration: `${animationDuration(index)}s` }}
                  >
                    <SurveySimulation itemData={item.data} />
                  </div>
                );

              case SurveyItemType.QUESTION:
                return (
                  <div
                    className={`${baseClass}-slide-in mb-2 bg-white`}
                    key={item.data.id}
                    style={{ animationDuration: `${animationDuration(index)}s` }}
                  >
                    {closed ? (
                      <SurveyQuestionResults
                        events={events}
                        previousVote={
                          previousVotes?.find(
                            (v) => v.questionId === item.data.id
                          ) ?? null
                        }
                        question={item}
                        questionCount={item.questionNumber}
                        voted={voted}
                      />
                    ) : (
                      <SurveyQuestion
                        events={events}
                        hasInputError={!!errorsByQuestionId[item.data.id]}
                        inProcessVotes={surveyInProcessVotes ?? {}}
                        previousVote={
                          previousVotes?.find(
                            (v) => v.questionId === item.data.id
                          ) ?? null
                        }
                        question={item}
                        questionCount={item.questionNumber}
                        randomizedSurveyItems={randomizedSurveyItems}
                        showingSidebars={showingSidebars}
                        voted={voted}
                      />
                    )}
                  </div>
                );
            }
          });
        })}
      </div>
      <div className="flex-grow-1"></div>
    </div>
  );
};
SurveySharedInner.displayName = 'SurveySharedInner';
