import * as React from 'react';
import './styles.scss';

import {
  ClientQuestionId,
  SavedSurveyItem,
  SurveyItemType,
  scrollIdForSurveyItem,
} from 'client/shared/core/question';
import { ERROR_NAVIGATOR_COPY } from './copy';
import { Btn, ButtonTypes } from 'client/shared/components/base';
import { VotingProps } from 'client/respondent/core/types';
import { wrap } from 'core';
const baseClass = 'pn-error-navigator';
const MAX_QUESTION_ERRORS_TO_SHOW = 3;

interface ErrorData {
  readonly surveyItemIndex: number;
  readonly surveyItem: Extract<
    SavedSurveyItem,
    { readonly type: SurveyItemType.QUESTION }
  >;
  readonly errors: readonly VotingProps.VotingErrorType[];
}

interface ErrorStartProps {
  readonly handleChangePhase: (e: ErrorPhase) => void;
  readonly orderedErrors: readonly ErrorData[];
}
interface CheckMyAnswerProps {
  readonly goToCurrentQuestion: () => void;
  readonly goToNextQuestion: () => void;
  readonly orderedErrors: readonly ErrorData[];
  readonly currentError: ErrorData;
}

export interface SurveyErrorNavigatorProps {
  readonly orderedErrors: readonly ErrorData[];
  readonly handleSubmit: () => Promise<void>;
}

export interface AllResolvedProps {
  readonly finish: () => Promise<void>;
}

export interface QuestionPositionInfo {
  readonly questionId: ClientQuestionId;
  readonly questionNumber: number;
  readonly position: number;
  readonly errors: VotingProps.VotingError | null;
}

enum ErrorPhase {
  START = 'START',
  PROGRESS = 'PROGRESS',
  FIXED = 'FIXED',
}

export const SurveyErrorNavigator: React.FC<SurveyErrorNavigatorProps> = (p) => {
  const { orderedErrors } = p;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  const [errorPhase, setErrorPhase] = React.useState<ErrorPhase>(ErrorPhase.START);

  // used for tracking current error at Phase 2. Cannot just track orderedErrors index, because
  // index changes when items are fixed.
  const [currentError, setCurrentError] = React.useState<ErrorData | undefined>(
    orderedErrors[0]
  );

  React.useEffect(() => {
    if (
      (orderedErrors.length === 0 || !currentError) &&
      errorPhase !== ErrorPhase.FIXED
    ) {
      setErrorPhase(ErrorPhase.FIXED);
    } else if (orderedErrors.length !== 0 && errorPhase === ErrorPhase.FIXED) {
      setErrorPhase(ErrorPhase.START);
    }

    // If the current error is no longer an error, set the current error to the "next" error
    if (
      !orderedErrors.find(
        (error) => error.surveyItemIndex === currentError?.surveyItemIndex
      )
    ) {
      const nextError =
        orderedErrors.find(
          (error) =>
            currentError && error.surveyItemIndex > currentError.surveyItemIndex
        ) ?? orderedErrors[0];
      setCurrentError(nextError);
    }
  }, [orderedErrors, errorPhase, currentError]);

  const calcPos = (elementId: string) => {
    const elementForId = document.getElementById(elementId);
    const rect = elementForId?.getBoundingClientRect().top ?? 0;
    return rect + scrollTop;
  };

  const handleChangePhase = (ep: ErrorPhase) => {
    if (ep === ErrorPhase.PROGRESS) {
      setCurrentError(orderedErrors[0]);
      scrollToPosition(
        calcPos(scrollIdForSurveyItem(orderedErrors[0].surveyItem.data.id))
      );
    }
    setErrorPhase(ep);
  };

  const handleGoToCurrentQuestion = () => {
    if (!currentError) {
      return; // nothing we can do if they want to go the current error but there is not one
    }
    scrollToPosition(
      calcPos(scrollIdForSurveyItem(currentError.surveyItem.data.id))
    );
  };

  const handleGoToNextQuestion = () => {
    const nextError =
      orderedErrors.find(
        (error) =>
          currentError && error.surveyItemIndex > currentError.surveyItemIndex
      ) ?? orderedErrors[0];
    setCurrentError(nextError);
    scrollToPosition(calcPos(scrollIdForSurveyItem(nextError.surveyItem.data.id)));
  };

  return (
    <div aria-live="assertive" className={`${baseClass}`}>
      {wrap(() => {
        switch (errorPhase) {
          case ErrorPhase.START:
            return (
              <ErrorStart
                handleChangePhase={handleChangePhase}
                orderedErrors={orderedErrors}
              />
            );
          case ErrorPhase.PROGRESS:
            return currentError ? (
              <CheckMyAnswer
                currentError={currentError}
                goToCurrentQuestion={handleGoToCurrentQuestion}
                goToNextQuestion={handleGoToNextQuestion}
                orderedErrors={orderedErrors}
              />
            ) : null;
          case ErrorPhase.FIXED:
            return <AllResolved finish={p.handleSubmit} />;
        }
      })}
    </div>
  );
};
SurveyErrorNavigator.displayName = 'SurveyErrorNavigator';

// Phase 1: summary of all errors. It shows maximum MAX_QUESTION_TO_SHOW questions.
const ErrorStart: React.FC<ErrorStartProps> = (p) => {
  const { handleChangePhase, orderedErrors } = p;

  // could have multiple errors in one question, but for now, we have one error in one question
  const questionNumbersWithErrorMsg = orderedErrors
    .slice(0, MAX_QUESTION_ERRORS_TO_SHOW)
    .map((error) => ({
      number: error.surveyItem.questionNumber,
      title: error.surveyItem.data.title,
      message: error.errors[0],
    }));
  const errorCount = orderedErrors.length;
  const firstQuestionNumber = questionNumbersWithErrorMsg[0]?.number || '';

  return (
    <div className={`${baseClass}-start p-2`}>
      <div className={'font-weight-bold text-center m-1'}>
        {`${errorCount} ${ERROR_NAVIGATOR_COPY.attention(errorCount === 1)}`}
      </div>

      <div className="d-flex flex-column px-2 m-2">
        <div className="text-left ">
          {questionNumbersWithErrorMsg.map((e) => {
            if (e.message) {
              return (
                <div className="d-flex flex-row" key={e.title}>
                  <div className={`${baseClass}-question-title font-size-sm`}>
                    {`Question ${e.number}: ${e.title}`}
                  </div>
                </div>
              );
            }
          })}
          {errorCount > MAX_QUESTION_ERRORS_TO_SHOW && (
            <div className="font-size-sm">{ERROR_NAVIGATOR_COPY.thereIsMore}</div>
          )}
        </div>
      </div>

      <div className={'d-flex justify-content-center align-items-center m-2'}>
        <Btn
          action={() => {
            handleChangePhase(ErrorPhase.PROGRESS);
          }}
          className={'text-center py-1 w-75'}
          type={ButtonTypes.PRIMARY}
        >
          {`Go to question ${firstQuestionNumber}`}
        </Btn>
      </div>
    </div>
  );
};

// Phase 2: it shows an error for one question and allows a user to navigate each question.
const CheckMyAnswer: React.FC<CheckMyAnswerProps> = (p) => {
  const { currentError, orderedErrors, goToNextQuestion, goToCurrentQuestion } = p;
  const questionNumber = currentError.surveyItem.questionNumber;
  const questionTitle = currentError.surveyItem.data.title;
  const nextError =
    orderedErrors.find(
      (error) => error.surveyItem.questionNumber > questionNumber
    ) ?? orderedErrors[0];
  const nextQuestionNumber = nextError?.surveyItem?.questionNumber ?? '';

  return (
    <div className={`${baseClass}-start p-2`}>
      <div className="d-flex flex-row text-left m-3 mb-1">
        <div className={`${baseClass}-current-error-text px-1 font-size-sm`}>
          {`Question ${questionNumber}: ${questionTitle}`}
        </div>
      </div>

      <div className="m-3">
        {
          <div className="font-size-sm font-weight-bold">{`${errorEnumToErrorMessage(
            currentError.errors[0]
          )}`}</div>
        }
      </div>

      <div className={'d-flex justify-content-center align-items-center m-2'}>
        <Btn
          action={() =>
            orderedErrors.length === 1 ? goToCurrentQuestion() : goToNextQuestion()
          }
          className={'text-center w-75 py-1'}
          type={ButtonTypes.PRIMARY}
        >
          {orderedErrors.length === 1
            ? ERROR_NAVIGATOR_COPY.viewQuestion
            : `Go to question ${nextQuestionNumber}`}
        </Btn>
      </div>
    </div>
  );
};

const AllResolved: React.FC<AllResolvedProps> = (p) => {
  return (
    <div
      className={`${baseClass}-resolved d-flex flex-row align-items-center justify-content-center`}
    >
      <div className={'text-center p-3'}>{ERROR_NAVIGATOR_COPY.resolved}</div>
      <div className="mr-3">
        <Btn action={() => p.finish()} type={ButtonTypes.PRIMARY}>
          {ERROR_NAVIGATOR_COPY.submitSurvey}
        </Btn>
      </div>
    </div>
  );
};

export function errorEnumToErrorMessage(e: VotingProps.VotingErrorType): string {
  switch (e) {
    case VotingProps.VotingErrorType.NO_INPUT:
      return 'Unanswered';
    case VotingProps.VotingErrorType.MC_OVER_MAX_SELECTION:
      return 'Too many choices selected';
    case VotingProps.VotingErrorType.PA_POINT_NOT_TEN:
      return 'Points not allocated correctly';
    case VotingProps.VotingErrorType.GRID_MISSING_ROW:
      return 'No choice selected. Please select a choice before submitting';
  }
}

const HEADER_HEIGHT_PLUS_PADDING = 100; // px
function scrollToPosition(position: number) {
  window.scrollTo(0, position - HEADER_HEIGHT_PLUS_PADDING);
}
