import { useRedirect } from 'client/shared/hooks';
import { useQueryInfo } from 'client/shared/containers/query';
import { QueryInfos } from 'client/shared/graphql-queries';
import { CurrentRespondent as GqlCurrentRespondent } from 'client/shared/graphql-client/graphql-operations.g';
import { ApiDate, Case, Require, unionOfEnum } from 'core';
import { UserLocation } from '../core/types';
import { heapIdentify, heapAddUserProperties } from 'client/shared/integrations';
import { ApolloError } from '@apollo/client';

export enum CurrentRespondentState {
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  RESPONDENT = 'RESPONDENT',
  GUEST_RESPONDENT = 'GUEST_RESPONDENT',
  NO_DATA = 'NO_DATA',
}

export type GqlUserWithRespondent = Omit<
  GqlCurrentRespondent,
  'user' | 'location'
> & {
  readonly user: Require<
    NonNullable<GqlCurrentRespondent['currentUser']['user']>,
    'respondent'
  >;
  readonly location: UserLocation | null;
};

export interface CurrentRespondentData {
  readonly id: string;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly avatarUrl: string | null;
  readonly zipCode: string | null;
  readonly inputtedLat: number | null;
  readonly inputtedLon: number | null;
  readonly requestLocationLastShown: ApiDate | null;
  readonly guest: boolean;
  readonly verified: boolean;
  readonly invitedPlaceholderAccount: boolean;
  readonly requiresVerificationPrompt: boolean;
  readonly postAnonymously: boolean;
  readonly email: string | null;
}

export interface CurrentAdminData {
  readonly id: string;
  readonly isEnvisioUser: boolean;
}

export interface CurrentUser {
  readonly id: string;
  readonly user: {
    readonly id: string;
    readonly respondent: CurrentRespondentData | null;
    readonly admin: CurrentAdminData | null;
  } | null;
  readonly location: UserLocation | null;
  readonly subscriptionSlugs: readonly string[] | null;
}

type CurrentRespondent_Limited =
  | Case<CurrentRespondentState.LOADING>
  | Case<CurrentRespondentState.RESPONDENT, CurrentUser>
  | Case<CurrentRespondentState.GUEST_RESPONDENT, CurrentUser>;

export type CurrentRespondent =
  | CurrentRespondent_Limited
  | Case<CurrentRespondentState.ERROR, ApolloError | { readonly message: string }>
  | Case<CurrentRespondentState.NO_DATA>;

export const CurrentRespondent = unionOfEnum(CurrentRespondentState, {
  ...CurrentRespondentState,
}).andType<CurrentRespondent>();

export function useCurrentRespondent(): CurrentRespondent;
export function useCurrentRespondent(
  redirectOnLoggedOut: string
): CurrentRespondent_Limited;
export function useCurrentRespondent(
  redirectOnLoggedOut?: string | undefined
): CurrentRespondent {
  const redir = useRedirect();
  const result = useQueryInfo(QueryInfos.currentRespondent, {});
  const u = result.data?.currentUser;
  const user = u?.user;
  const resp = user?.respondent;
  const admin = user?.admin;
  const subscriptionSlugs =
    resp?.__typename === 'PrivateRespondent'
      ? resp.subscriptions.map((sub) => sub.slug)
      : null;

  if (
    result.error ||
    (result.data?.currentUser && !result.data.currentUser.user?.respondent)
  ) {
    const redirRoute = redirectOnLoggedOut;
    if (redirRoute) {
      redir(redirRoute ?? 'NEVER', { push: true });
    }
  }
  if (result.error) {
    return CurrentRespondent.ERROR(result.error);
  }
  if (result.loading) {
    return CurrentRespondent.LOADING();
  }

  if (!u) {
    return CurrentRespondent.NO_DATA();
  }

  const location = u?.location
    ? {
        zip: u.location.zipCode,
        latLng: u.location.coordinate
          ? { lat: u.location.coordinate.lat, lng: u.location.coordinate.lng }
          : null,
      }
    : null;

  if (resp && resp?.__typename !== 'PrivateRespondent') {
    // This would be a VERY unexpected case. It means that the respondent returned by
    // the CurrentUser query was a 'PublicRespondent'. That should only happen if
    // the requester is NOT the owner of the respondent. But because we are loading
    // "currentRespondent" we should always have access to the respondent.
    return CurrentRespondent.ERROR({
      message: 'No access to current users respondent',
    });
  }
  const guest = resp?.guest ?? true;

  if (user) {
    heapIdentify(user.id);
    heapAddUserProperties({
      email: resp?.email,
      firstName: resp?.firstName,
      lastName: resp?.lastName,
      zipCode: resp?.zipCode,
      guest,
      respId: resp?.id,
    });
  }

  if (user && resp && guest) {
    return CurrentRespondent.GUEST_RESPONDENT({
      ...u,
      location,
      user: {
        ...user,
        respondent: {
          ...resp,
        },
        admin: null,
      },
      subscriptionSlugs: subscriptionSlugs,
    });
  }

  return CurrentRespondent.RESPONDENT({
    ...u,
    location,
    user: user
      ? {
          ...user,
          respondent: resp ?? null,
          admin: admin ?? null,
        }
      : null,
    subscriptionSlugs: subscriptionSlugs,
  });
}
