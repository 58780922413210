import { words } from 'lodash';
import { stopwordsMap } from './stopwords';

export interface WordCount {
  readonly text: string;
  readonly value: number;
}

interface WordCountMap {
  readonly [k: string]: number;
}

interface Options {
  readonly minOcurrences?: number;
  readonly discardNumbers?: boolean;
}

export function getWordCounts(
  text: string | readonly string[],
  opts: Options = {}
): readonly WordCount[] {
  const textArr = Array.isArray(text) ? text : [text];

  const wordMap: WordCountMap = textArr.reduce((wmap, current) => {
    words(current)
      .filter((w) => w.length > 3)
      .map((w) => w.toLowerCase())
      .filter((w) => !stopwordsMap[w])
      .forEach((word: string) => {
        wmap[word] = (wmap[word] || 0) + 1;
      });

    return wmap;
  }, {} as WordCountMap);

  const wordsArr = Object.entries(wordMap).map(([key, value]) => ({
    text: key,
    value,
  }));

  return wordsArr
    .filter((wc) => wc.value >= (opts.minOcurrences || 1))
    .filter((wc) => (opts.discardNumbers ? !wc.text.match(/^\d+$/) : true));
}
