import { serverOAuthLogin } from '../server-social-login';
import { fbLogin as sharedFbLogin } from 'client/shared/integrations/facebook';
import {
  SocialLoginData,
  SocialLoginType,
  SocialLoginMode,
} from 'client/respondent/core/types';

const scope = [
  'email',
  /* some scopes we may want in the future; these were a bit of a pain to find */
  // 'user_gender',
  // 'user_birthday',
  // 'user_location'
].join(',');

export function fbLogin(
  _mode: SocialLoginMode,
  onSuccess: (data: SocialLoginData) => void,
  onFailure?: (r: fb.AuthResponse | string) => void
) {
  sharedFbLogin(
    false, // TODO use mode, but for right now we don't support server social login
    (token) =>
      onSuccess({
        type: SocialLoginType.FACEBOOK,
        token,
      }),
    onFailure,
    { scope, return_scopes: true },
    () => {
      serverOAuthLogin('facebook');
    }
  );
}
