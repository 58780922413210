import * as React from 'react';
import { VotingProps } from 'client/respondent/core/types';
import { Modal } from 'client/shared/components/modal';
import { Btn, ButtonTypes, HtmlContent } from 'client/shared/components/base';
import { MORE_INFO_COPY } from './copy';
import './styles.scss';
import { LoadedEvents } from 'client/shared/core/types';

export interface MoreInfoProps {
  readonly details: VotingProps.QuestionSetLoaded['details'];
  readonly isOpen: boolean;
  readonly cancel: LoadedEvents['cancelInteraction'];
}

export const MoreInfo: React.FC<MoreInfoProps> = (props) => {
  return (
    <Modal
      header={{
        title: MORE_INFO_COPY.moreInfo,
        className: 'font-weight-bold mt-1 ml-1',
        small: true,
      }}
      isCloseable
      isOpen={props.isOpen}
      onRequestClose={props.cancel}
    >
      <div className="ml-1 mr-1 text-break">
        {props.details.summary && (
          <div className="mb-4">
            <div className="pn-voting-more-info-header font-size-sm font-weight-bold mb-2">
              {MORE_INFO_COPY.summary}
            </div>
            <HtmlContent
              html={props.details.summary ?? ''}
              imageAlt={MORE_INFO_COPY.moreInfo}
            />
          </div>
        )}
        {props.details.description && (
          <div className="mb-4">
            <div className="pn-voting-more-info-header font-size-sm font-weight-bold mb-2">
              {MORE_INFO_COPY.description}
            </div>
            <div className="pn-voting-more-info-summary font-size-sm">
              <HtmlContent
                html={props.details.description ?? ''}
                imageAlt={MORE_INFO_COPY.moreInfo}
              />
            </div>
          </div>
        )}
        <div className="d-flex justify-content-end">
          <Btn
            action={props.cancel}
            className="py-1 px-4 font-size-sm"
            type={ButtonTypes.PRIMARY}
          >
            {MORE_INFO_COPY.button}
          </Btn>
        </div>
      </div>
    </Modal>
  );
};
