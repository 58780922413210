import { ErrorNotFound } from 'client/respondent/shared/components/error-not-found';
import * as React from 'react';
import { MainPage } from '../main-page';

export const NotFound: React.FC = () => {
  return (
    <MainPage>
      {() => {
        return <ErrorNotFound />;
      }}
    </MainPage>
  );
};
NotFound.displayName = 'NotFound';
