import * as React from 'react';
import { MaterialIcon, MaterialIconName } from '../base';

export enum IconType {
  MATERIAL = 'material',
  SVG = 'svg',
}

interface Props {
  readonly type: IconType;
  readonly name: string | MaterialIconName;
  readonly outline?: boolean;
  readonly className?: string;
  readonly materialStyles?: {
    readonly color?: string;
    readonly fontSize?: string;
  };
}

export const ListIcon: React.FC<Props> = (p) => {
  switch (p.type) {
    case IconType.MATERIAL:
      return (
        <MaterialIcon
          className={p.className}
          icon={p.name as MaterialIconName}
          iconType={p.outline ? 'material-icons-outlined' : undefined}
          style={{
            ...p.materialStyles,
          }}
        />
      );
    case IconType.SVG:
      return <img alt={`${p.name} icon`} src={p.name} />;
  }
};
