import * as React from 'react';
import {
  LoginType,
  EmailLoginType,
  SocialLoginType,
} from 'client/respondent/core/types';
import { useSocialLogins } from 'client/respondent/hooks';
import { SocialButton } from 'client/shared/components/social-button';
import { MaterialIconName } from 'client/shared/components/base';
import facebookImage from '../assets/login_facebook.svg';
import googleImage from '../assets/login_google.svg';

interface LoginTypeSpecificInfo {
  readonly imgSrc?: string;
  readonly imgAlt?: string;
  readonly isProviderInitialized: boolean;
}
interface Props {
  readonly loginType: LoginType;
  readonly action: () => void | Promise<any>;
  readonly cta: string;
  readonly disabled?: boolean;
  readonly tabIndex?: 0 | -1;
}

export const SocialSignupButton: React.FC<Props> = (p) => {
  const logins = useSocialLogins();
  const loginTypeSpecificInfo: Record<LoginType, LoginTypeSpecificInfo> = {
    [SocialLoginType.FACEBOOK]: {
      imgSrc: facebookImage,
      imgAlt: 'Facebook logo',
      isProviderInitialized: logins.FACEBOOK.isProviderInitialized,
    },
    [SocialLoginType.GOOGLE]: {
      imgSrc: googleImage,
      imgAlt: 'Google logo',
      isProviderInitialized: logins.GOOGLE.isProviderInitialized,
    },
    [EmailLoginType.EMAIL]: {
      isProviderInitialized: true,
    },
  };

  const { imgSrc, imgAlt, isProviderInitialized } =
    loginTypeSpecificInfo[p.loginType];

  const isEmail = p.loginType === EmailLoginType.EMAIL;

  const actionEnabled = isProviderInitialized && !p.disabled;

  return (
    <SocialButton
      action={p.action}
      cta={p.cta}
      disabled={!actionEnabled}
      image={
        !isEmail && imgAlt && imgSrc
          ? {
              src: imgSrc,
              alt: imgAlt,
            }
          : undefined
      }
      materialIcon={isEmail ? MaterialIconName.EMAIL : undefined}
      tabIndex={p.tabIndex}
    />
  );
};
