import * as React from 'react';

import './styles.scss';
import { ClickableDiv } from 'client/shared/components/base';
import { handlePressEnter } from 'client/shared/core/helpers';

export interface Props {
  readonly className?: string;
  readonly side: 'right' | 'left';
  readonly open: boolean;
  readonly clickedOutside: () => void;
}

const baseClass = 'pn-content-drawer';

export const ContentDrawer: React.FC<Props> = (p) => {
  const overlay = (
    <ClickableDiv
      action={p.clickedOutside}
      className={`${baseClass}-overlay flex-grow-1`}
    />
  );

  const drawer = (
    <div
      aria-label="close content drawer"
      className={`${baseClass}-content d-flex`}
      id="content-drawer-menu"
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={handlePressEnter((e) => e.stopPropagation())}
      role="button"
      tabIndex={0}
    >
      <div className={`${baseClass}-content-children`}>{p.children}</div>
    </div>
  );

  return (
    <div
      className={`
      ${baseClass}
      position-fixed
      d-flex flex-row
      ${p.className ? p.className : ''}
      ${p.open ? 'mod-open' : 'mod-closed'}
      ${p.side === 'right' ? 'mod-right' : 'mod-left'}
    `}
    >
      {p.side === 'right' && overlay}
      {drawer}
      {p.side === 'left' && overlay}
    </div>
  );
};

ContentDrawer.displayName = 'ContentDrawer';
