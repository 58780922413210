import * as React from 'react';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import {
  QuestionType,
  SavedSurveyItem,
  SurveyItemType,
} from 'client/shared/core/question';
import { VotingProps, NotLoggedInActions } from 'client/respondent/core/types';
import { compact } from 'lodash';
import { useRespondentDispatch } from 'client/respondent/core/reducers/context';
import { processPreLoginAction } from 'client/respondent/shared/functions';
import { VotingSuccessModal } from '../../../shared/components/voting-success-modal';
import { _dangerousUseEffectOnMount } from 'client/shared/helpers/hooks';
import { SurveyErrorNavigator } from '../../components/survey-error-navigator';
import { SurveySharedInner } from '../survey-shared';
import {
  SubscriptionType,
  QuestionSetStatus,
} from 'client/shared/graphql-client/graphql-operations.g';
import { RespondentActions } from 'client/respondent/core/reducers/actions';
import { Tooltip } from 'client/shared/components/tooltip';
import { VerificationModal } from 'client/respondent/shared/account/containers/verification-modal';
import {
  isSurveyItemVisible,
  validateVotes,
} from 'client/respondent/core/vote-validator';
import { Language, QuestionSetAttributeTypes, QuestionSetType, wrap } from 'core';
import { ThankYouModal } from 'client/respondent/voting/shared/components/thank-you-modal';
import { ConfirmationModal } from 'client/shared/components/modal/confirmation-modal';
import { hasVote } from 'client/respondent/core';
import { AuthnFlowSliderModal } from 'client/respondent/shared/account/containers/authn-flow-slider-modal';
import polcoLogo from 'client/assets/polco-logo.svg';
import PolcoManIdea from 'client/respondent/shared/account/containers/sliders/assets/polcoman-idea.svg';
import { EmbedContext } from 'client/shared/contexts/embed-context';

export interface SurveyResponseProps {
  readonly surveyTitle: string;
  readonly description: string;
  readonly expireDate: Date | null;
  readonly surveyItems: readonly SavedSurveyItem[];
  readonly alternateLanguages: readonly Omit<Language, 'id'>[];
}

const baseClass = 'pn-survey-response';

export const SURVEY_LOADED_COPY = {
  submit: 'Submit',
  returnToFeed: 'Return Home',
  notActive: 'This survey is not active.',
  submittedInfo: 'Thank you! Your response has been submitted.',
  confirmationModal: {
    prompt: 'Are you sure?',
    body: 'There are unanswered questions remaining in this survey. You can continue working or submit your responses now.',
    confirm: 'Submit Survey',
    cancel: 'Continue Working',
  },
  guestModalVoting: {
    title: 'We’ve recorded your response!',
    description: (publisher: string) =>
      `If you’d like to stay connected and see the outcome of this Survey, please consider signing up for Polco and adding your voice to the growing resident community of ${publisher}.`,
  },
  submitVoting: {
    title: 'We’re almost ready to submit!',
    description:
      'The poster of this Survey requires you to have an account in order to respond. Once you join Polco, your response will be submitted.',
  },
  learnMore: {
    title: 'Please create an account to submit your response',
    description: (publisher: string) =>
      `${publisher} is only accepting responses from users with a Polco account.
      When you sign up, we make sure no one else responds on your behalf and we personalize your experience.
      Questions on Polco are posted by officials in YOUR local government or community who want your input.`,
    prompt: {
      description: `Polco improves how community leaders seek input on important topics and how their members provide it.
        As a result, the civic process becomes a lively exchange of ideas, restoring civility and quality to policy-making discourse.`,
      description2: `Polco's civic engagement platform makes meaningful communication between community leaders and the people they serve not only possible, but enjoyable.`,
      button: 'Continue',
      title: 'What is Polco?',
    },
  },
};

export const CONTENT_POST_LOADED_COPY = {
  submit: 'Submit',
  returnToFeed: 'Return Home',
  notActive: 'This post is not active.',
  submittedInfo: 'Thank you! Your response has been submitted.',
  confirmationModal: {
    prompt: 'Are you sure?',
    body: 'There are unanswered questions remaining in this post. You can continue working or submit your responses now.',
    confirm: 'Submit Post Responses',
    cancel: 'Continue Working',
  },
  guestModalVoting: {
    title: 'We’ve recorded your response!',
    description: (publisher: string) =>
      `If you’d like to stay connected and see the outcome of this Post, please consider signing up for Polco and adding your voice to the growing resident community of ${publisher}.`,
  },
  submitVoting: {
    title: 'We’re almost ready to submit!',
    description:
      'The poster of this post requires you to have an account in order to respond. Once you join Polco, your response will be submitted.',
  },
  learnMore: {
    title: 'Please create an account to submit your response',
    description: (publisher: string) =>
      `${publisher} is only accepting responses from users with a Polco account.
      When you sign up, we make sure no one else responds on your behalf and we personalize your experience.
      Questions on Polco are posted by officials in YOUR local government or community who want your input.`,
    prompt: {
      description: `Polco improves how community leaders seek input on important topics and how their members provide it.
        As a result, the civic process becomes a lively exchange of ideas, restoring civility and quality to policy-making discourse.`,
      description2: `Polco's civic engagement platform makes meaningful communication between community leaders and the people they serve not only possible, but enjoyable.`,
      button: 'Continue',
      title: 'What is Polco?',
    },
  },
};

export const SurveyLoaded: React.FC<VotingProps.SurveyLoaded> = (props) => {
  const {
    surveyInProcessVotes,
    voted,
    requiredToLogin,
    showingSidebars,
    subscriptionType,
    surveyItems,
    isGuest,
    respId,
    status,
    showVerification,
    disabledSubmit,
    showConversionPrompts,
    repostData,
    contentType,
    questionSetId,
  } = props;
  const embedApp = React.useContext(EmbedContext);

  const dispatch = useRespondentDispatch();

  const COPY = wrap(() => {
    switch (contentType) {
      case QuestionSetType.CONTENT_POST:
        return CONTENT_POST_LOADED_COPY;
      default:
        return SURVEY_LOADED_COPY;
    }
  });

  // If this is a repost, set QMA data so that we can capture
  // what "pub context" this vote came from
  const reposterId = repostData?.reposterId;
  React.useEffect(() => {
    if (reposterId) {
      dispatch(
        RespondentActions.setQuestionMetadataItem(questionSetId, {
          type: QuestionSetAttributeTypes.REPOST_VOTE_PUBLISHER_CONTEXT,
          value: reposterId,
        })
      );
    } else {
      dispatch(
        RespondentActions.clearQuestionMetadataItem(
          QuestionSetAttributeTypes.REPOST_VOTE_PUBLISHER_CONTEXT
        )
      );
    }
  }, [questionSetId, reposterId, dispatch]);

  // create reference wrapper around latest respId so that event closures
  // get the latest value. This is important for "long-lived" events that
  // want to receive changes to the component props/state that occur
  // "during" the event
  // Note: in a traditional "class-based" component this is handled by
  // having the event refer to `this.props.respId`, in which case `this`
  // is acting as the 'ref'.
  const respRef = React.useRef(respId);
  React.useEffect(() => {
    respRef.current = respId;
  }, [respRef, respId]);

  const [savedSubscriptionType] = React.useState(subscriptionType);
  const [openVotingSuccessModal, setOpenVotingSuccessModal] = React.useState(false);
  const [disabledSubmitButtonOnVoteSuccess, setDisabledSubmitButtonOnVoteSuccess] =
    React.useState(false);

  // check invalid questions at submit time.
  // We track this because we dont want to show errors until
  // after they try to submit
  const [hasVotingErrorAtSubmit, setHasVotingErrorAtSubmit] = React.useState(false);

  const errorsByQuestionId = validateVotes(surveyItems, surveyInProcessVotes ?? {});

  const checkForErrors = () => {
    if (Object.values(errorsByQuestionId).length > 0) {
      setHasVotingErrorAtSubmit(true);
      return true;
    }

    setHasVotingErrorAtSubmit(false);
    return false;
  };

  const surveyQuestions = surveyItems.filter(
    (item) => item.type === SurveyItemType.QUESTION
  );

  const hasUnansweredQuestions = React.useMemo(
    () =>
      surveyItems.filter(
        (item) =>
          item.type === SurveyItemType.QUESTION &&
          isSurveyItemVisible(item.data.conditions, surveyInProcessVotes ?? {}) &&
          !hasVote(surveyInProcessVotes?.[item.data.id] ?? null)
      ).length > 0,
    [surveyItems, surveyInProcessVotes]
  );

  const [showCreateAccountModalAtVoting, setShowCreateAccountModalAtVoting] =
    React.useState(false);

  const [
    showCreateAccountModalAtVotingForGuest,
    setShowCreateAccountModalAtVotingForGuest,
  ] = React.useState(false);
  const [showOnboarding, setShowOnboarding] = React.useState<boolean>(false);
  const [showVerifyModal, setShowVerifyModal] = React.useState<boolean>(false);
  const [showThankYouModal, setShowThankYouModal] = React.useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState<boolean>(false);
  const [showSubmittedInfo, setShowSubmittedInfo] = React.useState<boolean>(voted);

  React.useEffect(() => {
    setShowSubmittedInfo(voted);
  }, [voted]);

  const voteForSurvey = async () => {
    // If this close referred to `respId` directly, it would close over the value
    // at the time this handler is created.
    // Then this function will (eventually) cause a re-render where respId
    // is set on this component.

    const voteResult = await props.events.submitVotes();
    // However, if we referred to `respId` at this point, essentially
    // we would get the value prior to this event started. So if it was null
    // previously but `submitVotes()` changed the value for this component,
    // we can't see it here.
    // Therefore we create a ref in this component whose value will stay consistent
    // across renders. Thus we can close over it but still receive updates
    // when respId changes.
    await props.events.followPublisher(respRef.current);

    if (voteResult) {
      props.events.surveySubmitClearVote(props.questionSetId);

      const choicesIds = props.surveyItems
        .filter(
          ({ type, data }) =>
            type === SurveyItemType.QUESTION &&
            (data.typedData.type === QuestionType.MULTIPLE_CHOICE ||
              data.typedData.type === QuestionType.GRID_CHOICE) &&
            data.typedData.randomizeChoices
        )
        .map(({ data: { id } }) => id);

      if (choicesIds.length) {
        props.events.clearRandomizedSurveyItems(choicesIds);
      }

      if (props.allowMultipleResponses && isGuest) {
        setShowThankYouModal(true);
        await props.events.logout();
      } else if (!showConversionPrompts) {
        setOpenVotingSuccessModal(true);
      } else if (isGuest) {
        setShowCreateAccountModalAtVotingForGuest(true);
      } else if (showVerification) {
        setShowVerifyModal(true);
      } else {
        setOpenVotingSuccessModal(true);
      }
    }
  };
  const handleSubmit = async (
    skipUnansweredQuestionsValidation: boolean = false
  ) => {
    if (checkForErrors()) {
      return;
    }
    if (!skipUnansweredQuestionsValidation && hasUnansweredQuestions) {
      setShowConfirmationModal(true);
      return;
    }
    if (requiredToLogin) {
      setShowCreateAccountModalAtVoting(true);
    } else {
      await voteForSurvey();
    }
  };

  _dangerousUseEffectOnMount(() => {
    processPreLoginAction(
      props.preLoginAction,
      NotLoggedInActions.SURVEY_VOTE,
      dispatch,
      (act) => {
        if (act.data.setId !== props.id) {
          return null;
        }
        return handleSubmit();
      }
    );
  });

  React.useEffect(() => {
    if (!props.voted) {
      props.events.startSurveyInProcessVote(props.questionSetId, props.surveyItems);
    }
  }, [props.questionSetId]); // eslint-disable-line react-hooks/exhaustive-deps

  const surveyErrors = compact(
    surveyItems.map((surveyItem, i) => {
      if (
        surveyItem.type !== SurveyItemType.QUESTION || // If this is not a question
        !errorsByQuestionId[surveyItem.data.id] // Or it is a question, but there are no errors
      ) {
        return null;
      }

      return {
        surveyItem,
        surveyItemIndex: i,
        errors: errorsByQuestionId[surveyItem.data.id],
      };
    })
  );

  return (
    <div
      className={`${baseClass} d-flex flex-column h-100 text-break ${
        !showingSidebars && `${baseClass}-set-darker-bg-color`
      }`}
    >
      {/* modal for guest*/}
      {showCreateAccountModalAtVotingForGuest && (
        <AuthnFlowSliderModal
          isOpen={showCreateAccountModalAtVotingForGuest}
          onClose={() => setShowCreateAccountModalAtVotingForGuest(false)}
          onLastSlideClose={() => props.events.goToFeed()}
          registrationPrompt={{
            description: COPY.guestModalVoting.description(props.publisher),
            image: <MaterialIcon icon={MaterialIconName.CHECK_CIRCLE} />,
            title: COPY.guestModalVoting.title,
          }}
        />
      )}
      {showVerifyModal && (
        <VerificationModal
          action={() => setShowVerifyModal(false)}
          isFeed={false}
          onClose={() => setShowVerifyModal(false)}
        />
      )}
      <VotingSuccessModal
        events={{ goToFeed: props.events.goToFeed }}
        isOpen={openVotingSuccessModal}
        opt={{
          pubName: props.repostData?.reposterName ?? props.publisher,
          //don't show follow confirmation text if you're a guest since you didn't technically know you made an account
          showFollowText: isGuest
            ? false
            : respId
            ? savedSubscriptionType === null ||
              savedSubscriptionType === SubscriptionType.MANUAL_UNFOLLOWED
            : false,
        }}
        setOpen={setOpenVotingSuccessModal}
      />
      <ConfirmationModal
        cancelLabel={COPY.confirmationModal.cancel}
        confirmLabel={COPY.confirmationModal.confirm}
        copy={COPY.confirmationModal.body}
        events={{
          confirm: async () => {
            await handleSubmit(true);
            setShowConfirmationModal(false);
          },
          cancel: () => setShowConfirmationModal(false),
        }}
        isOpen={showConfirmationModal}
        prompt={COPY.confirmationModal.prompt}
      />
      {/* modal for not guest */}
      {showCreateAccountModalAtVoting && (
        <AuthnFlowSliderModal
          isOpen={showCreateAccountModalAtVoting}
          onAuthenticationSuccess={async () => {
            await props.events.submitVotes();
            // see note on voteForSurvey() about use of ref
            await props.events.followPublisher(respRef.current);
            setDisabledSubmitButtonOnVoteSuccess(true);
            setShowSubmittedInfo(true);
          }}
          onClose={() => setShowCreateAccountModalAtVoting(false)}
          onLastSlideClose={() => props.events.goToFeed()}
          registrationPrompt={{
            description: SURVEY_LOADED_COPY.submitVoting.description,
            image: (
              <div className="w-100 d-flex justify-content-center">
                <img alt="Polco logo" src={polcoLogo} />
              </div>
            ),
            title: COPY.submitVoting.title,
          }}
        />
      )}
      {showOnboarding && (
        <AuthnFlowSliderModal
          informationPrompt={{
            name: COPY.learnMore.prompt.title,
            paragraphs: [
              <p>{COPY.learnMore.prompt.description}</p>,
              <p className="mt-3">{COPY.learnMore.prompt.description2}</p>,
            ],
            background: PolcoManIdea,
            buttons: {
              name: COPY.learnMore.prompt.button,
            },
          }}
          isOpen={showOnboarding}
          onClose={() => setShowOnboarding(false)}
          registrationPrompt={{
            description: COPY.learnMore.description(props.publisher),
            image: <MaterialIcon icon={MaterialIconName.ACCOUNT_CIRCLE} />,
            title: COPY.learnMore.title,
          }}
        />
      )}
      <ThankYouModal
        events={{
          cancel: () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setShowThankYouModal(false);
          },
        }}
        isOpen={showThankYouModal}
        isSurvey
      />
      <SurveySharedInner
        {...props}
        errorsByQuestionId={
          hasVotingErrorAtSubmit && !voted ? errorsByQuestionId : {}
        }
        setShowOnboarding={setShowOnboarding}
      />
      {showSubmittedInfo ? (
        <div className={`${baseClass}-submit-notification mt-2`}>
          <div
            className={
              'w-50 text-center font-weight-bold text-white text-bold font-size-sm pt-lg-3 pb-lg-3 pt-2 pb-2 mx-auto'
            }
          >
            {SURVEY_LOADED_COPY.submittedInfo}
          </div>
        </div>
      ) : (
        !embedApp && (
          <div className={`${baseClass}-submit-btn-wrapper`}>
            <Tooltip
              content={COPY.notActive}
              disable={
                (status !== QuestionSetStatus.NOT_STARTED &&
                  status !== QuestionSetStatus.SCHEDULED) ||
                !surveyQuestions.length
              }
              id="survey-disabled-submit"
            >
              {surveyQuestions.length ? (
                <Btn
                  action={async () => await handleSubmit()}
                  ariaLabel="Submit survey"
                  className={'w-100'}
                  disabled={disabledSubmit || disabledSubmitButtonOnVoteSuccess}
                  type={ButtonTypes.PRIMARY}
                >
                  {COPY.submit}
                </Btn>
              ) : (
                <Btn
                  action={props.events.goToFeed}
                  ariaLabel="Return home"
                  className={'w-100'}
                  type={ButtonTypes.PRIMARY}
                >
                  {COPY.returnToFeed}
                </Btn>
              )}
            </Tooltip>
          </div>
        )
      )}
      {hasVotingErrorAtSubmit && !voted && (
        <SurveyErrorNavigator
          handleSubmit={handleSubmit}
          orderedErrors={surveyErrors}
        />
      )}
    </div>
  );
};
SurveyLoaded.displayName = 'SurveyLoaded';
