import * as React from 'react';
import './styles.scss';
import { Form, Col } from 'react-bootstrap';
import { useForm, RegisterOptions, ValidationValueMessage } from 'react-hook-form';
import { ConversionType, RegistrationEvents } from 'client/respondent/core/types';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { CommonRegistrationProps, RegistrationContainerProps } from '.';
import { TextInputProps } from 'client/shared/components/base/text-input';
import { EMAIL_VALIDATION_REGEX, typedKeys, MIN_PASSWORD_LENGTH } from 'core';
import {
  NOT_EMPTY_STRING_REGEX,
  ZIP_VALIDATION_REGEX,
} from 'client/shared/core/constants';
import { RBRef } from 'client/shared/core/types';
import { FormPassword } from 'client/shared/components/form-password';
import { useId } from 'react-use-id-hook';

enum ErrorType {
  SIGNUP_FAILED = 'SIGNUP_FAILED',
}

export const baseClass = 'pn-registration-signup';

export const copy = {
  labels: {
    firstName: {
      label: 'Full Legal Name',
      placeholder: 'Legal first name',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Legal last name',
    },
    zip: {
      label: '5-Digit Zip Code',
      placeholder: '12345',
    },
    email: {
      label: 'Email Address',
      placeholder: 'me@example.com',
    },
    password: {
      label: 'Password',
      placeholder: '',
    },
  },
  errorMessages: {
    firstName: {
      required: 'This field is required',
    },
    lastName: {
      required: 'This field is required',
    },
    email: {
      required: 'This field is required',
      pattern: 'Please enter a valid email address',
    },
    password: {
      required: 'This field is required',
      minLength: `Password must contain at least ${MIN_PASSWORD_LENGTH} characters`,
    },
    zipCode: {
      required: 'This field is required',
      pattern: 'Please enter a valid 5-digit zip code',
    },
    server: 'Something went wrong',
  },
  actions: {
    signUp: 'Create Account',
    alreadyHaveAccount: 'I already have an account',
  },
};

export namespace RegistrationEmailSignup {
  export interface Props extends CommonRegistrationProps {
    readonly events: {
      readonly converted: RegistrationContainerProps['converted'];
      readonly emailSignup: RegistrationEvents['emailSignup'];
    };
    readonly fields: {
      readonly firstName: {
        readonly isRequired: boolean;
      };
      readonly lastName: {
        readonly isRequired: boolean;
      };
    };
  }

  export interface Fields {
    readonly email: string;
    readonly password: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly zipCode: string;
  }

  type ValidationSet = Record<
    keyof Fields,
    Pick<RegisterOptions, 'pattern' | 'minLength'> & {
      readonly required: ValidationValueMessage<boolean>;
    }
  >;
  export const validations: ValidationSet = {
    firstName: {
      required: {
        value: true,
        message: copy.errorMessages.firstName.required,
      },
      pattern: {
        value: NOT_EMPTY_STRING_REGEX,
        message: copy.errorMessages.lastName.required,
      },
    },
    lastName: {
      required: {
        value: true,
        message: copy.errorMessages.lastName.required,
      },
      pattern: {
        value: NOT_EMPTY_STRING_REGEX,
        message: copy.errorMessages.lastName.required,
      },
    },
    email: {
      required: {
        value: true,
        message: copy.errorMessages.email.required,
      },
      pattern: {
        value: EMAIL_VALIDATION_REGEX,
        message: copy.errorMessages.email.pattern,
      },
    },
    password: {
      required: {
        value: true,
        message: copy.errorMessages.password.required,
      },
      minLength: {
        value: MIN_PASSWORD_LENGTH,
        message: copy.errorMessages.password.minLength,
      },
    },
    zipCode: {
      required: {
        value: true,
        message: copy.errorMessages.zipCode.required,
      },
      pattern: {
        value: ZIP_VALIDATION_REGEX,
        message: copy.errorMessages.zipCode.pattern,
      },
    },
  };
}

export const RegistrationEmailSignupForm: React.FC<RegistrationEmailSignup.Props> = (
  props
) => {
  const { register, handleSubmit, errors, setError, clearErrors, formState } =
    useForm<RegistrationEmailSignup.Fields>({
      mode: 'onChange',
    });
  const submitted = formState.isSubmitted;
  const createRef = (opts: RegisterOptions): RBRef => {
    return register(opts) as RBRef;
  };

  const text = (
    label: string,
    formFieldName: keyof RegistrationEmailSignup.Fields,
    id: string,
    feedbackId: string,
    opts?: {
      readonly inputType?: TextInputProps['inputType'];
      readonly autoComplete?: string;
      readonly placeholder?: string;
      readonly maxLength?: number;
      readonly ariaLabel?: string;
      readonly hideLabel?: boolean;
      readonly required?: boolean;
    }
  ) => {
    const isInvalid = !!errors[formFieldName]?.message && submitted;
    const validationSet = RegistrationEmailSignup.validations[formFieldName];
    const validation = {
      ...validationSet,
      required: { ...validationSet.required, value: opts?.required ?? true },
    };
    return (
      <Form.Group as={Col}>
        <Form.Label
          className={`font-size-sm ${!!opts?.hideLabel ? 'd-none' : ''}`}
          htmlFor={id}
        >
          {opts?.required ?? true ? label : `${label} (Optional)`}
        </Form.Label>
        <Form.Control
          aria-errormessage={isInvalid ? feedbackId : undefined}
          aria-invalid={isInvalid}
          aria-label={opts?.ariaLabel ?? label ?? undefined}
          autoComplete={opts?.autoComplete}
          className={`rounded accessible-input ${isInvalid ? 'has-error' : ''}`}
          id={id}
          isInvalid={isInvalid}
          maxLength={opts?.maxLength}
          name={formFieldName}
          placeholder={opts?.placeholder}
          ref={createRef(validation ?? {})}
          required={opts?.required}
          type={opts?.inputType}
        />
        <Form.Control.Feedback
          className={submitted ? 'd-block' : ''}
          id={feedbackId}
          type="invalid"
        >
          {errors[formFieldName]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  };
  const submit = async (fields: RegistrationEmailSignup.Fields) => {
    clearErrors();
    const data = {
      ...fields,
      zipCode: fields.zipCode,
    };
    const result = await props.events.emailSignup(data);
    if (result.type === 'FIELD_ERRORS') {
      const errs = result.errors;
      typedKeys(errs).forEach((k) => {
        const err = errs[k];
        if (err) {
          setError(k, { type: ErrorType.SIGNUP_FAILED, message: err });
        }
      });
    } else if (result.type === 'ERROR') {
      setError('email', {
        type: ErrorType.SIGNUP_FAILED,
        message: copy.errorMessages.server,
      });
    } else {
      await props.events.converted({
        conversionType: ConversionType.EMAIL_SIGNUP,
        respondentId: result.respId,
        requiresVerification: !!result.requiresVerification,
      });
    }
  };

  return (
    <Form className={`${baseClass} mr-auto ml-auto`}>
      <Form.Row>
        {text(copy.labels.firstName.label, 'firstName', useId(), useId(), {
          autoComplete: 'given-name',
          placeholder: copy.labels.firstName.placeholder,
          ariaLabel: copy.labels.firstName.placeholder,
          required: props.fields?.firstName.isRequired,
        })}
      </Form.Row>
      <Form.Row className="mod-no-margin-top">
        {text(copy.labels.lastName.label, 'lastName', useId(), useId(), {
          autoComplete: 'family-name',
          placeholder: copy.labels.lastName.placeholder,
          ariaLabel: copy.labels.lastName.placeholder,
          hideLabel: true,
          required: props.fields?.lastName.isRequired,
        })}
      </Form.Row>
      <Form.Row>
        {text(copy.labels.email.label, 'email', useId(), useId(), {
          autoComplete: 'email',
          placeholder: copy.labels.email.placeholder,
          required: true,
        })}
      </Form.Row>
      <Form.Row>
        {text(copy.labels.zip.label, 'zipCode', useId(), useId(), {
          autoComplete: 'postal-code',
          placeholder: copy.labels.zip.placeholder,
          required: true,
        })}
      </Form.Row>
      <Form.Row>
        <FormPassword
          createRef={createRef}
          error={errors.password?.message ?? null}
          label={copy.labels.password.label}
          submitted={submitted}
        />
      </Form.Row>

      <Btn
        action={handleSubmit(submit)}
        className="w-100 mt-2"
        type={ButtonTypes.PRIMARY}
      >
        {copy.actions.signUp}
        <MaterialIcon
          className={`${baseClass}-arrow-forward ml-1`}
          icon={MaterialIconName.ARROW_FORWARD}
        />
      </Btn>
    </Form>
  );
};
