import { Modal } from 'client/shared/components/modal';
import * as React from 'react';
import { UNFOLLOW_CONFIRM_COPY } from './copy';
import {
  ButtonTypes,
  Btn,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import './styles.scss';

const baseClass = 'pn-unfollow-confirm';

interface Props {
  readonly events: {
    readonly cancel: () => void;
    readonly unfollow: () => Promise<any>;
  };
  readonly publisherName: string;
  readonly isOpen: boolean;
}

export const UnfollowConfirm: React.FC<Props> = (props) => {
  return (
    <Modal
      bodyClassName="m-3"
      className="d-flex justify-center align-items-center h-100"
      isCloseable
      isOpen={props.isOpen}
      onRequestClose={props.events.cancel}
      size="md"
    >
      <div className={`${baseClass}-content`}>
        <div className={`${baseClass}-image my-4 text-center`}>
          <MaterialIcon icon={MaterialIconName.PERSON_REMOVE_ALT_1} />
        </div>
        <h2
          className={`${baseClass}-title font-weight-bold text-gray-60 font-size-xl`}
        >
          {UNFOLLOW_CONFIRM_COPY.unfollow} {props.publisherName}
        </h2>
        <div className="font-size-sm my-4">
          {UNFOLLOW_CONFIRM_COPY.desc} {props.publisherName}?
        </div>
      </div>
      <div className="d-flex flex-column">
        <Btn
          action={props.events.unfollow}
          className="mb-3 w-100"
          type={ButtonTypes.PRIMARY}
        >
          {UNFOLLOW_CONFIRM_COPY.button}
        </Btn>
        <Btn
          action={props.events.cancel}
          className="w-100"
          type={ButtonTypes.SECONDARY}
        >
          {UNFOLLOW_CONFIRM_COPY.cancel}
        </Btn>
      </div>
    </Modal>
  );
};
