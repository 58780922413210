import * as React from 'react';
import Slider, { Settings } from 'react-slick';
import './styles.scss';
import { Modal } from 'client/shared/components/modal';
import {
  RegistrationPrompt,
  RegistrationPromptProps,
} from '../../components/registration-prompt';
import { LoginContainer } from 'client/respondent/account/containers/access/login';
import { RegistrationConfirmation } from '../../components/registration-confirmation';
import { SignupContainer } from 'client/respondent/account/containers/access/signup';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { ClientRespondentId } from 'client/respondent/core';
import { WhyWeNeedLocation } from 'client/respondent/location/components/why-we-need-location';
import { LocationUpdated } from 'client/respondent/location/components/location-updated';
import { LocationContainer } from 'client/respondent/location/containers/set-your-location';
import { KnownFlag, useFlagEnabled } from 'client/shared/contexts/flags-context';
import polcoLogo from 'client/assets/polco-logo-dark-text.svg';
import { SnackbarMessageProps, useSnackbarMessage } from 'client/shared/hooks';
import { InformationPrompt } from '../../components/information-prompt';
import { VerificationStepContainer } from '../verification-step';

const baseClass = 'pn-authn-flow';

interface AuthenticationSuccess {
  readonly respondentId: ClientRespondentId;
  readonly requiresVerification?: boolean;
}

interface Buttons {
  readonly ariaLabel?: string;
  readonly name: string;
  readonly goToFeed?: boolean;
}

export interface SlideProps {
  readonly name: string;
  readonly paragraphs: readonly JSX.Element[];
  readonly privacy?: (isActiveSlide: boolean) => JSX.Element;
  readonly background?: string;
  readonly checkmark?: boolean;
  readonly buttons?: Buttons;
  readonly subTitle?: string;
}

export interface AuthnFlowSliderModalProps {
  readonly onClose: () => void;
  readonly onAuthenticationSuccess?: (args: AuthenticationSuccess) => void;
  readonly onLastSlideClose?: () => void;
  readonly isOpen: boolean;
  readonly registrationPrompt: RegistrationPromptProps;
  readonly informationPrompt?: SlideProps;
}

export enum AuthnFlowSlideType {
  INFORMATION_PROMPT = 'INFORMATION_PROMPT',
  REGISTRATION_PROMPT = 'REGISTRATION_PROMPT',
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  SUCCESS = 'SUCCESS',
  CONNECT_COMMUNITIES = 'CONNECT_COMMUNITIES',
  SET_UP_LOCATION = 'SET_UP_LOCATION',
  LOCATION_UPDATED = 'LOCATION_UPDATED',
  VERIFICATION = 'VERIFICATION',
}

export interface AuthnFlowSlide {
  readonly name: string;
  readonly type: AuthnFlowSlideType;
}

interface AuthSlideExtras extends AuthnFlowSlide {
  readonly showNextSlide: () => void;
  readonly setSnackbarMessage: (msg: SnackbarMessageProps) => void;
  readonly onClickLogin: () => void;
  readonly onClickSignup: () => void;
  readonly onAuthenticationSuccess: (args: AuthenticationSuccess) => void;
  readonly isActiveSlide: boolean;
  readonly registrationPrompt: RegistrationPromptProps;
  readonly onClose: () => void;
  readonly informationPrompt?: SlideProps;
}

export const AuthnFlowSliderModal: React.FC<AuthnFlowSliderModalProps> = (props) => {
  const [SnackbarMessage, setSnackbarMessage] = useSnackbarMessage();
  const locationFlowActivated = useFlagEnabled(KnownFlag.RESIDENTS_STORE_LOCATION);

  const slides: readonly AuthnFlowSlide[] = [
    ...(props.informationPrompt
      ? [
          {
            name: 'Information Prompt',
            type: AuthnFlowSlideType.INFORMATION_PROMPT,
          },
        ]
      : []),
    {
      name: 'Registration Prompt',
      type: AuthnFlowSlideType.REGISTRATION_PROMPT,
    },
    {
      name: 'Login',
      type: AuthnFlowSlideType.LOGIN,
    },
    {
      name: 'Sign up',
      type: AuthnFlowSlideType.SIGNUP,
    },
    {
      name: 'Verification',
      type: AuthnFlowSlideType.VERIFICATION,
    },
    {
      name: 'Success',
      type: AuthnFlowSlideType.SUCCESS,
    },
    ...(locationFlowActivated
      ? [
          {
            name: 'Connect with Communities',
            type: AuthnFlowSlideType.CONNECT_COMMUNITIES,
          },
          {
            name: 'Set Up your Location',
            type: AuthnFlowSlideType.SET_UP_LOCATION,
          },
          {
            name: 'Location updated',
            type: AuthnFlowSlideType.LOCATION_UPDATED,
          },
        ]
      : []),
  ];

  const slider = React.useRef<Slider | null>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState<number>(0);
  const loginNum = slides.findIndex((s) => s.type === AuthnFlowSlideType.LOGIN);
  const signupNum = slides.findIndex((s) => s.type === AuthnFlowSlideType.SIGNUP);
  const successNum = slides.findIndex((s) => s.type === AuthnFlowSlideType.SUCCESS);
  const verificationNum = slides.findIndex(
    (s) => s.type === AuthnFlowSlideType.VERIFICATION
  );

  const showLoginSlide = () => {
    if (!slider.current) {
      return;
    }
    slider.current.slickGoTo(loginNum);
    setCurrentSlideIndex(loginNum);
  };

  const showSpecificSlide = (slideNum: number) => {
    if (!slider.current) {
      return;
    }
    slider.current.slickGoTo(slideNum);
    setCurrentSlideIndex(slideNum);
  };

  const showSignupSlide = () => {
    if (!slider.current) {
      return;
    }
    slider.current.slickGoTo(signupNum);
    setCurrentSlideIndex(signupNum);
  };

  const showNextSlide = (slideNum: number) => {
    if (!slider.current) {
      return;
    }
    slider.current.slickNext();
    setCurrentSlideIndex(slideNum + 1);
  };

  const onLastSlideClose = () => {
    if (props.onLastSlideClose) {
      props.onLastSlideClose();
    } else {
      props.onClose();
    }
  };

  const onAuthnCompletion = (slideNum: Number, args: AuthenticationSuccess) => {
    if (slideNum === loginNum) {
      props.onClose();
    } else if (slideNum === signupNum) {
      if (args.requiresVerification) {
        showSpecificSlide(verificationNum);
      } else {
        showSpecificSlide(successNum);
      }
    }
  };

  const settings: Settings = {
    className: `${baseClass}-slider`,
    // Since we have slides that scroll onSuccess, we want to remove the ability to navigate the modal with arrows.
    accessibility: false,
    infinite: false,
    dots: false,
    arrows: false,
    swipe: false,
    adaptiveHeight: true,
  };

  return (
    <>
      <SnackbarMessage />
      <Modal
        bodyClassName="p-0"
        className={`${baseClass}-modal`}
        header={{
          title: (
            <div className="d-flex align-items-center justify-content-between">
              <img alt="Polco" height={24} src={polcoLogo} width={102} />
              <Btn action={props.onClose} type={ButtonTypes.SEAMLESS}>
                <MaterialIcon
                  className="text-gray-40"
                  icon={MaterialIconName.CLOSE}
                  style={{ fontSize: '24px' }}
                />
                <span className="sr-only">Close</span>
              </Btn>
            </div>
          ),
          bsClassName: 'bg-white p-3 border-gray-20 border-bottom sticky-top',
          className: 'font-size-lg w-100',
        }}
        isCloseable={false}
        isOpen={props.isOpen}
        onRequestClose={props.onClose}
        size="md"
      >
        <Slider ref={slider} {...settings}>
          {slides.map((slide, slideNum) => {
            return (
              <Slide
                {...slide}
                informationPrompt={props.informationPrompt}
                isActiveSlide={slideNum === currentSlideIndex}
                key={slide.name}
                onAuthenticationSuccess={async (args) => {
                  props?.onAuthenticationSuccess?.(args);
                  onAuthnCompletion(slideNum, args);
                }}
                onClickLogin={showLoginSlide}
                onClickSignup={showSignupSlide}
                onClose={props.onClose}
                registrationPrompt={props.registrationPrompt}
                setSnackbarMessage={setSnackbarMessage}
                showNextSlide={() => {
                  slideNum === slides.length - 1
                    ? onLastSlideClose()
                    : showNextSlide(slideNum - 1);
                }}
              />
            );
          })}
        </Slider>
      </Modal>
    </>
  );
};

AuthnFlowSliderModal.displayName = 'AuthnFlowSlider';

const Slide: React.FC<AuthSlideExtras> = (props) => {
  function switchRenderAuthSlide() {
    switch (props.type) {
      case AuthnFlowSlideType.INFORMATION_PROMPT: {
        const informationPrompt = props.informationPrompt;
        if (!informationPrompt) {
          return null;
        }

        return (
          <InformationPrompt
            {...informationPrompt}
            action={props.showNextSlide}
            isActiveSlide={props.isActiveSlide}
          />
        );
      }
      case AuthnFlowSlideType.REGISTRATION_PROMPT: {
        return (
          <RegistrationPrompt
            {...props.registrationPrompt}
            onLogin={props.onClickLogin}
            onSignUp={props.onClickSignup}
          />
        );
      }
      case AuthnFlowSlideType.LOGIN:
        return (
          <LoginContainer
            converted={props.onAuthenticationSuccess}
            includeAdminRedirection={false}
            onClickSignup={props.onClickSignup}
          />
        );
      case AuthnFlowSlideType.SUCCESS:
        return <RegistrationConfirmation onConfirm={props.showNextSlide} />;
      case AuthnFlowSlideType.CONNECT_COMMUNITIES:
        return <WhyWeNeedLocation onContinue={props.showNextSlide} />;
      case AuthnFlowSlideType.SET_UP_LOCATION:
        return (
          <LocationContainer
            onSaveLocation={props.showNextSlide}
            onSkip={props.onClose}
            setSnackbarMessage={props.setSnackbarMessage}
            showSkipButton
          />
        );
      case AuthnFlowSlideType.LOCATION_UPDATED:
        return <LocationUpdated onFinish={props.showNextSlide} />;
      case AuthnFlowSlideType.SIGNUP: {
        return (
          <SignupContainer
            converted={props.onAuthenticationSuccess}
            onClickLogin={props.onClickLogin}
          />
        );
      }
      case AuthnFlowSlideType.VERIFICATION: {
        return (
          <VerificationStepContainer
            action={props.showNextSlide}
            isActiveSlide={props.isActiveSlide}
            isFeed={false}
            setSnackbarMessage={props.setSnackbarMessage}
          />
        );
      }
    }
  }

  return (
    <div className={`${baseClass}-slide`}>
      {props.informationPrompt?.background &&
        props.type === AuthnFlowSlideType.INFORMATION_PROMPT && (
          <div className={`${baseClass}-image`}>
            <img alt="" src={props.informationPrompt.background} />
          </div>
        )}
      <div className={`${baseClass}-content p-3`}>
        {switchRenderAuthSlide()}
        {/**Form elements internally control nextSlide on success */}
      </div>
    </div>
  );
};
