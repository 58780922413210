import * as React from 'react';
import { PARSE_DATE_FORMAT } from 'core';
import moment from 'moment';
import { HtmlContent } from 'client/shared/components/base';
import { SURVEY_RESPONSE_COPY } from '../../containers/survey-loaded/copy';

import './styles.scss';
import { EmbedContext } from 'client/shared/contexts/embed-context';

export interface SurveyVoteInfoProps {
  readonly surveyTitle: string;
  readonly subheader: string;
  readonly description: string;
  readonly expireDate: Date | null;
}

const baseClass = 'pn-survey-response';

export const SurveyInfo: React.FC<SurveyVoteInfoProps> = (props) => {
  const embedApp = React.useContext(EmbedContext);
  const dateToRender = props.expireDate
    ? moment(props.expireDate).format(PARSE_DATE_FORMAT)
    : null;

  return (
    <div className={`mx-3 ${embedApp ? 'pt-3 pb-2' : 'py-3'}`}>
      <h1
        className={`${baseClass}-survey-title ${
          embedApp ? 'embed mb-0' : 'mb-2'
        } font-weight-bold`}
      >
        {props.surveyTitle}
      </h1>
      <div className="d-flex font-size-sm">
        <div className="pr-3">{props.subheader}</div>
        {dateToRender && (
          <div>
            {SURVEY_RESPONSE_COPY.expiresOn} {dateToRender}
          </div>
        )}
      </div>
      <div className={`${baseClass}-desc py-2 font-size-sm`}>
        <HtmlContent html={props.description} imageAlt="Survey description image" />
      </div>
    </div>
  );
};
