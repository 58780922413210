import { RespondentActions } from 'client/respondent/core/reducers/actions';

import { compose, QuestionSetType } from 'core';
import { fbShare } from 'client/shared/integrations';
import { QuestionType, ClientQuestionId } from 'client/shared/core/question';
import {
  VotingProps,
  QuestionDispatchContext,
  Type,
  NotLoggedInActions,
  NotLoggedInActionStatus,
  QuestionMetadataEntry,
} from 'client/respondent/core/types';
import * as VotingRules from 'client/respondent/core';
import { RedirectFunction } from 'client/shared/hooks';
import {
  getVoteData,
  toggleCommentUpvote,
  loadComments,
  vote,
  addComment,
} from '../../../shared/functions';
import { RouteComponentProps } from 'react-router';
import { QuestionVotingMutationReturns } from 'client/shared/graphql-mutations/mutation-infos';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { ClientPublishingEntityId } from 'client/shared/core/publishing-entity';
import {
  LoadedEvents,
  VotingInteraction,
  PollSetVoteResult,
  PollSetVoteFailureMessage,
  VoteResultType,
  PointAllocationChoice,
  AnswerChangeability,
  MCChoice,
} from 'client/shared/core/types';
import { CurrentUser } from 'client/respondent/hooks';
import { ClientQuestionSetId } from 'client/shared/core/question-set';
import { RESPONDENT_STATE_IN_LOCAL_STORAGE } from 'client/respondent/core/reducers/context';
import { twitterShare } from 'client/shared/integrations/twitter';

export interface Props
  extends RouteComponentProps<{
    readonly questionId: string;
    readonly setSlug: string;
    readonly pubSlug?: string;
    readonly selection?: string;
  }> {
  readonly isPolcoLive: boolean;
  readonly respondent: CurrentUser | null;
}

export function mapContextToEvents(
  ctx: QuestionDispatchContext,
  redirect: RedirectFunction,
  goBack: () => void,
  questionSet: {
    readonly id: ClientQuestionSetId;
    readonly slug: string | null;
    readonly pubSlug: string;
    readonly setType: VotingProps.SetType;
  },
  contextPublishingEntity: {
    readonly id: ClientPublishingEntityId;
    readonly slug: string;
  } | null
): LoadedEvents {
  const { id } = ctx;
  const currentUser = ctx.user;
  const currentVote = ctx.data.openQuestion?.previousVote;
  const resp = currentUser?.user?.respondent;

  const preprocessComment = () => {
    const comment = ctx.state.inProcessComment?.comment.trim();
    // bad comment input
    if (!comment || comment.length === 0) {
      return null;
    }
    return { id: null, comment };
  };

  const inProcessVote: VotingProps.Vote | null = ctx.state.inProcessVote
    ? {
        ...ctx.state.inProcessVote,
        comment: preprocessComment(),
        upvotedCommentIds: ctx.state.upvoteCommentsOnVote,
      }
    : null;

  const events: LoadedEvents = {
    startInProcessVote: (
      questionId: ClientQuestionId,
      questionType: QuestionType
    ) => {
      ctx.dispatch(RespondentActions.startInProcessVote(questionId, questionType));
    },
    selectMultipleChoice: (
      choices: readonly MCChoice[],
      questionId: ClientQuestionId
    ) => {
      ctx.dispatch(RespondentActions.selectedMultipleChoice(choices, questionId));
    },
    selectPointAllocation: (
      choices: readonly PointAllocationChoice[],
      questionId: ClientQuestionId
    ) => {
      ctx.dispatch(RespondentActions.selectedPointAllocation(choices, questionId));
    },
    updateCommentText: (comment: string, questionId: ClientQuestionId) => {
      ctx.dispatch(RespondentActions.updateCommentText(comment, questionId));
    },
    loadComments: async (page: number) => {
      await loadComments(id, page, ctx.query.fetchMore);
    },
    submitVote: async () => {
      return await handleSubmit({
        inProcessVote,
        upvoteCommentsOnVote: ctx.state.upvoteCommentsOnVote,
        voteForQuestion: ctx.mut.voteForQuestion,
        questionMetadata: ctx.state.questionMetadata ?? [],
      });
    },
    submitComment: async (comment: string, questionId: ClientQuestionId) => {
      return (
        ctx.mut.addComment &&
        (await addComment(ctx.mut.addComment, questionId, comment))
      );
    },

    changeAnswer: (which: AnswerChangeability) => {
      if (!inProcessVote || !inProcessVote?.type) {
        throw 'Could not find vote';
      }

      ctx.dispatch(
        RespondentActions.changeAnswer({
          which,
          currentVote: currentVote
            ? VotingRules.graphqlVoteToPropsVote(currentVote, inProcessVote.type)
            : null,
        })
      );
    },
    cancelInteraction: () => ctx.dispatch(RespondentActions.cancelInteraction()),
    promptRegistration: (questionId: ClientQuestionId) => {
      ctx.dispatch(
        RespondentActions.promptRegistration({
          actionType: NotLoggedInActions.SET_VOTE,
          redirectLink: ClientUrlUtils.respondent.question.path({
            questionId: questionId,
            setIdOrSlug: questionSet.slug ?? questionSet.id,
            pubSlug: questionSet.pubSlug,
            setType: clientToCoreSetType(questionSet.setType),
          }),
          data: {
            setId: questionSet.id,
            questionId: questionId,
          },
          status: NotLoggedInActionStatus.PRE_REGISTRATION,
          registrationType: null,
        })
      );
    },
    facebook: {
      shareQuestion: (
        _questionId: ClientQuestionId,
        currentUserId: string | null
      ) => {
        const queryStr = currentUserId ? `?iid=${currentUserId}` : '';
        const url = window.location.href + queryStr;
        fbShare(url);
      },
    },
    twitter: {
      shareQuestion: (
        _questionId: ClientQuestionId,
        currentUserId: string | null
      ) => {
        const queryStr = currentUserId ? `?iid=${currentUserId}` : '';
        const url = window.location.href + queryStr;
        twitterShare(url);
      },
    },
    upvoteComment: async (commentId: string) => {
      await toggleCommentUpvote(ctx, commentId, true);
    },
    unUpvoteComment: async (commentId: string) =>
      await toggleCommentUpvote(ctx, commentId, false),
    goToQuestion: (questionId: string, fromOverview?: boolean) => {
      ctx.dispatch(RespondentActions.clearInProcessVote());
      return redirect(
        ClientUrlUtils.respondent.question.path({
          questionId,
          setIdOrSlug: questionSet.slug ?? questionSet.id,
          pubSlug: questionSet.pubSlug,
          setType: clientToCoreSetType(questionSet.setType),
          fromOverview: fromOverview,
        }),
        {
          push: true,
        }
      );
    },
    goToPublishingEntity: (publisherSlug: string) => {
      redirect(VotingRules.publishingEntityPath(publisherSlug), {
        push: true,
      });
    }, // click on icon,
    setShowFullDetails: compose(RespondentActions.setShowFulLDetails, ctx.dispatch),
    bookmark: () => {
      console.log('bookmark');
    }, // FIXME
    setInteraction: (interaction: VotingInteraction) => {
      ctx.dispatch(RespondentActions.setInteraction(interaction));
    },
    doneAnsweringQuestions: () => {
      redirect(ClientUrlUtils.respondent.feed.path(), { push: true });
    },
    // content is accessed either by the pubisher feed or the main feed. the 'exit' button within content sets logically you to whichever place you came from
    goToFeed: () => {
      if (ctx.state.lastFeedLocation) {
        redirect(
          ClientUrlUtils.respondent.pubProfile.path({
            slug: ctx.state.lastFeedLocation,
          }),
          { push: true }
        );
      } else {
        redirect(ClientUrlUtils.respondent.feed.path(), { push: true });
      }
    },
    goBack,
    followPublisher: async () => {
      if (resp?.id && contextPublishingEntity && ctx.mut.followOnVoting) {
        await ctx.mut.followOnVoting.fn({
          variables: {
            follow: true,
            publishingEntityId: contextPublishingEntity.id,
            respondentId: resp.id,
          },
        });
      }
    },
    logout: async () => {
      await ctx.mut.logout?.fn();
      if (localStorage) {
        localStorage.removeItem(RESPONDENT_STATE_IN_LOCAL_STORAGE);
      }
      ctx.dispatch(RespondentActions.clearInProcessVote());
    },
    goToSet: () => {
      redirect(
        ClientUrlUtils.respondent.set.path({
          setIdOrSlug: questionSet.slug ?? questionSet.id,
          pubSlug: questionSet.pubSlug,
          setType: clientToCoreSetType(questionSet.setType),
        }),
        { push: true }
      );
    },
  };
  return events;
}

// Exported for tests
export const handleSubmit = async (args: {
  readonly inProcessVote: VotingProps.Vote | null;
  readonly upvoteCommentsOnVote: Type['upvoteCommentsOnVote'];
  readonly voteForQuestion: QuestionVotingMutationReturns['voteForQuestion'];
  readonly questionMetadata: readonly QuestionMetadataEntry[];
}): Promise<PollSetVoteResult> => {
  const { inProcessVote, upvoteCommentsOnVote, voteForQuestion, questionMetadata } =
    args;

  if (!inProcessVote || !inProcessVote.type) {
    return {
      type: VoteResultType.ERROR,
      message: PollSetVoteFailureMessage.NO_VOTE,
    };
  }
  if (inProcessVote.type === QuestionType.GRID_CHOICE) {
    return {
      type: VoteResultType.ERROR,
      message: PollSetVoteFailureMessage.GRID,
    };
  }
  const voteData: VotingProps.Vote = getVoteData(inProcessVote);
  const choiceIds = VotingRules.generateUniformChoiceIdsArray(inProcessVote);
  if (!choiceIds?.length && voteData.type !== QuestionType.FREE_TEXT) {
    return {
      type: VoteResultType.ERROR,
      message: PollSetVoteFailureMessage.NO_CHOICE,
    };
  }
  const comment = voteData.comment?.comment.trim();
  if (voteData.type === QuestionType.FREE_TEXT && !comment?.length) {
    return {
      type: VoteResultType.ERROR,
      message: PollSetVoteFailureMessage.NO_COMMENT,
    };
  }
  return await vote(
    upvoteCommentsOnVote,
    voteForQuestion,
    voteData,
    questionMetadata
  );
};

const clientToCoreSetType = (
  setType: VotingProps.SetType
): QuestionSetType.SET | QuestionSetType.POLCO_LIVE => {
  switch (setType) {
    case VotingProps.SetType.POLCO_LIVE:
      return QuestionSetType.POLCO_LIVE;
    case VotingProps.SetType.POLL_SET:
      return QuestionSetType.SET;
  }
};
