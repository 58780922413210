import { VotingProps } from './types';
import {
  SavedSurveyItem,
  QuestionType,
  SavedQuestion,
  SurveyItemType,
} from 'client/shared/core/question';
import { sumBy } from 'lodash';
import {
  executeConditionalLogic,
  ConditionOutput,
  Result,
  MetaCondition,
  QuestionChoiceCondition,
} from 'core';

export function validateVotes(
  surveyItems: readonly SavedSurveyItem[],
  votes: VotingProps.VotesByQuestionId
): {
  readonly [questionId: string]: readonly VotingProps.VotingErrorType[];
} {
  // We only want visible survey items, because they are the only ones that can
  // make a set of votes invalid
  const visibleSurveyItems = surveyItems.filter((surveyItem) =>
    isSurveyItemVisible(surveyItem.data.conditions, votes)
  );

  // Check for missing required votes, or invalid votes
  return visibleSurveyItems.reduce((acc, surveyItem) => {
    if (surveyItem.type !== SurveyItemType.QUESTION) {
      return acc;
    }

    const vote = votes[surveyItem.data.id];

    if (!vote) {
      if (surveyItem.data.optional) {
        // This survey item is not required, and they did not vote on it, therefore it is valid
        return acc;
      } else {
        // CHECK FOR MISSING REQUIRED QUESTIONS
        return {
          ...acc,
          [surveyItem.data.id]: [VotingProps.VotingErrorType.NO_INPUT],
        };
      }
    }

    // CHECK FOR INVALID RESPONSES
    switch (vote.type) {
      case QuestionType.FREE_TEXT:
        const ftValidation = validateFreeTextVote(vote, surveyItem.data);
        return ftValidation.length
          ? { ...acc, [vote.questionId]: ftValidation }
          : acc;
      case QuestionType.GRID_CHOICE:
        const gridValidation = validateGridChoiceVote(vote, surveyItem.data);
        return gridValidation.length
          ? { ...acc, [vote.questionId]: gridValidation }
          : acc;
      case QuestionType.MULTIPLE_CHOICE:
        const mcValidation = validateMultipleChoiceVote(vote, surveyItem.data);
        return mcValidation.length
          ? { ...acc, [vote.questionId]: mcValidation }
          : acc;
      case QuestionType.POINT_ALLOCATION:
        const paValidation = validatePointAllocationVote(vote, surveyItem.data);
        return paValidation.length
          ? { ...acc, [vote.questionId]: paValidation }
          : acc;
    }
  }, {} as { readonly [questionId: string]: readonly VotingProps.VotingErrorType[] });
}

function validateGridChoiceVote(
  questionToValidate: VotingProps.Vote_GridChoice,
  savedQuestion: SavedQuestion
): readonly VotingProps.VotingErrorType[] {
  if (savedQuestion.optional) {
    return [];
  }
  const gridError: VotingProps.VotingErrorType[] = [];
  const typedData = savedQuestion.typedData;
  if (typedData.type !== QuestionType.GRID_CHOICE) {
    return []; // This would be a bizarre misconfigured situation
  }
  const numOfvotedChoice = Object.keys(questionToValidate.gridChoiceByRowId).length;

  // haven't selected all columns and grid is required
  if (numOfvotedChoice !== typedData.rows.length) {
    gridError.push(VotingProps.VotingErrorType.GRID_MISSING_ROW);
  }
  return gridError;
}

function validatePointAllocationVote(
  questionToValidate: VotingProps.Vote_PointAllocation,
  _savedQuestion: SavedQuestion
): readonly VotingProps.VotingErrorType[] {
  const paError: VotingProps.VotingErrorType[] = [];

  const totalPoint = sumBy(questionToValidate.choices, (ch) => ch.point);
  // point check
  if (totalPoint !== 10) {
    paError.push(VotingProps.VotingErrorType.PA_POINT_NOT_TEN);
  }
  return paError;
}

function validateFreeTextVote(
  questionToValidate: VotingProps.Vote_FreeText,
  savedQuestion: SavedQuestion
): readonly VotingProps.VotingErrorType[] {
  if (questionToValidate.comment?.comment === '' && !savedQuestion.optional) {
    return [VotingProps.VotingErrorType.NO_INPUT];
  }

  // as of now, any vote on a FT question is valid
  return [];
}

function validateMultipleChoiceVote(
  questionToValidate: VotingProps.Vote_MultipleChoice,
  savedQuestion: SavedQuestion
): readonly VotingProps.VotingErrorType[] {
  const mcError: VotingProps.VotingErrorType[] = [];
  const typedData = savedQuestion.typedData;
  if (typedData.type !== QuestionType.MULTIPLE_CHOICE) {
    return []; // somehow this question isn't a MC question
  }

  if (questionToValidate.choices.length === 0) {
    // Ideally we would not even have a vote if there are no choices,
    // but that would be a significant refactor (if they vote, then unvote)
    if (savedQuestion.optional) {
      return [];
    } else {
      return [VotingProps.VotingErrorType.NO_INPUT];
    }
  }

  // max selection check
  if (typedData.maxSelection < questionToValidate.choices.length) {
    mcError.push(VotingProps.VotingErrorType.MC_OVER_MAX_SELECTION);
  }

  return mcError;
}

export function isSurveyItemVisible(
  conditions: {
    readonly metaConditions: readonly MetaCondition[];
    readonly questionChoiceConditions: readonly QuestionChoiceCondition[];
  } | null,
  votes: VotingProps.VotesByQuestionId
): boolean {
  // This is what 99% of questions will hit - there are no conditions, so show the question
  if (!conditions || conditions.metaConditions.length === 0) {
    return true;
  }

  const inputValues = {
    questionChoices: Object.values(votes).reduce<
      readonly {
        readonly questionId: string;
        readonly questionChoiceIds: readonly string[];
      }[]
    >((acc, vote) => {
      if (!vote) {
        return acc;
      }

      switch (vote.type) {
        case QuestionType.FREE_TEXT:
          return acc;
        case QuestionType.GRID_CHOICE:
          return [
            ...acc,
            ...Object.entries(vote.gridChoiceByRowId).map(([rowId, choice]) => ({
              questionId: rowId,
              questionChoiceIds: choice ? [choice] : [],
            })),
          ];
        case QuestionType.MULTIPLE_CHOICE:
          return [
            ...acc,
            {
              questionId: vote.questionId,
              questionChoiceIds: vote.choices.map((choice) => choice.id),
            },
          ];
        case QuestionType.POINT_ALLOCATION:
          return [
            ...acc,
            {
              questionId: vote.questionId,
              questionChoiceIds: vote.choices.map((choice) => choice.id),
            },
          ];
      }
    }, []),
  };

  const conditionalLogicResult = executeConditionalLogic(conditions, inputValues);

  if (Result.isSuccess(conditionalLogicResult)) {
    return conditionalLogicResult.value === ConditionOutput.SUCCESS;
  } else {
    return false;
  }
}
