import * as React from 'react';
import './styles.scss';
import {
  RegistrationEvents,
  RegistrationErrors,
  SocialLoginType,
} from 'client/respondent/core/types';
import { CommonRegistrationProps } from '.';
import { useRespondentState } from 'client/respondent/core/reducers/context';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { SocialSignupButton } from '../social';
import { googleLogin, fbLogin } from '../../containers/social';
import { PRIVACY_URL } from 'client/shared/core/constants';
import {
  ActionLink,
  AppLink,
  SeparatorWithText,
} from 'client/shared/components/base';
import {
  RegistrationEmailSignup,
  RegistrationEmailSignupForm,
} from './email-signup';

export interface RegistrationStartProps extends CommonRegistrationProps {
  readonly events: {
    readonly socialLogin: RegistrationEvents['socialLogin'];
  };
  readonly registrationErrors: RegistrationErrors;
  readonly emailSignupProps: RegistrationEmailSignup.Props;
  readonly onClickLoginLink?: () => void;
}

const baseClass = 'pn-registration-start';

const copy = {
  separationText: 'or',
  privacy: (
    <>
      We will never share your information with anyone other than the city officials
      in your county. Read our&nbsp;
      <AppLink className="text-jungle" target="_blank" to={PRIVACY_URL}>
        Privacy Policy
      </AppLink>
      .
    </>
  ),
  selectACustomTitleIfThereIsPublisher(pubName: string | undefined) {
    return pubName
      ? `To send your response to ${pubName}, we need to make sure you're a resident.`
      : 'Join Polco today';
  },
  actions: {
    signUpWithFacebook: 'Sign up with Facebook',
    signUpWithGoogle: 'Sign up with Google',
  },
};

export const RegistrationStart: React.FC<RegistrationStartProps> = (props) => {
  const state = useRespondentState();

  return (
    <div className="h-100 bg-white">
      <div
        className={`${baseClass} flex-grow-1 d-flex flex-column justify-content-center p-4 ml-auto mr-auto`}
      >
        <div className={`${baseClass}-header mod-center`}>
          <h1
            className={`${baseClass}-header-content font-size-xl mb-3 font-weight-bold`}
          >
            {copy.selectACustomTitleIfThereIsPublisher(
              props.questionInfo?.publishingEntity.name
            )}
          </h1>
        </div>

        <div className={`${baseClass}-start-ctas`}>
          <RegistrationEmailSignupForm {...props.emailSignupProps} />

          <div className="font-size-sm my-3">{copy.privacy}</div>

          <SeparatorWithText text={copy.separationText} />

          <SocialSignupButton
            action={() => googleLogin(props.events.socialLogin)}
            cta={copy.actions.signUpWithGoogle}
            disabled={!!props.registrationErrors.GOOGLE}
            loginType={SocialLoginType.GOOGLE}
          />
          <SocialSignupButton
            action={() => fbLogin(props.events.socialLogin)}
            cta={copy.actions.signUpWithFacebook}
            disabled={!!props.registrationErrors.FACEBOOK}
            loginType={SocialLoginType.FACEBOOK}
          />
          <div className="mt-3 font-size-base">
            Already have an account?{' '}
            {props.onClickLoginLink ? (
              <ActionLink action={props.onClickLoginLink} underlineOnHover>
                Login
              </ActionLink>
            ) : (
              <AppLink
                className="m-auto text-jungle"
                to={
                  state.isEmbed
                    ? ClientUrlUtils.embed.login.path()
                    : ClientUrlUtils.respondent.login.path()
                }
              >
                Login
              </AppLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
