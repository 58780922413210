import * as React from 'react';
import './styles.scss';
import { ClickableDiv } from 'client/shared/components/base';

export interface Props {
  readonly alertText: string;
  readonly alertAction?: () => void;
  readonly errorType?: boolean;
}

const baseClass = 'pn-alert-banner';
const animationDelay = 2000;

export const AlertBanner: React.FC<Props> = (p) => {
  const [animatedClass, setAnimatedClass] = React.useState(
    `${baseClass}-pre-animation`
  );

  React.useEffect(() => {
    // provide a bit of a delay so as not to startle the user with an alert upon load
    const timeout = setTimeout(
      () => setAnimatedClass(`${baseClass}-post-animation`),
      animationDelay
    );
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const classes = `${baseClass} ${animatedClass} text-center font-size-sm w-100 position-absolute ${
    p.errorType ? 'mod-error' : ''
  }`;

  if (p.alertAction === undefined) {
    return (
      <div aria-live="polite" className={classes}>
        <div className="p-2">{p.alertText}</div>
      </div>
    );
  }

  return (
    <ClickableDiv action={p.alertAction} className={classes}>
      <div className="p-2">{p.alertText}</div>
    </ClickableDiv>
  );
};
