import * as React from 'react';
import './styles.scss';

import {
  VotingProps,
  NotLoggedInActions,
  QuestionPageRedirectLocation,
} from 'client/respondent/core/types';
import { VotingButtons } from '../../../shared/components/voting-buttons';
import { VotingHeader } from '../../../shared/components/header';
import { ActionButtons } from '../../components/action-buttons';
import { QUESTION_PAGE_COPY } from './copy';
import { Comments } from '../../../shared/components/comments';
import { MoreInfo } from '../../../shared/components/more-info';
import { ShareModal } from 'client/shared/components/share-modal';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { AddComment } from '../../../shared/components/add-comment';
import { VotingResult } from '../../../shared/components/voting-result';
import { QuestionType } from 'client/shared/core/question';
import { nth } from 'lodash';

import { ClientRespondentId } from 'client/respondent/core';
import { AlertBanner } from '../../../shared/components/alert-banner';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { VotingInteraction, VoteResultType } from 'client/shared/core/types';
import { _dangerousUseEffectOnMount } from 'client/shared/helpers/hooks';
import {
  checkIfInProcessVoteIsValid,
  processPreLoginAction,
} from 'client/respondent/shared/functions';
import {
  useRespondentDispatch,
  useRespondentState,
} from 'client/respondent/core/reducers/context';
import { publishingEntityPrimaryLogoUrl } from 'client/shared/core/publishing-entity';
import { RespondentActions } from 'client/respondent/core/reducers/actions';
import { useRedirect } from 'client/shared/hooks';
import { QuestionSetType } from 'client/shared/core/question-set';
import { TextWithLineBreak } from 'client/shared/components/text-with-line-break';
import { ThankYouModal } from 'client/respondent/voting/shared/components/thank-you-modal';
import { AuthnFlowSliderModal } from 'client/respondent/shared/account/containers/authn-flow-slider-modal';
import polcoLogo from 'client/assets/polco-logo.svg';
import { WithDotsAction } from 'client/respondent/voting/shared/containers/dots-actions';
import { VerificationModal } from 'client/respondent/shared/account/containers/verification-modal';

const baseClass = 'pn-voting-prevote';

enum ModalType {
  MORE_INFO = 'MORE_INFO',
  CREATE_ACCOUNT_PRE_VOTE = 'CREATE_ACCOUNT_PRE_VOTE',
  CONVERT_GUEST = 'CONVERT_GUEST',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  SHARING = 'SHARING',
  THANK_YOU = 'THANK_YOU',
  VERIFICATION = 'VERIFICATION',
}

export enum BannerType {
  SHARE = 'SHARE',
  COMMENT = 'COMMENT',
  VIEW_COMMENTS = 'VIEW_COMMENTS',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  CLOSED = 'CLOSED',
}

export const QuestionSetLoaded: React.FC<VotingProps.QuestionSetLoaded> = (
  props
) => {
  const nextQuestionId =
    nth(props.setData.questions, props.setData.currentIndex + 1)?.id ?? null;

  const voted = !!props.typedData.previousVote;
  const dispatch = useRespondentDispatch();
  const alreadyPromptedConversion =
    useRespondentState().mostRecentContentSetThatPromptedConversion ===
    props.questionSetId;

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [showBanner, setShowBanner] = React.useState<BannerType | null>(
    BannerType.VIEW_COMMENTS
  );
  const [error, setError] = React.useState<string | null>(null);
  const [modalType, setModalType] = React.useState<ModalType | null>(null);
  const redirect = useRedirect();

  const voteForQuestion = async () => {
    const vote = await props.events.submitVote();
    if (vote.type === VoteResultType.SUCCESS) {
      await props.events.followPublisher();
    } else if (vote.type === VoteResultType.ERROR) {
      setError(vote.message);
    }
    return vote;
  };
  const handleSubmit = async () => {
    if ((!props.respId || props.isGuest) && !props.allowGuestRespondents) {
      setModalType(ModalType.CREATE_ACCOUNT_PRE_VOTE);
    } else {
      const vote = await voteForQuestion();
      if (vote.type === VoteResultType.SUCCESS) {
        if ((!props.respId || props.isGuest) && !alreadyPromptedConversion) {
          setModalType(ModalType.CONVERT_GUEST);
          dispatch(
            RespondentActions.setMostRecentContentSetThatPromptedConversion(
              props.questionSetId
            )
          );
        } else {
          props.events.setInteraction(VotingInteraction.VOTE_SUCCESSFUL);
          setSubmitting(false);
        }
      }
    }
  };

  const currentIndex = Math.max(0, props.setData.currentIndex);
  const questionNumber = (currentIndex + 1).toString().padStart(2, '0');
  const navPercentage =
    ((props.setData.currentIndex + 1) / props.setData.questions.length) * 100;

  const prevQuestionId =
    currentIndex === 0
      ? null
      : nth(props.setData.questions, currentIndex - 1)?.id ?? null;
  const setIdOrSlug = props.questionSetSlug ?? props.questionSetId;
  const pubSlug = props.publishingEntity.slug;

  const handleGoToPreviousQuestion = prevQuestionId
    ? () => {
        redirect(
          ClientUrlUtils.respondent.question.path({
            questionId: prevQuestionId,
            pubSlug,
            setIdOrSlug,
            setType: QuestionSetType.SET,
            fromOverview:
              props.redirectedFrom === QuestionPageRedirectLocation.OVERVIEW,
          }),
          {
            push: true,
          }
        );
      }
    : undefined;

  const handleGoToOverview = () => {
    redirect(
      ClientUrlUtils.respondent.setOverview.path({
        pubSlug,
        setIdOrSlug,
      }),
      {
        push: true,
      }
    );
  };

  _dangerousUseEffectOnMount(() =>
    processPreLoginAction(
      props.preLoginAction,
      NotLoggedInActions.SET_VOTE,
      dispatch,
      (act) => {
        if (act.data.questionId !== props.id) return null;
        return handleSubmit();
      }
    )
  );

  React.useEffect(() => {
    // This is to clear actions such as commenting from a previous question
    props.events.cancelInteraction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const existingComments = props.comments.loadedQuestion.length > 0;
  const alreadyCommented = !!props.typedData.previousVote?.comment;
  const isFreeTextType = props.typedData.questionType === QuestionType.FREE_TEXT;

  React.useEffect(() => {
    const bannerType = determineBannerType({
      closed: props.closed,
      isGuest: props.isGuest,
      voted,
      existingComments,
      alreadyCommented,
      isFreeTextType,
      votingInteraction: props.votingInteraction,
      respId: props.respId,
      allowGuestRespondents: props.allowGuestRespondents,
    });
    setShowBanner(bannerType);
  }, [
    props.closed,
    existingComments,
    props.isGuest,
    alreadyCommented,
    isFreeTextType,
    voted,
    props.votingInteraction,
    props.respId,
    props.allowGuestRespondents,
  ]);

  const renderAlert = () => {
    switch (showBanner) {
      case BannerType.VIEW_COMMENTS: {
        return (
          <AlertBanner
            alertAction={() => {
              props.events.setInteraction(VotingInteraction.VIEWING_COMMENTS);
              setShowBanner(null);
            }}
            alertText={QUESTION_PAGE_COPY.alertText(BannerType.VIEW_COMMENTS)}
          />
        );
      }
      case BannerType.COMMENT: {
        return (
          <AlertBanner
            alertAction={() => {
              props.events.setInteraction(VotingInteraction.COMMENTING);
              setShowBanner(null);
            }}
            alertText={QUESTION_PAGE_COPY.alertText(BannerType.COMMENT)}
          />
        );
      }
      case BannerType.CLOSED: {
        return (
          <AlertBanner
            alertAction={() => {
              setShowBanner(null);
            }}
            alertText={QUESTION_PAGE_COPY.alertText(BannerType.CLOSED)}
            errorType
          />
        );
      }
      case BannerType.SHARE: {
        return (
          <AlertBanner
            alertAction={() => {
              setModalType(ModalType.SHARING);
              setShowBanner(null);
            }}
            alertText={QUESTION_PAGE_COPY.alertText(BannerType.SHARE)}
          />
        );
      }
      case BannerType.CREATE_ACCOUNT: {
        return (
          <AlertBanner
            alertAction={() => {
              setModalType(ModalType.CREATE_ACCOUNT);
              setShowBanner(null);
            }}
            alertText={QUESTION_PAGE_COPY.alertText(BannerType.CREATE_ACCOUNT)}
          />
        );
      }
    }
  };

  const shareUrl = ClientUrlUtils.respondent.question.url({
    questionId: props.id,
    setIdOrSlug,
    pubSlug,
    setType: QuestionSetType.SET,
  });

  return (
    <div className={`${baseClass} bg-white text-wrap`}>
      <h1 className="d-none">Poll set page</h1>
      <WithDotsAction
        isGuest={props.isGuest}
        publisherId={props.publishingEntity.id}
        publisherName={props.publishingEntity.name}
        respondentId={props.respId}
        shareUrl={shareUrl}
        subscriptionType={props.publishingEntity.subscriptionType}
      >
        {({ executePublisherAction, executeShareModalAction }) => (
          <VotingHeader
            events={{
              goToFeed: props.events.goToFeed,
              goBack: props.events.goBack,
              openShareModal: () => executeShareModalAction(true),
              goToPublishingEntity: () => props.events.goToPublishingEntity(pubSlug),
              publisherAction: executePublisherAction,
            }}
            navPercentage={navPercentage}
            primaryLogo={publishingEntityPrimaryLogoUrl(
              props.publishingEntity.assets
            )}
            publisherName={props.publishingEntity.name}
            shareable={props.isShareable}
            subscriptionType={props.subscriptionType}
            voted={voted}
          />
        )}
      </WithDotsAction>
      <div className="position-fixed overflow-hidden">
        <div aria-hidden="true" className={`${baseClass}-background-number z1`}>
          {questionNumber}
        </div>
      </div>
      {/* position relative is required so the text is on top of the background number */}
      <div className="position-relative z3">
        <div
          className={`d-flex ${
            handleGoToPreviousQuestion
              ? 'justify-content-between'
              : 'justify-content-end'
          }`}
        >
          {handleGoToPreviousQuestion && (
            <Btn
              action={handleGoToPreviousQuestion}
              className="p-2 m-1"
              type={ButtonTypes.SEAMLESS}
            >
              <MaterialIcon icon={MaterialIconName.KEYBOARD_ARROW_LEFT} />{' '}
              {QUESTION_PAGE_COPY.previous}
            </Btn>
          )}
          {props.redirectedFrom === QuestionPageRedirectLocation.OVERVIEW && (
            <Btn
              action={handleGoToOverview}
              className="p-2 m-1"
              type={ButtonTypes.SEAMLESS}
            >
              {QUESTION_PAGE_COPY.pollSetOverview}
            </Btn>
          )}
        </div>

        {showBanner && renderAlert()}
        <div className={`${baseClass}-container`}>
          <div className="mx-3 py-3">
            <div className={`${baseClass}-title-container mt-5`}>
              <div className={`${baseClass}-title font-weight-bold `}>
                <TextWithLineBreak text={props.title} />
              </div>
            </div>
            {props.votingInteraction === VotingInteraction.VIEWING_COMMENTS && (
              <div className="w-100 mt-4">
                <Btn
                  action={props.events.cancelInteraction}
                  type={ButtonTypes.SECONDARY}
                >
                  {!props.closed && !props.typedData.previousVote
                    ? 'Answer Poll'
                    : 'View Poll'}
                </Btn>
              </div>
            )}
            <div className="d-flex justify-content-start align-items-center mt-3 mb-3">
              <VotingInteractionInner
                {...props}
                setModalType={setModalType}
                submitting={submitting}
              />
            </div>
          </div>
          <ActionButtons
            allowGuestRespondents={props.allowGuestRespondents}
            allowMultipleResponses={props.allowMultipleResponses}
            allowSubmit={checkIfInProcessVoteIsValid(
              props.typedData,
              props.inProcessComment
            )}
            closed={props.closed}
            error={error}
            events={{
              setInteraction: props.events.setInteraction,
              goToQuestion: props.events.goToQuestion,
              submit: async () => {
                setSubmitting(true);
                return await handleSubmit();
              },
              doneAnsweringQuestions: () => {
                if (props.showVerification && !props.isGuest) {
                  setModalType(ModalType.VERIFICATION);
                } else {
                  props.events.goToFeed();
                }
              },
              followPublisher: props.events.followPublisher,
              restart: async () => {
                await props.events.logout();
                props.events.goToSet();
              },
              done: async () => {
                setModalType(ModalType.THANK_YOU);
                await props.events.logout();
              },
            }}
            fromOverview={
              props.redirectedFrom === QuestionPageRedirectLocation.OVERVIEW
            }
            interaction={props.votingInteraction}
            isGuest={props.isGuest}
            nextQuestionId={nextQuestionId ?? null}
            showAddComment={
              voted &&
              !props.closed &&
              !props.typedData.previousVote?.comment &&
              !props.isGuest &&
              props.votingInteraction !== VotingInteraction.COMMENTING
            }
            voted={voted}
          />
        </div>
        {modalType && (
          <VotingModals
            details={props.details}
            events={{
              cancel: () => {
                setModalType(null);
                setSubmitting(false);
              },
              goToFeed: props.events.goToFeed,
              onSuccessfulSignup: async () => {
                await props.events.submitVote();
                await props.events.followPublisher();
              },
              onSuccessfulGuestConversion: async () => {
                await props.events.followPublisher();
              },
              promptRegistration: () => {
                props.events.promptRegistration(props.id);
              },
              facebook: {
                shareQuestion: () => {
                  props.events.facebook.shareQuestion(props.id, props.currentUserId);
                },
              },
              twitter: {
                shareQuestion: () => {
                  props.events.twitter.shareQuestion(props.id, props.currentUserId);
                },
              },
              goToSet: props.events.goToSet,
            }}
            modalType={modalType}
            publisherName={props.publishingEntity.name}
            respId={props.respId}
            shareUrl={shareUrl}
          />
        )}
      </div>
    </div>
  );
};
QuestionSetLoaded.displayName = 'QuestionSetLoaded';

const Voting: React.FC<
  VotingProps.QuestionSetLoaded & {
    readonly submitting: boolean;
    readonly setModalType: (type: ModalType) => void;
  }
> = (props) => {
  const showCommentPrompt =
    props.typedData.questionType !== QuestionType.FREE_TEXT &&
    props.comments.loadedQuestion.length;
  const commentButtonText = `${QUESTION_PAGE_COPY.view} ${props.comments.loadedQuestion.length} ${QUESTION_PAGE_COPY.comments}`;

  return (
    <div className="w-100">
      <div className="d-flex mb-3 align-items-center">
        {showCommentPrompt ? (
          <Btn
            action={() => {
              props.events.setInteraction(VotingInteraction.VIEWING_COMMENTS);
            }}
            ariaLabel={commentButtonText}
            className={`${baseClass}-button-view-comments mr-2`}
            type={ButtonTypes.SEAMLESS}
          >
            {commentButtonText}
          </Btn>
        ) : !props.comments.loadedQuestion.length &&
          props.typedData.questionType !== QuestionType.FREE_TEXT ? (
          <p className="mr-4">{QUESTION_PAGE_COPY.noComments}</p>
        ) : null}
        {(props.details.description || props.details.summary) && (
          <Btn
            action={() => props.setModalType(ModalType.MORE_INFO)}
            ariaLabel={QUESTION_PAGE_COPY.moreInfo}
            className={`${baseClass}-button-more-info`}
            type={ButtonTypes.SEAMLESS}
          >
            {QUESTION_PAGE_COPY.moreInfo}
          </Btn>
        )}
      </div>
      <VotingButtonsOrResults
        allowGuestRespondents={props.allowGuestRespondents}
        choices={props.choices}
        closed={props.closed}
        comments={props.comments}
        events={props.events}
        id={props.id}
        inProcessComment={props.inProcessComment ?? null}
        preLoginAction={props.preLoginAction}
        respId={props.respId}
        submitting={props.submitting}
        title={props.title}
        typedData={props.typedData}
      />
    </div>
  );
};

const VotingInteractionInner: React.FC<
  VotingProps.QuestionSetLoaded & {
    readonly submitting: boolean;
    readonly setModalType: (type: ModalType) => void;
  }
> = (props) => {
  switch (props.votingInteraction) {
    case VotingInteraction.VIEWING_COMMENTS:
      return (
        <Comments
          choices={props.choices}
          closed={props.closed}
          comments={props.comments}
          currentUserId={props.currentUserId}
          events={{
            upvoteComment: props.events.upvoteComment,
            unUpvoteComment: props.events.unUpvoteComment,
            createAccount: () => props.setModalType(ModalType.CREATE_ACCOUNT),
          }}
          id={props.id}
          isGuest={props.isGuest}
          typedData={props.typedData}
          upvoteCommentsOnVote={props.upvoteCommentsOnVote}
        />
      );
    case VotingInteraction.COMMENTING:
      return (
        <AddComment
          choices={props.choices}
          comment={props.typedData.previousVote?.comment ?? null}
          events={{
            cancel: props.events.cancelInteraction,
            submitComment: props.events.submitComment,
            setInteraction: props.events.setInteraction,
          }}
          previousVote={props.typedData.previousVote}
          questionId={props.id}
        />
      );
    default:
      return <Voting {...props} />;
  }
};

interface VotingModalsProps {
  readonly modalType: ModalType;
  readonly details: VotingProps.QuestionSetLoaded['details'];
  readonly events: {
    readonly cancel: () => void;
    readonly goToFeed: () => void;
    readonly onSuccessfulSignup: () => Promise<void>;
    readonly onSuccessfulGuestConversion: () => Promise<void>;
    readonly promptRegistration: () => void;
    readonly facebook: {
      readonly shareQuestion: () => void;
    };
    readonly twitter: {
      readonly shareQuestion: () => void;
    };
    readonly goToSet: () => void;
  };
  readonly respId: ClientRespondentId | null;
  readonly shareUrl: string;
  readonly publisherName: string;
}

const VotingModals: React.FC<VotingModalsProps> = (props) => {
  switch (props.modalType) {
    case ModalType.MORE_INFO:
      return (
        <MoreInfo cancel={props.events.cancel} details={props.details} isOpen />
      );
    case ModalType.SHARING:
      return (
        <ShareModal
          events={{
            facebook: {
              share: props.events.facebook.shareQuestion,
            },
            twitter: {
              share: props.events.twitter.shareQuestion,
            },
            cancel: props.events.cancel,
          }}
          isOpen
          url={props.shareUrl}
        />
      );
    case ModalType.CREATE_ACCOUNT_PRE_VOTE:
      return (
        <AuthnFlowSliderModal
          isOpen
          onAuthenticationSuccess={props.events.onSuccessfulSignup}
          onClose={props.events.cancel}
          registrationPrompt={{
            description: QUESTION_PAGE_COPY.submitVoting.description,
            image: (
              <div className="w-100 d-flex justify-content-center">
                <img alt="Polco logo" src={polcoLogo} />
              </div>
            ),
            title: QUESTION_PAGE_COPY.submitVoting.title,
          }}
        />
      );
    case ModalType.CONVERT_GUEST:
      return (
        <AuthnFlowSliderModal
          isOpen
          onAuthenticationSuccess={props.events.onSuccessfulGuestConversion}
          onClose={props.events.cancel}
          registrationPrompt={{
            description: QUESTION_PAGE_COPY.guestModalVoting.description(
              props.publisherName
            ),
            image: <MaterialIcon icon={MaterialIconName.CHECK_CIRCLE} />,
            title: QUESTION_PAGE_COPY.guestModalVoting.title,
          }}
        />
      );
    case ModalType.CREATE_ACCOUNT:
      return (
        <AuthnFlowSliderModal
          isOpen
          onAuthenticationSuccess={props.events.onSuccessfulGuestConversion}
          onClose={props.events.cancel}
          registrationPrompt={{
            description: QUESTION_PAGE_COPY.createAccountSlider.description,
            image: <MaterialIcon icon={MaterialIconName.ADD_COMMENT} />,
            title: QUESTION_PAGE_COPY.createAccountSlider.title,
          }}
        />
      );
    case ModalType.THANK_YOU:
      return (
        <ThankYouModal
          events={{
            cancel: () => {
              props.events.goToSet();
            },
          }}
          isOpen={true}
        />
      );
    case ModalType.VERIFICATION: {
      return (
        <VerificationModal
          action={props.events.goToFeed}
          isFeed={false}
          onClose={props.events.cancel}
        />
      );
    }
  }
};

export const VotingButtonsOrResults: React.FC<
  Pick<
    VotingProps.QuestionSetLoaded,
    | 'typedData'
    | 'inProcessComment'
    | 'choices'
    | 'id'
    | 'preLoginAction'
    | 'events'
    | 'allowGuestRespondents'
    | 'closed'
    | 'comments'
    | 'respId'
    | 'title'
  > & {
    readonly submitting: boolean;
  }
> = (props) => {
  if (props.closed || props.typedData.previousVote) {
    return <VotingResult {...props} animateComments minCommentsForWordCloud={3} />;
  } else {
    return (
      <VotingButtons
        allowGuestRespondents={props.allowGuestRespondents}
        choices={props.choices}
        events={{
          cancel: props.events.cancelInteraction,
          updateCommentText: props.events.updateCommentText,
          submit: props.events.submitVote,
          selectMultipleChoice: props.events.selectMultipleChoice,
          selectPointAllocation: props.events.selectPointAllocation,
          startInProcessVote: props.events.startInProcessVote,
        }}
        inProcessComment={props.inProcessComment ?? null}
        questionId={props.id}
        submitting={props.submitting}
        title={props.title}
        typedData={props.typedData}
      />
    );
  }
};

const determineBannerType = (args: {
  readonly closed: boolean;
  readonly voted: boolean;
  readonly isFreeTextType: boolean;
  readonly existingComments: boolean;
  readonly isGuest: boolean;
  readonly alreadyCommented: boolean;
  readonly votingInteraction: VotingInteraction | null;
  readonly respId: ClientRespondentId | null;
  readonly allowGuestRespondents: boolean;
}): BannerType => {
  const {
    closed,
    voted,
    isFreeTextType,
    alreadyCommented,
    existingComments,
    isGuest,
    votingInteraction,
    respId,
    allowGuestRespondents,
  } = args;

  if (closed) {
    return BannerType.CLOSED;
  } else if (
    !voted &&
    existingComments &&
    !isFreeTextType &&
    votingInteraction !== VotingInteraction.VIEWING_COMMENTS
  ) {
    return BannerType.VIEW_COMMENTS;
  } else if (
    voted &&
    !alreadyCommented &&
    !isGuest &&
    votingInteraction !== VotingInteraction.COMMENTING &&
    !isFreeTextType
  ) {
    return BannerType.COMMENT;
  } else if (isGuest && !isFreeTextType && !respId && allowGuestRespondents) {
    return BannerType.CREATE_ACCOUNT;
  } else {
    return BannerType.SHARE;
  }
};
