import { Case, unionOfEnum } from 'core';
import { SocialLoginType } from '../types';

export enum AnalyticsEventType {
  SELECTED_RESPONSE = 'SELECTED_RESPONSE',
  ATTEMPTED_SUBMIT_VOTE = 'ATTEMPTED_SUBMIT_VOTE',
  SIGNED_UP = 'SIGNED_UP',
  SIGNED_IN = 'SIGNED_IN',
  VIEWED_CONTENT = 'VIEWED_CONTENT',
  VIEWED_REGISTRATION = 'VIEWED_REGISTRATION',
  VIEWED_SIGN_UP = 'VIEWED_SIGN_UP',
  VIEWED_SIGN_IN = 'VIEWED_SIGN_IN',
}

export type AnalyticsViewEventType =
  | AnalyticsEventType.VIEWED_CONTENT
  | AnalyticsEventType.VIEWED_REGISTRATION
  | AnalyticsEventType.VIEWED_SIGN_UP
  | AnalyticsEventType.VIEWED_SIGN_IN;

export type AnalyticsEvent =
  | Case<AnalyticsEventType.VIEWED_CONTENT>
  | Case<AnalyticsEventType.VIEWED_REGISTRATION>
  | Case<AnalyticsEventType.VIEWED_SIGN_UP>
  | Case<AnalyticsEventType.VIEWED_SIGN_IN>
  | Case<
      AnalyticsEventType.SIGNED_UP,
      {
        readonly email: string | null;
        readonly loginType: SocialLoginType | 'EMAIL';
        readonly name: string | null;
        readonly user_id: string;
      }
    >
  | Case<
      AnalyticsEventType.SIGNED_IN,
      {
        readonly email: string | null;
        readonly loginType: SocialLoginType | 'EMAIL';
        readonly name: string | null;
        readonly user_id: string;
      }
    >
  | Case<AnalyticsEventType.SELECTED_RESPONSE>
  | Case<AnalyticsEventType.ATTEMPTED_SUBMIT_VOTE>;

export const AnalyticsEvent = unionOfEnum(AnalyticsEventType, {
  ...AnalyticsEventType,
}).andType<AnalyticsEvent>();

export function analyticNameFromUser(respondent: {
  readonly firstName: string | null;
  readonly lastName: string | null;
}): string | null {
  if (respondent.firstName && respondent.lastName) {
    return `${respondent.firstName} ${respondent.lastName}`;
  } else if (respondent.firstName) {
    return `${respondent.firstName}`;
  } else {
    // Including just the last name seems like it would be more confusing than useful
    return null;
  }
}
