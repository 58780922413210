import * as React from 'react';
import './styles.scss';
import { AppLink, Btn, ButtonTypes } from 'client/shared/components/base';
import locationLogo from 'client/assets/location-logo.png';
import { PRIVACY_URL } from 'client/shared/core/constants';

interface Props {
  readonly className?: string;
  readonly onContinue: () => void;
}

export const COPY = {
  subtitle: `Let's connect you to your neighborhood!`,
  description:
    'To provide the most accurate results, we need to know which area of the community you live in. Your location will NOT be shared with anyone.',
  description2:
    'This information will be reported in group-form with the collective results, allowing your local decision-makers to better understand and serve each distinct area of the community.',
  readOur: 'Read our',
  privacyPolicy: 'Privacy Policy',
  button: 'Continue',
};

export const baseClass = 'pn-why-we-need-location';

export const WhyWeNeedLocation: React.FC<Props> = (p) => {
  return (
    <div
      className={`${baseClass}-container d-flex flex-column p-3 align-items-center ${
        p.className ?? ''
      }`}
    >
      <img
        alt="Location logo"
        className={`${baseClass}-location-logo w-50 mb-4`}
        src={locationLogo}
      />
      <h4 className={`${baseClass}-subtitle font-weight-bold mb-4`}>
        {COPY.subtitle}
      </h4>
      <p className="w-100 mb-4">{COPY.description}</p>
      <p className="w-100 mb-4">{COPY.description2}</p>
      <span className="w-100 font-size-sm mb-4">
        {COPY.readOur}{' '}
        <AppLink target="_blank" to={PRIVACY_URL}>
          {COPY.privacyPolicy}
        </AppLink>
      </span>
      <Btn
        action={p.onContinue}
        ariaLabel={COPY.button}
        className={`${baseClass}-button btn-sm w-100 mt-3`}
        type={ButtonTypes.PRIMARY}
      >
        {COPY.button}
      </Btn>
    </div>
  );
};
