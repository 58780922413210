import * as React from 'react';
import { Modal } from 'client/shared/components/modal';
import './styles.scss';
import { EXIT_CONFIRMATION_COPY } from './copy';
import {
  ButtonTypes,
  Btn,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';

interface ExitConfirmationModalProps {
  readonly ariaLabelCloseButton: string;
  readonly ariaLabelLeaveButton: string;
  readonly events: {
    readonly leave: () => void;
    readonly cancel: () => void;
  };
  readonly isOpen: boolean;
}

const baseClass = 'pn-exit-confirmation-modal';

export const ExitConfirmationModal: React.FC<ExitConfirmationModalProps> = (
  props
) => {
  return (
    <Modal
      ariaLabelCloseButton={props.ariaLabelCloseButton}
      bodyClassName="m-3"
      className="d-flex justify-center align-items-center h-100"
      isCloseable
      isOpen={props.isOpen}
      onRequestClose={props.events.cancel}
      size="md"
    >
      <div className={`${baseClass}-content`}>
        <div className={`${baseClass}-icon my-4 text-center`}>
          <MaterialIcon icon={MaterialIconName.CANCEL_PRESENTATION} />
        </div>
        <h2
          className={`${baseClass}-title font-weight-bold text-gray-60 font-size-xl`}
        >
          {EXIT_CONFIRMATION_COPY.title}
        </h2>
        <div className="font-size-sm my-4">{EXIT_CONFIRMATION_COPY.subTitle}</div>
      </div>

      <div className="d-flex flex-column">
        <Btn
          action={props.events.leave}
          ariaLabel={props.ariaLabelLeaveButton}
          className="mb-3 w-100"
          type={ButtonTypes.PRIMARY}
        >
          {EXIT_CONFIRMATION_COPY.leave}
        </Btn>
        <Btn
          action={props.events.cancel}
          className="w-100"
          type={ButtonTypes.SECONDARY}
        >
          {EXIT_CONFIRMATION_COPY.cancel}
        </Btn>
      </div>
    </Modal>
  );
};

export function setupEventListeners(
  selectors: ReadonlyArray<string>,
  setSelectedLink: React.Dispatch<React.SetStateAction<string | null>>,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  currentContentIsDirty: boolean,
  confirmationModalIsOpen: boolean
) {
  const controller = new AbortController();
  const { signal } = controller;
  if (currentContentIsDirty) {
    // Attach a listener to each element based on selectors
    selectors.forEach((selector) => {
      const elements = document.getElementsByClassName(selector);
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i] as HTMLElement;
        const newListener = (event: MouseEvent) => {
          if (event.currentTarget) {
            event.preventDefault();
            const href = element.getAttribute('href');
            if (href) {
              setSelectedLink(href.replace('/n', ''));
              setIsOpen(true);
            }
          }
        };
        element.addEventListener('click', newListener, { signal });
      }
    });
    // Attach a listener to the window 'beforeunload' event
    const beforeUnloadListener = (event: BeforeUnloadEvent) =>
      onBeforeUnload({
        event,
        currentContentIsDirty: currentContentIsDirty,
        confirmationModalIsOpen: confirmationModalIsOpen,
      });
    window.addEventListener('beforeunload', beforeUnloadListener, { signal });
  } else {
    setSelectedLink(null);
  }
  return { onUnload: () => controller.abort() };
}

const onBeforeUnload = (props: {
  readonly event: BeforeUnloadEvent;
  readonly currentContentIsDirty: boolean;
  readonly confirmationModalIsOpen: boolean;
}) => {
  const { currentContentIsDirty, confirmationModalIsOpen, event } = props;
  if (!currentContentIsDirty || confirmationModalIsOpen) {
    return true;
  }
  event.preventDefault();
  event.returnValue = '';
};
