import {
  RegistrationErrors,
  SocialLoginMode,
  SocialLoginType,
  ConversionType,
} from 'client/respondent/core/types';
import { AnalyticsEvent } from 'client/respondent/core/analytics';
import {
  EventHandler,
  SocialLoginsContextInfo,
} from 'client/respondent/core/reducers/context';
import { MutationReturn } from 'client/shared/containers/mutation';
import { socialLogin } from 'client/respondent/shared/functions';
import { recordMouseFlow } from 'client/shared/integrations/mouseflow';
import { errorLogger } from 'client/shared/core/error-handler';
import {
  SocialLogin,
  SocialLoginVariables,
} from 'client/shared/graphql-client/graphql-operations.g';
import { ClientRespondentId } from 'client/respondent/core';

// will probably look at isFacebookBrowser() eventually,
// and other "external" dependencies
export function selectLoginMode(_provider: SocialLoginType): SocialLoginMode {
  return SocialLoginMode.CLIENT;
}

export async function socialLoginHandler(
  provider: SocialLoginType,
  logins: SocialLoginsContextInfo,
  socialLoginMut: MutationReturn<SocialLogin, SocialLoginVariables>,
  eventHandler: EventHandler,
  converted: (args: {
    readonly conversionType: ConversionType;
    readonly respondentId: ClientRespondentId;
  }) => Promise<any> | void,
  setRegistrationErrors: (error: RegistrationErrors) => void
) {
  const mode = selectLoginMode(provider);
  const fn = () => logins[provider].loginFn(mode);
  const ret = await socialLogin(socialLoginMut, fn);
  if (ret.type === 'SUCCESS') {
    eventHandler(
      ret.isNewUser
        ? AnalyticsEvent.SIGNED_UP({
            email: ret.email,
            loginType: provider,
            name: null,
            user_id: ret.userId,
          })
        : AnalyticsEvent.SIGNED_IN({
            email: ret.email,
            loginType: provider,
            name: null,
            user_id: ret.userId,
          })
    );
    const conversionType = ret.isNewUser
      ? ConversionType.SOCIAL_SIGNUP
      : ConversionType.SOCIAL_LOGIN;
    await converted({
      conversionType,
      respondentId: ret.respId,
    });
  } else if (ret.type === 'ERROR' || ret.type === 'FIELD_ERRORS') {
    setRegistrationErrors({ [provider]: 'ERROR' });
    recordMouseFlow({ tag: `${provider}-ERROR` });
    errorLogger.log(`Error at Login with: ${provider}`);
  }
}
