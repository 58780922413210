import * as React from 'react';
import './styles.scss';
import { RegisterOptions } from 'react-hook-form';
import {
  RegistrationErrors,
  RegistrationEvents,
  SocialLoginType,
} from 'client/respondent/core/types';
import { CommonRegistrationProps, RegistrationContainerProps } from '.';
import { MIN_PASSWORD_LENGTH, EMAIL_VALIDATION_REGEX } from 'core';
import {
  ActionLink,
  AppLink,
  SeparatorWithText,
} from 'client/shared/components/base';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { SocialSignupButton } from '../social';
import { useRespondentState } from 'client/respondent/core/reducers/context';
import { googleLogin, fbLogin } from '../../containers/social';
import { EmailLoginForm } from './email-login-form';
import { ACCESS_COPY as copy } from './copy';

const baseClass = 'pn-registration-login';

export namespace RegistrationEmailLogin {
  export interface Props extends CommonRegistrationProps {
    readonly events: {
      readonly emailLogin: RegistrationEvents['emailLogin'];
      readonly socialLogin: RegistrationEvents['socialLogin'];
      readonly converted: RegistrationContainerProps['converted'];
    };
    readonly registrationErrors: RegistrationErrors;
    readonly includeAdminRedirection?: boolean;
    readonly onClickSignupLink?: () => void;
  }

  export interface Fields {
    readonly email: string;
    readonly password: string;
  }

  type ValidationSet = Record<keyof Fields, RegisterOptions>;
  export const validations: ValidationSet = {
    email: {
      required: {
        value: true,
        message: copy.errorMessages.email.required,
      },
      pattern: {
        value: EMAIL_VALIDATION_REGEX,
        message: copy.errorMessages.email.pattern,
      },
    },
    password: {
      required: {
        value: true,
        message: copy.errorMessages.password.required,
      },
      minLength: {
        value: MIN_PASSWORD_LENGTH,
        message: copy.errorMessages.password.minLength,
      },
    },
  };
}

export const RegistrationEmailLoginForm: React.FC<RegistrationEmailLogin.Props> = (
  props
) => {
  const state = useRespondentState();

  return (
    <div className="h-100 bg-white">
      <div className={`${baseClass} p-4 mx-auto`}>
        <h1 className={`${baseClass}-title font-weight-bold font-size-xl mb-3`}>
          {copy.emailLogin.title}
        </h1>
        {props.includeAdminRedirection ? (
          <div
            className={`${baseClass}-admin-redirection mt-2 mb-4 font-size-sm d-flex flex-column`}
          >
            <span>{copy.emailLogin.adminNote}</span>
            <div className="mt-1">
              <AppLink
                className="text-jungle"
                to={ClientUrlUtils.admin.login.path()}
              >
                {copy.emailLogin.loginAsAdmin}
              </AppLink>
            </div>
          </div>
        ) : null}
        <div className="my-4">
          <EmailLoginForm
            converted={props.events.converted}
            emailLogin={props.events.emailLogin}
          />
        </div>

        <div className="my-3 text-center">
          <AppLink
            className={`${baseClass}-link text-jungle font-size-sm font-weight-bold`}
            to={ClientUrlUtils.respondent.resetPasswordRequest.path()}
            unstyled
          >
            {copy.actions.forgotPassword}
          </AppLink>
        </div>
        <SeparatorWithText text={copy.or} />
        <div className="mt-3">
          <SocialSignupButton
            action={() => googleLogin(props.events.socialLogin)}
            cta={copy.actions.googleSignin}
            disabled={!!props.registrationErrors.GOOGLE}
            loginType={SocialLoginType.GOOGLE}
          />
        </div>
        <div className="mt-3">
          <SocialSignupButton
            action={() => fbLogin(props.events.socialLogin)}
            cta={copy.actions.facebookSignin}
            disabled={!!props.registrationErrors.FACEBOOK}
            loginType={SocialLoginType.FACEBOOK}
          />
        </div>

        <div className="mt-4">
          {copy.noAccount}
          {props.onClickSignupLink ? (
            <ActionLink
              action={props.onClickSignupLink}
              aria-label="Go to signup"
              className="font-size-md ml-1 text-jungle font-weight-bold"
              underlineOnHover
            >
              {copy.actions.signUp}
            </ActionLink>
          ) : (
            <AppLink
              className={`${baseClass}-link ml-1 text-jungle font-size-m font-weight-bold`}
              to={
                state.isEmbed
                  ? ClientUrlUtils.embed.signup.path()
                  : ClientUrlUtils.respondent.signup.path()
              }
            >
              {copy.actions.signUp}
            </AppLink>
          )}
        </div>
      </div>
    </div>
  );
};
