import { AnalyticsEvent, AnalyticsViewEventType } from '../core/analytics';
import * as React from 'react';
import { RespondentAnalyticsContext } from '../core/reducers/context';
import { _dangerousUseEffectOnMount } from 'client/shared/helpers/hooks';

export type EventHandler = (event: AnalyticsEvent) => void;

export function useRespondentAnalyticsCallback() {
  return React.useContext(RespondentAnalyticsContext);
}

export function useRespondentAnalyticsViewEvent(viewType: AnalyticsViewEventType) {
  const cb = useRespondentAnalyticsCallback();
  _dangerousUseEffectOnMount(() => {
    cb({ type: viewType });
  });
}
