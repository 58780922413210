import * as PPropTypes from 'prop-types';

function checkPropValues<V>(
  typeSpecs: V,
  values: Partial<PPropTypes.InferProps<V>>,
  location?: string
): string | null {
  let error: string | null = null;
  const oldError = console.error;
  console.error = (e: any) => {
    console.info(e);
    error = e;
  };
  PPropTypes.checkPropTypes(
    typeSpecs,
    values,
    location ?? 'PRODUCTION',
    'production-prop-types'
  );
  console.error = oldError;
  return error;
}

function readonlyArrayOf<T>(
  type: PPropTypes.Validator<T>
): PPropTypes.Requireable<readonly T[]> {
  return PPropTypes.arrayOf(type);
}

function nullable<T>(type: PPropTypes.Validator<T>): PPropTypes.Validator<T | null> {
  // un-require, technically allows undefined too
  return PPropTypes.oneOfType([type]) as any;
}

function undefineable<T>(
  type: PPropTypes.Validator<T>
): PPropTypes.Validator<T | undefined> {
  // un-require, technically allows null too
  return PPropTypes.oneOfType([type]) as any;
}

const PropTypesWithCheckValues = {
  ...PPropTypes,
  checkPropValues,
  readonlyArrayOf,
  nullable,
  undefineable,
};

export default PropTypesWithCheckValues;
