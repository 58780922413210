export const QUESTION_SET_PAGE_COPY = {
  subHeader: 'Poll Set',
  questions: 'Polls',
  viewOutcome: 'View outcome',
  providedInput: (count: number) =>
    `Join ${count} other ${count === 1 ? 'person' : 'people'} who provided input`,
  actionTitleForPollSet: {
    COMPLETE: 'View Response',
    CLOSED: 'View Results',
    START: 'Start',
    NOT_STARTED: 'Go back',
  },
  actionTitleForPolcoLive: {
    COMPLETE: 'View Response',
    CLOSED: 'View Results',
    START: 'Join',
    NOT_STARTED: 'Go back', // From the design this should be 'Join' and then it transitions to 'Set Reminder', but for v1 we use one static text to avoid animation implementation.
  },
  eventEnded: 'The event ended',
  didNotStart: 'Event has not started yet',
};
