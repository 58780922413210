import * as React from 'react';
import './styles.scss';
import {
  Btn,
  MaterialIcon,
  MaterialIconName,
  ButtonTypes,
} from 'client/shared/components/base';
import { QUESTION_SET_PAGE_COPY } from './copy';
import { CSSTransition } from 'react-transition-group';
import { isColorDark } from 'client/respondent/core';
import { VotingProps } from 'client/respondent/core/types';
import { AM_PM_TIME, PARSE_DATE_FORMAT, RespondentsSetStatus, wrap } from 'core';
import moment from 'moment';
import { AddToCalendarDropdown } from '../add-to-calendar';
import { TermsAndPrivacy } from 'client/respondent/shared/components/terms-and-privacy';

export interface Props {
  readonly title: string;
  readonly setType: VotingProps.SetType;
  readonly numOfQuestions: number;
  readonly totalRespondents: number;
  readonly status: RespondentsSetStatus;
  readonly events: {
    readonly start: () => void | Promise<any>;
    readonly goToFeed: () => void;
    readonly goBack: () => void;
    readonly goToOutcome?: () => void;
  };
  readonly imageUrl: string | null;
  readonly backgroundColor: string | null;
  readonly eventDate?: Date;
  readonly eventLink?: string;
  readonly hasOutcomeAndClosed?: boolean;
}

interface ActionTitleProps {
  readonly title: string;
  readonly setType: VotingProps.SetType;
  readonly numOfQuestions: number;
  readonly startAnimation: boolean;
  readonly fontColor: string;
  readonly totalRespondents: number;
  readonly status: RespondentsSetStatus;
  readonly events: {
    readonly start: () => void | Promise<any>;
    readonly goToFeed: () => void;
  };
  readonly eventDate?: Date;
  readonly eventLink?: string;
  readonly hasOutcomeAndClosed?: boolean;
}

const baseClass = 'pn-voting-set-title-page';
const MIN_DATA_POINTS_FOR_ANALYTICS = 20;
const defaultColor = '#132d29';
const defaultImage =
  'https://res.cloudinary.com/polco-us/image/upload/v1591881178/feed/polco-colorful-quotes-vertical.svg';
const lightFontColor = 'white';
const darkFontColor = 'gray-60';

const POLL_SET_STATUS_TEXT: Record<RespondentsSetStatus, string> = {
  [RespondentsSetStatus.CLOSED]: QUESTION_SET_PAGE_COPY.actionTitleForPollSet.CLOSED,
  [RespondentsSetStatus.COMPLETE]:
    QUESTION_SET_PAGE_COPY.actionTitleForPollSet.COMPLETE,
  [RespondentsSetStatus.NOT_STARTED]:
    QUESTION_SET_PAGE_COPY.actionTitleForPollSet.NOT_STARTED,
  [RespondentsSetStatus.START]: QUESTION_SET_PAGE_COPY.actionTitleForPollSet.START,
};

const LIVE_EVENT_STATUS_TEXT: Record<RespondentsSetStatus, string> = {
  [RespondentsSetStatus.CLOSED]:
    QUESTION_SET_PAGE_COPY.actionTitleForPolcoLive.CLOSED,
  [RespondentsSetStatus.COMPLETE]:
    QUESTION_SET_PAGE_COPY.actionTitleForPolcoLive.COMPLETE,
  [RespondentsSetStatus.NOT_STARTED]:
    QUESTION_SET_PAGE_COPY.actionTitleForPolcoLive.NOT_STARTED,
  [RespondentsSetStatus.START]: QUESTION_SET_PAGE_COPY.actionTitleForPolcoLive.START,
};

export const Splash: React.FC<Props> = (props) => {
  const [startAnimation, setAnimationStart] = React.useState(false);
  const fontColor = isColorDark(props.backgroundColor ?? defaultColor)
    ? lightFontColor
    : darkFontColor;

  React.useEffect(() => {
    setAnimationStart(true);
  }, []);

  return (
    <div
      className={`${baseClass} d-flex w-100 vh-100 mx-auto flex-column`}
      style={{ backgroundColor: props.backgroundColor ?? defaultColor }}
    >
      <h1 className="d-none">
        {props.setType === VotingProps.SetType.POLL_SET
          ? 'Poll set page'
          : 'Live Event page'}
      </h1>
      <div className="position-relative h-100 d-flex flex-column">
        <div className="d-flex justify-content-end">
          <Btn
            action={props.events.goBack}
            ariaLabel="Close"
            className={`${baseClass}-close-btn mt-2 mr-2`}
            iconOnly
            size="large"
            type={ButtonTypes.SEAMLESS}
          >
            <MaterialIcon
              className={`text-${fontColor}`}
              icon={MaterialIconName.CLOSE}
            />
          </Btn>
        </div>
        <ActionTitle
          eventDate={props.eventDate}
          eventLink={props.eventLink}
          events={props.events}
          fontColor={fontColor}
          hasOutcomeAndClosed={props.hasOutcomeAndClosed}
          numOfQuestions={props.numOfQuestions}
          setType={props.setType}
          startAnimation={startAnimation}
          status={props.status}
          title={props.title}
          totalRespondents={props.totalRespondents}
        />
        <CSSTransition
          classNames={`${baseClass}-background-img`}
          in={startAnimation}
          timeout={2000}
        >
          <img
            alt=""
            className={`${baseClass}-background-img mx-auto`}
            src={props.imageUrl ?? defaultImage}
          />
        </CSSTransition>
        <div className={`${baseClass}-terms-and-privacy text-${fontColor}`}>
          <TermsAndPrivacy />
        </div>
      </div>
    </div>
  );
};

export const ActionTitle: React.FC<ActionTitleProps> = (props) => {
  const {
    title,
    setType,
    numOfQuestions,
    startAnimation,
    fontColor,
    totalRespondents,
    events,
    status,
    eventDate,
    eventLink,
    hasOutcomeAndClosed,
  } = props;
  const calendarEvent = eventDate
    ? {
        description: `Polco Live Event. ${
          eventLink ? `Join here - ${eventLink}` : ''
        }`,
        duration: 1,
        endDatetime: moment(eventDate).add(1, 'hour').format('YYYYMMDDTHHmmssZ'),
        location: 'Polco',
        startDatetime: moment(eventDate).format('YYYYMMDDTHHmmssZ'),
        title,
      }
    : null;

  const textAndButton = wrap(() => {
    switch (setType) {
      case VotingProps.SetType.POLL_SET:
        return (
          <div className={`${baseClass}-text text-${fontColor}`}>
            <p>
              {QUESTION_SET_PAGE_COPY.subHeader} ({numOfQuestions})
            </p>
            <div className="overflow-hidden">
              <CSSTransition
                classNames={`${baseClass}-title`}
                in={startAnimation}
                timeout={2000}
              >
                <h2 className="h4 font-weight-bold">{title}</h2>
              </CSSTransition>
            </div>
            <div className="d-flex pt-4 justify-content-between">
              <p className={`${baseClass}-copy-text pr-1`}>
                {totalRespondents > MIN_DATA_POINTS_FOR_ANALYTICS &&
                  QUESTION_SET_PAGE_COPY.providedInput(totalRespondents)}
              </p>

              <Btn
                action={() => events.start()}
                className={`${baseClass}-start-button`}
                type={ButtonTypes.PRIMARY}
              >
                {hasOutcomeAndClosed ? (
                  <div>
                    {QUESTION_SET_PAGE_COPY.viewOutcome}
                    <MaterialIcon icon={MaterialIconName.ARROW_FORWARD} />
                  </div>
                ) : (
                  <div>
                    {POLL_SET_STATUS_TEXT[status]}{' '}
                    <MaterialIcon icon={MaterialIconName.ARROW_FORWARD} />
                  </div>
                )}
              </Btn>
            </div>
          </div>
        );
      case VotingProps.SetType.POLCO_LIVE:
        return (
          <div className={`${baseClass}-text text-${fontColor}`}>
            {wrap(() => {
              switch (props.status) {
                case RespondentsSetStatus.START:
                  return (
                    <>
                      <div className="overflow-hidden">
                        <CSSTransition
                          classNames={`${baseClass}-title`}
                          in={startAnimation}
                          timeout={2000}
                        >
                          <h2 className="h4 font-weight-bold">{title}</h2>
                        </CSSTransition>
                      </div>
                      <div className="d-flex pt-4 justify-content-end">
                        <Btn
                          action={() => {
                            return events.start();
                          }}
                          className={`${baseClass}-start-button`}
                          type={ButtonTypes.PRIMARY}
                        >
                          <div>{LIVE_EVENT_STATUS_TEXT[status]}</div>
                        </Btn>
                      </div>
                    </>
                  );

                case RespondentsSetStatus.NOT_STARTED:
                  return (
                    <>
                      {eventDate && (
                        <div className="pb-3">
                          {dateToEventStartTimeFormat(eventDate)}
                        </div>
                      )}
                      <div className="overflow-hidden">
                        <CSSTransition
                          classNames={`${baseClass}-title`}
                          in={startAnimation}
                          timeout={2000}
                        >
                          <h2 className="h4 font-weight-bold">{title}</h2>
                        </CSSTransition>
                      </div>
                      <div className="d-flex pt-4 justify-content-between">
                        <p className={`${baseClass}-copy-text pr-1`}>
                          {QUESTION_SET_PAGE_COPY.didNotStart}
                        </p>

                        {calendarEvent ? (
                          <AddToCalendarDropdown event={calendarEvent} />
                        ) : (
                          <Btn
                            action={events.goToFeed}
                            className={`${baseClass}-start-button`}
                            type={ButtonTypes.PRIMARY}
                          >
                            <div>{LIVE_EVENT_STATUS_TEXT[status]}</div>
                          </Btn>
                        )}
                      </div>
                    </>
                  );
                case RespondentsSetStatus.CLOSED:
                  return (
                    <>
                      <div className="overflow-hidden">
                        <CSSTransition
                          classNames={`${baseClass}-title`}
                          in={startAnimation}
                          timeout={2000}
                        >
                          <h2 className="h4 font-weight-bold">{title}</h2>
                        </CSSTransition>
                      </div>
                      <div className="d-flex pt-4 justify-content-between">
                        <p className={`${baseClass}-copy-text pr-1`}>
                          {QUESTION_SET_PAGE_COPY.eventEnded}
                        </p>
                        <Btn
                          action={events.start}
                          className={`${baseClass}-start-button`}
                          type={ButtonTypes.PRIMARY}
                        >
                          {hasOutcomeAndClosed ? (
                            <div>
                              {QUESTION_SET_PAGE_COPY.viewOutcome}
                              <MaterialIcon icon={MaterialIconName.ARROW_FORWARD} />
                            </div>
                          ) : (
                            <div>{LIVE_EVENT_STATUS_TEXT[status]}</div>
                          )}
                        </Btn>
                      </div>
                    </>
                  );
              }
            })}
          </div>
        );
    }
  });
  return (
    <div className={`${baseClass}-action-container position-relative`}>
      {textAndButton}
    </div>
  );
};

function dateToEventStartTimeFormat(eventDate: Date): React.ReactElement {
  const dateText = (
    <span className="font-weight-bold">
      {moment(eventDate).format(PARSE_DATE_FORMAT)}
    </span>
  );

  const timeText = (
    <span className="font-weight-bold">{moment(eventDate).format(AM_PM_TIME)}</span>
  );

  return (
    <span>
      Starts on {dateText} at {timeText}
    </span>
  );
}
