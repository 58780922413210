import * as React from 'react';
import { useMutationInfo } from 'client/shared/containers/mutation';
import * as MutationInfos from 'client/shared/graphql-mutations/mutation-infos';
import {
  RegistrationErrors,
  SocialLoginType,
  RegistrationEvents,
  ConversionType,
} from 'client/respondent/core/types';
import {
  useRespondentAnalyticsCallback,
  useRespondentAnalyticsViewEvent,
  useSocialLogins,
} from 'client/respondent/hooks';
import { AnalyticsEventType } from 'client/respondent/core/analytics';
import { socialLoginHandler } from 'client/respondent/shared/account/containers/shared';
import { SocialSignupButton } from 'client/respondent/shared/account/components/social';
import { ClientRespondentId } from 'client/respondent/core';

export async function fbLogin(socialLogin: RegistrationEvents['socialLogin']) {
  return await socialLogin(SocialLoginType.FACEBOOK);
}

export async function googleLogin(socialLogin: RegistrationEvents['socialLogin']) {
  return await socialLogin(SocialLoginType.GOOGLE);
}

const socialCopy = {
  signUpWithGoogle: 'Sign up with Google',
  signUpWithFacebook: 'Sign up with Facebook',
  logInWithGoogle: 'Log in with Google',
  logInWithFacebook: 'Log in with Facebook',
};

export enum SignupType {
  SIGN_UP = 'SIGN_UP',
  LOG_IN = 'LOG_IN',
}

interface SocialSignupContainerProps {
  readonly context: SignupType;
  readonly converted: (args: {
    readonly conversionType: ConversionType;
    readonly respondentId: ClientRespondentId;
  }) => Promise<void>;
}

export const SocialSignupContainer: React.FC<SocialSignupContainerProps> = (
  props
) => {
  const [registrationErrors, setRegistrationErrors] =
    React.useState<RegistrationErrors>({
      [SocialLoginType.GOOGLE]: null,
      [SocialLoginType.FACEBOOK]: null,
    });
  const muts = {
    socialLogin: useMutationInfo(MutationInfos.socialLogin),
  };
  const eventHandler = useRespondentAnalyticsCallback();
  useRespondentAnalyticsViewEvent(AnalyticsEventType.VIEWED_REGISTRATION);
  const logins = useSocialLogins();

  const socialLogin = async (provider: SocialLoginType) => {
    return await socialLoginHandler(
      provider,
      logins,
      muts.socialLogin,
      eventHandler,
      props.converted,
      setRegistrationErrors
    );
  };

  return (
    <>
      <SocialSignupButton
        action={async () => await googleLogin(socialLogin)}
        cta={
          props.context === SignupType.SIGN_UP
            ? socialCopy.signUpWithGoogle
            : socialCopy.logInWithGoogle
        }
        disabled={!!registrationErrors.GOOGLE}
        loginType={SocialLoginType.GOOGLE}
      />
      <SocialSignupButton
        action={async () => await fbLogin(socialLogin)}
        cta={
          props.context === SignupType.SIGN_UP
            ? socialCopy.signUpWithFacebook
            : socialCopy.logInWithFacebook
        }
        disabled={!!registrationErrors.FACEBOOK}
        loginType={SocialLoginType.FACEBOOK}
      />
    </>
  );
};
