import * as React from 'react';
import { ErrorForbidden } from '../../../../shared/components/error-notice';
import { useMutationInfo } from 'client/shared/containers/mutation';
import * as MutationInfos from 'client/shared/graphql-mutations/mutation-infos';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { useRedirect } from 'client/shared/hooks';

export const copy = {
  body: 'You are currently logged in as a user with restricted access. Please sign out below to access the resident application.',
  button: 'Sign Out',
};

interface Props {
  readonly redirectPath?: string;
}

export const ErrorRestrictedAccess: React.FC<Props> = (p) => {
  const { fn: logoutFn } = useMutationInfo(MutationInfos.logout, {});
  const redirect = useRedirect();
  const onClickLogout = async () => {
    await logoutFn();
    redirect(p.redirectPath ?? ClientUrlUtils.respondent.feed.path(), {
      push: true,
    });
  };
  return (
    <ErrorForbidden
      bodyText={copy.body}
      continueButton={{ title: copy.button, action: onClickLogout }}
      hideContact={true}
    />
  );
};

ErrorRestrictedAccess.displayName = 'ErrorRestrictedAccess';
