import * as React from 'react';
import mapImage from './assets/no-entity-map.svg';
import { NO_GOV_CARD_COPY } from './copy';
import { ActionButton } from '../common';
import { ButtonTypes } from 'client/shared/components/base';
import { FeedCardBannerProps } from '../feed-card-banner';

import './styles.scss';

const baseClass = 'pn-no-gov-feed-card';

export const NoLocalGovFeedCard = (p: FeedCardBannerProps) => {
  return (
    <div className={`${baseClass} bg-white`}>
      <img
        alt=""
        className="w-100"
        height="230px"
        src={mapImage}
        style={{ objectFit: 'cover' }} //to match the height of other feed cards plus their header section
      />
      <div
        className={`${baseClass}-bottom d-flex flex-row justify-content-between align-items-end`}
      >
        <div className="w-66">
          <h2 className="pb-2 font-size-lg font-weight-bold">{p.postTitle}</h2>
          <p className="font-size-sm">{NO_GOV_CARD_COPY.desc}</p>
        </div>
        <div className="w-33">
          {p.primaryAction.disabled && p.primaryAction.content.type === 'LABEL' ? (
            <p className="text-gray-60 font-weight-bold font-size-sm">
              {p.primaryAction.content.label}
            </p>
          ) : (
            <ActionButton
              act={p.primaryAction}
              btnType={ButtonTypes.PRIMARY}
              key={p.primaryAction.key}
            />
          )}
        </div>
      </div>
    </div>
  );
};
