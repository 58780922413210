import * as React from 'react';
import { SearchPublisher } from '../../components/search-publisher';
import { usePublisherSubscriptionData } from 'client/respondent/hooks/use-respondent-subscription-info';
import { CurrentRespondentData } from 'client/respondent/hooks';

export const SearchPublisherContainer: React.FC<{
  readonly respondent?: CurrentRespondentData | null | undefined;
  readonly includeActions: boolean;
}> = (p) => {
  const sidebarData = usePublisherSubscriptionData(p.respondent?.id);

  return (
    <SearchPublisher
      event={sidebarData.events && p.includeActions ? sidebarData.events : null}
      isGuest={p.respondent?.guest}
      noAccount={!p.respondent?.id}
      searchablePublishers={[
        ...sidebarData.subscribablePubs,
        ...sidebarData.subscribedPubs,
      ]}
    />
  );
};
