import {
  optionalInit,
  fbInit,
  getIntegrationConfigKey,
  initTwitterPixel,
  initCloudinary,
  heapInit,
  initRollbar,
  initTinyMCE,
  nonceInit,
} from 'client/shared/integrations';
import { AnalyticsEvent } from '../core/analytics';
import { handleEvent as fbHandleEvent } from './facebook/events';
import { SocialLoginType } from '../core/types';
import { SocialLoginsContextProps } from 'client/respondent/core/reducers/context';
import { fbLogin } from './facebook/login';
import { googleLogin } from './google/login';
import { fbIsInitialized } from 'client/shared/integrations/facebook/core';
import { isGAuthInitialized } from 'client/shared/integrations/google/core';
// import { initAppcues } from 'client/shared/integrations/appcues';

export function integrationsInit() {
  optionalInit(
    fbInit,
    'facebookAppId',
    getIntegrationConfigKey('facebookRespondentPixelId')
  );
  initTwitterPixel();
  initTinyMCE(getIntegrationConfigKey('tinyApiKey'));

  optionalInit(initRollbar, 'rollbarClientToken');
  optionalInit(initCloudinary, 'cloudinaryUploadPreset');
  optionalInit(heapInit, 'heapAppId');
  nonceInit();
  // optionalInit(initAppcues, 'appcuesId');
}

export function handleEvent(event: AnalyticsEvent) {
  fbHandleEvent(event);
}

export const SocialLoginsImpl: SocialLoginsContextProps = {
  // Mock this for tests?
  [SocialLoginType.FACEBOOK]: {
    isProviderInitializedFn: fbIsInitialized,
    loginFn(mode) {
      return new Promise((res, rej) => fbLogin(mode, res, rej));
    },
  },
  [SocialLoginType.GOOGLE]: {
    isProviderInitializedFn: isGAuthInitialized,
    loginFn(mode) {
      return new Promise((res, rej) => googleLogin(mode, res, rej));
    },
  },
};
