import * as React from 'react';
import { defaultPadding } from '../util';
import { SavedSurveyItem, SurveyItemType } from 'client/shared/core/question';
import { HtmlContent } from 'client/shared/components/base';
import { TextWithLineBreak } from 'client/shared/components/text-with-line-break';
import { EmbedContext } from 'client/shared/contexts/embed-context';

export interface SurveyHeaderProps {
  readonly header: Extract<
    SavedSurveyItem,
    { readonly type: SurveyItemType.HEADER }
  >;
  readonly headerCount: number;
}
const baseClass = 'pn-survey-response';

export const SurveyHeader: React.FC<SurveyHeaderProps> = (props) => {
  const embedApp = React.useContext(EmbedContext);
  return (
    <div className={defaultPadding}>
      <div
        className={`${baseClass}-section-header-title font-italic font-weight-bold ${
          embedApp ? '' : 'pt-3'
        }`}
      >
        <TextWithLineBreak text={props.header.data.title} />
      </div>
      {props.header.data.descriptionHtml && (
        <HtmlContent
          html={props.header.data.descriptionHtml}
          imageAlt="description"
        />
      )}
    </div>
  );
};
