import { SUPPORT_EMAIL } from 'core';

export const SEARCH_PUBLISHER_COPY = {
  search: 'Search Publishers',
  searchPlaceholer: 'Search by publisher name',
  noResultTitle: "Sorry, we didn't find any results",
  noResultDesc1: 'If you are looking for a specific organization on Polco',
  notifyUs: 'notify us',
  noResultDesc2:
    'so we can make them aware that people like you would like to see them here.',
  accurateResult:
    'We only show 20 results at a time, please continue typing to see more accurate results',
  noAccount: 'Login to search publishers',
  modal: {
    title: 'Find your communities on Polco',
    description: 'Once you join Polco, you can search and follow communities.',
    secondaryButtonLabel: 'Account Setup',
  },
};

export const MAIL_TO_COPY = {
  email: SUPPORT_EMAIL,
  subject: 'Request organization: ',
  body: 'Organization name: ',
};
