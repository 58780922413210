import * as React from 'react';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: DOMStringList;
  readonly userChoice: Promise<{ readonly outcome: 'accepted' | 'dismissed' }>;
  readonly prompt: () => void;
}

let deferredPrompt: BeforeInstallPromptEvent | null = null;
// eslint-disable-next-line functional/prefer-readonly-type
let deferredPromptListeners: ((p: BeforeInstallPromptEvent) => void)[] = [];

export function getDeferredInstallPrompt(): BeforeInstallPromptEvent | null {
  return deferredPrompt;
}

window.addEventListener('beforeinstallprompt', function (e) {
  const evt = e as BeforeInstallPromptEvent;
  deferredPrompt = evt;
  evt.preventDefault();
  deferredPromptListeners.forEach((l) => l(evt));
  deferredPromptListeners = [];
});

export function useDeferredInstallPrompt(): BeforeInstallPromptEvent | null {
  const [prompt, setPrompt] = React.useState<BeforeInstallPromptEvent | null>(
    deferredPrompt
  );
  if (!prompt) {
    deferredPromptListeners.push(setPrompt);
  }
  return prompt;
}
