import * as React from 'react';
import {
  SavedSurveyItem,
  SurveyItemType,
  QuestionType,
} from 'client/shared/core/question';
import { VotingProps } from 'client/respondent/core/types';
import { wrap } from 'core';
import { FreeTextVote } from 'client/shared/components/free-text-vote';
import { MCVote } from 'client/shared/components/mc-vote';
import { PAVote } from 'client/shared/components/pa-vote';
import { GridVote, GridVoteMode } from 'client/shared/components/grid-vote';
import { defaultPadding } from '../util';
import './styles.scss';
import { SurveyLoadedEvents } from 'client/shared/core/types';

export interface SurveyQuestionResultsProps {
  readonly question: Extract<
    SavedSurveyItem,
    { readonly type: SurveyItemType.QUESTION }
  >;
  readonly questionCount: number;
  readonly events: SurveyLoadedEvents;
  readonly voted: boolean;
  readonly previousVote: VotingProps.Vote | null;
}
const baseClass = 'pn-survey-response';

export const SurveyQuestionResults: React.FC<SurveyQuestionResultsProps> = (
  props
) => {
  const { question, questionCount, events, voted, previousVote } = props;

  return (
    <div className={defaultPadding}>
      <div className={`${baseClass}-question-title w-100 bg-white py-2 h-auto`}>
        <div className="d-flex font-size-xs">
          <p className="pr-1">Question {questionCount}</p>
          <p className={`${baseClass}-required-text font-size-sm`}>
            {question.data.optional ? '' : '*'}
          </p>
        </div>

        <div
          className={`${baseClass}-question-title font-size-sm font-weight-bold w-100 bg-white py-2 h-auto`}
        >
          {question.data.title}
        </div>
      </div>
      {wrap(() => {
        switch (question.data.typedData.type) {
          case QuestionType.FREE_TEXT:
            return (
              <FreeTextVote
                disabled
                inProcessComment={previousVote?.comment?.comment ?? ''}
                updateCommentText={(comment) => {
                  events.surveyUpdateFreeTextComment(comment, question.data.id);
                }}
              />
            );
          case QuestionType.MULTIPLE_CHOICE:
            return (
              <MCVote
                choices={question.data.typedData.choices}
                disabled
                maxSelection={question.data.typedData.maxSelection}
                questionId={question.data.id}
                questionTitle={question.data.title}
                selectMultipleChoice={{
                  type: 'ACTION',
                  action: events.surveySelectMultipleChoice,
                }}
                selectedChoices={
                  voted && previousVote?.type === QuestionType.MULTIPLE_CHOICE
                    ? previousVote.choices
                    : []
                }
              />
            );
          case QuestionType.POINT_ALLOCATION:
            return (
              <PAVote
                choices={question.data.typedData.choices}
                disabled
                questionId={question.data.id}
                selectPointAllocation={events.surveySelectPointAllocation}
                selectedChoices={
                  voted && previousVote?.type === QuestionType.POINT_ALLOCATION
                    ? previousVote.choices
                    : question.data.typedData.choices.map((ch) => ({
                        id: ch.id,
                        point: 0,
                      }))
                }
              />
            );
          case QuestionType.GRID_CHOICE:
            return (
              <GridVote
                disabled
                gridChoice={question.data.typedData}
                gridVoteMode={GridVoteMode.DROPDOWN}
                hasInputError={false}
                questionId={question.data.id}
                required={!question.data.optional}
                selectGridChoice={events.surveySelectGridChoice}
                selectedGridChoice={
                  voted && previousVote?.type === QuestionType.GRID_CHOICE
                    ? previousVote.gridChoiceByRowId
                    : null
                }
                stickyTitle
              />
            );
        }
      })}
    </div>
  );
};
