export const ACTION_BUTTON_COPY = {
  comment: 'Comment',
  share: 'Share',
  skip: 'Skip',
  submit: 'Submit',
  next: 'Next',
  exit: 'Exit',
  answerPoll: 'Answer Poll',
  restartPoll: 'Restart Poll',
  done: 'Done',
};
