import {
  AnalyticsEvent,
  AnalyticsEventType,
} from 'client/respondent/core/analytics';
import { fbq } from 'client/shared/integrations';

export function handleEvent(event: AnalyticsEvent): boolean {
  switch (event.type) {
    case AnalyticsEventType.SIGNED_UP:
      fbq('track', 'CompleteRegistration', {
        email: event.email,
      });
      return true;
    case AnalyticsEventType.SIGNED_IN:
      fbq('trackCustom', 'CompleteSignin', {
        email: event.email,
      });
      return true;
    case AnalyticsEventType.SELECTED_RESPONSE:
      fbq('trackCustom', 'ResponseSelect');
      return true;
    case AnalyticsEventType.ATTEMPTED_SUBMIT_VOTE:
      fbq('trackCustom', 'SubmitResponse');
      return true;
    case AnalyticsEventType.VIEWED_CONTENT:
      fbq('trackCustom', 'ViewContent');
      return true;
    case AnalyticsEventType.VIEWED_REGISTRATION:
      fbq('trackCustom', 'ViewSignup');
      return true;
    case AnalyticsEventType.VIEWED_SIGN_UP:
      fbq('trackCustom', 'ViewSignup');
      return true;
    case AnalyticsEventType.VIEWED_SIGN_IN:
      fbq('trackCustom', 'ViewSignin');
      return true;
  }
}
