import * as React from 'react';
import { ErrorNotFound as ErrorNotFoundShared } from 'client/shared/components/error-notice';
import { ClientUrlUtils } from 'client/shared/core/helpers';

export const copy = {
  continue: 'Go to my feed',
  body: "It looks like you've tried to find a page that doesn't exist. Please use the top menu or the button below to navigate back to familiarity.",
};

export const ErrorNotFound: React.FC = () => {
  return (
    <ErrorNotFoundShared
      continueRoute={ClientUrlUtils.respondent.feed.path()}
      copy={copy}
    />
  );
};
