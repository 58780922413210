import * as React from 'react';
import './styles.scss';
import logo from 'client/assets/polco-logo-dark-text.svg';
import {
  MaterialIcon,
  MaterialIconName,
  ClickableDiv,
  AppLink,
} from 'client/shared/components/base';
import { ClientUrlUtils } from 'client/shared/core/helpers';

export interface Props {
  readonly events: {
    readonly openNotifications?: () => void;
    readonly openBookmarks?: () => void;
    readonly openMenu?: () => void;
  };
  readonly showSimulationLink?: boolean;
}

const baseClass = 'pn-top-nav';

export const TopNav = (p: Props) => {
  const openMenuEvent = p.events.openMenu;
  return (
    <header
      className={`${baseClass} pt-1 pb-2 border-bottom ${
        openMenuEvent ? 'text-center pl-2' : 'text-left px-4'
      } shadow-sm sticky-top`}
    >
      <div
        className={`${baseClass}-content d-flex ${
          p.showSimulationLink ? 'justify-content-between' : ''
        }`}
      >
        {openMenuEvent ? (
          <ClickableDiv action={() => openMenuEvent()}>
            <MaterialIcon
              className={`${baseClass}-menu-icon pl-2 pb-2`}
              icon={MaterialIconName.MENU}
            />
          </ClickableDiv>
        ) : null}
        <div className="flex-grow-1 pt-2 pb-2">
          <AppLink
            className={`${baseClass}-link ${openMenuEvent ? 'mod-center' : 'pl-2'}`}
            to={ClientUrlUtils.respondent.feed.path()}
          >
            <img
              alt="Polco Resident Homepage"
              className={`${baseClass}-img`}
              src={logo}
            />
          </AppLink>
        </div>
        {p.showSimulationLink && !p.events.openMenu ? (
          <div className="ml-auto py-2">
            <AppLink
              className="d-flex align-items-center text-decoration-none"
              to={ClientUrlUtils.respondent.feed.path()}
            >
              Exit Simulation{' '}
              <MaterialIcon className="px-2" icon={MaterialIconName.EXIT_TO_APP} />
            </AppLink>
          </div>
        ) : null}
      </div>
    </header>
  );
};
