import * as React from 'react';
import { VotingProps } from 'client/respondent/core/types';
import { QuestionType } from 'client/shared/core/question';
import { BarsResult } from './bars-result';
import { FreeTextResult } from './free-result';
import { RESULT_COPY } from './copy';

import './styles.scss';

export interface VotingResultProps {
  readonly choices: VotingProps.QuestionSetLoaded['choices'];
  readonly typedData: VotingProps.QuestionSetLoaded['typedData'];
  readonly comments: VotingProps.QuestionSetLoaded['comments'];
  readonly minCommentsForWordCloud: number;
  readonly animateComments: boolean;
}

export const VotingResult: React.FC<VotingResultProps> = (props) => {
  switch (props.typedData.questionType) {
    case QuestionType.POINT_ALLOCATION:
    case QuestionType.MULTIPLE_CHOICE:
      return (
        <BarsResult
          choices={props.choices}
          prevVote={props.typedData.previousVote}
          questionType={props.typedData.questionType}
        />
      );
    case QuestionType.FREE_TEXT:
      return (
        <FreeTextResult
          animate={props.animateComments}
          comments={props.comments}
          minCommentsForWordCloud={props.minCommentsForWordCloud}
          prevVote={props.typedData.previousVote}
        />
      );
    default:
      return <div>{RESULT_COPY.error}</div>;
  }
};
