import * as React from 'react';
import { TopNav as TopNavComponent } from '../../components/top-nav';
import { Sidebar, SideBarProps } from '../../components/sidebar';
import { CurrentUser } from 'client/respondent/hooks';

export const TopNav: React.FC<{
  readonly showMenu: boolean;
  readonly respondent: CurrentUser | null;
  readonly isGuest: boolean;
  readonly showSimulationLink?: boolean;
  readonly hideSideNavAuthButtons?: boolean;
}> = (p) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TopNavComponent
        events={{
          openMenu: p.showMenu ? () => setOpen(!open) : undefined,
        }}
        showSimulationLink={p.showSimulationLink}
      />
      {p.showMenu && (
        <Sidebar
          {...SideBarProps.TRANSIENT({
            open,
            close: () => setOpen(false),
            isGuest: p.isGuest,
            respondent: p.respondent,
            hideAuthButtons: p.hideSideNavAuthButtons,
          })}
        ></Sidebar>
      )}
    </>
  );
};
