import { getRespondentUser } from 'client/respondent/account/pages/access/helpers';
import { ClientRespondentId } from 'client/respondent/core';
import { useCurrentRespondent } from 'client/respondent/hooks';
import { AuthnFlowSliderModal } from 'client/respondent/shared/account/containers/authn-flow-slider-modal';
import {
  UpDownVoteButton,
  getVoteDiffs,
} from 'client/shared/components/vote-up-down-button';
import { VoteType } from 'client/shared/graphql-client/graphql-operations.g';
import { useDebounce } from 'client/shared/helpers/hooks';
import * as React from 'react';
export interface AuthnVoteSectionProps {
  readonly action: (
    voteType: VoteType,
    respondentId: ClientRespondentId
  ) => Promise<void>;
  readonly respondentVote?: VoteType;
  readonly voteCounts: {
    readonly up: number;
    readonly down: number;
  };
  readonly respId?: ClientRespondentId;
  readonly promptItems?: {
    readonly image: JSX.Element;
    readonly title: string;
    readonly description: string;
    readonly secondaryButtonLabel: string;
  };
}

export const AuthnVoteSection: React.FC<{
  readonly className: string;
  readonly props: AuthnVoteSectionProps;
}> = ({ className, props }) => {
  const [isAuthnSliderModalOpen, setIsAuthnSliderModalOpen] = React.useState(false);
  const [pendingVote, setPendingVote] = React.useState<{
    value: VoteType;
    voteCounts: {
      up: number;
      down: number;
    };
  } | null>(null);
  const currentRespondent = useCurrentRespondent();
  const isGuest = getRespondentUser(currentRespondent)?.respondent?.guest;

  async function onVote(vote: VoteType) {
    if (props.respId && !isGuest) {
      await props.action(vote, props.respId);
    } else {
      const { upVoteDiff, downVoteDiff } = getVoteDiffs({
        newVote: vote,
        previousVote: VoteType.NONE,
      });

      setPendingVote({
        value: vote,
        voteCounts: {
          up: props.voteCounts.up + upVoteDiff,
          down: props.voteCounts.down + downVoteDiff,
        },
      });
      setIsAuthnSliderModalOpen(true);
    }
  }
  const onAuthenticationSuccess = async (args: {
    readonly respondentId: ClientRespondentId;
  }) => {
    if (pendingVote) {
      await props.action(pendingVote.value, args.respondentId);
      setPendingVote(null);
    }
  };
  const onClose = () => {
    setIsAuthnSliderModalOpen(false);
    if (!voteButton.respId) {
      setPendingVote(null);
    }
  };
  const voteButton = {
    ...props,
    action: onVote,
  };
  const { promptItems } = voteButton;
  return (
    <div className={`${className}-authn-vote-section`}>
      <VoteSection
        className={`${className}-card-vote-button`}
        isGuest={isGuest}
        voteButton={voteButton}
      />
      {isAuthnSliderModalOpen && promptItems ? (
        <AuthnFlowSliderModal
          isOpen={isAuthnSliderModalOpen}
          onAuthenticationSuccess={onAuthenticationSuccess}
          onClose={onClose}
          registrationPrompt={{
            description: promptItems.description,
            image: promptItems.image,
            title: promptItems.title,
            secondaryButtonLabel: promptItems.secondaryButtonLabel,
            switchButtons: true,
          }}
        />
      ) : null}
    </div>
  );
};

AuthnVoteSection.displayName = AuthnVoteSection.name;

interface VoteButton {
  readonly action: (voteType: VoteType) => Promise<void>;
  readonly respondentVote?: VoteType;
  readonly voteCounts: {
    readonly up: number;
    readonly down: number;
  };
  readonly respId?: ClientRespondentId;
}

const VoteSection: React.FC<{
  readonly className: string;
  readonly isGuest?: boolean;
  readonly voteButton: VoteButton;
}> = ({ className, voteButton, isGuest }) => {
  const debouncedOnValueChanged = useDebounce(voteButton.action, 500);

  const { voteCounts, respondentVote, respId } = voteButton;

  return (
    <div className={`${className} mr-1`}>
      <UpDownVoteButton
        disableVoting={!respId || isGuest}
        downVoteCount={voteCounts.down}
        onValueChanged={debouncedOnValueChanged}
        upVoteCount={voteCounts.up}
        value={respondentVote ?? VoteType.NONE}
      />
    </div>
  );
};

VoteSection.displayName = VoteSection.name;
