import { useQueryInfo } from 'client/shared/containers/query';
import * as QueryInfos from 'client/shared/graphql-queries/query-infos';

interface UseNearbyLocationsProps {
  readonly zipCode: string | null;
}

export function useNearbyLocations(props: UseNearbyLocationsProps) {
  const { data, loading } = useQueryInfo(QueryInfos.currentUserNearbyLocations, {
    variables: {
      zipCode: props.zipCode,
    },
  });
  const zipCode = data?.currentUser.nearbyLocations?.zipCode ?? null;
  const nearbyLocations = data?.currentUser.nearbyLocations?.locations ?? [];
  return { loading, nearbyLocations, zipCode };
}
