import * as React from 'react';
import { WithLocationSelector } from '../containers/with-location-selector';
import { useTrackVariables } from 'client/shared/hooks/use-track-variables';
import { StatisticType } from 'core';

export const RentBurdenScorecard: React.FC = () => {
  return (
    <WithLocationSelector>
      {({ fipsCode, fipsName }) => {
        return (
          <>
            <h2 className="my-2">Rent Burden Scorecord for {fipsName}</h2>
            <div>Data for {fipsCode}</div>
            {fipsCode && <RentBurdenScorecardData fipsCode={fipsCode} />}
          </>
        );
      }}
    </WithLocationSelector>
  );
};

const RentBurdenScorecardData: React.FC<{ readonly fipsCode: string }> = (p) => {
  const trackVariables = useTrackVariables({
    fipsCodes: [p.fipsCode],
    variableNames: [
      'overall_homeless_rate_per_100k',
      'housing_burden_rate',
      'multifamily_pct',
      'housing_burden_owner_rate',
      'housing_overcrowding_rate',
      'median_home_value',
    ],
    type: StatisticType.INDICATOR,
    pubId: null,
  });

  return trackVariables.loading ? (
    <p>Loading data...</p>
  ) : (
    <p>
      Loaded data for {trackVariables.trackVariables.map((v) => v.label).join(', ')}
    </p>
  );
};
