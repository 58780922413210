import * as React from 'react';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import './styles.scss';
import { SlideProps } from '../../containers/authn-flow-slider-modal';

export const baseClass = 'pn-information-prompt';

const copy = {
  button: 'Continue',
  buttonAriaLabel: 'Go to the next page',
};

interface Props extends SlideProps {
  readonly isActiveSlide: boolean;
  readonly action: () => void;
}

export const InformationPrompt: React.FC<Props> = (p) => {
  return (
    <div className="px-3 pb-3">
      <h2
        className={`${baseClass}-title font-weight-bold text-jungle text-left py-1 mb-3`}
      >
        {p.name}
      </h2>
      <div className="font-size-sm py-1 mb-3">
        {p.subTitle && <p className="text-gray-60">{p.subTitle}</p>}
        {paragraphs(p.paragraphs, p.checkmark)}
        {p.privacy && (
          <span className={`${baseClass}-inline-check d-flex`}>
            <MaterialIcon icon={MaterialIconName.DONE} />
            <p className="text-gray-60">{p.privacy(p.isActiveSlide)}</p>
          </span>
        )}
      </div>
      <Btn
        action={p.action}
        ariaLabel={copy.buttonAriaLabel}
        className={`${baseClass}-button mt-4 w-100`}
        tabIndex={p.isActiveSlide ? 0 : -1}
        type={ButtonTypes.PRIMARY}
      >
        {p.buttons?.name || copy.button}
      </Btn>
    </div>
  );
};

InformationPrompt.displayName = 'InformationPrompt';

const paragraphs = (p: ReadonlyArray<JSX.Element>, checkmark?: boolean): any => {
  return p.map((paragraph, i) => {
    return checkmark ? (
      <div className={`${baseClass}-inline-check d-flex pb-3`} key={i}>
        <MaterialIcon icon={MaterialIconName.DONE} />
        <span className="text-gray-60 w-100">{paragraph}</span>
      </div>
    ) : (
      <div className="text-gray-60" key={i}>
        {paragraph}
      </div>
    );
  });
};
