import * as React from 'react';
import {
  SocialLoginData,
  SocialLoginMode,
  SocialLoginType,
  LocalStoragePropTypes,
  Type,
  CurrentQuestion,
} from 'client/respondent/core/types';
import { votingReducer } from 'client/respondent/core/reducers';
import { RespondentActions } from './actions';
import { AnalyticsEvent } from '../analytics';
import PropTypes from 'client/respondent/core/production-prop-types';
import { AnswerChangeability } from 'client/shared/core/types';
import { useLocalStorage } from 'client/shared/helpers/hooks';
import { onSocialProviderInitialization } from 'client/shared/integrations/util';
import { mapValues, pick } from 'lodash';

export type EventHandler = (event: AnalyticsEvent) => void;

export function questionChanged(
  oldQ: CurrentQuestion | null,
  newQ: CurrentQuestion | null
): boolean {
  if (!oldQ) {
    return !!newQ;
  } else if (!newQ) {
    return false;
  }
  return oldQ.id !== newQ.id || oldQ.status !== newQ.status;
}

export const initialState: Type = {
  answerChangeability: AnswerChangeability.CAN_CHANGE_COMMENT,
  upvoteCommentsOnVote: [],
  mostRecentContentSetThatPromptedConversion: null,
  showFullDetails: false,
  inProcessVote: null,
  inProcessComment: null,
  interaction: null,
  currentQuestion: null,
  surveyInProcessId: null,
  lastFeedLocation: null,
  preLoginAction: null,
  currentLocation: null,
  surveyItems: [],
  randomizedSurveyItems: null,
  questionMetadataSetId: null,
  questionMetadata: [],
  isEmbed: false,
  inProcessVotesByQuestionSet: {},
};

const RespondentStateContext = React.createContext<Type | undefined>(undefined);
const RespondentDispatchContext = React.createContext<
  React.Dispatch<RespondentActions.Types> | undefined
>(undefined);

export const RESPONDENT_STATE_IN_LOCAL_STORAGE = 'respondentState';

const RespondentAnalyticsContext = React.createContext<EventHandler>(() => {});

export type SocialLoginFunction = (
  mode: SocialLoginMode.CLIENT // Server-side social login is currently broken, so ensure we are always trying to use client mode
) => Promise<SocialLoginData>;

export interface SocialLoginEntry {
  readonly isProviderInitialized: boolean;
  readonly loginFn: SocialLoginFunction;
}

export type SocialLoginsContextProps = Record<
  SocialLoginType,
  Omit<SocialLoginEntry, 'isProviderInitialized'> & {
    readonly isProviderInitializedFn: () => boolean;
  }
>;

export type SocialLoginsContextInfo = Record<SocialLoginType, SocialLoginEntry>;

const defaultSocialLogins: SocialLoginsContextInfo = {
  [SocialLoginType.FACEBOOK]: {
    isProviderInitialized: false,
    loginFn() {
      throw 'default';
    },
  },
  [SocialLoginType.GOOGLE]: {
    isProviderInitialized: false,
    loginFn() {
      throw 'default';
    },
  },
};

export const SocialLoginContext =
  React.createContext<SocialLoginsContextInfo>(defaultSocialLogins);

const RespondentProvider: React.FC<{
  readonly handleEvent?: EventHandler;
  readonly socialLogins?: SocialLoginsContextProps;
  readonly isEmbed?: boolean;
}> = (props) => {
  const localStorage = useLocalStorage();
  const localState = getLocalState(localStorage);

  const [state, dispatch] = React.useReducer(votingReducer, {
    ...initialState,
    ...localState,
    isEmbed: props.isEmbed ?? initialState.isEmbed,
  });

  // Used to update state when social media providers initialize
  // Number does not have meaning, but we need value of state to change each time to trigger the re-render
  const [numberSocialIntegrationUpdates, setNumberSocialIntegrationUpdates] =
    React.useState<number>(0);

  React.useEffect(() => {
    onSocialProviderInitialization(() =>
      setNumberSocialIntegrationUpdates(numberSocialIntegrationUpdates + 1)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (localStorage) {
      localStorage.setItem(RESPONDENT_STATE_IN_LOCAL_STORAGE, JSON.stringify(state));
    }
  }, [state, localStorage]);

  const socialLoginsValue = props.socialLogins
    ? mapValues(props.socialLogins, (socialLogin) => ({
        ...pick(socialLogin, 'loginFn'),
        isProviderInitialized: socialLogin.isProviderInitializedFn(),
      }))
    : defaultSocialLogins;

  return (
    <RespondentStateContext.Provider value={state}>
      <RespondentDispatchContext.Provider value={dispatch}>
        <RespondentAnalyticsContext.Provider value={props.handleEvent ?? (() => {})}>
          <SocialLoginContext.Provider value={socialLoginsValue}>
            <div className="pn-respondent">{props.children}</div>
          </SocialLoginContext.Provider>
        </RespondentAnalyticsContext.Provider>
      </RespondentDispatchContext.Provider>
    </RespondentStateContext.Provider>
  );
};

function getLocalState(localStorage: Storage | null): Type | null {
  if (!localStorage) {
    return null;
  }
  const rawState = localStorage.getItem(RESPONDENT_STATE_IN_LOCAL_STORAGE);
  const localState: Type = rawState ? JSON.parse(rawState) : null;
  if (localState) {
    const localStoragePropTypes = LocalStoragePropTypes;
    const error = PropTypes.checkPropValues(
      localStoragePropTypes,
      localState as any
    );
    if (error) {
      localStorage.removeItem(RESPONDENT_STATE_IN_LOCAL_STORAGE);
      return null;
    }
  }
  return localState;
}

function useRespondentState() {
  const context = React.useContext(RespondentStateContext);
  if (context === undefined) {
    throw new Error('useRespondentState must be used within a RespondentProvider');
  }
  return context;
}
function useRespondentDispatch() {
  const context = React.useContext(RespondentDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useRespondentDispatch must be used within a RespondentProvider'
    );
  }
  return context;
}

export {
  useRespondentState,
  useRespondentDispatch,
  RespondentProvider,
  RespondentAnalyticsContext,
};
