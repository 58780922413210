import * as React from 'react';

export interface ScrollProps {
  readonly prevPos: Position;
  readonly currPos: Position;
}

interface Position {
  readonly x: number;
  readonly y: number;
}

interface UseScrollPositionProps {
  readonly effect: (props: ScrollProps) => void;
  readonly element?: React.MutableRefObject<HTMLElement | null>;
  readonly deps: React.DependencyList;
}

interface GetScrollPositionProps {
  readonly element?: React.MutableRefObject<HTMLElement | null>;
}

interface PositionInfo {
  readonly height: number;
  readonly percentage: number;
}

export function useScrollPosition(props: UseScrollPositionProps) {
  const { effect, element, deps } = props;
  const position = React.useRef(getScrollPosition({}));

  React.useLayoutEffect(() => {
    const callback = () => {
      const currPos = getScrollPosition({ element });
      effect({ prevPos: position.current, currPos });
      position.current = currPos;
    };
    window.addEventListener('scroll', callback);
    // without unmounting a event listener, we will have lots of events and they cause performance issue.
    return () => {
      window.removeEventListener('scroll', callback);
    };
  }, [effect, element, deps]);
}

function getScrollPosition(p: GetScrollPositionProps): Position {
  const { element } = p;
  const target = element ? element.current : document.body;
  if (target) {
    const position = target.getBoundingClientRect();
    return { x: position.left, y: position.top };
  }
  return { x: 0, y: 0 };
}

export function useScrollPositionInfo(): PositionInfo {
  const [scrollPositionInfo, setScrollPositionInfo] = React.useState({
    height: 0,
    percentage: 0,
  });

  useScrollPosition({
    effect: ({ currPos }) => {
      const maxScrollHeight =
        window.innerHeight - document.documentElement.scrollHeight;
      setScrollPositionInfo({
        height: currPos.y,
        percentage: Math.min((currPos.y / maxScrollHeight) * 100, 100),
      });
    },
    deps: [scrollPositionInfo],
  });
  return scrollPositionInfo;
}
