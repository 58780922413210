import * as React from 'react';
import { ADD_COMMENT_COPY } from './copy';
import { ButtonTypes, Btn, Well, WellType } from 'client/shared/components/base';
import { Form } from 'react-bootstrap';
import { useForm, RegisterOptions } from 'react-hook-form';
import { VotingProps } from 'client/respondent/core/types';
import {
  ClientQuestionId,
  QuestionType,
  VotingChoice,
} from 'client/shared/core/question';
import { LoadedEvents, RBRef, VotingInteraction } from 'client/shared/core/types';
import './styles.scss';
import { previousVoteToChoiceText } from 'client/respondent/core';
import { useId } from 'react-use-id-hook';

export interface AddCommentProps {
  readonly events: {
    readonly cancel: LoadedEvents['cancelInteraction'];
    readonly submitComment: LoadedEvents['submitComment'];
    readonly setInteraction: LoadedEvents['setInteraction'];
  };
  readonly comment: VotingProps.VoteComment | null;
  readonly questionId: ClientQuestionId;
  readonly previousVote: VotingProps.QuestionSetVote | null;
  readonly choices: readonly VotingChoice[];
}

interface FormData {
  readonly comment: string;
}

export const AddComment: React.FC<AddCommentProps> = (props) => {
  const { events } = props;
  const { register, handleSubmit, errors } = useForm<FormData>({});

  const createRef = (opts: RegisterOptions): RBRef => register(opts) as RBRef;

  const onSubmit = async (
    data: FormData,
    evt: React.BaseSyntheticEvent | undefined
  ) => {
    evt?.preventDefault();
    events.submitComment(data.comment, props.questionId);
    events.setInteraction(VotingInteraction.VIEWING_COMMENTS);
  };

  const responseChoices =
    props.previousVote?.type !== QuestionType.MULTIPLE_CHOICE &&
    props.previousVote?.type !== QuestionType.POINT_ALLOCATION
      ? null
      : previousVoteToChoiceText(props.previousVote, props.choices);

  const canAddComment = responseChoices && responseChoices.length > 0;
  const addCommentControlId = useId();
  const addCommentFeedbackId = useId();
  const isCommentInvalid = !!props.comment;

  return (
    <div className="w-100 mt-5">
      {!canAddComment ? (
        <>
          <Well ariaLive="polite" type={WellType.ERROR}>
            {ADD_COMMENT_COPY.respondBeforeCommenting}
          </Well>
          <Btn
            action={props.events.cancel}
            className="font-size-sm p-2 px-4 mx-1"
            type={ButtonTypes.SECONDARY}
          >
            {ADD_COMMENT_COPY.respond}
          </Btn>
        </>
      ) : (
        <>
          <div className="mb-3">
            {ADD_COMMENT_COPY.youResponded}{' '}
            {responseChoices?.map((ch, index, array) => (
              <span className="font-italic font-weight-bold text-rose-d" key={ch}>
                {ch}
                {index !== array.length - 1 && ', '}
              </span>
            ))}
          </div>
          <Form className="w-100">
            <Form.Label className="d-none" htmlFor={addCommentControlId}>
              {ADD_COMMENT_COPY.headerTitle}
            </Form.Label>
            <Form.Control
              aria-errormessage={isCommentInvalid ? addCommentFeedbackId : undefined}
              aria-invalid={isCommentInvalid}
              aria-label={ADD_COMMENT_COPY.headerTitle}
              as="textarea"
              className={`${
                !!errors.comment ? 'has-error' : 'bg-gray-5 border-gray-10'
              } accessible-input`}
              disabled={!!props.comment}
              id={addCommentControlId}
              isInvalid={isCommentInvalid}
              name="comment"
              placeholder={ADD_COMMENT_COPY.placeholder}
              ref={createRef({
                required: true,
              })}
              required
              rows={5}
              value={props.comment?.comment}
            />
            {!!errors.comment && (
              <span
                className="pn-add-comment-warning-text font-size-xs"
                id={addCommentFeedbackId}
                role="alert"
              >
                {ADD_COMMENT_COPY.errorMessage}
              </span>
            )}
            <div className="d-flex justify-content-end my-2 mt-4">
              <Btn
                action={props.events.cancel}
                className="font-size-sm p-2 px-4 mx-1"
                type={ButtonTypes.SECONDARY}
              >
                {ADD_COMMENT_COPY.cancel}
              </Btn>
              {!props.comment ? (
                <Btn
                  action={handleSubmit(onSubmit)}
                  className="font-size-sm border-gray-10 p-2 px-4 mx-1"
                  disabled={!!props.comment || !responseChoices}
                  type={ButtonTypes.PRIMARY}
                >
                  {ADD_COMMENT_COPY.submit}
                </Btn>
              ) : (
                <Btn
                  action={() =>
                    props.events.setInteraction(VotingInteraction.VIEWING_COMMENTS)
                  }
                  className="font-size-sm border-gray-10 p-2 px-4 mx-1"
                  type={ButtonTypes.PRIMARY}
                >
                  {ADD_COMMENT_COPY.viewComments}
                </Btn>
              )}
            </div>
          </Form>
        </>
      )}
    </div>
  );
};
