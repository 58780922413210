import * as React from 'react';
import { useQueryInfo } from 'client/shared/containers/query';
import { QueryInfos } from 'client/shared/graphql-queries';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Splash } from '../../components/splash';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { useLanguageByQueryParam, useRedirect } from 'client/shared/hooks';
import * as RespondentContext from 'client/respondent/core/reducers/context';
import { SurveyVoting } from 'client/respondent/voting/survey/containers/survey-voting';
import { convertToSurveyProps } from 'client/respondent/voting/shared/functions';
import { MainPage } from 'client/respondent/shared/pages/main-page';
import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';
import { SurveyVotingMutationReturns } from 'client/shared/graphql-mutations/mutation-infos';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { CurrentUser } from 'client/respondent/hooks';
import { VotingProps } from 'client/respondent/core/types';
import {
  QuestionStatus,
  RespondentVotingPage,
} from 'client/shared/graphql-client/graphql-operations.g';
import {
  ApolloErrorMessage,
  ExtractGql,
  QuestionSetType,
  RespondentsSetStatus,
} from 'core';
import { EmbedContext } from 'client/shared/contexts/embed-context';
import { EmbedLogoFooter } from 'client/shared/components/embed-logo-footer';
import { Helmet } from 'react-helmet';
import { CustomVotingPageWrapper } from 'client/respondent/voting/custom/containers/custom-voting-page-wrapper';
type SetForRespondentVote = ExtractGql<
  NonNullable<RespondentVotingPage['openContentSetBySlug']>,
  'PolcoLive'
>['setForRespondentVote'];

export interface SetRouteSlugs {
  readonly pubSlug?: string;
  readonly setSlug: string;
}

export type Props = RouteComponentProps<SetRouteSlugs>;
export const VotingPage: React.FC<Props> = (p) => {
  const [hideRightSidebar, setHideRightSidebar] = React.useState(false);
  const [showTermsAndPrivacyMobile, setShowTermsAndPrivacyMobile] =
    React.useState(false);

  const redirect = useRedirect();
  const goBack =
    p.history.length > 1
      ? p.history.goBack
      : () => {
          redirect(ClientUrlUtils.respondent.feed.path(), { push: false });
        };
  const goToFeed = () =>
    redirect(ClientUrlUtils.respondent.feed.path(), {
      push: true,
    });
  return (
    <MainPage
      hideRightSidebar={hideRightSidebar}
      hideTopNav={true}
      showTermsAndPrivacyDesktop
      showTermsAndPrivacyMobile={showTermsAndPrivacyMobile}
    >
      {(respondentUser, showingSidebars) => {
        return (
          <CustomVotingPageWrapper slugs={p.match.params}>
            <VotingPageInner
              animateDirection={showingSidebars ? 'slideInUp' : 'slideInRight'}
              curUser={respondentUser}
              goBack={goBack}
              goToFeed={goToFeed}
              setHideRightSidebar={setHideRightSidebar}
              setShowTermsAndPrivacyMobile={setShowTermsAndPrivacyMobile}
              showingSidebars={showingSidebars}
              slugs={p.match.params}
            />
          </CustomVotingPageWrapper>
        );
      }}
    </MainPage>
  );
};
VotingPage.displayName = 'VotingPage';

export const VotingPageInner: React.FC<{
  readonly slugs: SetRouteSlugs;
  readonly curUser: CurrentUser | null;
  readonly animateDirection: 'slideInRight' | 'slideInUp';
  readonly goBack: () => void;
  readonly goToFeed: () => void;
  readonly showingSidebars: boolean;
  readonly setHideRightSidebar: (hide: boolean) => void;
  readonly setShowTermsAndPrivacyMobile: (show: boolean) => void;
}> = (p) => {
  const resp = p.curUser?.user?.respondent ?? null;
  const respId = resp?.id ?? null;
  const embedApp = React.useContext(EmbedContext);
  const setSlug = p.slugs.setSlug;
  const pubSlug = p.slugs.pubSlug ?? 'by-id'; // not actually used, but helps clarify the code here
  const query = useQueryInfo(QueryInfos.respondentVotingPage, {
    variables: {
      questionSetSlug: setSlug,
      publishingEntitySlug: pubSlug,
      respondentId: respId,
    },
    errorPolicy: 'all',
  });

  const goToOutcome = () =>
    redirect(
      ClientUrlUtils.respondent.setOutcome.path({
        pubSlug: pubSlug,
        setIdOrSlug: setSlug,
      }),
      {
        push: true,
      }
    );

  const surveyMuts = useSurveyMutations(respId, setSlug, pubSlug);

  const state = RespondentContext.useRespondentState();
  const dispatch = RespondentContext.useRespondentDispatch();
  const redirect = useRedirect();

  const isSurvey =
    query.data?.openContentSetBySlug?.__typename === 'Survey' ||
    query.data?.openContentSetBySlug?.__typename === 'ContentPost';
  const { setHideRightSidebar, setShowTermsAndPrivacyMobile } = p;
  React.useEffect(() => {
    setHideRightSidebar(isSurvey);
    setShowTermsAndPrivacyMobile(isSurvey);
  }, [isSurvey, setHideRightSidebar]);
  const { selectLanguageText } = useLanguageByQueryParam();

  if (query.loading && !query.data) {
    return <RespondentPageLoader />;
  }

  if (query.error && query.error.graphQLErrors.length > 0) {
    const isUnauthorizeError =
      query.error.graphQLErrors[0].message === ApolloErrorMessage.UNAUTHORIZED;
    if (isUnauthorizeError) {
      return <Redirect to={'/res/not-available'} />;
    }
  }

  // Survey Voting Case
  if (
    query.data?.openContentSetBySlug?.__typename === 'Survey' ||
    query.data?.openContentSetBySlug?.__typename === 'ContentPost'
  ) {
    const props = convertToSurveyProps(
      state,
      dispatch,
      query,
      surveyMuts,
      redirect,
      {
        id: query.data.openContentSetBySlug.id,
        slug: query.data.openContentSetBySlug.slug,
        pubSlug: query.data.openContentSetBySlug.publishingEntity.slug,
      },
      resp,
      p.goBack,
      p.goToFeed,
      p.showingSidebars,
      query.data.contextPublishingEntity?.id ?? null,
      selectLanguageText
    );

    return (
      <>
        <SurveyVoting {...props} />
        {embedApp && <EmbedLogoFooter className="pb-3 pt-2" />}
      </>
    );
  }

  // Question Set (Poll Set) Voting Case
  if (query.data?.openContentSetBySlug?.__typename === 'QuestionSet') {
    const questionSetData = query.data.openContentSetBySlug.setForRespondentVote;
    const { totalRespondents } = query.data.openContentSetBySlug;
    const numOfQuestions = questionSetData.questions.length ?? 0;
    const image = query.data.openContentSetBySlug.image;
    const hasOutcomeAndClosed =
      query.data.openContentSetBySlug.outcome !== null &&
      query.data.openContentSetBySlug.setForRespondentVote.status ===
        RespondentsSetStatus.CLOSED;

    if (numOfQuestions > 0) {
      return (
        <>
          <Helmet>
            <title>
              {selectLanguageText(query.data.openContentSetBySlug.name)} -{' '}
              {selectLanguageText(
                query.data.openContentSetBySlug.publishingEntity.name
              )}{' '}
              - Polco
            </title>
          </Helmet>
          <Splash
            backgroundColor={image?.backgroundColor ?? null}
            events={{
              start: () => {
                if (hasOutcomeAndClosed) {
                  goToOutcome();
                } else {
                  const targetQuestion =
                    selectStartingQuestionForPollSet(questionSetData);
                  redirect(
                    ClientUrlUtils.respondent.question.path({
                      questionId: targetQuestion.id,
                      setIdOrSlug: setSlug,
                      pubSlug,
                      setType: QuestionSetType.SET,
                    }),
                    {
                      push: true,
                    }
                  );
                }
              },
              goToFeed: p.goToFeed,
              goToOutcome: goToOutcome,
              goBack: p.goBack,
            }}
            hasOutcomeAndClosed={hasOutcomeAndClosed}
            imageUrl={image?.verticalUrl ?? null}
            numOfQuestions={numOfQuestions}
            setType={VotingProps.SetType.POLL_SET}
            status={questionSetData.status}
            title={selectLanguageText(query.data.openContentSetBySlug.name)}
            totalRespondents={totalRespondents ?? 0}
          />
        </>
      );
    }
  }

  // Note: we don't handle polco live case here
  // redirect to polco live page to handle
  if (query.data?.openContentSetBySlug?.__typename === 'PolcoLive') {
    return (
      <Redirect
        to={ClientUrlUtils.respondent.set.path({
          pubSlug,
          setIdOrSlug: setSlug,
          setType: QuestionSetType.POLCO_LIVE,
        })}
      />
    );
  }

  return <Redirect to={'/res/not-found'} />;
};

function selectStartingQuestionForPollSet(questionSetData: SetForRespondentVote) {
  const firstQuestion = questionSetData.questions[0];
  const targetQuestion =
    questionSetData.questions.find(
      (q) => q.schedule.status === QuestionStatus.PUBLISHED && !q.previousVote
    ) ?? firstQuestion;
  return targetQuestion;
}

function useSurveyMutations(
  respId: string | null,
  setSlug: string,
  pubSlug: string
): SurveyVotingMutationReturns {
  // If we don't have a user, voting might create one, so we need to check.
  const refetchUser = respId ? [] : [QueryInfos.currentRespondent.refetchInfo({})];

  const refetchQueries = [
    QueryInfos.respondentVotingPage.refetchInfo({
      respondentId: respId,
      questionSetSlug: setSlug,
      publishingEntitySlug: pubSlug,
    }),
    ...refetchUser,
  ];

  const surveyMuts: SurveyVotingMutationReturns = {
    voteForSurvey: useMutationInfo(MutationInfos.voteForSurvey, {
      refetchQueries,
    }),
    logout: useMutationInfo(MutationInfos.logout),
    followOnVoting: useMutationInfo(MutationInfos.followPublishingEntity, {
      refetchQueries: [
        QueryInfos.respondentSubscriptions.refetchInfo({
          respondentId: respId ?? 'never',
        }),
      ],
    }),
  };
  return surveyMuts;
}
