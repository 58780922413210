import * as React from 'react';
import { MaterialIcon, MaterialIconName } from 'client/shared/components/base';
import { QuestionType, VotingChoice } from 'client/shared/core/question';
import { loadingBarHexColorsAccessible } from 'client/shared/core/colors';
import { voteIncludesChoice } from 'client/respondent/core';
import { VotingProps } from 'client/respondent/core/types';
import { HorizontalBar } from 'client/shared/components/data-viz-d3/horizontal-bar';
import './styles.scss';

const baseClass = 'pn-bars-results-vote';

interface Props {
  readonly choices: readonly VotingChoice[];
  readonly prevVote:
    | VotingProps.Vote_MultipleChoice
    | VotingProps.Vote_PointAllocation
    | null;
  readonly questionType:
    | QuestionType.MULTIPLE_CHOICE
    | QuestionType.POINT_ALLOCATION;
}

export const BarsResult: React.FC<Props> = (props) => {
  return (
    <div className={`${baseClass}`}>
      {props.choices.map(({ choice, result }: VotingChoice, i: number) => {
        const percentage = result * 100;
        const isUserVote = voteIncludesChoice(
          props.questionType,
          props.prevVote,
          props.choices,
          choice.id
        );
        return (
          <div key={choice.id}>
            <div
              className={`${baseClass}-choice text-left w-100 ${baseClass}-choice-submitting pb-4`}
            >
              <HorizontalBar
                barColor={loadingBarHexColorsAccessible[i]}
                percentage={percentage}
                showGrid={false}
                showPercent
              />
              <div className={`pl-2 d-flex flex-row align-items-center`}>
                {isUserVote && (
                  <MaterialIcon
                    ariaLabel="Your answer"
                    className={`${baseClass}-icon-small pt-1 mr-2`}
                    icon={MaterialIconName.CHECK_CIRCLE}
                  />
                )}
                <p className={`${isUserVote ? 'font-weight-bold' : ''}`}>
                  {choice.text} - {percentage.toFixed()}%
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
