import { Case } from 'core';
import { ButtonTypes, BtnLink, Btn, AppLink } from 'client/shared/components/base';
import * as React from 'react';
import { handlePressEnter } from 'client/shared/core/helpers';
import { FeedCardBannerProps } from './feed-card-banner';
import { FeedCardHeaderProps } from './feed-card-header';

export type LinkAction = Case<'LINK', { readonly url: string }>;

export interface FeedCardProps {
  readonly header: FeedCardHeaderProps;
  readonly banner: FeedCardBannerProps;
}

export interface BannerAction {
  readonly key: string;
  readonly disabled?: boolean;
  readonly content:
    | Case<'ICON', { readonly icon: string; readonly alt: string }>
    | Case<'LABEL', { readonly label: string; readonly active?: boolean }>;
  readonly action:
    | LinkAction
    | Case<'ACTION', { readonly action: () => Promise<any> | void }>;
}

export function ActionButton(p: {
  readonly ariaLabel?: string;
  readonly act: BannerAction;
  readonly btnType: ButtonTypes;
}) {
  const { ariaLabel, act, btnType } = p;
  const content =
    act.content.type === 'ICON' ? (
      <img alt={act.content.alt} src={act.content.icon} />
    ) : (
      act.content.label
    );
  switch (act.action.type) {
    case 'LINK':
      return (
        <BtnLink
          className="py-1 ml-1"
          disabled={act.disabled}
          to={act.action.url}
          type={btnType}
        >
          {content}
        </BtnLink>
      );
    case 'ACTION':
      const cb = act.action.action;
      return (
        <Btn
          action={cb}
          ariaLabel={ariaLabel}
          className={`py-1 ml-1 border border-light ${
            btnType === ButtonTypes.SECONDARY ? 'text-dark' : ''
          }`}
          disabled={act.disabled}
          type={btnType}
        >
          {content}
        </Btn>
      );
  }
}

export const PrimaryActionWrapper: React.FC<{
  readonly className?: string;
  readonly action: BannerAction['action'];
}> = (p) => {
  switch (p.action.type) {
    case 'LINK':
      return (
        <AppLink className={p.className} to={p.action.url}>
          {p.children}
        </AppLink>
      );
    case 'ACTION':
      const act = p.action;
      return (
        <div
          className={p.className}
          onClick={() => act.action()}
          onKeyUp={handlePressEnter(() => act.action())}
          role="button"
          tabIndex={0}
        >
          {p.children}
        </div>
      );
  }
};
