import * as React from 'react';
import './styles.scss';
import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';
import { Dropdown } from 'client/shared/components/base/dropdown_v2';
import { TextInput } from 'client/shared/components/base/text-input';
import { Btn, ButtonTypes } from 'client/shared/components/base/btn';
import { useNearbyLocations } from '../../hooks/use-nearby-locations';

interface WithLocationSelectorProps {
  readonly children: (data: WithLocationSelectorChildrenProps) => JSX.Element | null;
}

interface WithLocationSelectorChildrenProps {
  readonly fipsCode: string | null;
  readonly fipsName: string | null;
}

const baseClass = 'pn-with-location-selector';

export const WithLocationSelector: React.FC<WithLocationSelectorProps> = (p) => {
  const [zipCode, setZipCode] = React.useState<string | null>(null);
  const [inProgressZipCode, setInProgressZipCode] = React.useState<string | null>(
    null
  );
  const [selectedFipsCode, setSelectedFipsCode] = React.useState<string | null>(
    null
  );

  const updateInProgressZipCode = React.useCallback((updatedZipCode: string) => {
    const useZipCode = updatedZipCode.slice(0, 5);
    setInProgressZipCode(useZipCode);
  }, []);
  const {
    nearbyLocations,
    zipCode: queryZipCode,
    loading,
  } = useNearbyLocations({ zipCode });
  const locationOptions = React.useMemo(
    () =>
      nearbyLocations.map((location) => ({
        value: location.id,
        label: location.name,
      })),
    [nearbyLocations]
  );
  const selectedLocation = React.useMemo(
    () =>
      locationOptions.find((location) => location?.value === selectedFipsCode) ??
      locationOptions[0] ??
      null,
    [locationOptions, selectedFipsCode]
  );
  const childData: WithLocationSelectorChildrenProps = React.useMemo(
    () => ({
      fipsCode: selectedLocation?.value ?? null,
      fipsName: selectedLocation?.label ?? null,
    }),
    [selectedLocation]
  );

  if (loading) {
    return <RespondentPageLoader />;
  }

  return (
    <div className={`${baseClass} p-3`}>
      <div className="d-flex mb-3">
        <TextInput
          applyAccessibilityStyles
          ariaLabel="Enter Zip Code"
          className="mr-3 mb-0"
          horizontal
          id="pn-zip-code-location-input"
          label="Show Locations Near"
          labelClassName="text-nowrap"
          onChange={updateInProgressZipCode}
          placeholder="Zip Code"
          value={inProgressZipCode ?? queryZipCode ?? ''}
        />
        <Btn
          action={() => {
            setZipCode(inProgressZipCode);
            setInProgressZipCode(null);
          }}
          ariaLabel="Update Zip Code"
          className="text-nowrap"
          disabled={
            inProgressZipCode?.length !== 5 ||
            !inProgressZipCode ||
            inProgressZipCode === zipCode
          }
          type={ButtonTypes.PRIMARY}
        >
          Update
        </Btn>
      </div>

      <Dropdown
        ariaLabel="Select Location"
        displayInline
        id="pn-select-location-dropdown"
        label="Select Location"
        onChange={(l) => setSelectedFipsCode(l?.value ?? null)}
        options={locationOptions}
        placeholder="Select a Location"
        value={selectedLocation}
      />
      {p.children(childData)}
    </div>
  );
};
