import * as React from 'react';
import './styles.scss';
import { compact } from 'lodash';
import { ShareModal } from 'client/shared/components/share-modal';
import { UnfollowConfirm } from 'client/respondent/feed/components';
import { MaterialIconName, OptionListMenu } from 'client/shared/components/base';
import { fbShare, twitterShare } from 'client/shared/integrations';
import { isPublisherFollowed } from 'client/respondent/core';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { QueryInfos } from 'client/shared/graphql-queries';
import { SubscriptionType } from 'client/shared/graphql-client/graphql-operations.g';
import { FollowPublisherAccountPrompt } from 'client/respondent/shared/components/follow-publisher-account-prompt';

interface WithDotsActionChildrenProps {
  readonly executePublisherAction: () => Promise<void>;
  readonly executeShareModalAction: (isOpen: boolean) => void;
}

interface Props {
  readonly shareUrl: string | null;
  readonly publisherName: string;
  readonly publisherId: string;
  readonly subscriptionType: SubscriptionType | null;
  readonly respondentId: string | null;
  readonly isGuest: boolean;
  readonly children: (data: WithDotsActionChildrenProps) => JSX.Element | null;
}

export const WithDotsAction: React.FC<Props> = (p) => {
  const [showShareModal, setShowShareModal] = React.useState<boolean>(false);
  const [showUnfollowConfirm, setShowUnfollowConfirm] =
    React.useState<boolean>(false);
  const [showCreateAccountFollowPublisher, setShowCreateAccountFollowPublisher] =
    React.useState<boolean>(false);

  const handleFacebookShareQuestion = () => {
    const url = window.location.href + '?iid=' + p.respondentId;
    fbShare(url);
  };

  const handleTwitterShareQuestion = () => {
    const url = window.location.href + '?iid=' + p.respondentId;
    twitterShare(url);
  };

  const followMut = useMutationInfo(MutationInfos.followPublishingEntity, {});

  const followPublisher = async (respondentId: string | null, follow: boolean) => {
    if (!respondentId) {
      return;
    }

    return await followMut.fn({
      variables: {
        follow,
        publishingEntityId: p.publisherId,
        respondentId,
      },
      refetchQueries: [
        QueryInfos.respondentSubscriptions.refetchInfo({
          respondentId,
        }),
      ],
    });
  };

  const executePublisherAction = async () => {
    if (p.isGuest) {
      setShowCreateAccountFollowPublisher(true);
    } else if (isPublisherFollowed(p.subscriptionType)) {
      setShowUnfollowConfirm(true);
    } else {
      await followPublisher(p.respondentId, true);
    }
  };

  const executeShareModalAction = (isOpen: boolean) => {
    setShowShareModal(isOpen);
  };

  return (
    <>
      {showShareModal && p.shareUrl && (
        <ShareModal
          events={{
            facebook: {
              share: handleFacebookShareQuestion,
            },
            twitter: {
              share: handleTwitterShareQuestion,
            },
            cancel: () => setShowShareModal(false),
          }}
          isOpen
          url={p.shareUrl}
        />
      )}
      {showUnfollowConfirm && (
        <UnfollowConfirm
          events={{
            cancel: () => setShowUnfollowConfirm(false),
            unfollow: async () => {
              await followPublisher(p.respondentId, false);
              setShowUnfollowConfirm(false);
            },
          }}
          isOpen
          publisherName={p.publisherName}
        />
      )}
      {showCreateAccountFollowPublisher && (
        <FollowPublisherAccountPrompt
          onAuthenticationSuccess={async ({ respondentId }) => {
            await followPublisher(respondentId, true);
          }}
          onClose={() => setShowCreateAccountFollowPublisher(false)}
          publisherName={p.publisherName}
        />
      )}
      {p.children({
        executePublisherAction,
        executeShareModalAction,
      })}
    </>
  );
};

WithDotsAction.displayName = 'WithDotsAction';

interface DotsActionProps extends WithDotsActionChildrenProps {
  readonly publisher: string;
  readonly subscriptionType: SubscriptionType | null;
  readonly shareable: boolean;
}

const baseClass = 'pn-dot-actions';

export const DOTS_COPY = {
  follow: 'Follow',
  unfollow: 'Unfollow',
  shareVia: 'Share via...',
};

export const DotsActions: React.FC<DotsActionProps> = (p) => {
  const isFollowingPublisher = isPublisherFollowed(p.subscriptionType);

  return (
    <OptionListMenu
      className={`${baseClass}-option-list-container ml-1`}
      options={compact([
        {
          label: `${isFollowingPublisher ? DOTS_COPY.unfollow : DOTS_COPY.follow} ${
            p.publisher
          }`,
          icon: isFollowingPublisher
            ? MaterialIconName.PERSON_REMOVE_ALT_1
            : MaterialIconName.PERSON_ADD_ALT_1,
          action: p.executePublisherAction,
        },
        p.shareable
          ? {
              label: DOTS_COPY.shareVia,
              icon: MaterialIconName.SHARE,
              action: () => p.executeShareModalAction(true),
            }
          : null,
      ])}
      toggle={{
        icon: MaterialIconName.MORE_VERT,
        className: 'rounded',
      }}
    />
  );
};
