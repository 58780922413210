import * as React from 'react';
import './styles.scss';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import {
  FollowingBtn,
  FollowStatus,
} from 'client/respondent/shared/components/following-button';
import { FollowingEvent, FollowingPublisher } from 'client/respondent/core/types';
import { AppLink } from 'client/shared/components/base';
import { getInitials } from 'core';

const baseClass = 'pn-following-card';

export enum CardType {
  FOLLOWING = 'FOLLOWING',
  FOLLOW = 'FOLLOW',
}
export interface FollowingCardProps {
  readonly publisher: FollowingPublisher;
  readonly event: FollowingEvent | null;
  readonly cardType: CardType;
  readonly handleUpdateSearchResult?: () => void;
}

export const FollowingCard: React.FC<FollowingCardProps> = (props) => {
  const { publisher, event, cardType } = props;
  return (
    <div className="d-flex mx-3 my-2">
      <AppLink
        className={`${baseClass}-link flex-column flex-grow-1 px-2 py-1`}
        to={ClientUrlUtils.respondent.pubProfile.path({ slug: publisher.slug })}
      >
        <div className="d-flex flex-row align-items-center">
          <div className="mr-2">
            {publisher.seal ? (
              <img
                alt={`${publisher.name} Profile`}
                aria-hidden={true}
                className={`${baseClass}-seal`}
                height="33px"
                src={publisher.seal}
                width="33px"
              />
            ) : (
              <div
                className={`${baseClass}-name-initials text-white bg-jungle-d d-flex flex-row justify-content-center align-items-center font-weight-bold`}
              >
                {getInitials(publisher.name)}
              </div>
            )}
          </div>
          <p
            className={`${baseClass}-pub-name font-weight-bold font-size-sm ml-2 text-truncate`}
          >
            {publisher.name}
          </p>
          {publisher.location && (
            <p className="font-size-sm text-gray-40">{`${publisher.location}`}</p>
          )}
        </div>
      </AppLink>
      {event && (
        <FollowingBtn
          events={event}
          followStatus={
            cardType === CardType.FOLLOW
              ? FollowStatus.NOT_FOLLOWING
              : FollowStatus.FOLLOWING
          }
          pubId={publisher.id}
        />
      )}
    </div>
  );
};
